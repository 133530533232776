import { faStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { ReviewCompanyStoryblok, ReviewGridStoryblok } from "../../../component-types-sb"
import classNames from "../../utils/classNames"
import Container from "../general/Container"
import Image from "../general/Image"

interface Props {
  readonly blok: ReviewGridStoryblok
}

const Wrapper = tw.div`
  w-full my-4
  py-6 border-t border-b border-gray-4
  [&[data-review-grid~="hide-border"]]:(
    py-0 border-0
  )
`

const GridWrapper = tw.ul`
  max-w-container-large mx-auto
  flex flex-wrap
  justify-center
  gap-x-4 gap-y-6 w-full
`

const ReviewWrapper = tw.li`
  flex flex-col items-center justify-center
  flex[1 1 200px]
`

const Logo = tw(Image)`
  h-7 tablet:h-8
`

const TextWrapper = tw.div`
  text-yellow
  mt-1
  text-14 tablet:text-16
`

const ReviewGridNew: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <Wrapper
    data-review-grid={classNames([blok.hide_border && "hide-border"])}
  >
    <Container>
      <GridWrapper>
        {blok.review_companies.map((company) => {
          const { content } = company as { content: ReviewCompanyStoryblok }
          return <ReviewWrapper key={content._uid}>
            <div>
              <Logo image={content.logo} tw="[.section-dark-background &]:(brightness-0 invert) " />
            </div>
            <TextWrapper>
              {[...Array(5).keys()].map((i) => (
                <span key={i}>
                  <FontAwesomeIcon icon={faStar} />
                </span>
              ))}
              <span tw="text-gray-3 ml-2 [.section-dark-background &]:text-white">
                {content.rating}/5
              </span>
            </TextWrapper>
          </ReviewWrapper>
        })}
      </GridWrapper>
    </Container>
  </Wrapper>
</SbEditable>

export default ReviewGridNew
