import { Link } from "gatsby"
import React, { useMemo } from "react"
import SbEditable from "storyblok-react"
import _ from "lodash"
import { AcademyChapterStoryblok, AcademyCourseStoryblok, BlogPostStoryblok, HelpVideoStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import { rewriteSlug } from "../../utils/rewriteSlug"
import BlogNav from "../blogComponents/BlogNav"
import Container from "../general/Container"
import Image from "../general/Image"
import Section from "../general/Section"
import { H1, H5, Subtitle } from "../typography/Typography"
import useAcademyEntries from "../../hooks/useAcademyEntries"
import useLocale from "../../hooks/useLocale"
import CardList from "../general/CardList"

interface Props {
  readonly blok: AcademyCourseStoryblok
  readonly chapters?: {
    content: AcademyChapterStoryblok
    full_slug: string
  }[]
}

const AcademyCourse: React.FC<Props> = ({ blok, chapters }) => {
  const { academy_course_section, min_text, recommended_cards_title, placeholder_image } = useGlobalSettings()
  const courses = useAcademyEntries()
  const { locale } = useLocale()
  const localizedCourses = useMemo(() => courses.filter(
    (course) => course.lang === locale && course.title !== blok.title,
  ), [courses, locale, blok.title])
  const academyCourses = localizedCourses.filter((course) => {
    const content = JSON.parse(course.content) as BlogPostStoryblok | HelpVideoStoryblok | AcademyCourseStoryblok
    return content.component === "academyCourse"
  })
  const recommendedCourses = _.sampleSize(academyCourses, 3)
  return (
    <SbEditable content={blok}>
      <div tw="sticky w-full top-17.5 z-20">
        <BlogNav section="academyChapter" title={blok.title} level={1} />
      </div>
      <Container>
        <div tw="mt-16 text-center max-w-container">
          <H1>{blok.title}</H1>
          <Subtitle>{blok.subtitle}</Subtitle>
          <p tw="mt-8 text-18">{blok.intro_text}</p>
        </div>
        {chapters && chapters.length > 0 && <div tw="divide-y-2 w-full max-w-container my-20">
          {chapters.map((chapter) => <div
            key={chapter.full_slug}
            tw="hover:(shadow-hover border-transparent) focus-within:(shadow-hover border-transparent)"
          >
            <Link className="group" to={rewriteSlug(chapter.full_slug)}>
              <div tw="flex flex-col space-y-8 tablet:(flex-row items-center space-y-0) p-8 ">
                <div tw="mr-6 max-width[200px]">
                  {chapter.content.thumbnail?.filename
                    ? <Image image={chapter.content.thumbnail} />
                    : <Image image={placeholder_image} fit="cover" />}
                </div>
                <div tw="flex-1">
                  <H5 tw="mb-2 mt-0 group-hocus:text-blue">{chapter.content.title}</H5>
                  <p tw="text-14 text-gray-3 mb-2">{`${chapter.content.time_to_read} ${min_text}`}</p>
                  <p>{chapter.content.description}</p>
                </div>
              </div>
            </Link>
          </div>)}
        </div>
        }
      </Container>
      {blok.sections?.map((childBlok) => <Section blok={childBlok} key={childBlok._uid} />)}
      <Container tw="pb-16 pt-8">
        <CardList name={recommended_cards_title} cards={recommendedCourses} />
      </Container>
      <Section blok={academy_course_section[0]} lastSection />
    </SbEditable>
  )
}

export default AcademyCourse
