import React from "react"
import SbEditable from "storyblok-react"
import tw, { styled } from "twin.macro"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ModernCardStoryblok } from "../../../component-types-sb"
import Popup from "../general/Popup"
import {
  CardContainer, CardImage, CardSubtitle, CardTagContainer, CardText, CardTitle,
} from "./Card"
import Tag from "./Tag"
import LinkResolver from "./Link"

interface Props {
  blok: ModernCardStoryblok
}

const CardPopoverWrapper = styled.div(() => [tw`text-left h-[min-content]`])

const ModernCard: React.FC<Props> = ({ blok }) => {
  const Card = () => <CardContainer key={blok._uid}
    size="default"
    minheight
  >
    <CardImage image={blok.image} aspectratio="4/5" />
    {blok.subtitle && <CardSubtitle>{blok.subtitle}</CardSubtitle>}
    {blok.title && <CardTitle tw="line-clamp-2">{blok.title}</CardTitle>}
    {blok.description && <CardText tw="line-clamp-5">{blok.description}</CardText>}
    {blok.tags && <CardTagContainer>
      {blok.tags?.split(",").map((tag) => tag && (
        <Tag key={tag} tw="group-hover:shadow duration-150">
          {tag.trim()}
        </Tag>
      ))}
    </CardTagContainer>}
    {blok.url && blok.url_text && <>
      <div tw="hidden [[data-stretch-items='true'] &]:(block flex-1)" />
      <CardText>
        <LinkResolver
          url={blok.url}
          tw="flex items-center"
        >{blok.url_text} <FontAwesomeIcon icon={faArrowRight} tw="ml-2 group-hocus:(animate-bounce-x)" />
        </LinkResolver>
      </CardText>
    </>}
  </CardContainer>

  if (blok.popup && blok.popup.length === 1) {
    return (<SbEditable content={blok}>
      <CardPopoverWrapper
        as={Popup}
        blok={blok.popup[0]}
        tw="cursor-pointer"
      >
        <Card />
      </CardPopoverWrapper>
    </SbEditable>
    )
  }

  return (<SbEditable content={blok}>
    <Card />
  </SbEditable>
  )
}

export default ModernCard
