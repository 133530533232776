import React from "react"
import tw from "twin.macro"
import { PricingPlan, usePricingData } from "../../contexts/PricingDataContext"
import { isUndefined } from "../../utils/typeGuards"

const Wrapper = tw.div`
  flex flex-col items-center
  space-y-1
  text-14
  font-medium
  mb-6
`

const ButtonWrapper = tw.div`
  grid grid-flow-col
  rounded-full overflow-hidden
  bg-blue-primary-1
  p-1
  w-max
  space-x-1
  [&[data-full-width="true"]]:(
    desktop-xl:(
      w-full
      text-center
    )
  )
`

const ViewerButton = tw.button`
  w-max
  px-4 py-2
  rounded-full overflow-hidden
  bg-transparent text-blue-metallic-seaweed
  hover:bg-blue/[0.12]
  [&[data-selected="true"]]:(
    bg-blue text-white
  )
  [[data-theme='secondary'] &]:(
    hover:bg-blue-police/[0.12]
    [&[data-selected="true"]]:(
      bg-blue-police text-white
    )
  )
`

const Message = tw.p`
  px-4 py-2
  rounded-full overflow-hidden
  bg-transparent text-blue-metallic-seaweed
`

interface Props {
  readonly selectAttendees: string
  readonly plan: PricingPlan
  readonly customMessage?: string | null
}

const PricingViewerSelector: React.FC<Props> = ({ selectAttendees, plan, customMessage }) => {
  const {
    selectedPlans, setSelectedPlans, availablePlans,
  } = usePricingData()

  const plans = availablePlans[plan]

  if (!customMessage && (!plans || plans.length === 0)) return null

  const showCustomMessage = !isUndefined(customMessage) && customMessage !== null && customMessage.length > 0

  return (
    <Wrapper>
      <span tw="text-16 desktop-xl:text-14">
        {selectAttendees}
      </span>
      <ButtonWrapper data-full-width={showCustomMessage || plans.length > 3}>
        {customMessage
          ? <Message>{customMessage}</Message>
          : <>
            {plans.length === 1
              ? <ViewerButton
                as="span"
                data-selected={true}>
                {plans[0].viewers}
              </ViewerButton>
              : <>{plans.map((data, i) => <ViewerButton
                onClick={() => setSelectedPlans((prev) => ({ ...prev, [plan]: i }))}
                data-selected={selectedPlans[plan] === i}
                key={i}>
                {data.viewers}
              </ViewerButton>)}</>
            }</>}
      </ButtonWrapper>
    </Wrapper>
  )
}

export default PricingViewerSelector
