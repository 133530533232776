import React, { useRef } from "react"
import SbEditable from "storyblok-react"
import { domAnimation, LazyMotion } from "framer-motion"
import { PageStoryblok } from "../../../component-types-sb"
import PageNavChapterList from "../general/PageNavChapterList"
import PageNav from "../general/PageNav"
import Section from "../general/Section"
import FakeHeader from "../layout/FakeHeader"
import { H1 } from "../typography/Typography"
import ScrollProgress from "../blogComponents/ScrollProgress"

interface Props {
  readonly blok: PageStoryblok
}

// eslint-disable-next-line complexity
const Page: React.FC<Props> = ({ blok }) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  const usesLeftPageNav = blok.page_nav && blok.page_nav.length > 0 && blok.page_nav_type === "left"

  return (
    <LazyMotion features={domAnimation}>
      <SbEditable content={blok}>
        {blok.noRenderLayout && <FakeHeader hideTrialBtn={blok.no_free_trial_btn ?? false} />}
        {blok.page_nav && blok.page_nav.length > 0
          && (blok.page_nav_type === "topbar" || !blok.page_nav_type)
          && <PageNav pageNav={blok.page_nav} color={blok.page_nav_color} stickToTop={blok.noRenderLayout ?? false} />
        }
        {usesLeftPageNav && (
          <div tw="hidden desktop-2xl:block sticky w-full top-17.5 z-20">
            <ScrollProgress containerRef={scrollRef} />
          </div>
        )}
        <div tw="flex flex-col items-center w-full relative" ref={scrollRef}>
          {blok.page_nav && usesLeftPageNav && (
            <PageNavChapterList pageNavItems={blok.page_nav} />
          )}
          {blok.body?.map((childBlok, index) => <Section
            blok={childBlok}
            key={childBlok._uid}
            asHeader={index === 0}
            lastSection={index === blok.body.length - 1} />)}
        </div>
        {!blok.body && <H1 tw="text-center">Create a blok to get started</H1>}
      </SbEditable>
    </LazyMotion>
  )
}

export default Page
