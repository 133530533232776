import React from "react"
import { StoryblokStory } from "storyblok-generate-ts"
import {
  CustomerSuccessStoryStoryblok,
  CustomerSuccessUseCaseStoryblok,
} from "../../../component-types-sb"
import { GridComponent } from "../section/CardGrid"
import { ChildrenProp } from "../../../react"
import Container from "./Container"
import { CardContainer, CardImage, CardTagContainer, CardTitle } from "./Card"
import Tag from "./Tag"
import ClientOnly from "./ClientOnly"

export interface SuccessStory {
  content: CustomerSuccessStoryStoryblok
  full_slug: string
  uuid: string
}

interface Props {
  readonly stories: SuccessStory[]
  readonly spacing: "tight" | "normal" | "large"
  readonly width: "small" | "normal" | "wide"
  readonly columns: number
  readonly showTags?: boolean
}

export const parseUseCase = (useCase: string | undefined | StoryblokStory<CustomerSuccessUseCaseStoryblok>) => (
  useCase as { content: CustomerSuccessUseCaseStoryblok } | undefined
)

const CustomerSuccessStoriesGrid: React.FC<ChildrenProp & Props> = ({
  stories,
  spacing,
  width,
  columns,
  showTags = true,
  children,
}) => (
  <Container>
    <ClientOnly>
      <GridComponent spacing={spacing} width={width} columns={columns}>
        {stories?.map((story) => {
          if (!story) return null
          const parsedUseCase = parseUseCase(story.content.use_case)
          return (
            <CardContainer key={story.uuid} href={`/${story.full_slug}`}>
              <CardImage image={story.content.image} />
              <CardTagContainer>
                {showTags && parsedUseCase?.content?.title && (
                  <Tag>{parsedUseCase.content.title}</Tag>
                )}
              </CardTagContainer>
              <CardTitle size="small">{story.content.title ?? story.content.title}</CardTitle>
            </CardContainer>
          )
        })}
        {children}
      </GridComponent>
    </ClientOnly>
  </Container>
)

export default CustomerSuccessStoriesGrid
