import React from "react"
import SbEditable from "storyblok-react"
import { PricingCardStoryblok } from "../../../component-types-sb"
import { usePricingData } from "../../contexts/PricingDataContext"
import { getCurrencySymbol } from "../../utils/getCurrencySymbol"
import FeatureList from "../section/FeatureList"
import { H5 } from "../typography/Typography"

interface Props {
  readonly blok: PricingCardStoryblok
}

// eslint-disable-next-line complexity
const PricingCard: React.FC<Props> = ({ blok }) => {
  const { currency, yearlyBilling, getPrice, getCardPrice } = usePricingData()
  const percentageOfPrice = Number(blok.percentage_of_price)
  const prices = [blok.fixed_price_monthly, blok.fixed_price_annually]
  if (!percentageOfPrice && !(blok.fixed_price_annually && blok.fixed_price_monthly)) {
    return <p>
    Specify a percentage of the price or give both fixed prices
    </p>
  }

  const annualPlanPrice = getPrice("annual", "premium")
  const normalPlanPrice = getPrice("normal", "premium")

  if (!annualPlanPrice || !normalPlanPrice) return null

  const annualPrice = percentageOfPrice
    ? (annualPlanPrice * percentageOfPrice).toFixed(2)
    : getCardPrice(blok.fixed_price_annually)
  if (!annualPrice) return <p>Specify if blok is a percentage of total price or has fixed price</p>

  return <SbEditable content={blok}>
    <div tw="bg-white shadow px-6.25 pb-6.25 flex flex-col">
      <H5>{blok.title}</H5>
      <div tw="flex-1"><FeatureList blok={blok.feature_list[0]} /></div>
      <div tw="block tablet:flex laptop:block desktop:flex! mt-4">
        <div tw="flex-shrink-0 text-40 mr-4 font-heading">
          {getCurrencySymbol(currency)}
          {percentageOfPrice
            ? (normalPlanPrice * percentageOfPrice).toFixed(2)
            : getCardPrice(prices[yearlyBilling
              ? 1
              : 0])}
        </div>
        <div tw="text-14 text-gray-3 pt-1">
          {yearlyBilling
            ? blok.pricing_text_annually
            : blok.pricing_text_monthly
              .replace(/PRICE/u, `${getCurrencySymbol(currency)}${annualPrice}`)
              .split("\n")
              .map((text, index) => (index === 0
                ? <p key={0}>{text}</p>
                : <p key={1} tw="italic">{text}</p>))
          }
        </div>
      </div>
    </div>
  </SbEditable>
}

export default PricingCard
