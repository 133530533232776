import React, { useState } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { TabbedContentNewStoryblok } from "../../../component-types-sb"
import TabbedContentNewItem from "../general/TabbedContentNewItem"
import Image from "../general/Image"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Container from "../general/Container"
import { H2, Subtitle } from "../typography/Typography"

const Wrapper = tw.div`
  max-w-container-large w-full my-4
`

interface Props {
  readonly blok: TabbedContentNewStoryblok
}

const TabbedContentNew: React.FC<Props> = ({ blok }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)

  if (blok.items.length === 0) {
    return <SbEditable content={blok}>
      <span tw="text-red-500">Please add some items</span>
    </SbEditable>
  }
  const { width } = getImageDimensions(blok.items[selectedItemIndex].picture)

  return (
    <SbEditable content={blok}>
      <Container>
        <Wrapper>
          {blok.title && <H2>{blok.title}</H2>}
          {blok.subtitle && <Subtitle>{blok.subtitle}</Subtitle>}
          <div tw="flex flex-col justify-between items-start space-x-8 laptop:flex-row desktop:space-x-16">
            <div tw="grid grid-cols-1 gap-3 laptop:(grid-cols-2 gap-8) desktop-xl:(flex-2 grid-cols-1) ">
              {blok.items?.map((item, i) => (
                <TabbedContentNewItem
                  key={item._uid}
                  blok={item}
                  active={selectedItemIndex === i}
                  setSelectedItemIndex={setSelectedItemIndex} index={i} />
              ))}
            </div>
            <div tw="flex-3 p-10 bg-white-blue-3 hidden desktop-xl:(block p-14)">
              <div tw="relative padding-top[100%] w-full">
                <div tw="absolute inset-0 grid items-center overflow-hidden">
                  <Image
                    key={selectedItemIndex}
                    image={blok.items[selectedItemIndex].picture}
                    tw="shadow w-full"
                    fit="contain"
                    width="100%"
                    fluid={Math.min(600, width)}
                    showPlaceholder={false} />
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </Container>
    </SbEditable>
  )
}

export default TabbedContentNew
