import React, { useState, createContext, useContext, useMemo } from "react"
import { ChildrenProp } from "../../react"

interface Props {
  font: string
  setFont: React.Dispatch<React.SetStateAction<string>>
}

const FontContext = createContext<Props | null>(null)

const FontContextProvider: React.FC<ChildrenProp> = ({ children }) => {
  const [font, setFont] = useState("font-heading")

  const contextValue = useMemo(() => ({
    font,
    setFont,
  }), [font])

  return (
    <FontContext.Provider value={contextValue}>
      {children}
    </FontContext.Provider>
  )
}

export default FontContextProvider

export const useFont = (): Props => {
  const context = useContext(FontContext)
  if (!context) throw new Error("Only use in provider")
  return context
}
