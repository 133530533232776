import React, { useEffect, useState } from "react"
import SbEditable from "storyblok-react"
import "twin.macro"
import { FeatureFilterStoryblok } from "../../../component-types-sb"
import { useFeatureFilter } from "../../contexts/FeatureFilterContext"
import FilterItem from "../general/FilterItem"

interface Props {
  readonly blok: FeatureFilterStoryblok
}

const FeatureFilter: React.FC<Props> = ({ blok }) => {
  const [selectedPlan, setSelectedPlan] = useState("advanced")
  const { setSelectedPlans, setFilterEnabled } = useFeatureFilter()
  const availablePlans = [
    {
      label: blok.advanced_label,
      name: "advanced",
      selectedPlans: ["advanced", "premium", "starter"],
    },
    {
      label: blok.premium_label,
      name: "premium",
      selectedPlans: ["premium", "starter"],
    },
    {
      label: blok.starter_label,
      name: "starter",
      selectedPlans: ["starter"],
    },
  ]

  useEffect(() => {
    setSelectedPlans(["advanced", "premium", "starter"])
    setFilterEnabled(true)
  }, [setSelectedPlans, setFilterEnabled])

  return <SbEditable content={blok}>
    <div tw="flex flex-col justify-center px-4 tablet:(flex-row px-6) ">
      {availablePlans.map((plan) => <FilterItem
        name={plan.label}
        value={plan.name}
        onChange={() => {
          setSelectedPlan(plan.name)
          setSelectedPlans(plan.selectedPlans)
        }}
        checked={selectedPlan === plan.name}
        key={plan.name}
        spacing="big"
      />)}
    </div>
  </SbEditable>
}

export default FeatureFilter
