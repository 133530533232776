import React from "react"
import SbEditable from "storyblok-react"
import { AcademyCourseStoryblok, AcademyGridStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import { AcademyCard } from "../cards/AcademyCard"
import Container from "../general/Container"
import useAcademyEntries from "../../hooks/useAcademyEntries"

interface Props {
  readonly blok: AcademyGridStoryblok
}

const AcademyGrid: React.FC<Props> = ({ blok }) => {
  const courses = useAcademyEntries()
  const { locale } = useLocale()
  const localizedCourses = courses.filter((course) => course.lang === locale)

  return <SbEditable content={blok}>
    <Container>
      <div
        tw="grid w-full grid-cols-1 my-4
          laptop:grid-cols-2 desktop:grid-cols-3 gap-4 tablet:gap-8 max-w-container-large"
      >
        {localizedCourses.map((course) => {
          const content = JSON.parse(course.content) as AcademyCourseStoryblok
          return <AcademyCard
            key={course.uuid}
            content={content}
            full_slug={course.full_slug}
            duration={course.fields.duration}
          />
        })}
      </div>
    </Container>
  </SbEditable>
}

export default AcademyGrid
