import React from "react"
import SbEditable from "storyblok-react"
import { PeopleStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { ImageWrapper } from "../general/Card"
import { P } from "../typography/Typography"

interface Props {
  readonly blok: PeopleStoryblok
}

const PersonCard: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div tw="my-2 max-width[366px] mx-auto w-full">
    <ImageWrapper aspectratio="1/1" tw="rounded-full">
      {blok.photo && <Image image={blok.photo} fluid={366} />}
    </ImageWrapper>
    <div tw="mt-2">
      <P tw="text-18 text-center font-medium mb-0">{blok.name}</P>
      <P tw="text-16 text-center">{blok.function}</P>
    </div>
  </div>
</SbEditable>

export default PersonCard
