import React, { useRef } from "react"
import SbEditable from "storyblok-react"
import { Link as GatsbyLink } from "gatsby"
import { faFacebookSquare, faLinkedin, faPinterestSquare, faTwitterSquare } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useLocation } from "@reach/router"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { H1 } from "../typography/Typography"
import { AcademyChapterStoryblok, BlogPostStoryblok } from "../../../component-types-sb"
import DefaultResolver from "../blogComponents/DefaultResolver"
import Tag from "../blogComponents/Tag"
import BlogMedia from "../blogComponents/BlogMedia"
import Image from "../general/Image"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import CardList from "../general/CardList"
import BlogNav from "../blogComponents/BlogNav"
import ScrollProgress from "../blogComponents/ScrollProgress"
import Container from "../general/Container"
import BlogChapterList from "../blogComponents/BlogChapterList"
import useTags from "../../hooks/useTags"

interface Props {
  readonly blok: BlogPostStoryblok | AcademyChapterStoryblok
  readonly tag_list?: string[]
  readonly related?: {
    full_slug: string
    content: string
  }[]
  readonly previous?: {
    title: string
    full_slug: string
  }
  readonly next?: {
    title: string
    full_slug: string
  }
  readonly regexCategory?: string
}

const icons = [
  { icon: faFacebookSquare, link: "http://www.facebook.com/share.php?u=", title: "Facebook" },
  { icon: faLinkedin, link: "https://www.linkedin.com/sharing/share-offsite/?url=", title: "LinkedIn" },
  { icon: faTwitterSquare, link: "http://twitter.com/share?url=", title: "Twitter" },
  { icon: faPinterestSquare, link: "https://pinterest.com/pin/create/bookmarklet/?url=", title: "Pintrest" },
]

// eslint-disable-next-line complexity
const BlogOrChapterPost: React.FC<Props> = ({
  blok, tag_list = [], related = [], previous = null, next = null, regexCategory = "",
}) => {
  const { related_articles_text, next_courses_text, next_chapter_text, previous_chapter_text } = useGlobalSettings()
  const scrollRef = useRef<HTMLDivElement>(null)
  const { href } = useLocation()
  const tags = useTags()

  return <SbEditable content={blok} key={blok._uid}>
    <div tw="sticky w-full top-17.5 z-20">
      <BlogNav section={blok.component} regexCategory={regexCategory} title={blok.title} level={2} />
      <ScrollProgress containerRef={scrollRef} />
    </div>
    <Container>
      <div tw="bg-white w-full mx-auto pt-8 laptop:pt-10 pb-20" ref={scrollRef}>
        <div tw="max-w-blog-text mx-auto w-full">
          <H1>{blok.title}</H1>
          {tag_list.length > 0 && <div tw="flex my-8 flex-wrap">
            {tags.filter((tag) => tag_list.includes(tag.slug))
              .map((tag) => (
                <Tag key={tag.title} tag={tag} />
              ))}
          </div>}
          {blok.component === "blogPost" && <BlogMedia blok={blok} />}
          <div tw="my-8 desktop-large:hidden">
            <ul tw="text-gray-3 flex text-20 space-x-2">
              {icons.map(({ icon, link, title }) => <li key={title}>
                <a tw="hover:text-blue" href={`${link}${href}`} target="blank">
                  <span tw="sr-only">{`Share post on ${title}`}</span>
                  <FontAwesomeIcon icon={icon} />
                </a>
              </li>)}
            </ul>
          </div>
        </div>
        <div tw="block desktop-large:flex justify-center mt-8">
          <BlogChapterList content={blok.content as DocumentNode} />
          <div tw="flex-shrink-0 w-full max-w-blog-component mx-auto">
            {blok.component === "blogPost" && !blok.hide_cover_image && <div tw="w-full mb-8 max-w-blog-component">
              {blok.coverImage?.filename
                ? <Image image={blok.coverImage} fluid={820} />
                : <div tw="bg-gray-4 w-full padding-top[56%]" />
              }
            </div>}
            <div tw="flex flex-col items-center">
              <DefaultResolver content={blok.content as DocumentNode} />
            </div>
          </div>
          <div tw="flex-1 mt-4 hidden desktop-large:block">
            <ul tw="text-gray-3 text-20 space-y-1 pl-8">
              {icons.map(({ icon, link, title }) => <li key={title}>
                <a tw="hover:text-blue" href={`${link}${href}`} target="blank">
                  <span tw="sr-only">{`Share post on ${title}`}</span>
                  <FontAwesomeIcon icon={icon} />
                </a>
              </li>)}
            </ul>
          </div>
        </div>
        {blok.component === "academyChapter" && <div tw="flex justify-between space-x-4 max-w-blog-component mx-auto mt-8">
          {previous
            ? <GatsbyLink className="group" to={rewriteSlug(previous.full_slug)}>
              <div tw="text-left">
                <p tw="text-16 tablet:text-18 text-gray-2 group-hocus:text-blue">
                  <FontAwesomeIcon tw="mr-2" icon={faArrowLeft} />
                  {previous_chapter_text}
                </p>
                <p tw="text-14 tablet:text-16 text-gray-3">{previous.title}</p>
              </div>
            </GatsbyLink>
            : <div />}
          {next
            ? <GatsbyLink className="group" to={rewriteSlug(next.full_slug)}>
              <div tw="text-right">
                <p tw="text-16 tablet:text-18 text-gray-2 group-hocus:text-blue">
                  {next_chapter_text}
                  <FontAwesomeIcon tw="ml-2" icon={faArrowRight} />
                </p>
                <p tw="text-14 tablet:text-16 text-gray-3">{next.title}</p>
              </div>
            </GatsbyLink>
            : <div />}
        </div>}
      </div>
      {related.length > 0 && <CardList name={blok.component === "blogPost"
        ? related_articles_text
        : next_courses_text} cards={related} />}
    </Container>
    <div tw="mb-20" />
  </SbEditable>
}

export default BlogOrChapterPost
