import React from "react"
import { AllCustomerSuccessStoriesStoryblok, CustomerSuccessStoryStoryblok } from "../../../component-types-sb"
import { CustomerSuccessStoryCard } from "./CustomerSuccessStoryCard"

interface Props {
  readonly blok: AllCustomerSuccessStoriesStoryblok
}

const AllCustomerSuccessStories: React.FC<Props> = ({ blok }) => {
  const stories = blok.success_stories as { content: CustomerSuccessStoryStoryblok, full_slug: string, uuid: string }[]
  return (
    <div tw="space-y-16 my-8">
      {stories?.map((story) => {
        if (!story) return null
        return <CustomerSuccessStoryCard key={story.uuid} full_slug={story.full_slug} content={story.content} />
      })}
    </div>
  )
}

export default AllCustomerSuccessStories
