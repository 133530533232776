import React from "react"
import SbEditable from "storyblok-react"
import { IconStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { ButtonVariant } from "./Button"

interface Props {
  blok: IconStoryblok
  variant?: ButtonVariant
}

const iconVariants: Record<ButtonVariant, string> = {
  blue: "invert(100%) brightness(1000%)",
  "light-blue": "",
  orange: "invert(100%) brightness(1000%)",
  text: "",
  white: "",
  "white-blue": "",
  "white-no-border": "",
}

const Icon: React.FC<Props> = ({ blok, variant }) => (
  <SbEditable content={blok}>
    <Image
      width="20px"
      height="20px"
      style={{ filter: variant && iconVariants[variant] }}
      image={blok.icon_svg}
    />
  </SbEditable>
)

export default Icon
