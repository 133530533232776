import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import tw from "twin.macro"
import { ChildrenProp } from "../../../react"

interface Props {
  readonly color: "yellow" | "blue"
}

const AlertBox = tw.div`
  flex items-baseline justify-center
  p-4
  border rounded-md
  text-14 font-normal text-left
  [&[data-alert-color="blue"]]:(
    bg-white-blue-2 border-blue
    [svg]:text-blue
    )
  [&[data-alert-color="yellow"]]:(
    bg-yellow-light border-yellow
    [svg]:text-yellow
  )
`

const Alert: React.FC<ChildrenProp & Props> = ({ color, children }) => <AlertBox data-alert-color={color}>
  <div tw="mr-2">
    <FontAwesomeIcon icon={faInfoCircle} />
  </div>
  {children}
</AlertBox>

export default Alert
