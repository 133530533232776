import React, { ComponentPropsWithoutRef } from "react"
import tw from "twin.macro"
import { isUndefined } from "../../utils/typeGuards"
import Radio from "./Radio"

interface FilterItemProps extends ComponentPropsWithoutRef<"input"> {
  readonly name: string
  readonly spacing: "big" | "small"
  readonly type?: "radio" | "checkbox"
}

export const Wrapper = tw.label`
  my-2
  inline-flex items-center
  hover:(
    cursor-pointer
  )
  [&[data-spacing="big"]]:mx-6
  [&[data-spacing="small"]]:mx-2
`

export const HiddenInput = tw.input`
  absolute
  w-0 h-0
  opacity-0
`

const FilterItem: React.FC<FilterItemProps> = ({
  name,
  spacing,
  children,
  type = "radio",
  ...rest
}) => (
  <Wrapper className="group" key={name} data-spacing={spacing}>
    <HiddenInput className="peer" type={type} {...rest} />
    {isUndefined(children)
      ? <Radio name={name} />
      : children}
  </Wrapper>
)

export default FilterItem
