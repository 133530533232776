import { graphql, PageProps } from "gatsby"
import React from "react"
import { getPlainText, Richtext } from "@storyofams/storyblok-toolkit"
import { Helmet } from "react-helmet"
import useStoryblok from "../hooks/useStoryblok"
import { AcademyChapterStoryblok } from "../../component-types-sb"
import BlogOrChapterPost from "../components/templates/BlogOrChapterPost"
import BaseTemplate from "../components/general/BaseTemplate"
import { GQLStory } from "../lib/baseStoryTemplate"

interface GQLResult extends GQLStory {
  courses: {
    nodes: {
      full_slug: string
      content: string
    }[]
  }
}

interface PageContext {
  slug: string
  next: {
    title: string
    full_slug: string
  }
  previous: {
    title: string
    full_slug: string
  }
  regexSlug: string
}

const AcademyChapterTemplate: React.FC<PageProps<GQLResult, PageContext>> = ({ data, location, pageContext }) => {
  const { content } = useStoryblok(data.story, location) as { content: AcademyChapterStoryblok }

  // Only show 3 random courses and no this current course
  const regex = new RegExp(pageContext.regexSlug.slice(1, pageContext.regexSlug.length - 3), "u")
  const courses = data.courses.nodes
    .filter((course) => !regex.test(course.full_slug))
    .sort(() => Math.random() - 0.5)
    .slice(0, 3)

  const richResult = {
    "@context": "https://schema.org",
    "@type": "Article",
    about: content.description,
    dateModified: data.story.published_at,
    datePublished: data.story.first_published_at,
    headline: content.title,
    image: content.thumbnail.filename,
    name: content.title,
    text: getPlainText(content.content as Richtext),
    url: data.story.full_slug,
  }

  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(richResult)}</script>
    </Helmet>
    <BlogOrChapterPost blok={content} previous={pageContext.previous} next={pageContext.next} related={courses} regexCategory={pageContext.regexSlug} />
  </BaseTemplate>
}

export default AcademyChapterTemplate

export const query = graphql`
  query AcademyChapterTemplate($slug: String!, $lang: String){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
    courses: allStoryblokEntry(
      filter:{
        field_component: {eq: "academyCourse"},
        lang: {eq: $lang}
      }
    ) {
      nodes {
        full_slug
        content
      }
    }
  }
`
