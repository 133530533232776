import { Dialog, Transition } from "@headlessui/react"
import React, { Fragment, useRef } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { useMobileHeader } from "../../../contexts/MobileHeaderContext"
import LinkResolver from "../../general/Link"
import { ChildrenProp } from "../../../../react"
interface Props {
  readonly open: boolean
}

const MobileMenuTransition = styled(Transition.Child)(() => [
  tw`w-screen h-full overflow-y-auto bg-white`,
  {
    "&.enter": tw`transition duration-500 ease-in-out transform tablet:duration-700`,
    "&.enterFrom": tw`-translate-x-full`,
    "&.enterTo": tw`translate-x-0`,
    "&.leave": tw`transition duration-500 ease-in-out transform tablet:duration-700`,
    "&.leaveFrom": tw`translate-x-0`,
    "&.leaveTo": tw`-translate-x-full`,
  },
])

const MobileHeaderTransition: React.FC<ChildrenProp & Props> = ({ children, open }) => {
  const initialRef = useRef(null)
  const { content, closeAll } = useMobileHeader()

  return <Transition.Root show={open} as={Fragment}>
    <Dialog initialFocus={initialRef} as={Fragment} open={open} onClose={closeAll}>
      <div tw="fixed inset-0 overflow-hidden z-40">
        <Dialog.Overlay tw="absolute inset-0 content" />
        <div tw="fixed height[70px] flex justify-between items-center inset-x-0 top-0">
          <LinkResolver tw="ml-4 tablet:ml-6" url={content.home_link} onClick={closeAll} aria-label="home">
            <div tw="width[200px] h-10" />
          </LinkResolver>
          <button ref={initialRef} tw="height[70px] width[70px] mr-3.5" onClick={closeAll} aria-label="close" />
        </div>
        <div tw="fixed left-0 top-17.5 max-w-full flex h-full">
          <MobileMenuTransition
            enter="enter"
            enterFrom="enterFrom"
            enterTo="enterTo"
            leave="leave"
            leaveFrom="leaveFrom"
            leaveTo="leaveTo"
          >
            {children}
          </MobileMenuTransition>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
}

export default MobileHeaderTransition
