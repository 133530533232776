import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { ImageRowStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Image from "../general/Image"

interface Props {
  readonly blok: ImageRowStoryblok
}

const ImageWrapper = styled.div(() => [
  tw`w-full m-4`,
  {
    ".storyblok-image-wrapper": tw`mx-auto`,
  },
])

const ImageRow: React.FC<Props> = ({ blok }) => {
  const maxImageWidth = 820 / blok.images.length
  return <SbEditable content={blok}>
    <div tw="flex flex-wrap laptop:(flex-nowrap) mb-4 justify-center -mx-4">
      {blok.images?.map((image) => {
        const { width } = getImageDimensions(image)
        return <ImageWrapper key={image.id}>
          <Image tw="mx-auto" image={image} fluid={width < maxImageWidth
            ? width
            : maxImageWidth}
          fit="contain" {...(width < maxImageWidth && { width })} />
        </ImageWrapper>
      })}
    </div>
  </SbEditable>
}

export default ImageRow
