import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { A } from "../typography/Typography"
import {
  VirtualBackgroundSettingsStoryblok,
  VirtualBackgroundTagStoryblok,
} from "../../../component-types-sb"
import useLocale, { Locale } from "../../hooks/useLocale"

import LinkResolver from "../general/Link"
import { isUndefined } from "../../utils/typeGuards"
import useBackgroundEntries from "../../hooks/useBackgroundEntries"
import { useVirtualBackgroundSettings } from "../../contexts/VirtualBackgroundSettingsContext"
import { HowToContainer } from "./VirtualBackgroundComponents"
import BackgroundCard from "./BackgroundCard"
import { Title } from "./ExploreBackgroundsSection"

interface ComponentProps {
  readonly title_size?: "smaller"
  readonly tag?: {
    content: VirtualBackgroundTagStoryblok
  }
}

export interface Tag {
  name: string
  created_at: string
  id: number
  uuid: string
  content: Content
  slug: string
  full_slug: string
  position: number
  is_startpage: boolean
  parent_id: number
  group_id: string
  lang: string
  path: string
  default_full_slug: string
  translated_slugs: TranslatedSlug[]
  _stopResolving: boolean
}

export interface Content {
  _uid: string
  title: string
  component: string
  _editable: string
}

export interface TranslatedSlug {
  path: string
  name: string | null
  lang: string
}

const addTagTitleToText = (text: string | undefined, tag: ComponentProps["tag"]) => (
  text?.replace("{{tagTitle}}", isUndefined(tag)
    ? ""
    : tag.content.title.toLowerCase()).replace("  ", " ") ?? ""
)

const getSlug = (locale: Locale, tag: Tag, isFiltered: boolean) => {
  if (isFiltered) {
    if (locale === "default") {
      return tag.full_slug
    }
    const tagInfo = tag.translated_slugs.find((value) => value.lang === locale)
    return tagInfo?.path ?? null
  }
  return null
}

interface ButtonProps {
  readonly buttonText: string
  readonly slug: string | null
  readonly indexUrl: VirtualBackgroundSettingsStoryblok["more_slug"] | undefined
}

const MoreButton = (props: ButtonProps) => props.slug === null
  ? (
    <LinkResolver
      url={props.indexUrl}
      tw="text-orange text-18 font-medium mt-14 font-heading flex items-center gap-1.875 tracking-[0.9px]"
    >
      {props.buttonText} <FontAwesomeIcon icon={faChevronRight} tw="text-12" />
    </LinkResolver>
  )
  : (
    <A href={`/${props.slug}`}
      tw="text-orange text-18 font-medium mt-14 font-heading flex items-center gap-1.875 tracking-[0.9px]">
      {props.buttonText} <FontAwesomeIcon icon={faChevronRight} tw="text-12" />
    </A>
  )

const MoreBackgroundsSection = (props: ComponentProps) => {
  const { locale } = useLocale()
  const backgrounds = useBackgroundEntries()
  const {
    more_button_text, more_title, more_slug,
  } = useVirtualBackgroundSettings()

  const title = addTagTitleToText(more_title, props.tag)

  const filteredBackgrounds = backgrounds.filter((bg) => {
    const matchesLang = bg.lang === locale
    if (isUndefined(props.tag)) return matchesLang
    const tag = bg.content.tag as unknown as Tag
    const matchesTagIfDefined = (
      isUndefined(props.tag.content) || tag.content.title === props.tag.content.title
    )
    const matchesTitleTag = tag.content.title === props.tag.content.title
    return matchesLang && matchesTagIfDefined && matchesTitleTag
  })

  const featuredBackgrounds = filteredBackgrounds.slice(0, 4)
  const slug = featuredBackgrounds.length > 0
    ? getSlug(locale, featuredBackgrounds[0].content.tag as unknown as Tag, !isUndefined(props.tag))
    : null

  return (
    <HowToContainer>
      <Title
        data-text-size={props.title_size}
      >
        {title}
      </Title>
      <div
        tw="grid desktop:grid-cols-4 tablet:grid-cols-2 laptop:grid-cols-3 gap-x-10 text-left w-full max-w-[74.5rem]"
        className={featuredBackgrounds.length >= 3
          ? "hide-last"
          : ""}
      >
        {featuredBackgrounds.map((bg) => <BackgroundCard background={bg} key={bg.content._uid} />)}
      </div>
      <MoreButton buttonText={more_button_text} slug={slug} indexUrl={more_slug} />
    </HowToContainer>
  )
}

export default MoreBackgroundsSection
