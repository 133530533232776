import React from "react"
import SbEditable from "storyblok-react"
import { useLocation } from "@reach/router"
import { AppInfoPageStoryblok } from "../../../component-types-sb"
import { H1, Subtitle } from "../typography/Typography"
import Button from "../general/Button"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Image from "../general/Image"
import ClientOnly from "../general/ClientOnly"

interface Props {
  readonly blok: AppInfoPageStoryblok
}

const AppInfoPage: React.FC<Props> = ({ blok }) => {
  const location = useLocation()
  const { site_url } = useGlobalSettings()
  const rand = Math.floor(Math.random() * blok.variants.length)
  // If inside the Storyblok editor only show the first one so it can be easily edited
  const randomBlok = blok.variants[location.search.includes("_storyblok")
    ? 0
    : rand]
  const [button] = randomBlok.button

  let buttonLink = null
  if (button.url?.linktype === "story" && button.url.story?.full_slug) {
    buttonLink = `${site_url}${rewriteSlug(button.url.story?.full_slug)}`
  }
  if (button.url?.linktype === "url" && button.url.url) buttonLink = button.url.url

  return <SbEditable content={blok}>
    <div tw="h-screen w-full flex flex-col">
      <div tw="flex-1 bg-white-blue-2 pt-20  overflow-hidden flex flex-col justify-between relative">
        <ClientOnly>
          <div tw="px-16 mb-8">
            <div tw="py-2.5 px-6 bg-blue text-white w-fit rounded-full">{randomBlok.tag}</div>
            <H1>{randomBlok.title}</H1>
            <Subtitle tw="max-width[500px]">{randomBlok.description}</Subtitle>
            {buttonLink
              && <Button as="a" tw="no-underline w-fit" href={buttonLink}
                target="_blank" rel="noreferrer" variant={button.variant} size={button.size}>
                {button.text}
              </Button>}
          </div>
          <div tw="flex-1 self-end flex flex-col justify-end pb-16">
            <div tw="relative">
              <div tw="absolute inset-0 w-3/5 my-6 bg-white-blue-3" />
              <div tw="absolute right-0 top-0 bottom-0 w-3/5 my-11 bg-white" />
              <div tw="object-contain ml-8 mr-6 my-16 relative">
                <Image image={randomBlok.image} fluid={250} width="250px" />
              </div>
              <div tw="absolute top-0 right-0 bg-blue h-20 w-20" />
              <Image image={blok.flowers} tw="absolute -bottom-4 -left-16" />
            </div>
          </div>
        </ClientOnly>
      </div>
    </div>
  </SbEditable>
}

export default AppInfoPage
