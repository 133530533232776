import React from "react"
import SbEditable from "storyblok-react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { GlossaryKeywordStoryblok } from "../../../component-types-sb"
import DefaultResolver from "../blogComponents/DefaultResolver"
import Image from "../general/Image"
import GlossaryItem from "./GlossaryItem"

interface Props {
  readonly blok: GlossaryKeywordStoryblok
}

const GlossaryKeyword: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <div tw="flex-shrink-0 w-full max-w-blog-component mx-auto px-4">
      <div tw="my-20">
        <GlossaryItem title={blok.title} description={blok.description} tag={blok.title[0].toUpperCase()} />
      </div>

      <Image image={blok.header_image} tw="rounded-lg" />
      <div tw="flex flex-col items-center mt-12 mb-12">
        <DefaultResolver content={blok.content as DocumentNode} />
      </div>
    </div>

  </SbEditable>
)

export default GlossaryKeyword
