import { useEffect, useState } from "react"
import { ZapierResponse } from "../api/getZapierResults"

export const useZapierSearch = (query: string) => {
  const [results, setResults] = useState<ZapierResponse>({ data: null, error: null })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const res = await fetch(`/api/getZapierResults?q=${query}`)
      const data = await res.json()
      setResults(data)
      setLoading(false)
    }
    fetchData()
  }, [query])

  return { ...results, loading }
}
