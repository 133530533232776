import React, { useState } from "react"
import SbEditable from "storyblok-react"
import { CompanyStoryblok, ComparsionTableStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Image from "../general/Image"
import LinkResolver from "../general/Link"
import useLocale from "../../hooks/useLocale"
import { P } from "../typography/Typography"
import TableCategory from "../general/TableCategory"
import useGoogleSheets from "../../hooks/useGoogleSheets"

interface Props {
  readonly blok: ComparsionTableStoryblok
}

interface Globals {
  update_table_link_text: string
  companies: {
    slug: string
    content: CompanyStoryblok
  }[]
  table_updated_text: string
}

interface TBody {
  _uid: string
  body: {
    _uid: string
    value: string
  }[]
}

interface SortedArray {
  _uid: string
  value: string
}

// Sorts the rows according to an index array but after the first two indexes
// eg sortByIndex([0,1,2,3,4,5], [4,3,2,5]) => [0,1,4,3,2,5]
export const sortByIndex = (array: { _uid: string, value: string }[], indexes: number[]): SortedArray[] => {
  const newArray = [array[0], array[1]]
  indexes.forEach((index) => newArray.push(array[index]))
  return newArray
}

const ComparisonTable: React.FC<Props> = ({ blok }) => {
  const { companies, table_updated_text, update_table_link_text } = useGlobalSettings() as unknown as Globals

  const { locale } = useLocale()

  const { data: comparisonTable, loading, error } = useGoogleSheets(locale)
  const [openCategoryIndex, setOpenCategoryIndex] = useState(0)

  if (loading || !comparisonTable || error) return <p tw="hidden laptop:block">Loading...</p>

  if (blok.companies.length !== 6) return <p>Please select six companies to compare</p>
  const compareCompanies = blok.companies as { slug: string }[]

  // Reduce method for generating categories
  let currentCatObject = {} as { name: string, rows: TBody[] }
  const newCats = comparisonTable.tbody.reduce((a: { name: string, rows: TBody[] }[], row, index) => {
    if (row.body[1].value === "Category" || row.body[1].value === "Kategorie") {
      // Push the previous category object if it's defined
      if (typeof currentCatObject.name !== "undefined") a.push(currentCatObject)
      // Create a new category object with the new category name
      currentCatObject = { name: row.body[0].value, rows: [] }
    } else {
      // Add the row to the list of rows on the current category
      currentCatObject.rows.push(row)
    }
    // On the last index, add the final category
    if (index === comparisonTable.tbody.length - 1) a.push(currentCatObject)
    return a
  }, [])
  const indexes = [...Array(6).keys()].map((i) => (
    comparisonTable.thead.findIndex((x) => x.value === compareCompanies[i].slug)))

  return <SbEditable content={blok}>
    <div tw="w-full px-6 hidden laptop:block">
      <div tw="my-8 overflow-x-auto mx-auto">
        <table tw="mx-auto border-separate border-spacing[0]">
          <thead>
            <tr>
              <th tw="bg-white sticky left-0 bg-clip-padding z-10" />
              {sortByIndex(comparisonTable.thead, indexes).map((item, i) => {
                if (i < 2) return null
                const company = companies.find((x) => x.slug === item.value)
                if (company) {
                  return <th tw="px-3" key={item._uid}>
                    <Image image={company.content.logo} useRegularImageTag width="144px" />
                  </th>
                }
                return <th key={item._uid}>{item.value} not found</th>
              })}
            </tr>
          </thead>
          {newCats.map((category, i) => <TableCategory
            key={category.name}
            category={category}
            indexes={indexes}
            isOpen={i === openCategoryIndex}
            openCategory={() => setOpenCategoryIndex(i)}
            closeCategory={() => setOpenCategoryIndex(-1)}
            isFirst={i === 0}
          />)}
        </table>
      </div>
    </div>
    <P tw="text-center hidden laptop:block">
      {table_updated_text} &nbsp;&mdash;&nbsp;
      <LinkResolver styling="none" url={{ linktype: "url", url: "openIntercom" }}>
        <span tw="underline font-light">{update_table_link_text}</span>
      </LinkResolver>
    </P>
  </SbEditable>
}

export default ComparisonTable
