import React, { Suspense } from "react"
import SbEditable from "storyblok-react"
import { NativeFormStoryblok } from "../../../component-types-sb"
import Spinner from "../general/Spinner"

// No types for this file
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const NativeForms = React.lazy(() => import("native-forms-react"))

interface Props {
  readonly blok: NativeFormStoryblok
}

const NativeForm: React.FC<Props> = ({ blok }) => {
  if (typeof window === "undefined") return null
  return <SbEditable content={blok}>
    <Suspense fallback={<div tw="flex justify-center w-full my-8"><Spinner size="large" /></div>}>
      <NativeForms form={`${blok.form_url}?website=${window.location.href}&title=${window.document.title}`} />
    </Suspense>
  </SbEditable>
}

export default NativeForm
