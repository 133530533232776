import { faSearch, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dialog, Transition } from "@headlessui/react"
import React, { useEffect, useRef, useState } from "react"
import algoliasearch from "algoliasearch"
import styled from "styled-components"
import tw from "twin.macro"
import { P } from "../typography/Typography"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useLocale from "../../hooks/useLocale"
import Spinner from "../general/Spinner"
import SearchItem from "./SearchItem"

const client = algoliasearch(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  process.env.GATSBY_ALGOLIA_APP_ID!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  process.env.GATSBY_ALGOLIA_SEARCH_KEY!,
)

export interface AlgoliaResult {
  objectID: string
  name: string
  description: string
  body: string
  timeToRead: string
  datePublished: string
  coverImage: {
    alt?: string
    copyright?: string
    id: number
    filename: string
    name: string
    title?: string
  }
  full_slug: string
  author: string
  _highlightResult: {
    title: {
      value: string
    }
    description: {
      value: string
    }
  }
}

const TransitionOverlay = styled(Transition.Child)(() => [
  {
    "&.enter": tw`duration-200 ease-in`,
    "&.enterFrom": tw`opacity-0`,
    "&.enterTo": tw`opacity-100`,
    "&.leave": tw`duration-200 ease-in`,
    "&.leaveFrom": tw`opacity-100`,
    "&.leaveTo": tw`opacity-0`,
  },
])

const TransitionModal = styled(Transition.Child)(() => [
  tw`
    flex flex-col
    text-left
    transition-all transform
    bg-white
    rounded-lg
    shadow-xl
    w-full max-w-container
    max-height[calc(100vh - 56px)]
    mx-auto
    my-8 p-6
  `,
  {
    "&.enter": tw`duration-300 ease-out`,
    "&.enterFrom": tw`opacity-0 translate-y-20 tablet:(translate-y-20 scale-95)`,
    "&.enterTo": tw`translate-y-0 opacity-100 tablet:scale-100`,
    "&.leave": tw`duration-200 ease-in`,
    "&.leaveFrom": tw`translate-y-0 opacity-100 tablet:scale-100`,
    "&.leaveTo": tw`opacity-0 translate-y-20 tablet:(translate-y-20 scale-95)`,
  },
])

const SearchContainer = styled.div(() => [
  tw`flex-1 overflow-y-auto`,
  {
    "::-webkit-scrollbar": tw`w-2`,
    "::-webkit-scrollbar-thumb": tw`rounded-full`,
    "::-webkit-scrollbar-track": tw`rounded-full`,
  },
])

interface Props {
  readonly section: "blogPost" | "academyChapter"
}

const Search: React.FC<Props> = ({ section }) => {
  const { locale } = useLocale()
  const index = client.initIndex(`storyblok-${section}-${locale}`)
  const indexRef = useRef(index)

  const [open, setOpen] = useState(false)
  const [results, setResults] = useState<AlgoliaResult[]>([])
  const [searchTerm, setSearchTerm] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const latestSearchTerm = useRef(searchTerm)
  const searchInputRef = useRef(null)

  const { search_for_articles_text, no_results_text, try_searching_text }
    = useGlobalSettings()

  useEffect(() => {
    latestSearchTerm.current = searchTerm
    setIsSearching(true)
    const search = () => {
      indexRef.current
        .search(searchTerm, {
          hitsPerPage: 8,
        })
        .then(({ hits }) => {
          const algoliaHits = hits as unknown as AlgoliaResult[]
          setResults(algoliaHits)
        })
        .catch((err: unknown) => {
          console.error(err)
        })
        .finally(() => setIsSearching(false))
    }
    if (searchTerm.length === 0) {
      setResults([])
      setIsSearching(false)
    } else {
      setTimeout(() => {
        // Only commense the search if input hasn't chnage in half a second
        if (latestSearchTerm.current === searchTerm) search()
      }, 500)
    }
  }, [searchTerm])

  return (
    <>
      <button
        tw="text-gray-2 hocus:text-blue py-5 flex items-baseline px-0.5"
        onClick={() => setOpen(true)}
        aria-label="search"
      >
        <FontAwesomeIcon icon={faSearch} />
        <span tw="ml-2 hidden laptop:inline">{search_for_articles_text}</span>
      </button>
      <Transition.Root show={open} as={React.Fragment}>
        <Dialog
          as="div"
          static
          open={open}
          onClose={() => setOpen(false)}
          initialFocus={searchInputRef}
        >
          <div tw="fixed inset-0 z-40 flex flex-row items-start justify-center min-h-screen px-4 text-center ">
            <TransitionOverlay
              enter="enter"
              enterFrom="enterFrom"
              enterTo="enterTo"
              leave="leave"
              leaveFrom="leaveFrom"
              leaveTo="leaveTo"
            >
              <Dialog.Overlay tw="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </TransitionOverlay>
            <TransitionModal
              enter="enter"
              enterFrom="enterFrom"
              enterTo="enterTo"
              leave="leave"
              leaveFrom="leaveFrom"
              leaveTo="leaveTo"
            >
              <button
                tw="absolute top-4 right-4 px-2 text-24 hocus:text-blue"
                aria-label="close-search"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                tw="w-full shadow-hover text-23 py-2 px-4 mt-8 mb-4 rounded-[5px] border border-gray-4"
                placeholder={search_for_articles_text}
                ref={searchInputRef}
              />
              {isSearching
                ? (
                  <div tw="h-10 flex w-full justify-center">
                    <Spinner size="large" />
                  </div>
                )
                : (
                  <SearchContainer>
                    {results.length > 0
                      ? (
                        <ul tw="divide-y p-2">
                          {results.map((item) => {
                            if (!item) return null
                            return <SearchItem item={item} key={item.objectID} />
                          })}
                        </ul>
                      )
                      : (
                        <P tw="py-4">
                          {searchTerm
                            ? `${no_results_text}"${searchTerm}"`
                            : try_searching_text}
                        </P>
                      )}
                  </SearchContainer>
                )}
            </TransitionModal>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Search
