import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import SbEditable from "storyblok-react"
import { faClock } from "@fortawesome/pro-regular-svg-icons"
import styled from "styled-components"
import tw from "twin.macro"
import { SmallTag } from "../blogComponents/Tag"
import { getDuration } from "../section/AcademyWrapper"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Image from "../general/Image"
import { H5 } from "../typography/Typography"
import {
  AcademyCardStoryblok,
  AcademyChapterStoryblok,
  AcademyCourseStoryblok,
  AcademyTagStoryblok,
} from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import CardBase from "./CardBase"

interface Props {
  readonly blok: AcademyCardStoryblok
}

interface CardProps {
  readonly content: AcademyCourseStoryblok
  readonly full_slug: string
  readonly duration?: number | null
}

const StyledCardBase = tw(CardBase)`
  absolute inset-0
  z-index[-1]
  [[data-highlighted="true"] &]:bg-blue-baby
`

const ImageWrapper = styled.div(() => [
  tw`relative padding-top[57%] w-full overflow-hidden`,
  {
    ".storyblok-image-wrapper": tw`absolute! w-full inset-0`,
  },
])

const StyledLink = tw(Link)`
  flex flex-col items-center
  h-full
  text-left
  relative
  transition-card duration-300
`

const TagWrapper = tw.div`
  flex flex-wrap
  justify-start items-start
  border-t border-gray-5
  w-full
  text-14
  mt-8 pt-1.5
  [[data-highlighted="true"] &]:(
    border-body border-opacity-20
  )
`

const TimeWrapper = tw.div`
  text-gray-3
  text-14 leading-6
  my-1
  flex-shrink-0
  [[data-highlighted="true"] &]:(
    text-body opacity-70
  )
`

const TagWithHighlight = tw(SmallTag)`
  mr-2 my-1
  last:mr-0
  [[data-highlighted="true"] &]:(
    bg-white text-body
  )
`

const TitleWithHighlight = tw(H5)`
  w-full
  group-hocus:text-blue
  [[data-highlighted="true"].group:hover &, [data-highlighted="true"].group:focus &]:(
    text-body
  )
`

export const AcademyCard: React.FC<CardProps> = ({ content, full_slug, duration }) => {
  const { min_text, placeholder_image } = useGlobalSettings()
  const { chapters } = content as { chapters: { content: AcademyChapterStoryblok }[] }
  const getCourseDuration = () => {
    if (content.duration) {
      return Number(content.duration)
    }
    if (chapters) {
      return chapters.reduce((a: number, { content: chapter }) => (
        a + parseInt(chapter?.time_to_read?.toString() || "0", 10)
      ), 0)
    }
    return 0
  }

  const tags = content.tags as { content: AcademyTagStoryblok }[]

  const courseDuration = getDuration(duration) ?? getCourseDuration()

  return <StyledLink
    data-highlighted={content.highlight_card}
    className="group"
    to={rewriteSlug(full_slug)}
  >
    <StyledCardBase>
      <ImageWrapper>
        <Image image={content.cover_image?.filename
          ? content.cover_image
          : placeholder_image} fluid={464} height="100%" fit="cover" />
      </ImageWrapper>
    </StyledCardBase>
    <div tw="w-full flex flex-col flex-grow p-4 pb-3 margin-top[57%]">
      <TitleWithHighlight>{content.title}</TitleWithHighlight>
      {courseDuration > 0 && <TimeWrapper>
        <span tw="mr-1">
          <FontAwesomeIcon icon={faClock} />
        </span>
        {courseDuration}
        <span tw="ml-1">{min_text}</span>
      </TimeWrapper>
      }
      <p tw="flex-1 w-full">{content.subtitle}</p>
      <TagWrapper>
        {tags && tags.length > 0
          ? tags.map((tag, i) => <TagWithHighlight key={i}>{tag.content.title}</TagWithHighlight>)
          : null
        }
      </TagWrapper>
    </div>
  </StyledLink>
}

const AcademyCardEditable: React.FC<Props> = ({ blok }) => {
  const { content, full_slug } = blok.course as unknown as { content: AcademyCourseStoryblok, full_slug: string }
  return <SbEditable content={blok}>
    {content
      ? <AcademyCard content={content} full_slug={full_slug} />
      : <div tw="text-center">Select a course</div>}
  </SbEditable>
}

export default AcademyCardEditable
