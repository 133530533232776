import React from "react"
import SbEditable from "storyblok-react"
import { PricingPageStoryblok } from "../../../component-types-sb"
import PricingDataProvider from "../../contexts/PricingDataContext"
import Section from "../general/Section"
import { H1 } from "../typography/Typography"

interface Props {
  readonly blok: PricingPageStoryblok
}

const PricingPage: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <PricingDataProvider pricingData={blok.pricing_data}>
      <div tw="flex flex-col items-center w-full">
        {blok.body?.map((childBlok, index) => <Section
          blok={childBlok}
          key={childBlok._uid}
          asHeader={index === 0}
          lastSection={index === blok.body.length - 1}
        />)}
      </div>
      {!blok.body && <H1 tw="text-center">Create a blok to get started</H1>}
    </PricingDataProvider>
  </SbEditable>
)

export default PricingPage
