import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons"
import LinkButton from "../../general/LinkButton"
import { HeaderDropdownOrLinkStoryblok } from "../../../../component-types-sb"
import { useMobileHeader } from "../../../contexts/MobileHeaderContext"
import LinkResolver from "../../general/Link"
import MobileCategoryLinks from "./MobileCategoryLinks"
import MobileHeaderTransition from "./MobileHeaderTransition"

interface Props {
  readonly section: HeaderDropdownOrLinkStoryblok
}

const MobileCategoryMenu: React.FC<Props> = ({ section }) => {
  const { closeAll, closeSubmenuRef } = useMobileHeader()
  const [open, setOpen] = useState(false)
  const [categoryOpen, setCategoryOpen] = useState(-1)
  useEffect(() => {
    if (open) closeSubmenuRef.current = () => setOpen(false)
    if (!open) closeSubmenuRef.current = () => null
  }, [open, closeSubmenuRef])

  useEffect(() => {
    if (!open) setCategoryOpen(-1)
  }, [open])

  return (
    <button
      className="group"
      tw="flex items-center justify-between cursor-pointer px-4
        tablet:px-6 py-5 w-full focus:outline-none focus-visible:outline-black"
      onClick={() => setOpen(true)}
    >
      <span tw="group-hocus:text-blue">{section.name}</span>
      <div tw="group-hocus:text-blue">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
      <MobileHeaderTransition open={open}>
        <div tw="divide-y divide-gray-5 pt-2">
          <button
            className="group"
            tw="flex items-center justify-between cursor-pointer w-full px-4
              tablet:px-6 focus:outline-none focus-visible:outline-black py-5"
            onClick={() => setOpen(false)}
          >
            <span tw="group-hocus:text-blue">{section.name}</span>
            <div tw="group-hocus:text-blue">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </button>
          {section.main_links.map(({ text, url }) => <LinkResolver
            url={url}
            onClick={closeAll}
            styling="highlight"
            key={text}
            tw="flex py-5 px-12 font-medium"
          >
            {text}
          </LinkResolver>)}
          {section.categories.map(({ title, links }, i) => (
            <MobileCategoryLinks
              key={title}
              title={title}
              links={links}
              open={categoryOpen === i}
              setOpen={categoryOpen === i
                ? () => setCategoryOpen(-1)
                : () => setCategoryOpen(i)}
            />))}
        </div>
        <div tw="flex flex-col items-center mt-4 bg-white  pb-4 margin-bottom[70px]">
          <LinkButton button={section.button[0]} tw="width[60vw]" onClick={closeAll} />
        </div>
      </MobileHeaderTransition>
    </button>
  )
}

export default MobileCategoryMenu
