import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import useBlogDateFormat from "../../hooks/useBlogDateFormat"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Image from "../general/Image"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import { H5, P } from "../typography/Typography"
import { AlgoliaResult } from "./Search"

interface Props {
  readonly item: AlgoliaResult
}

const HightlightedH6 = styled(H5)(() => [
  tw`mt-0 mb-2`,
  {
    "> em": tw`not-italic bg-blue bg-opacity-40`,
  },
])

const HightlightedP = styled(P)(() => [
  tw`flex-1 mb-1.5 text-16 text-gray-2`,
  {
    "> em": tw`not-italic bg-blue bg-opacity-40`,
  },
])

const SearchItem: React.FC<Props> = ({ item }) => {
  const datePublished = useBlogDateFormat(item.datePublished)
  const { min_text } = useGlobalSettings()
  const { placeholder_image } = useGlobalSettings()

  return (
    <li key={item.objectID}>
      <Link className="group" to={rewriteSlug(item.full_slug)}>
        <div tw="py-7 flex flex-col space-y-2 laptop:(flex-row space-x-4 space-y-0) relative">
          <div tw="width[200px] laptop:(flex-shrink-0 my-auto)">
            {
              item.coverImage.filename
                ? <Image image={item.coverImage} width="200px" />
                : <Image image={placeholder_image} width="200px" />
            }
          </div>
          <div tw="flex flex-col flex-1">
            <HightlightedH6 dangerouslySetInnerHTML={{ __html: item._highlightResult.title.value }} />
            <p tw="text-14 text-gray-3 mb-2">{`${item.datePublished
              ? `${datePublished} - `
              : ""}${item.timeToRead} ${min_text}`}</p>
            <HightlightedP dangerouslySetInnerHTML={{ __html: item._highlightResult.description.value }} />
            <div tw="hidden tablet:block absolute bottom-2 right-2 text-24">
              <FontAwesomeIcon tw="motion-safe:group-hocus:animate-bounce-x" icon={faArrowRight} />
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default SearchItem
