import { graphql, navigate, PageProps, useStaticQuery } from "gatsby"
import React, { useEffect, useState, useLayoutEffect } from "react"
import { SbEditableContent } from "storyblok-react"
import BaseTemplate from "../components/general/BaseTemplate"
import { Layout } from "../components/layout/Layout"
import Page from "../components/templates/Page"
import { PageStoryblok } from "../../component-types-sb"
import TemplateSelector from "../components/templates/TemplateSelector"
import useLocale from "../hooks/useLocale"
import useStoryblok from "../hooks/useStoryblok"

interface GQLResult {
  stories: {
    nodes: {
      content: string
      uuid: string
      lang: string
      field_component: string
      full_slug: string
      default_full_slug: string
      translated_slugs: {
        path: string
        lang: string
      }[]
    }[]
  }
}

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  const { preferredLang } = useLocale()
  // Have to use state here to force the language update of 404 content or else it doesn't work
  const [lang, setLang] = useState("default")

  const emptyStory = {
    content: "{}",
    default_full_slug: "",
    field_component: "",
    full_slug: "",
    lang: preferredLang,
    translated_slugs: [],
  }
  const story = useStoryblok(emptyStory, location) as { content: SbEditableContent }
  const data: GQLResult = useStaticQuery(graphql`
    query NotFoundQuery {
      stories: allStoryblokEntry(filter:{name:{eq:"404"}}){
        nodes{
          uuid
          lang
          content
          field_component
          full_slug
          default_full_slug
          translated_slugs {
            path
            lang
          }
        }
      }
    }
  `)

  useLayoutEffect(() => {
    setLang(preferredLang)
  }, [preferredLang])

  useEffect(() => {
    setTimeout(() => {
      if (!location.href.includes("_storyblok") && ["/404", location.pathname].includes(window.location.pathname)) {
        void navigate(`/${preferredLang === "default"
          ? ""
          : preferredLang}`, { replace: true })
      }
    }, 5000)
  }, [location, preferredLang])

  // Inside Storyblok editor as an unpublished page
  if (location?.search.includes("_storyblok")) {
    return <Layout>
      <TemplateSelector blok={story.content} />
    </Layout>
  }

  // Vistor to the site who has hit a 404 route
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const localeStory = data.stories.nodes.find((x) => x.lang === lang)!
  const content = JSON.parse(localeStory.content) as PageStoryblok
  return <BaseTemplate story={localeStory} content={content}>
    <Layout>
      <Page blok={content} />
    </Layout>
  </BaseTemplate>
}

export default NotFoundPage
