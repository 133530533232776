import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { TextColumnsStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import DynamicComponent from "../general/DynamicComponent"

interface Props {
  readonly blok: TextColumnsStoryblok
}

interface ColumnsWrapperProps {
  spacing: "normal" | "large"
  width: "normal" | "small" | "tiny" | "big"
}

const ColumnsWrapper = styled.div<ColumnsWrapperProps>(({ spacing, width }) => [
  tw`flex flex-col space-x-0 laptop:(flex-row justify-center w-full) text-left w-fit mx-auto`,
  {
    ul: tw`laptop:mx-auto`,
  },
  spacing === "normal" && tw`laptop:space-x-8`,
  spacing === "large" && tw`laptop:space-x-20`,
  width === "big" && tw`max-w-container-big`,
  width === "normal" && tw`max-w-container`,
  width === "small" && tw`max-w-container-small`,
  width === "tiny" && tw`max-w-blog-text`,
])

const ColumnWrapper = styled.div<{ width: string }>(({ width }) => [width !== "tiny" && tw`flex-1`])

const TextColumns: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <Container>
    <ColumnsWrapper spacing={blok.spacing} width={blok.width}>
      {blok.columns.map((column) => <ColumnWrapper key={column._uid} width={blok.width}>
        <DynamicComponent blok={column} />
      </ColumnWrapper>)}
    </ColumnsWrapper>
  </Container>
</SbEditable>

export default TextColumns
