import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw, { css } from "twin.macro"
import {
  SectionStoryblok,
} from "../../../component-types-sb"
import { Heading, Subtitle } from "../typography/Typography"
import { useHiddenContext } from "../../contexts/HiddenContext"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import classNames from "../../utils/classNames"
import { includes, isEmpty } from "../../utils/typeGuards"
import Container from "./Container"
import DynamicComponent from "./DynamicComponent"
import Image from "./Image"
import LinkResolver from "./Link"
import Tag from "./Tag"

interface Props {
  readonly blok: SectionStoryblok
  readonly asHeader?: boolean
  readonly lastSection?: boolean
}

interface BlocksWrapperProps {
  spaceBetweenBlocks?: "none" | "small" | "large"
}

const darkBackgrounds = ["blue_gradient", "blue_dark", "blue_police", "blue_blumine", "blue_white"] as const
const backgroundColors = [...darkBackgrounds, "blue", "bright_gray", "white"] as const
type BackgroundColor = typeof backgroundColors[number]

interface BackgroundProps {
  restrictBackgroundHeight: boolean
  color: BackgroundColor
}

const SectionWrapper = tw.section`
  relative
  flex flex-col items-center
  w-full
  transition-opacity duration-700 opacity-100
  overflow-hidden
  before:(
    block
    h-24 w-full
    -mt-24
    pointer-events-none
  )
  py-8
  [&[data-section~="padding-none"]]:(
    py-0!
  )
  [&[data-section~="padding-large"]]:(
    py-16
  )
  [&[data-section~="padding-xl"]]:(
    pt-32! pb-24
  )
  [&[data-section~="padding-top"]]:(
    pt-16! pb-0!
  )
  first:pt-20
  last:pb-20
  [&[data-sticky="true"]]:(
    sticky top-[70px] z-50
  )
`

const SubtitleWrapper = tw(Subtitle)`
  text-center whitespace-pre-line
  [.section-dark-background &]:(text-white)
`

const HeadingWrapper = tw(Heading)`
  text-center mt-4 [.section-dark-background &]:(text-white)
`

const BlocksWrapper = styled.div<BlocksWrapperProps>(({ spaceBetweenBlocks }) => [
  tw`flex flex-col items-center w-full`,
  spaceBetweenBlocks === "small" && tw`space-y-4`,
  spaceBetweenBlocks === "large" && tw`space-y-4 laptop:space-y-12`,
])

const blueWhiteGradient = css`
@media (min-width: 768px){
  background: linear-gradient(to bottom, #1D5A7A 50%, white 50%);
};
background: linear-gradient(to bottom, #1D5A7A 60%, white 20%);
`

const Background = styled.div<BackgroundProps>(({ restrictBackgroundHeight, color }) => [
  tw`bg-white-blue-2 absolute top-0  w-full z-index[-1]`,
  color === "blue_gradient" && tw`bg-blue-midnight bg-gradient-to-b from-blue-midnight to-blue-police`,
  color === "blue_white" && blueWhiteGradient,
  color === "blue_dark" && tw`bg-blue-prussian`,
  color === "blue_police" && tw`bg-blue-police`,
  color === "bright_gray" && tw`bg-gray-bright`,
  color === "blue_blumine" && tw`bg-blue-blumine`,
  color === "white" && tw`bg-white`,
  restrictBackgroundHeight && tw`height[480px]`,
  !restrictBackgroundHeight && tw`h-full`,
])

// eslint-disable-next-line complexity
const Section: React.FC<Props> = ({ blok, asHeader = false }) => {
  const { id } = useHiddenContext()
  if (blok.hide) return null
  return <SbEditable content={blok}>
    <SectionWrapper
      {...(blok.anchor_id && { id: blok.anchor_id })}
      data-section={classNames([blok.section_padding, blok.background])}
      className={includes(darkBackgrounds, blok.background)
        ? "section-dark-background"
        : ""}
      data-background-color={blok.background}
      data-sticky={blok.is_sticky}
    >
      {(!blok.hidden || id === blok.anchor_id) && <>
        {!isEmpty(blok.background) && includes(backgroundColors, blok.background) && (
          <Background restrictBackgroundHeight={Boolean(blok.restirct_background_height)} color={blok.background} />
        )}
        <Container>
          {blok.tag && <Tag tw="bg-yellow text-yellow-on mb-10">{blok.tag}</Tag>}
          <div tw="max-w-container text-center w-full">
            {blok.logo?.id && <div><Image tw="h-10 mx-auto" image={blok.logo} height="40px" fit="contain" showPlaceholder={false} /></div>}
            {blok.title && <HeadingWrapper
              level={asHeader
                ? 1
                : 2}
              dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.title) }}
            />}
            {blok.subtitle && (
              <SubtitleWrapper
                dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.subtitle) }}
              />
            )}
            {blok.link?.[0] && (
              <LinkResolver
                url={blok.link[0].url}
                styling="textlink"
                tw="[.section-dark-background &]:(text-white)">
                {blok.link[0].text}
              </LinkResolver>
            )}
          </div>
        </Container>
        <BlocksWrapper spaceBetweenBlocks={blok.space_between_blocks}>
          {blok.blocks?.map((block) => <DynamicComponent key={block._uid} blok={block} />)}
        </BlocksWrapper>
      </>}
    </SectionWrapper>
  </SbEditable>
}

export default Section
