/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { Link as SpyLink } from "react-scroll"
import styled from "styled-components"
import tw, { TwStyle } from "twin.macro"
import { useChat } from "react-live-chat-loader"
import useLocale from "../../hooks/useLocale"
import { useSitePages } from "../../contexts/SitePagesContext"
import { A } from "../typography/Typography"
import { useHiddenContext } from "../../contexts/HiddenContext"
import { useConfirmic } from "../../hooks/useConfirmic"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { isUndefined } from "../../utils/typeGuards"
import { ChildrenProp } from "../../../react"

type StylingType = "underline" | "highlight" | "textlink" | "none"

interface Props extends React.ComponentPropsWithoutRef<"a"> {
  readonly url?: {
    id?: string
    cached_url?: string
    linktype?: "story"
    story?: {
      full_slug?: string
      id?: number
      name?: string
      slug?: string
      url?: string
      uuid?: string
    }
    anchor?: string
  } | {
    url?: string
    cached_url?: string
    linktype?: "asset" | "url"
  } | {
    email?: string
    linktype?: "email"
  }
  /**
   * "underline" default color blue, hover color dark blue, underlined
   * "highlight" color blue when hovered over
   * "textlink" underlines and arrow at end of text
   * "none" force no styling
   */
  readonly styling?: StylingType
}

interface MarkLinkProps {
  readonly url: {
    href: string
    linktype: "url" | "story" | "asset" | "email"
    target: "_self" | "_blank" | null
    anchor?: string
    uuid: string | null
  }
  readonly is_blue?: boolean
}

interface StoryLinkProps extends React.ComponentPropsWithoutRef<"a"> {
  readonly uuid?: string | null
  readonly fallback?: string
  readonly anchor?: string
}

const StoryLink: React.FC<StoryLinkProps> = ({
  children,
  uuid,
  fallback,
  anchor,
  ...rest
}) => {
  // Get link from id of Story and use the fallback if doesn't exist
  const { preferredLang } = useLocale()
  const { getLinkFromId } = useSitePages()
  const resolvedLink
    = (uuid && getLinkFromId(uuid, preferredLang)) || rewriteSlug(fallback)
  const location = useLocation()
  const { setId } = useHiddenContext()
  if (
    typeof anchor !== "undefined"
    && anchor !== null
    && (location.pathname === resolvedLink
      || location.pathname === `${resolvedLink}/`)
  ) {
    // SpyLink uses button props so need to convert remove as
    // eslint-disable-next-line no-unused-vars
    const { as, ...buttonProps }
      = rest as unknown as React.ComponentPropsWithoutRef<"button">
    return (
      <SpyLink
        href=""
        to={anchor}
        offset={-80}
        smooth
        {...buttonProps}
        onClick={() => setId(anchor)}
      >
        {children}
      </SpyLink>
    )
  }
  return (
    <Link
      to={`${resolvedLink}${
        typeof anchor === "undefined" || anchor === null || !anchor
          ? ""
          : `#${anchor}`
      }`}
      {...rest}
    >
      {children}
    </Link>
  )
}

const stylingVariants: Record<StylingType, TwStyle> = {
  highlight: tw`cursor-pointer hocus:(text-blue)`,
  none: tw``,
  textlink: tw`text-orange cursor-pointer`,
  underline: tw`cursor-pointer text-blue hocus:(underline)`,
}

const StyledButtonLink = styled.button<{ styling?: StylingType }>(
  () => [({ styling }) => styling && stylingVariants[styling]],
)
const StyledStoryLink = styled(StoryLink)<{ styling?: StylingType }>(
  () => [({ styling }) => styling && stylingVariants[styling]],
)
export const StyledA = styled.a<{ styling?: StylingType }>(
  () => [({ styling }) => styling && stylingVariants[styling]],
)

// eslint-disable-next-line complexity
const LinkResolver: React.FC<Props> = ({ children, url, styling, ...rest }) => {
  let newChildren = <>{children}</>
  if (styling === "textlink") {
    newChildren = (
      <>
        {children}
        <FontAwesomeIcon
          tw="ml-2 group-hocus:(animate-bounce-x)"
          icon={faArrowRight}
        />
      </>
    )
  }

  const [, loadChat] = useChat()
  const { openConfirmic } = useConfirmic()

  if (!url) return newChildren

  // There is probably a better way to do this but we need to use a "button" tag as instead of an "a" tag as we need to use an onClick handler
  const buttonProps = rest as unknown as React.ComponentPropsWithoutRef<"button">

  if (url.linktype === "url" && url.url === "openIntercom") {
    return (
      <StyledButtonLink
        styling={styling}
        onClick={() => loadChat({ open: true })}
        {...buttonProps}
      >
        {newChildren}
      </StyledButtonLink>
    )
  }

  if (url.linktype === "url" && url.url === "openConfirmic") {
    return (
      <StyledButtonLink
        styling={styling}
        {...buttonProps}
        onClick={openConfirmic}
      >
        {newChildren}
      </StyledButtonLink>
    )
  }

  switch (url.linktype) {
    case "story":
      return (
        <StyledStoryLink
          styling={styling}
          uuid={url.id}
          fallback={url.cached_url}
          anchor={url.anchor}
          {...rest}
        >
          {newChildren}
        </StyledStoryLink>
      )
    case "email":
      return (
        <StyledA
          styling={styling}
          href={url.email && `mailto:${url.email}`}
          {...rest}
        >
          {newChildren}
        </StyledA>
      )
    case "asset":
      return (
        <StyledA
          styling={styling}
          href={url.cached_url}
          target="_blank"
          rel="noreferrer nofollow"
          {...rest}
        >
          {newChildren}
        </StyledA>
      )
    case "url":
      return (
        <StyledA
          styling={styling}
          href={url.cached_url}
          target="_blank"
          rel="noreferrer"
          {...rest}
        >
          {newChildren}
        </StyledA>
      )
    default:
      return <span>No link found</span>
  }
}

const DynamicStoryLink = tw(StoryLink)`
  cursor-pointer
  hocus:border-b-gray-3
  hocus:border-solid
  border-b border-b-gray-4 border-dotted
  [&[data-is-blue='true']]:(
    text-blue
    cursor-pointer
    hocus:(underline)
    border-b-0
    [[data-theme='secondary'] &]:text-blue-police
  )
`

export default LinkResolver

export const MarkLinkResolver: React.FC<ChildrenProp & MarkLinkProps> = ({ children, url, is_blue }) => {
  const { href, linktype, target, anchor, uuid } = url
  const [, loadChat] = useChat()
  const { openConfirmic } = useConfirmic()

  if (href === "openIntercom") {
    return (
      <A
        as="button"
        tw="text-blue hover:(underline) font-light"
        onClick={() => loadChat({ open: true })}
      >
        {children}
      </A>
    )
  }

  if (href === "openConfirmic") {
    return <A as="button" tw="text-blue hover:(underline) font-light" onClick={openConfirmic}>
      {children}
    </A>
  }

  switch (linktype) {
    case "email":
      return <A href={`mailto:${href}`} target={target ?? "_blank"}>
        {children}
      </A>
    case "url":
      return <A href={href} target={target ?? "_blank"} rel="noreferrer">
        {children}
      </A>
    case "story":
      return <DynamicStoryLink
        data-is-blue={is_blue ?? isUndefined(is_blue)}
        uuid={uuid} fallback={href} anchor={anchor}
      >
        {children}
      </DynamicStoryLink>
    default:
      return <A href={href} target={target ?? "_blank"}>
        {children}
      </A>
  }
}
