import React from "react"
import SbEditable from "storyblok-react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { ProductCardStoryblok } from "../../../component-types-sb"
import { colors } from "../general/ProductCarouselFeature"
import { P } from "../typography/Typography"
import LinkResolver from "../general/Link"

interface Props {
  blok: ProductCardStoryblok
}

export const ProductCardWrapper = styled.div<{
  color: "" | "100" | "200" | "300" | "400" | "500" | "600" | undefined
}>(() => [
  tw`w-full px-9 pt-9 pb-4 rounded-2xl flex flex-col justify-between h-full`,
  ({ color = "100" }) => colors[(Number(color) / 100) - 1],
])

const ProductCard: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <div tw="laptop:col-span-2 col-span-6 w-full flex">
      <LinkResolver url={blok.url?.id && blok.url}>
        <ProductCardWrapper color={blok.color} className="group">
          <div>
            <P tw="font-bold text-20">{blok.title}</P>
            <P>{blok.description}</P>
          </div>
          {blok.url?.id && <div tw="w-full flex justify-end pt-4">
            <FontAwesomeIcon icon={faChevronRight} tw="text-20 group-hocus:animate-bounce-x duration-200" />
          </div>}
        </ProductCardWrapper>
      </LinkResolver>
    </div>
  </SbEditable>
)

export default ProductCard
