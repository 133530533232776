import { graphql, useStaticQuery } from "gatsby"

import { BackgroundStoryblok } from "../../component-types-sb"

interface FeaturedBackgroundEntries {
  stories: {
    nodes: {
      content: string
      full_slug: string
      lang: string
    }[]
  }
}

const useBackgroundEntries = () => {
  const data = useStaticQuery<FeaturedBackgroundEntries>(graphql`{
  stories: allStoryblokEntry(
    filter: {field_component: {eq: "background"}}
    sort: {first_published_at: DESC}
  ) {
    nodes {
      content
      full_slug
      lang
    }
  }
}`)

  const allBackgrounds = data.stories.nodes.map((bg) => ({
    ...bg,
    content: JSON.parse(bg.content) as BackgroundStoryblok,
  }))

  return allBackgrounds
}

export default useBackgroundEntries
