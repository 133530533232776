import React from "react"
import { GatsbyBrowser } from "gatsby"
import { LiveChatLoaderProvider } from "react-live-chat-loader"
import FontContextProvider from "../contexts/FontContext"
import { TranslatedLinksProvider } from "../contexts/TranslatedLinksContext"
import { SitePagesProvider } from "../contexts/SitePagesContext"
import WistiaInfoProvider from "../contexts/BunnyInfoContext"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => (
  <SitePagesProvider>
    <TranslatedLinksProvider>
      <FontContextProvider>
        <WistiaInfoProvider>
          {process.env.GATSBY_INTERCOM_ID && <LiveChatLoaderProvider
            providerKey={process.env.GATSBY_INTERCOM_ID}
            provider="intercom"
          >
            {element}
          </LiveChatLoaderProvider>}
        </WistiaInfoProvider>
      </FontContextProvider>
    </TranslatedLinksProvider>
  </SitePagesProvider>
)
