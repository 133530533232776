import { graphql, useStaticQuery } from "gatsby"

import { GlossaryKeywordStoryblok } from "../../component-types-sb"

interface GlossaryKeywordEntries {
  stories: {
    nodes: {
      content: string
      full_slug: string
      lang: string
    }[]
  }
}

const useKeywordEntries = () => {
  const data = useStaticQuery<GlossaryKeywordEntries>(graphql`{
  stories: allStoryblokEntry(
    filter: {field_component: {eq: "glossaryKeyword"}}
    sort: {first_published_at: DESC}
  ) {
    nodes {
      content
      full_slug
      lang
    }
  }
}`)

  const allKeywords = data.stories.nodes.map((keyword) => ({
    ...keyword,
    content: JSON.parse(keyword.content) as GlossaryKeywordStoryblok,
  }))

  return allKeywords
}

export default useKeywordEntries
