import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { PricingInfoStoryblok } from "../../../component-types-sb"
import { usePricingData } from "../../contexts/PricingDataContext"
import Alert from "../general/Alert"
import Container from "../general/Container"

const Wrapper = tw.div`
  max-w-container w-full
  my-8
`

interface Props {
  readonly blok: PricingInfoStoryblok
}

const PricingInfo: React.FC<Props> = ({ blok }) => {
  const { yearlyBilling, selectedPlans, availablePlans } = usePricingData()
  const { viewers } = availablePlans.premium[selectedPlans.premium]
  return <SbEditable content={blok}>
    <Container>
      <Wrapper>
        <Alert color="blue">
          {blok.text
            .replace(/VIEWERS/u, viewers.toString())
            .replace(/BILLING/u, yearlyBilling
              ? blok.text_annually
              : blok.text_monthly)}
        </Alert>
      </Wrapper>
    </Container>
  </SbEditable>
}

export default PricingInfo
