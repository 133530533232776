import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import LinkResolver, { StyledA } from "../general/Link"
import { KeywordDefinitionStoryblok } from "../../../component-types-sb"
import { LetterTag } from "./GlossaryIndex"

interface GlossaryProps {
  readonly title: string
  readonly description: string
  readonly url?: string | KeywordDefinitionStoryblok["url"]
  readonly moreText?: string
  readonly tag: string
}

const GlossaryItem = ({ title, description: desc, url, moreText: more_text, tag }: GlossaryProps) => (
  <div tw="bg-blue-alice rounded-lg p-6">
    <div>
      <LetterTag data-oneletter={tag.length === 1}>{tag}</LetterTag>
      <h2 tw="font-medium tablet:text-45 text-34 py-1 text-black">{title}</h2>
    </div>
    <div tw="flex justify-between items-end gap-2">
      <p tw="text-20 text-black">{desc}</p>
      {typeof url === "string"
        ? url && more_text
        && <StyledA styling="textlink" tw="whitespace-nowrap" className="group" href={url}>
          {more_text}
          <FontAwesomeIcon
            tw="ml-2 group-hocus:animate-bounce-x "
            icon={faArrowRight}
          />
        </StyledA>
        : <LinkResolver url={url} styling="textlink" className="group">
          {more_text}
        </LinkResolver>}
    </div>
  </div>
)

export default GlossaryItem
