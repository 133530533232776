import React from "react"
import SbEditable from "storyblok-react"
import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import tw from "twin.macro"
import { LandingPageRegisterFormStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import { H1, Subtitle } from "../typography/Typography"
import StarReveiw from "../general/StarReveiw"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import LinkResolver from "../general/Link"
import Image from "../general/Image"
import RegisterForm from "../general/RegisterForm"

interface FormChecksProps {
  checkAboveForm?: boolean
}

const FormAndChecksWrapper = styled.div<FormChecksProps>(({ checkAboveForm }) => [
  tw`flex flex-col`,
  checkAboveForm && tw`flex-col-reverse`,
])

interface Props {
  readonly blok: LandingPageRegisterFormStoryblok
}

const LandingPageRegisterForm: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <Container>
      <div tw="mx-auto max-w-container-large grid grid-cols-1 desktop:grid-cols-2 w-full items-center gap-y-8">
        <div tw="text-center desktop:(text-left mr-16) [.section-dark-background &]:text-white">
          <H1 dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.title) }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.subtitle) }} />
          <StarReveiw position="left" />
          <FormAndChecksWrapper checkAboveForm={blok.checks_above_form}>
            <RegisterForm layoutStyle="hero" />
            <div tw="my-4 space-y-2">
              {blok.checks?.map((check) => <p
                tw="mr-2 text-gray-2 font-light [.section-dark-background &]:text-white"
                key={check._uid}
              >
                <span tw="text-blue mr-2">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                {check.text && <span dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(check.text) }} />}
              </p>)}
            </div>
          </FormAndChecksWrapper>
          <div tw="flex flex-row space-x-5 flex-wrap -ml-5 justify-center desktop:justify-start">
            {blok.badges?.map(({ image, url, _uid }) => <LinkResolver url={url} tw="ml-5" key={_uid}>
              <Image tw="width[75px]" image={image} width="75px" />
            </LinkResolver>)}
          </div>
        </div>

        <div>
          <Image tw="w-full" image={blok.main_image} fluid={960} />
        </div>
      </div>
    </Container>
  </SbEditable>
)

export default LandingPageRegisterForm
