import { graphql, useStaticQuery } from "gatsby"

interface QueryResult {
  allStoryblokEntry: {
    courses: {
      uuid: string
      content: string
      lang: string
      title: string
      subtitle: string
      full_slug: string
      fields: {
        duration: number
        course_count: number
      }
    }[]
  }
}

const useAcademyEntries = () => {
  const { allStoryblokEntry: { courses } } = useStaticQuery<QueryResult>(graphql`query allCourses {
  allStoryblokEntry(
    filter: {field_component: {eq: "academyCourse"}}
    sort: {first_published_at: DESC}
  ) {
    courses: nodes {
      uuid
      lang
      content
      title: field_title_string
      subtitle: field_subtitle_string
      full_slug
      fields {
        duration
        chapter_count
      }
    }
  }
}`)
  return courses
}

export default useAcademyEntries
