import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { BlogPostStoryblok, FeaturedBlogsStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import BlogCard from "../cards/BlogCard"
import Container from "../general/Container"

const Wrapper = tw.ul`
  grid grid-template-columns[repeat(auto-fit, minmax(min(300px, 100%), 1fr))]
  gap-8
  max-w-container-large w-full
  py-8
`

interface BlogsQuery {
  stories: {
    nodes: {
      content: string
      full_slug: string
      lang: string
    }[]
  }
}

interface Blog {
  content: BlogPostStoryblok
  full_slug: string
}

interface Props {
  readonly blok: FeaturedBlogsStoryblok
}

const FeaturedBlogs: React.FC<Props> = ({ blok }) => {
  const { locale } = useLocale()
  const data = useStaticQuery<BlogsQuery>(graphql`query AllChapterEntries {
  stories: allStoryblokEntry(
    filter: {field_component: {eq: "blogPost"}}
    limit: 12
    sort: {first_published_at: DESC}
  ) {
    nodes {
      content
      full_slug
      lang
    }
  }
}`)

  const latestBlogs = ((blok.blogs && blok.blogs.length > 0)
    ? blok.blogs
    : data.stories.nodes.filter((x) => x.lang === locale)
      .map((blog) => ({
        ...blog,
        content: JSON.parse(blog.content) as BlogPostStoryblok,
      }))) as Blog[]

  return (
    <SbEditable content={blok}>
      <Container>
        <Wrapper>
          {latestBlogs.map((blog: Blog) => (
            <li key={blog.full_slug}>
              <BlogCard content={blog.content} slug={blog.full_slug} />
            </li>
          ))}
        </Wrapper>
      </Container>
    </SbEditable>
  )
}

export default FeaturedBlogs
