/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { graphql, useStaticQuery } from "gatsby"
import { StyledLink } from "../typography/Typography"
import useLocale from "../../hooks/useLocale"
import Search from "./Search"

interface BlogOrAcademyCategory {
  full_slug: string
  slug: string
  field_title_string: string
  lang: string
}

interface BlogOrAcademyIndex {
  full_slug: string
  name: string
  lang: string
}

interface QueryResult {
  blogIndex: {
    nodes: BlogOrAcademyIndex[]
  }
  academyIndex: {
    nodes: BlogOrAcademyIndex[]
  }
  blogCategories: {
    nodes: BlogOrAcademyCategory[]
  }
  academyCourses: {
    nodes: BlogOrAcademyCategory[]
  }
}

interface Props {
  readonly section: "blogPost" | "academyChapter"
  readonly regexCategory?: string
  readonly title: string
  readonly level: number
}

const BlogNav: React.FC<Props> = ({ section, regexCategory, title, level }) => {
  const { locale } = useLocale()
  const data = useStaticQuery<QueryResult>(graphql`
    query allCategoryPages{
      blogIndex: allStoryblokEntry(filter: { field_component: {eq: "blogIndexPage"},}) {
        nodes {
          lang
          full_slug
          name
        }
      }
      academyIndex: allStoryblokEntry(filter: { default_full_slug: {eq: "academy/"},}) {
        nodes {
          lang
          full_slug
          name
        }
      }
      blogCategories: allStoryblokEntry(filter: { field_component: {eq: "categoryPage"},}) {
        nodes {
          lang
          slug
          full_slug
          field_title_string
        }
      }
      academyCourses: allStoryblokEntry(filter: {field_component: {eq: "academyCourse"}}) {
        nodes {
          lang
          slug
          full_slug
          field_title_string
        }
      }
    }
  `)

  const academyCourses = data.academyCourses.nodes.filter((x) => x.lang === locale)
  const academyIndex = data.academyIndex.nodes.find((x) => x.lang === locale)!
  const blogCategories = data.blogCategories.nodes.filter((x) => x.lang === locale)
  const blogIndex = data.blogIndex.nodes.find((x) => x.lang === locale)!

  const indexPage = section === "blogPost"
    ? blogIndex
    : academyIndex

  // get category slug from regexSlug in pageContext
  const categorySlug = regexCategory
    ? regexCategory.substring(1, regexCategory.length - 3)
    : ""
  const getCategory = () => {
    const testCategorySlug = (slug: string) => new RegExp(categorySlug, "u").test(`${slug}/`)

    if (section === "blogPost") return blogCategories.find((cat) => testCategorySlug(cat.full_slug))
    if (section === "academyChapter") return academyCourses.find((cat) => testCategorySlug(cat.full_slug))
    return { field_title_string: "coming soon", full_slug: "" }
  }
  const category = getCategory() ?? { field_title_string: "coming soon", full_slug: "" }

  return <div tw="height[70px] w-full justify-between text-center items-center bg-white flex px-4
    tablet:px-6 desktop-large:px-8 desktop-xl:px-24 space-x-2">
    <div tw="text-14 flex-wrap text-left
      -webkit-line-clamp[3] -webkit-box-orient[vertical] display[-webkit-box] overflow-hidden">
      {level === 0 && <div />}
      {level > 0 && <>
        <StyledLink to={`/${indexPage.full_slug}`}>
          {indexPage.name}
        </StyledLink>
        <span tw="px-2 text-gray-3 text-12">
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
        {level === 1
          ? <span tw="font-medium">{title}</span>
          : <StyledLink to={`/${category.full_slug}`}>
            {category.field_title_string}
          </StyledLink>}
      </>}
      {level === 2 && <>
        <span tw="px-2 text-gray-3 text-12">
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
        <span tw="font-medium">{title}</span>
      </>}
    </div>
    <Search section={section} />
  </div>
}

export default BlogNav
