import { useState, useEffect } from "react"

interface WindowSize {
  width: number
  height: number
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState({
    height: typeof window === "undefined"
      ? 0
      : window.innerHeight,
    width: typeof window === "undefined"
      ? 0
      : window.innerWidth,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}
