import React from "react"
import { useLocation } from "@reach/router"
import { IntegrationCardsStoryblok, PageCardStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import ClientOnly from "../general/ClientOnly"
import CardGrid from "./CardGrid"

interface Props {
  readonly blok: IntegrationCardsStoryblok
}

const IntegrationCards: React.FC<Props> = ({ blok }) => {
  const { integration_cards } = useGlobalSettings()
  const { pathname } = useLocation()
  let items = integration_cards[0].items.filter((item): item is PageCardStoryblok & { page: { full_slug: string } } => (
    item.component === "pageCard"))

  // Mix up the items and remove the card with the same link as the current page
  // eslint-disable-next-line etc/no-assign-mutated-array
  items = items.sort(() => {
    if (blok.random) return Math.random() - 0.5
    return 0
  })
    .filter((item) => `/${item.page.full_slug || ""}` !== pathname)
  if (Number(blok.limit) > 0) items = items.slice(0, Number(blok.limit))

  // Since the cards are in a random order, don't create this component on the server
  return <ClientOnly>
    <CardGrid blok={{ ...integration_cards[0], items, max_columns: "2" }} />
  </ClientOnly>
}

export default IntegrationCards
