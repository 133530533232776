import { graphql, useStaticQuery } from "gatsby"

interface IntegrationTags {
  allStoryblokDatasourceEntry: {
    integration_tags: {
      name: string
      value: string
    }[]
  }
}

const useIntegrationTags = () => {
  const data = useStaticQuery<IntegrationTags>(graphql`{
    allStoryblokDatasourceEntry(filter: {data_source: {eq: "integration-tags"}}) {
      integration_tags: nodes {
        name
        value
      }
    }
  }`)

  const tags = data.allStoryblokDatasourceEntry.integration_tags.map((tag) => {
    let lang = "default"
    if (tag.value.endsWith("_nl")) {
      lang = "nl"
    }
    if (tag.value.endsWith("_de")) {
      lang = "de"
    }
    return {
      lang,
      name: tag.name,
      value: tag.value,
    }
  })

  return tags
}

export default useIntegrationTags
