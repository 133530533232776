import React from "react"
import {
  CustomerSuccessStoriesHighlightStoryblok,
  CustomerSuccessStoryStoryblok,
} from "../../../component-types-sb"
import ImageCard from "../cards/ImageCard"
import "twin.macro"
import { parseUseCase } from "../general/CustomerSuccessStoriesGrid"

interface Props {
  readonly blok: CustomerSuccessStoriesHighlightStoryblok
}

interface ParsedSuccessStory {
  content: CustomerSuccessStoryStoryblok
  full_slug: string
  uuid: string
}

const CustomerSuccessStoriesHighlight: React.FC<Props> = ({ blok }) => {
  if (!blok.success_story) return null
  const successStory = blok.success_story as unknown as ParsedSuccessStory
  const parsedUseCase = parseUseCase(successStory.content.use_case)

  return (
    <ImageCard
      image={successStory.content.highlight_image ?? successStory.content.image}
      title={successStory.content.title ?? successStory.content.title}
      full_slug={successStory.full_slug}
      tag={parsedUseCase?.content?.title}
      variant="big"
      color="dark"
    />
  )
}

export default CustomerSuccessStoriesHighlight
