import React from "react"
import tw from "twin.macro"
import { usePricingData } from "../../contexts/PricingDataContext"

const FancyWrapper = tw.label`
  grid gap-0.5
  items-center
  w-fit
  z-10
  [&>*]:(row-span-1 row-start-1)
  hover:cursor-pointer
`

const FancyStyledToggle = tw.span`
  inline-block
  w-4 h-4
  bg-blue
  rounded-full
  transform
  transition-transform ease-in
  --tw-translate-x[4px]
  [[aria-checked="true"] > &]:(
    --tw-translate-x[24px]
  )
  [[disabled] > &]:(
    ring-2 ring-gray-4
  )
`

const FancyStyledSwitch = tw.button`
  relative
  inline-flex items-center
  px-0 mx-1.5 my-3.5
  h-9 w-14
  rounded-full
  transition-colors ease-in
  cursor-pointer
  bg-transparent
  border-width[6px] border-blue
  col-span-1 col-start-2
  focus-visible:(
    ring-offset-2
    ring-opacity-100
  )
`

const FancyStyledText = tw.span`
  m-0 py-2
  text-white text-24
  leading-none
  col-span-1
`

const FancyToggleBackground = tw.div`
  bg-white/[0.06]
  col-span-2 col-start-1
  self-stretch
  rounded-full
  [&[data-checked="true"]]:col-start-2
  group-hover:bg-white/[0.08]
`

const SaveSpan = tw.span`
text-green
[&[data-yellow-text="true"]]:(
  text-yellow
)
font-bold text-16
`

interface Props {
  readonly billedYearly: string
  readonly monthlyText: string
  readonly yearlyText: string
  readonly saveText?: string
  readonly saveTextYellow?: boolean | undefined
}

const PricingYearlyBillingSelector: React.FC<Props> = ({ billedYearly,
  monthlyText,
  yearlyText,
  saveText,
  saveTextYellow }) => {
  const {
    yearlyBilling, setYearlyBilling,
  } = usePricingData()

  return (
    <FancyWrapper className="group">
      <FancyToggleBackground data-checked={yearlyBilling} />

      <FancyStyledText tw="col-start-1 mr-1 ml-4" data-text-selected={!yearlyBilling}>
        {monthlyText}
      </FancyStyledText>

      <FancyStyledSwitch
        onClick={() => setYearlyBilling(!yearlyBilling)}
        role="switch"
        aria-checked={yearlyBilling}
        aria-label={billedYearly}
      >
        <FancyStyledToggle />
      </FancyStyledSwitch>

      <FancyStyledText tw="col-start-3 ml-1 mr-4 flex flex-col" data-text-selected={yearlyBilling}>
        {yearlyText}
        {saveText && <SaveSpan data-yellow-text={saveTextYellow}>{saveText}</SaveSpan>}
      </FancyStyledText>
    </FancyWrapper>
  )
}

export default PricingYearlyBillingSelector
