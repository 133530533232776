import React from "react"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-regular-svg-icons"
import { Heading } from "../typography/Typography"
import TagFilterButton from "../general/TagFilterButton"
import FilterItem from "../general/FilterItem"
import { isUndefined } from "../../utils/typeGuards"
import { TagPage as TagType } from "../../hooks/useTags"

type Tag = string | TagType

interface ComponentProps {
  readonly tagsDisabled?: boolean
  readonly selectedTag: Tag | null
  readonly setSelectedTag: React.Dispatch<React.SetStateAction<Tag | null>>
  readonly searchQuery: string
  readonly setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  readonly all_filter_text: string
  readonly search_placeholder: string
  readonly search_title?: string
  readonly tags: Tag[]
  readonly sortTagsFunction?: ((a: Tag, b: Tag) => number)
  readonly noBorder?: boolean
  readonly hideSearch?: boolean
}

const ExploreContainer = tw.div`
  text-black
  [.section-dark-background &]:text-white
  [&[data-no-border="true"]]:(border-b-0 border-t-0 my-0 mt-6 pb-8)
  text-center
  flex flex-col items-center
  w-full pb-14 px-4 my-[60px]
  border-b-2 border-t-2 border-gray-5
`

interface SearchProps {
  readonly searchQuery: string
  readonly setSearchQuery: React.Dispatch<React.SetStateAction<string>>
  readonly placeholder: string
}

const Label = tw.label`
  flex items-center
  border border-gray-3
  bg-white
  rounded-full
  focus-within:border-gray-2
  duration-200
  py-2.5
  pl-2
  w-full max-w-[350px] relative
`

const Search = ({ searchQuery, setSearchQuery, placeholder }: SearchProps) => (
  <Label htmlFor="searchInput">
    <FontAwesomeIcon icon={faSearch} tw="pl-2 text-blue-prussian text-12" />
    <input
      id="searchInput"
      type="text"
      placeholder={placeholder}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      tw="px-2 rounded-full bg-white w-full text-14 font-medium leading-tight tracking-tight"
    />
  </Label>
)

const SearchWithTags = ({
  tags,
  selectedTag,
  searchQuery,
  search_title,
  tagsDisabled,
  setSearchQuery,
  setSelectedTag,
  all_filter_text,
  sortTagsFunction,
  search_placeholder,
  noBorder,
  hideSearch,
}: ComponentProps) => {
  if (!isUndefined(sortTagsFunction)) {
    tags.sort(sortTagsFunction)
  }
  const tagText = (tag: TagType | string) => typeof tag === "string"
    ? tag
    : tag.title

  return (
    <ExploreContainer data-no-border={noBorder}>
      {!hideSearch && <>
        {search_title && <Heading level={2} tw="font-medium mt-14 mb-5 tracking-[1.6px]">{search_title}</Heading>}
        <Search
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={search_placeholder}
        />
      </>}
      {!tagsDisabled
      && <ul tw="flex w-full justify-center flex-wrap gap-y-4 mt-4"
        data-no-selected-items={selectedTag === null}
      >
        <li key="all"><FilterItem
          name="searchFilter"
          value="all"
          onChange={() => setSelectedTag(null)}
          type="radio"
          checked={selectedTag === null}
          spacing="small"
        >
          <TagFilterButton>{all_filter_text}</TagFilterButton>
        </FilterItem>
        </li>
        {tags.map((tag) => {
          const isChecked = selectedTag === tag
          return <li key={tagText(tag)}><FilterItem
            name="searchFilter"
            value={tagText(tag)}
            onChange={() => setSelectedTag(isChecked
              ? null
              : tag)}
            type="radio"
            checked={isChecked}
            spacing="small"
          >
            <TagFilterButton data-is-letter={true}>{tagText(tag)}</TagFilterButton>
          </FilterItem>
          </li>
        })}
      </ul>
      }
    </ExploreContainer>
  )
}

export default SearchWithTags
