import { useLocation } from "@reach/router"
import Cookies from "js-cookie"

const TRANSLATED_LANGS = ["nl", "de"]
const DEFAULT_LANG = "default"

export type Locale = "default" | "nl" | "de"

const useLocale = (): { locale: Locale, preferredLang: Locale } => {
  const { pathname } = useLocation()
  let locale = pathname.split("/")[1].replace("/", "") as Locale
  if (!TRANSLATED_LANGS.includes(locale)) locale = DEFAULT_LANG
  const preferredLang = locale === "default"
    ? Cookies.get("wg_preferred_lang") as Locale || locale
    : locale
  return { locale, preferredLang }
}

export default useLocale
