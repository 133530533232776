import React from "react"
import SbEditable from "storyblok-react"
import { CompanyStoryblok, ComparisonCardStoryblok } from "../../../component-types-sb"
import LinkResolver from "../general/Link"
import Image from "../general/Image"
import CardBase from "./CardBase"

interface Props {
  readonly blok: ComparisonCardStoryblok
}

const ComparisionCard: React.FC<Props> = ({ blok }) => {
  const companies = blok.companies as unknown as { content: CompanyStoryblok }[]
  return <SbEditable content={blok}>
    <LinkResolver
      className="group"
      url={blok.url}
      styling="none"
      tw="relative flex flex-col items-center mx-auto p-7 bg-white w-full max-width[300px]"
    >
      <CardBase tw="absolute inset-0" />
      {companies.length > 0
        ? <Image image={companies[0].content.logo} fluid={220} width={220} />
        : <p tw="my-10 text-red font-bold">Select a first company</p>
      }
      <div tw="text-20 text-center group-hocus:text-blue">VS</div>
      {companies.length > 1
        ? <Image image={companies[1].content.logo} fluid={220} width={220} />
        : <p tw="my-10 text-red font-bold">Select a second company</p>
      }
    </LinkResolver>
  </SbEditable>
}

export default ComparisionCard
