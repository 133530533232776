import { PageProps } from "gatsby"
import React from "react"
import { Intercom } from "react-live-chat-loader"
import { MobileHeaderContextProvider } from "../../contexts/MobileHeaderContext"
import useLocale from "../../hooks/useLocale"
import { ChildrenProp } from "../../../react"
import Header from "./header/Header"
import Footer from "./footer/Footer"

interface PageContext {
  renderLayout?: boolean
  noIntercom?: boolean
  lang: string
}

export const Layout: React.FC<ChildrenProp> = ({ children }) => <div id="top" tw="flex flex-col min-h-screen">
  <MobileHeaderContextProvider>
    <Header />
  </MobileHeaderContextProvider>
  <main tw="flex-grow flex flex-col items-center">{children}</main>
  <Footer />
</div>

const LayoutSwitcher: React.FC<PageProps<unknown, PageContext>> = ({ children, pageContext }) => {
  const { preferredLang } = useLocale()
  return <>
    {pageContext.renderLayout
      ? <Layout key={preferredLang}>
        {children}
      </Layout>
      : <main>
        {children}
      </main>}
    {/* Don't show intercom if flag set. Currently for app info pages */}
    {!pageContext.noIntercom && <Intercom color="#29a5c4" />}
  </>
}

export default LayoutSwitcher
