import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import tw from "twin.macro"
import { CustomerSuccessStoryStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Image from "../general/Image"
import { H4, P } from "../typography/Typography"
import CardBase from "./CardBase"

interface CardProps {
  readonly content: CustomerSuccessStoryStoryblok
  readonly uuid: string
  readonly full_slug: string
}

const ImageContainer = tw.div`
  relative
  padding-top[80%]
  w-full
`

const TextContainer = tw.div`
  p-4 flex flex-col h-full
`

const CustomerSuccessStoryCardNew: React.FC<CardProps> = ({ content, full_slug, ...rest }) => {
  const { width: imageWidth } = getImageDimensions(content.image)
  const { width: logoWidth } = getImageDimensions(content.companyLogo)

  return <Link to={rewriteSlug(full_slug)} className="group" {...rest}>
    <CardBase tw="relative flex flex-col h-full">
      <ImageContainer>
        <div tw="absolute inset-0">
          <Image image={content.image} fit="cover" height="100%" width="100%"
            fluid={Math.min(705, imageWidth)} />
        </div>
        <div tw="absolute bottom-4 left-4 w-full">
          <Image
            tw="object-contain object-left w-2/5 h-10"
            image={content.companyLogo}
            fit="contain"
            fluid={logoWidth}
            useRegularImageTag
          />
        </div>
      </ImageContainer>

      <TextContainer>
        <H4>{content.title}</H4>
        <P>{content.intro}</P>
        <div tw="text-17 align-self[flex-end] mt-auto">
          <FontAwesomeIcon tw="group-hocus:(animate-bounce-x)" icon={faArrowRight} />
        </div>
      </TextContainer>
    </CardBase>
  </Link>
}

export default CustomerSuccessStoryCardNew

const HighlightContentContainer = tw.div`
  absolute
  inset-0 bottom-1/2
  bg-gradient-to-b from-black to-transparent
`

const HighlightLogoContainer = tw.div`
  mt-5 px-4 py-1
  w-max
  bg-white
`

interface HighlightCardProps extends CardProps {
  readonly highlight_details: {
    name?: string
    jobTitle?: string
    quote?: string
  }
}

export const HighlightedCustomerSuccessStoryCard: React.FC<HighlightCardProps> = (
  { content, highlight_details, full_slug, ...rest },
) => {
  const { width: imageWidth } = getImageDimensions(content.image)
  const { width: logoWidth } = getImageDimensions(content.companyLogo)

  const { quote, name, jobTitle } = highlight_details

  return <Link to={rewriteSlug(full_slug)} className="group" tw="relative padding-top[45%] mb-4 block" {...rest}>
    <div tw="absolute inset-0">
      <Image image={content.image} fit="cover" height="100%" width="100%"
        fluid={Math.min(784, imageWidth)} />
    </div>

    <HighlightContentContainer>
      <HighlightLogoContainer>
        <Image tw="h-10" image={content.companyLogo} fit="contain" fluid={logoWidth} useRegularImageTag />
      </HighlightLogoContainer>

      <div tw="mx-5 my-4 text-white">
        {quote && <P tw="text-16 my-4 font-medium">{`"${quote}"`}</P>}
        {name && <P tw="text-14 my-1 font-bold ">{name}</P>}
        {jobTitle && <P tw="text-13 my-1 font-medium">{`${jobTitle}, ${content.company_name}`}</P>}
      </div>
    </HighlightContentContainer>
  </Link>
}
