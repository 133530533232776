import { Link } from "gatsby"
import React from "react"
import { AcademyCourseStoryblok, BlogPostStoryblok, HelpVideoStoryblok } from "../../../component-types-sb"
import BlogCard from "../cards/BlogCard"
import { H3 } from "../typography/Typography"
import { VideoCard } from "../cards/VideoCard"
import { AcademyCard } from "../cards/AcademyCard"
import ClientOnly from "./ClientOnly"

interface Props {
  readonly name: string
  readonly cards: {
    full_slug: string
    content: string
  }[]
  readonly link?: {
    text: string
    full_slug: string
  }
}

const CardList: React.FC<Props> = ({ name, cards, link }) => <section tw="max-w-container-large mx-auto w-full">
  <div tw="flex justify-between items-baseline border-b border-gray-5">
    <H3>{name}</H3>
    {link && <Link to={`/${link.full_slug}`}>
      <span tw="underline">{link.text}</span>
    </Link>}
  </div>
  <div tw="grid grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3 gap-8 mt-8">
    {cards.map((card) => {
      const content = JSON.parse(card.content) as BlogPostStoryblok | HelpVideoStoryblok | AcademyCourseStoryblok
      switch (content.component) {
        case "blogPost":
          return <BlogCard key={card.full_slug} content={content} slug={card.full_slug} />
        case "helpVideo":
          return <VideoCard key={card.full_slug} content={content} full_slug={card.full_slug} />
        case "academyCourse":
          return <ClientOnly key={card.full_slug}>
            <AcademyCard content={content} full_slug={card.full_slug} />
          </ClientOnly>
        default:
          return <p>Can&apos;t find card type. Only BlogPost, HelpVideo and AcademyCard are supported</p>
      }
    })}
  </div>
</section>

export default CardList
