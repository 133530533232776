import React from "react"
import { graphql, PageProps } from "gatsby"
import { VirtualBackgroundPlatformStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"
import VirtualBackgroundPlatform from "../components/templates/VirtualBackgroundsPlatform"

interface GQLResult extends GQLStory {
  tags: {
    nodes: {
      full_slug: string
      content: string
      lang: string
    }[]
  }
}

const VirtualBackgroundPlatformTemplate: React.FC<PageProps<GQLResult>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: VirtualBackgroundPlatformStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <VirtualBackgroundPlatform blok={content} />
  </BaseTemplate>
}

export default VirtualBackgroundPlatformTemplate

export const query = graphql`query VirtualBackgroundPlatformQuery($slug: String!, $lang: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  tags: allStoryblokEntry(
    filter: {field_component: {eq: "virtualBackgroundPlatform"}, lang: {eq: $lang}}
    sort: {first_published_at: DESC}
  ) {
    nodes {
      content
      full_slug
      lang
    }
  }
}`
