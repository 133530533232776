import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { CardGridStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import CardGridComponent from "../general/CardGridComponent"

interface Props {
  readonly blok: CardGridStoryblok
  readonly noContainer?: boolean
}

interface GridComponentProps {
  five_columns?: boolean
  spacing?: "normal" | "tight" | "large"
  width?: "normal" | "wide" | "small"
  columns?: number
}

interface GridWrapperProps {
  styling: "normal" | "blue"
}

const GridWrapper = styled.div<GridWrapperProps>(({ styling }) => [
  tw`w-full py-4`,
  styling === "blue" && tw`py-16 mt-8 bg-white-blue`,
])

const columnStyles = {
  1: tw`grid-cols-1`,
  2: tw`grid-cols-1 laptop:grid-cols-2`,
  3: tw`grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3`,
  4: tw`grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-4`,
  5: tw`grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-5`,
  6: tw`grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-6`,
}

const isValidColumnNumber
  = (key: number): key is keyof typeof columnStyles => (
    typeof columnStyles[key as keyof typeof columnStyles] !== "undefined"
  )

export const GridComponent = styled.div<GridComponentProps>(({ spacing, width, columns = 3 }) => [
  tw`
    grid w-full my-4
    [&[data-stretch-items='true']]:(
      [& > *]:height[unset]
    )
  `,
  spacing === "tight" && tw`gap-4`,
  spacing === "normal" && tw`gap-4 tablet:gap-8`,
  spacing === "large" && tw`gap-4 tablet:(gap-x-16 gap-y-8) laptop:gap-x-20`,
  width === "small" && tw`max-w-container-small`,
  (width === "normal" || !width) && tw`max-w-container`,
  width === "wide" && tw`max-w-container-large`,
  isValidColumnNumber(columns)
    ? columnStyles[columns]
    : columnStyles[3],
])

const CardGrid: React.FC<Props> = ({ blok, noContainer, ...rest }) => <SbEditable content={blok}>
  <GridWrapper styling={blok.styling} {...rest}>
    <Container noContainer={noContainer}>
      <GridComponent
        spacing={blok.spacing}
        width={blok.width}
        columns={Number(blok.max_columns)}
        data-item-style={blok.item_style}
        data-item-background-color={blok.item_background}
        data-stretch-items={blok.stretch_cards}
      >
        {blok.items.map((item) => <CardGridComponent blok={item} key={item._uid} />)}
      </GridComponent>
    </Container>
  </GridWrapper>
</SbEditable>

export default CardGrid
