import Cookies from "js-cookie"
import { useLocation } from "@reach/router"
import { useCookiebot } from "./useCookiebot"

const useCookies = (): void => {
  const { search } = useLocation()
  const { useIsMarketing } = useCookiebot()
  const searchParams = new URLSearchParams(search)

  // Set promo code session cookie
  const promoCode = searchParams.get("promo")
  if (promoCode && !Cookies.get("webinargeek_promo")) Cookies.set("webinargeek_promo", promoCode)

  // Set affiliate cookie
  const affiliateToken = searchParams.get("r")
  const geekVisitor = Cookies.get("webinargeek_visitor")
  const geekAffiliate = Cookies.get("webinargeek_affiliate")
  const marketing = useIsMarketing()

  // They are a new affiliate if they have a URL token, they haven't visited the site before as
  const newAffiliate = affiliateToken && !geekVisitor && !geekAffiliate
  if (marketing) {
    if (newAffiliate) {
      Cookies.set("webinargeek_affiliate", affiliateToken, { expires: 7 })
      Cookies.set("webinargeek_visitor", "1", { expires: 365 })
    }
  } else if (newAffiliate) {
    Cookies.set("webinargeek_affiliate", affiliateToken)
  }
}

export default useCookies
