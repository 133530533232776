import React, { Fragment, useCallback, useEffect, useMemo } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { AcademyCourseStoryblok, AcademyGridNewStoryblok, AcademyTagStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import { AcademyCard } from "../cards/AcademyCard"
import Container from "../general/Container"
import { H2, Subtitle } from "../typography/Typography"
import useAcademyEntries from "../../hooks/useAcademyEntries"
import AcademyTagFilter from "./AcademyTagFilter"
import AcademyWrapper, { getDuration, tagTypes, useAcademyFilter } from "./AcademyWrapper"

interface Props {
  readonly blok: AcademyGridNewStoryblok
}

const BaseGrid = tw.div`
  grid grid-cols-1
  w-full max-w-md
  laptop:(grid-cols-2 max-w-container-large)
  desktop:grid-cols-3
`

const Wrapper = tw.div`
  flex flex-row
  gap-8
  w-full max-w-md
  laptop:(max-w-container-large)
`

const CardGrid = tw(BaseGrid)`
  col-span-4
  gap-4
  my-4
  height[fit-content]
  flex-1
  tablet:gap-8
`

const NoResultsWrapper = tw.div`
  flex flex-col items-center justify-center
  text-center
  col-span-1
  desktop:(col-span-2 row-span-1)
  desktop-xl:(col-span-3 row-span-2)
`

const AcademyGridNew: React.FC<Props> = ({ blok }) => {
  const courses = useAcademyEntries()
  const { locale } = useLocale()
  const localizedCourses = useMemo(() => courses.filter((course) => course.lang === locale), [courses, locale])
  const { selectedFilters, setDurations } = useAcademyFilter()

  const isOneOfTagsSelected = useCallback((tags: { content: AcademyTagStoryblok }[]) => {
    // Returns true if at least one of the tag filters doesn't match
    const doesNotMeetFilter = tagTypes.find((type) => !(selectedFilters[type].includes("AllTags")
      || (tags?.some((tag: { content: AcademyTagStoryblok }) => (
        selectedFilters[type].includes(tag.content.title))))))

    return !doesNotMeetFilter
  }, [selectedFilters])

  // Set max duration
  useEffect(() => {
    if (localizedCourses) {
      let maxDuration = -1

      localizedCourses.forEach((course) => {
        const content = JSON.parse(course.content) as AcademyCourseStoryblok
        const durationFromField = course.fields?.duration ?? 0
        const duration = getDuration(content.duration) ?? durationFromField

        if (maxDuration < duration || maxDuration < 0) maxDuration = duration + (5 - duration % 5)
      })

      setDurations((prev) => ({ ...prev, max: maxDuration }))
    }
  }, [localizedCourses, setDurations])

  const availableCourses = localizedCourses.filter((course) => {
    const content = JSON.parse(course.content) as AcademyCourseStoryblok
    const tags = content.tags as { content: AcademyTagStoryblok }[]
    const durationFromField = course.fields?.duration ?? 0
    const duration = getDuration(content.duration) ?? durationFromField

    return isOneOfTagsSelected(tags) && duration <= selectedFilters.duration
  })

  const filterTexts = {
    all_tag_title: blok.all_tag_title,
    duration_filter_title: blok.duration_filter_title,
    expert_level_filter_title: blok.expert_level_filter_title,
    open_filter_btn: blok.open_filter_btn,
    reset_filter_btn: blok.reset_filter_btn,
    topic_filter_title: blok.topic_filter_title,
    type_filter_title: blok.type_filter_title,
  }

  return <SbEditable content={blok}>
    <Container>
      <Wrapper>
        {/* Filter for desktop, outside of CardGrid */}
        <div tw="hidden laptop:block width[200px]">
          <AcademyTagFilter texts={filterTexts} />
        </div>

        <CardGrid>
          {/* Filter for mobile devices, as part of CardGrid */}
          <div tw="block laptop:hidden">
            <AcademyTagFilter texts={filterTexts} />
          </div>

          {availableCourses.length > 0
            ? <Fragment>
              {availableCourses.map((course) => {
                const content = JSON.parse(course.content) as AcademyCourseStoryblok
                return (
                  <AcademyCard
                    key={course.uuid}
                    content={content}
                    full_slug={course.full_slug}
                    duration={typeof content.duration === "string"
                      ? Number(content.duration)
                      : content.duration}
                  />
                )
              })}
            </Fragment>
            : <NoResultsWrapper>
              <H2>{blok.no_results_title}</H2>
              <Subtitle>{blok.no_results_subtitle}</Subtitle>
            </NoResultsWrapper>}
        </CardGrid>
      </Wrapper>
    </Container>
  </SbEditable>
}

const AcademyGridWrapper: React.FC<Props> = ({ blok }) => <AcademyWrapper>
  <AcademyGridNew blok={blok} />
</AcademyWrapper>

export default AcademyGridWrapper
