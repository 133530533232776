import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { ButtonGroupStoryblok, ButtonStoryblok, HeroStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import LinkButton from "../general/LinkButton"
import Image from "../general/Image"
import { H1, Subtitle } from "../typography/Typography"
import { WistiaVideo } from "../general/WistiaEmbed"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import RegisterForm from "../general/RegisterForm"
import { getImageDimensions } from "../../utils/getImageDimensions"
import LinkResolver from "../general/Link"
import Container from "../general/Container"
import { BunnyVideo } from "../general/BunnyEmbed"
import ButtonGroup from "./ButtonGroup"

const ContentWrapper = tw.div`
  max-w-container-large w-full mx-auto
  grid gap-x-20 gap-y-16
  items-center
  desktop:(
    grid-template-columns[1fr 1fr]
  )
  [&[data-hero-text-image-ratio="1:2"]]:(
    desktop:(
      grid-template-columns[2fr 3fr]
    )
  )
`

export const HeroTitle = tw(H1)`
  text-center
  mt-0
  whitespace-pre-line
  desktop:text-left
  [.section-dark-background &]:(text-white)
  [span]:text-orange
`

export const isButtonGroup = (value: ButtonGroupStoryblok | ButtonStoryblok): value is ButtonGroupStoryblok => (
  value && "buttons" in value
)

interface Props {
  readonly blok: HeroStoryblok
}

const MediaWrapper = styled.div<{ showImageMobile: boolean }>(({ showImageMobile }) => [
  tw`flex-1`,
  !showImageMobile && tw`hidden desktop:block`,
])

const BadgeWrapper = tw.div`
  flex gap-5
  items-end justify-center
  mt-16
  [> *]:flex-shrink
`

// eslint-disable-next-line complexity
const Hero: React.FC<Props> = ({ blok }) => {
  const { checks, badges } = useGlobalSettings()
  const { width } = getImageDimensions(blok.image)
  const { width: fullHeightImageWidth } = getImageDimensions(blok.full_height_image)
  const defaultWidth = blok.text_image_ratio === "1:2"
    ? 667
    : 556
  return <SbEditable content={blok} key={blok._uid}>
    <Container tw="pb-8">
      <ContentWrapper data-hero-text-image-ratio={blok.text_image_ratio ?? "1:1"}>
        <div>
          <HeroTitle dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.title) }} />
          <Subtitle
            tw="text-center desktop:text-left [.section-dark-background &]:(text-white)"
            dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.description) }}
          />
          {blok.showRegistrationForm !== "none" && blok.showRegistrationForm !== "noFormWithChecks"
          && <div tw="w-full">
            <RegisterForm layoutStyle="hero" customCta={blok.registration_cta} />
          </div>}
          {blok.button && blok.button.length > 0 && <>
            {isButtonGroup(blok.button[0])
              ? <div tw="w-fit mx-auto desktop:(w-full mx-0)">
                <ButtonGroup blok={blok.button[0]} />
              </div>
              : <div tw="flex justify-center desktop:justify-start w-full">
                <LinkButton button={blok.button[0]} />
              </div>}
          </>
          }
          {(blok.showRegistrationForm === "checks" || blok.showRegistrationForm === "noFormWithChecks")
          && <div tw="text-center desktop:text-left text-14">
            {checks.map((check) => <span
              tw="mr-2 text-gray-2 font-light [.section-dark-background &]:(text-white font-normal)"
              key={check.text}
            >
              <span tw="text-blue mr-2">
                <FontAwesomeIcon icon={faCheck} />
              </span>
              {check.text}
            </span>)}
          </div>}
        </div>
        <MediaWrapper showImageMobile={Boolean(blok.show_image_mobile)}>
          {blok.image?.filename && <Image
            tw="laptop:(w-full object-right! ml-auto)"
            image={blok.image}
            fluid={Math.min(defaultWidth, width)}
            lazy={false}
            showPlaceholder={false} />
          }
          {blok.wistia_url && !blok.bunny_id && <div><WistiaVideo url={blok.wistia_url} /></div>}
          {blok.bunny_id && <div><BunnyVideo bunnyId={blok.bunny_id} /></div>}
        </MediaWrapper>
      </ContentWrapper>
      {blok.show_badges && <BadgeWrapper>
        {badges.map(({ image, url }) => <LinkResolver url={url} key={image.id}>
          <Image image={image} width="75px" />
        </LinkResolver>)}
      </BadgeWrapper>}
    </Container>
    {blok.full_height_image?.filename && <div
      tw="absolute left-1/2 right-0 inset-y-0 h-full overflow-hidden hidden desktop:block"
    >
      <Image
        tw="laptop:(min-h-full w-full object-left! object-cover)"
        image={blok.full_height_image}
        fluid={Math.min(defaultWidth, fullHeightImageWidth)}
        lazy={false}
        showPlaceholder={false}
      />
    </div>}
  </SbEditable>
}

export default Hero
