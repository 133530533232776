import React, { useState } from "react"
import SbEditable from "storyblok-react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { PopupStoryblok } from "../../../component-types-sb"
import DefaultResolver from "../blogComponents/DefaultResolver"
import Modal from "./Modal"

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  readonly blok: PopupStoryblok
}

const Popup: React.FC<Props> = ({ children, blok, ...rest }) => {
  const [open, setOpen] = useState(false)

  return (
    <SbEditable content={blok}>
      <button onClick={() => setOpen(true)} {...rest}>
        {children}
      </button>
      <Modal
        open={open}
        setOpen={setOpen}
        textAlign={blok.text_align}
        vCentered={blok.vertical_centered}
      >
        <Modal.Title>{blok.popup_title}</Modal.Title>
        <Modal.Subtitle>{blok.popup_description}</Modal.Subtitle>
        <Modal.Body className="section-dark-background" style={{
          textAlign: blok.text_align === "center"
            ? "center"
            : "left",
        }}>
          <DefaultResolver content={blok.richtext as DocumentNode} data-centered="center" />
        </Modal.Body>
      </Modal>
    </SbEditable>
  )
}

export default Popup
