import React from "react"
import SbEditable from "storyblok-react"
import { MediaVideoStoryblok } from "../../../component-types-sb"
import { WistiaVideo } from "../general/WistiaEmbed"
import { BunnyVideo } from "../general/BunnyEmbed"

interface Props {
  readonly blok: MediaVideoStoryblok
}

const MediaVideo: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    {blok.bunny_id && <BunnyVideo bunnyId={blok.bunny_id} />}
    {blok.wistia_url && !blok.bunny_id && <WistiaVideo url={blok.wistia_url} />}
  </SbEditable>
)

export default MediaVideo
