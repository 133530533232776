import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { MediaImageStoryblok } from "../../../component-types-sb"
import classNames from "../../utils/classNames"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Image from "../general/Image"

const Wrapper = tw.div`
  grid grid-template-areas["image"]
  [> *]:grid-area[image]
`

const ImageWrapper = tw.div`
  --image-padding[16px]
  desktop:--image-padding[44px]
  padding-right[var(--image-padding)]
  w-full
  [[data-media-image~="box-none"] &]:(
    pr-0
  )
  [[data-media-image~="box-top"] &]:(
    origin-top-right
    padding-top[var(--image-padding)]
    place-self[end start]
  )
  [[data-media-image~="box-bottom"] &]:(
    origin-bottom-right
    padding-bottom[var(--image-padding)]
    place-self[start]
  )
`

const Box = tw.div`
  w-72 h-52
  [[data-media-image~="box-dark"] &]:(
    bg-blue
  )
  [[data-media-image~="box-medium"] &]:(
    bg-blue-baby
  )
  [[data-media-image~="box-light"] &]:(
    bg-white-blue-3
  )
  [[data-media-image~="box-top"] &]:(
    place-self[start end]
  )
  [[data-media-image~="box-bottom"] &]:(
    place-self[end]
  )
`

interface Props {
  readonly blok: MediaImageStoryblok
}

const maxWidth = 900

const MediaImage: React.FC<Props> = ({ blok }) => {
  const { width } = getImageDimensions(blok.image)
  return (
    <SbEditable content={blok}>
      <Wrapper
        data-media-image={classNames([blok.box_color, blok.box_position ?? "box-none"])}
      >
        {blok.box_position && <Box />}
        <ImageWrapper>
          <Image image={blok.image} fluid={Math.min(maxWidth, width)} width="100%" />
        </ImageWrapper>
      </Wrapper>
    </SbEditable>
  )
}

export default MediaImage
