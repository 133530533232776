import React, { useEffect, useState } from "react"
import _ from "lodash"
import { TagPage as TagType } from "../../hooks/useTags"
import BackgroundCard from "../virtualBackgroundComponents/BackgroundCard"
import ExploreBackgroundsSection from "../virtualBackgroundComponents/ExploreBackgroundsSection"
import VirtualBackgroundGrid from "../virtualBackgroundComponents/VirtualBackgroundGrid"
import VirtualBackgroundHeader from "../virtualBackgroundComponents/VirtualBackgroundHeader"
import { isUndefined } from "../../utils/typeGuards"
import useBackgroundEntries from "../../hooks/useBackgroundEntries"
import useLocale from "../../hooks/useLocale"
import { Tag } from "../virtualBackgroundComponents/MoreBackgroundsSection"
import Section from "../general/Section"
import {
  VirtualBackgroundTagStoryblok,
  VirtualBackgroundPlatformStoryblok,
  SectionStoryblok,
} from "../../../component-types-sb"
import { useVirtualBackgroundSettings } from "../../contexts/VirtualBackgroundSettingsContext"
import useBackgroundTags from "../../hooks/useBackgroundTags"
import SearchWithTags from "../general/SearchWithTags"
import ClientOnly from "../general/ClientOnly"

const addTagTitleToText = (text: string | undefined, tag: TagOrPlatform | undefined) => (
  text?.replace("{{tagTitle}}", isUndefined(tag)
    ? ""
    : tag.title).replace("  ", " ") ?? ""
)

type TagOrPlatform = VirtualBackgroundTagStoryblok | VirtualBackgroundPlatformStoryblok

interface TagPageProps {
  readonly tag?: TagOrPlatform
  readonly blocks?: SectionStoryblok[]
}

export const VirtualBackgroundList = ({ tag, blocks }: TagPageProps) => {
  const { index_title, index_description, index_buttons } = useVirtualBackgroundSettings()
  const title = addTagTitleToText(index_title, tag)
  const description = addTagTitleToText(index_description, tag)
  const { locale } = useLocale()
  const tagType = tag?.component
  const [selectedTag, setSelectedTag] = useState<TagType | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>("")

  const allBackgrounds = useBackgroundEntries()

  const filteredBackgrounds = allBackgrounds.filter((bg) => {
    const matchesLang = bg.lang === locale
    if (tagType === "virtualBackgroundPlatform") {
      return matchesLang
    }
    const bgTag = bg.content.tag as unknown as Tag
    const matchesTagIfDefined = isUndefined(tag) || bgTag.content.title === tag.title
    return matchesLang && matchesTagIfDefined
  })

  const [featuredBackgrounds, setFeaturedBackgrounds] = useState(() => _.sampleSize(filteredBackgrounds, 2))

  useEffect(() => {
    setFeaturedBackgrounds(_.sampleSize(filteredBackgrounds, 2))
  // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount, no need to re-run
  }, [])

  const backgroundsWithoutFeatured = filteredBackgrounds.filter((bg) => !featuredBackgrounds.includes(bg))

  const backgroundsWithoutFeaturedSearched = backgroundsWithoutFeatured.filter((bg) => {
    const bgTag = bg.content.tag as unknown as Tag
    const matchesFilterTag = selectedTag === null || selectedTag.title === bgTag.content.title
    const matchesSearch = bg.content.title.toLowerCase().includes(searchQuery.toLowerCase())
      || bgTag.content.title.toLowerCase().includes(searchQuery.toLowerCase())
    return matchesFilterTag && matchesSearch
  })

  const tags = useBackgroundTags()
  const { all_filter_text, search_placeholder, search_title } = useVirtualBackgroundSettings()

  return (
    <>
      <ClientOnly>
        <VirtualBackgroundHeader
          title={title}
          description={description}
          tag={tag?.content}
          featuredBackgrounds={featuredBackgrounds.map((bg) => <BackgroundCard background={bg} key={bg.full_slug} />)}
          buttons={index_buttons}
          backgroundsLeft={false}
        />
      </ClientOnly>
      <SearchWithTags
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        tagsDisabled={tagType === "virtualBackgroundTag"}
        all_filter_text={all_filter_text}
        search_placeholder={search_placeholder}
        search_title={search_title}
        tags={tags}
      />
      <VirtualBackgroundGrid
        backgrounds={backgroundsWithoutFeaturedSearched}
        hideFixedCard={searchQuery !== ""}
      />
      {blocks?.map((block) => <Section blok={block} key={block._uid} />)}
      <ExploreBackgroundsSection />
    </>
  )
}
