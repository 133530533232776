import React from "react"
import {
  BlogPostStoryblok,
  PeopleStoryblok,
} from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useBlogDateFormat from "../../hooks/useBlogDateFormat"
import Image from "../general/Image"

interface Props {
  readonly blok: BlogPostStoryblok
}

const BlogMedia: React.FC<Props> = ({ blok }) => {
  const { min_text } = useGlobalSettings()
  const datePublished = useBlogDateFormat(blok.datePublished)
  if (!blok.author) return null
  const { content: author } = blok.author as unknown as {
    content: PeopleStoryblok
  }
  if (author) {
    return (
      <div tw="flex">
        <div tw="mr-4 flex-shrink-0 self-center">
          {author.photo.filename && (
            <Image
              width="50px"
              height="50px"
              tw="rounded-full"
              image={author.photo}
              fixed={[50, 50]}
            />
          )}
        </div>
        <div>
          <p>{author.name}</p>
          <p tw="text-gray-3 text-14">{`${datePublished} - ${
            blok.timeToRead ?? ""
          } ${min_text}`}</p>
        </div>
      </div>
    )
  }
  return null
}

export default BlogMedia
