import React, { useEffect, useRef } from "react"
import { navigate } from "gatsby"
import Cookies from "js-cookie"
import { useLocation } from "@reach/router"
import { useChat } from "react-live-chat-loader"
import {
  AcademyChapterStoryblok,
  AcademyCourseStoryblok,
  BlogPostStoryblok,
  BlogIndexPageStoryblok,
  CategoryPageStoryblok,
  CustomerSuccessStoryStoryblok,
  HelpVideoStoryblok,
  PageStoryblok,
  PricingPageStoryblok,
  RegisterPageStoryblok,
  TagPageStoryblok,
  BackgroundStoryblok,
  VirtualBackgroundsIndexStoryblok,
  VirtualBackgroundTagStoryblok,
  VirtualBackgroundPlatformStoryblok,
  GlossaryIndexStoryblok,
  GlossaryKeywordStoryblok,
} from "../../../component-types-sb"
import useTranslatedLinks from "../../contexts/TranslatedLinksContext"
import { useSitePages } from "../../contexts/SitePagesContext"
import "../../fonts/fonts.css"
import useCookies from "../../hooks/useCookies"
import { rewriteSlug } from "../../utils/rewriteSlug"
import useLocale from "../../hooks/useLocale"
import { changePreferredLang } from "../../utils/changePreferredLang"
import { getBrowserLang } from "../../utils/getBrowserLang"
import { handlePromise } from "../../utils/handlePromise"
import { ChildrenProp } from "../../../react"
import useAlternatesBySlug from "../../hooks/useAlternatesBySlug"
import Seo from "./SEO"

interface Props {
  readonly content: PageStoryblok
  | TagPageStoryblok
  | CategoryPageStoryblok
  | PricingPageStoryblok
  | BlogIndexPageStoryblok
  | BlogPostStoryblok
  | AcademyCourseStoryblok
  | AcademyChapterStoryblok
  | CustomerSuccessStoryStoryblok
  | HelpVideoStoryblok
  | RegisterPageStoryblok
  | BackgroundStoryblok
  | VirtualBackgroundsIndexStoryblok
  | VirtualBackgroundTagStoryblok
  | VirtualBackgroundPlatformStoryblok
  | GlossaryIndexStoryblok
  | GlossaryKeywordStoryblok
  readonly story: {
    content: string
    full_slug: string
    field_component: string
    default_full_slug: string
    translated_slugs: {
      path: string
      lang: string
    }[]
  }
  readonly article?: boolean
  readonly renderLayout?: boolean
}

const BaseTemplate: React.FC<ChildrenProp & Props> = ({ children, content, story }) => {
  const { changeTranslatedLinks } = useTranslatedLinks()
  const changeTranslatedLinksRef = useRef<typeof changeTranslatedLinks>(changeTranslatedLinks)
  const { isSitePage } = useSitePages()
  const isSitePageRef = useRef<typeof isSitePage>(isSitePage)
  const location = useLocation()
  const { locale } = useLocale()
  const [, loadChat] = useChat()
  const { alternates } = useAlternatesBySlug(story.full_slug)

  // Set the footer links
  useEffect(() => {
    changeTranslatedLinksRef.current(story)
  }, [story])

  // set the promo/affiliate cookies based on search params
  useCookies()

  useEffect(() => {
    // eslint-disable-next-line complexity
    const navigateUser = async () => {
      // Get preferred language from cookies if on English page or it's just locale of url
      let preferredLang = locale === "default"
        ? Cookies.get("wg_preferred_lang") ?? null
        : locale
      if (!preferredLang) {
        // If no stored cookies and on English page, get the browser language
        preferredLang = getBrowserLang()

        // If browser language is not a locale or null, get language from ip
        if (!preferredLang || !["nl", "de"].includes(preferredLang)) {
          // Get ip address
          const ipRes = await handlePromise(fetch("https://api.ipify.org?format=json")
            .then((response) => response.json() as Promise<{ ip: string }>))
          if (ipRes.error || !ipRes.data.ip) throw new Error("Failed getting ip address")

          // Get country lang from ip
          const ipLangRes = await handlePromise(fetch(`/api/getLangFromIp?ip=${ipRes.data.ip}`)
            .then((res) => res.json() as Promise<string>))
          if (ipLangRes.error) throw new Error("Failed getting language from ip address")
          // eslint-disable-next-line require-atomic-updates
          preferredLang = ipLangRes.data
        }
      }

      // Set the cookie preferred language
      if (preferredLang) changePreferredLang(preferredLang)

      let slug = story.default_full_slug
      // Get translated slug if not default language
      if (preferredLang && preferredLang !== "default") {
        const translated_slug = story.translated_slugs.find((x) => x.lang === preferredLang)
        if (translated_slug) slug = `${translated_slug.lang}/${translated_slug.path}`
      }
      // Find out if the translated slug exists before navigating
      const existingSlug = isSitePageRef.current(slug) ?? rewriteSlug(story.default_full_slug)
      // Only navigate the user if the current location is different from navigation location and on an English lang page
      if (
        location.pathname !== existingSlug
        && !["nl", "de"].includes(locale)
      ) void navigate(`${existingSlug}${location.search}`, { replace: true })
    }

    // Don't run navigation inside Storyblok editor or if 404 page
    if (!location?.search.includes("_storyblok")
      && story.full_slug !== "404"
    ) {
      void navigateUser()
        .catch((err: unknown) => console.error(err))
    }
  }, [story, location, locale])

  useEffect(() => {
    if (location.search.includes("chat=open")) loadChat({ open: true })
  }, [location, loadChat])

  const metaData = {
    ...content.meta_data,
    og_image: content.component === "blogPost" && !content.meta_data?.og_image
      ? content.coverImage.filename
      : content.meta_data?.og_image,
  }

  return <>
    <Seo
      {...metaData}
      default_full_slug={story.default_full_slug}
      translated_slugs={story.translated_slugs}
      article={["blogPost", "customerSuccessStory", "academyChapter"].includes(content.component)}
      full_slug={story.full_slug}
      noindex={content.component === "page"
        ? content.noindex
        : false}
      nofollow={content.component === "page"
        ? content.nofollow
        : false}
      canonicals={alternates.map((x) => x.full_slug)}
    />
    {children}
  </>
}

export default BaseTemplate
