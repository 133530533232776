import React from "react"
import { GatsbyBrowser } from "gatsby"
import { GlobalSettingsProvider } from "../contexts/GlobalSettingsContext"
import GlobalStyles from "../components/general/GlobalStyle"
import HiddenContextProvider from "../contexts/HiddenContext"
import FeatureFilterContextProvider from "../contexts/FeatureFilterContext"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element, props }) => (
  <GlobalSettingsProvider key={props.pageContext.lang as string}>
    <HiddenContextProvider>
      <FeatureFilterContextProvider>
        <GlobalStyles />
        {element}
      </FeatureFilterContextProvider>
    </HiddenContextProvider>
  </GlobalSettingsProvider>
)
