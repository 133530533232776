import React from "react"

interface Props {
  readonly noContainer?: boolean
  readonly children?: React.ReactNode
}

const Container: React.FC<Props> = ({ children, noContainer, ...rest }) => {
  if (noContainer) return <>{children}</>
  return <div tw="px-4 tablet:px-6 w-full flex flex-col items-center relative z-10" {...rest}>
    {children}
  </div>
}

export default Container
