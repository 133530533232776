import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { Link, Link as SpyLink } from "react-scroll"
import { Menu } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-light-svg-icons"
import { PageNavItemStoryblok } from "../../../component-types-sb"
import usePageNav from "../../hooks/usePageNav"

interface Props {
  readonly pageNavItems: PageNavItemStoryblok[]
}

interface ChapterProps {
  state: string
}

export const ChapterNavWrapper = tw.div`
  sticky top[168px]
  z-20
  max-width[calc((100vw - 1192px)/2)]
`

const ChapterNav = styled.nav<ChapterProps>(({ state }) => [
  tw`hidden px-4 transition-transform duration-500 transform -translate-x-full desktop-2xl:block`,
  state === "active" && tw`translate-x-0`,
])

// Before adds a circle in front of the chapter
// After adds a line between two chapers which has the height of the chapter
// The fist and last chapter only use a line half the height, so it ends with the dot
// If there is only one chapter, the line won't be visible
export const Chapter = styled.li<ChapterProps>(({ state }) => [
  tw`
    flex items-center
    transform
    py-1.5
    before:(
      width[5px] height[5px]
      mr-4
      rounded-full
      flex-shrink-0
    )
    after:(
      absolute top-1/2 left[2px]
      w-px h-full
      transform -translate-y-1/2
    )
    first:after:(
      h-1/2 top-1/2
      translate-y-0
    )
    last:after:(
      h-1/2 top-0
      translate-y-0
    )
    first:last:after:hidden
  `,
  ["read", "current"].includes(state)
    && tw`text-blue before:bg-current after:bg-current`,
  state === "unread" && tw`text-gray-4 before:bg-current after:bg-current`,
])

export const ChapterLine = styled.span<ChapterProps>(({ state }) => [
  tw`cursor-pointer hover:(underline text-blue)`,
  state === "unread" && tw`before:bg-gray-3 after:bg-gray-3`,
])

const DropdownNavWrapper = tw.div`
  sticky top[70px]
  bg-white text-gray-3
  text-14
  z-20
  shadow
  h-11
  px-4
  flex items-center
  tablet:px-6
  desktop-2xl:hidden
`

const DropdownNav = tw.div`
  relative
  h-full
  flex items-center
`

const MenuItem = styled.button<{ active: boolean }>(({ active }) => [
  tw`block w-full px-2 py-1 text-left rounded`,
  active && tw`text-white bg-blue`,
])

const Text = styled.span<{ active: boolean }>(({ active }) => [active && tw`text-blue`])

const PageNavChapterList: React.FC<Props> = ({ pageNavItems }) => {
  const chapterList = pageNavItems
  const { activeId, showMenu } = usePageNav("section")

  return (
    <div tw="mt-11 desktop-2xl:m-0">
      <div tw="absolute inset-0">
        {/* Chapter navigation, visible on screens wider than desktop-2xl */}
        <ChapterNavWrapper>
          <ChapterNav state={showMenu
            ? "active"
            : ""}>
            <ul tw="text-14">
              {chapterList?.map((pageNavItem, index) => {
                if (!pageNavItem.text) return null
                const { text } = pageNavItem
                if (!text) return null
                let state = "unread"
                if (index === activeId) state = "current"
                if (index < activeId) state = "read"
                return (
                  <Chapter key={text} state={state}>
                    <Link
                      to={pageNavItem.anchor}
                      smooth={true}
                      offset={-75}
                      tw="overflow-hidden overflow-ellipsis whitespace-nowrap"
                    >
                      <ChapterLine state={state}>{text}</ChapterLine>
                    </Link>
                  </Chapter>
                )
              })}
            </ul>
          </ChapterNav>
        </ChapterNavWrapper>

        {/* Dropdown, visible on screens smaller than desktop-2xl */}
        <DropdownNavWrapper>
          <DropdownNav data-id="pagenav-menu">
            <Menu>
              <Menu.Button tw="flex items-center hocus:(text-blue) focus:outline-none focus-visible:outline-black">
                Sections
                <FontAwesomeIcon tw="ml-2" icon={faChevronDown} />
              </Menu.Button>
              <Menu.Items tw="absolute top-full left-0 z-50 bg-white shadow p-1 rounded w-max max-w-xs
                focus:outline-none focus-visible:outline-black">
                {chapterList.map((item, index) => (
                  <Menu.Item key={item.anchor}>
                    {({ active }) => (
                      <SpyLink
                        to={item.anchor}
                        tw="cursor-pointer"
                        offset={-114}
                        smooth
                      >
                        <MenuItem active={active}>
                          <Text active={activeId === index && !active}>
                            {item.text}
                          </Text>
                        </MenuItem>
                      </SpyLink>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Menu>
          </DropdownNav>
        </DropdownNavWrapper>
      </div>
    </div>
  )
}

export default PageNavChapterList
