import { graphql, PageProps } from "gatsby"
import React from "react"
import { TagPageStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import CategoryPage from "../components/templates/CategoryPage"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

interface GQLResult extends GQLStory {
  stories: {
    nodes: {
      name: string
      full_slug: string
      content: string
    }[]
  }
}

const TagTemplate: React.FC<PageProps<GQLResult>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: TagPageStoryblok }
  return (
    <BaseTemplate
      content={content}
      story={data.story}
    >
      <CategoryPage blok={content} stories={data.stories.nodes} />
    </BaseTemplate>
  )
}

export default TagTemplate

export const query = graphql`query FilterByTag($regexSlug: String, $lang: String, $slug: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  stories: allStoryblokEntry(
    filter: {tag_list: {regex: $regexSlug}, lang: {eq: $lang}}
    sort: {first_published_at: DESC}
  ) {
    nodes {
      name
      full_slug
      content
    }
  }
}`
