import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { m, Variants } from "framer-motion"
import { IconCircleStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { useWindowSize } from "../../hooks/useWindowSize"
import InViewWrapper from "../general/InViewWrapper"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"

const Wrapper = tw(InViewWrapper)`
  grid grid-template-areas["circle"]
  [> *]:grid-area[circle]
  place-items-center
`

const MainLogo = tw(m.div)`
  h-20 w-20
  mx-28 my-24
  tablet:(
    h-32 w-32
    mx-40 my-36
    p-6
  )
  isolate
  bg-white
  p-3
  rounded-full
`

const mainLogoVariants: Variants = {
  offscreen: {
    scale: 0,
  },
  onscreen: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
}

interface smallLogoProps {
  index: number
  smallModifier: number
}
const iconXScale = 160
const iconYScale = 140
const smallLogoVariants: Variants = {
  offscreen: {
    scale: 0,
    x: 0,
    y: 0,
  },
  onscreen: ({ index, smallModifier }: smallLogoProps) => ({
    scale: 1,
    transition: {
      delay: index * 0.2 + 0.5,
      duration: 0.5,
      type: "spring",
    },
    x: Math.cos(index / 4 * Math.PI) * iconXScale * smallModifier,
    y: -1 * Math.sin(index / 4 * Math.PI) * iconYScale * smallModifier,
  }),
}

const smallLogoRepeatingVariants: Variants = {
  onscreen: (i: number) => ({
    transition: {
      delay: i * 0.25,
      duration: 1,
      yoyo: Infinity,
    },
    y: 5,
  }),
}

const SmallLogo = tw(m.div)`
  flex items-center justify-center
  bg-white
  rounded-full
  shadow-hover
  w-16 h-16
  tablet:(w-24 h-24)
  p-4
  [img]:height[50px]
`

interface Props {
  readonly blok: IconCircleStoryblok
}

const IconCircle: React.FC<Props> = ({ blok }) => {
  const { width } = useWindowSize()
  const smallModifier = width < 576
    ? 0.7
    : 1
  const { integration_main_image, integration_images } = useGlobalSettings()
  return (
    <SbEditable content={blok}>
      <Wrapper>
        {integration_images.map((icon, index) => (
          <m.div variants={smallLogoVariants} custom={{ index, smallModifier }} key={icon.id}>
            <SmallLogo variants={smallLogoRepeatingVariants} custom={index}>
              <Image image={icon} />
            </SmallLogo>
          </m.div>
        ))}
        <MainLogo variants={mainLogoVariants}>
          <Image image={integration_main_image} fixed={[128, 128]} useRegularImageTag />
        </MainLogo>
      </Wrapper>
    </SbEditable>
  )
}

export default IconCircle
