import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faPinterestSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import SbEditable from "storyblok-react"
import { SocialIconsStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"

interface Props {
  readonly blok: SocialIconsStoryblok
}

const SocialIcons: React.FC<Props> = ({ blok }) => {
  const { facebook, twitter, linkedin, instagram, pintrest } = useGlobalSettings()

  const icons = [
    { icon: faFacebookSquare, link: facebook, title: "Facebook" },
    { icon: faTwitterSquare, link: twitter, title: "Twitter" },
    { icon: faLinkedin, link: linkedin, title: "LinkedIn" },
    { icon: faInstagram, link: instagram, title: "Instagram" },
    { icon: faPinterestSquare, link: pintrest, title: "Pintrest" },
  ]
  return <SbEditable content={blok}>
    <div tw="flex space-x-4 my-4 justify-center">
      {icons.map(({ icon, link, title }) => <a
        key={title}
        tw="text-24 hocus:text-blue"
        target="_blank"
        href={link}
        rel="noreferrer noopener"
      >
        <span tw="sr-only">{`WebinarGeek ${title} page`}</span>
        <FontAwesomeIcon icon={icon} />
      </a>)}
    </div>
  </SbEditable>
}

export default SocialIcons
