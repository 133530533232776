interface Locale {
  locale: "default" | "nl" | "de"
  name: string
}

const locales: Locale[] = [
  {
    locale: "default",
    name: "English",
  },
  {
    locale: "nl",
    name: "Dutch",
  },
  {
    locale: "de",
    name: "Deutsch",
  },
]

export default locales
