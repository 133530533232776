import React from "react"
import SbEditable from "storyblok-react"
import RegisterForm from "../general/RegisterForm"
import { RegisterFormComponentStoryblok } from "../../../component-types-sb"

interface Props {
  readonly blok: RegisterFormComponentStoryblok
}

const RegisterFormComponent: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div tw="max-w-blog-text w-full">
    <RegisterForm layoutStyle="hero" />
  </div>
</SbEditable>

export default RegisterFormComponent
