import React, { useRef } from "react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import SbEditable from "storyblok-react"
import { AccordionItemStoryblok } from "../../../component-types-sb"
import { H6 } from "../typography/Typography"
import { Richtext } from "./Richtext"
import DetailsOpenIcon from "./DetailsOpenIcon"

interface Props {
  readonly blok: AccordionItemStoryblok
  readonly open: boolean
  readonly setOpen: () => void
}

const AccordionItem: React.FC<Props> = ({ blok, open, setOpen }) => {
  const ref = useRef<HTMLDivElement>(null)
  let maxHeight = 0
  if (ref.current && open) maxHeight = ref.current.scrollHeight

  return <SbEditable content={blok}>
    <div tw="border-gray-4 [.section-dark-background &]:text-white">
      <button tw="flex items-center justify-between cursor-pointer w-full pl-4 text-left" onClick={setOpen}>
        <H6>
          {blok.question}
        </H6>
        <div tw="text-blue mr-4 p-2 [.section-dark-background &]:text-white">
          <DetailsOpenIcon isOpen={open} />
        </div>
      </button>
      <div
        tw="overflow-hidden transition-max-height duration-300 ease-linear ml-4"
        ref={ref}
        style={{ maxHeight: `${maxHeight}px` }}
      >
        <Richtext text={blok.answer as DocumentNode} />
      </div>
    </div>
  </SbEditable>
}

export default AccordionItem
