import React from "react"
import { useLocation } from "@reach/router"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import { ContentCardStoryblok, ProductCardsStoryblok } from "../../../component-types-sb"
import ClientOnly from "../general/ClientOnly"
import Grid from "./CardGrid"

interface Props {
  readonly blok: ProductCardsStoryblok
}

const ProductCards: React.FC<Props> = ({ blok }) => {
  const { product_cards } = useGlobalSettings()
  const { pathname } = useLocation()
  let items = product_cards[0].items.filter((item): item is ContentCardStoryblok => item.component === "contentCard")

  // isProductCard is used in the ContentCardComponent to set some styling
  items = items.map((item) => ({
    ...item,
    isProductCard: true,
  }))

  // Mix up the items and remove the card with the same link as the current page
  // eslint-disable-next-line etc/no-assign-mutated-array
  items = items.sort(() => Math.random() - 0.5)
    .filter((item) => item.url?.linktype === "story" && `/${item.url.story?.full_slug ?? ""}` !== pathname)
  if (Number(blok.limit) > 0) items = items.slice(0, Number(blok.limit))

  // Since the cards are in a random order, don't create this component on the server
  return <ClientOnly>
    <Grid blok={{ ...product_cards[0], items, max_columns: "3" }} />
  </ClientOnly>
}

export default ProductCards
