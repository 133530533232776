import React from "react"
import SbEditable from "storyblok-react"
import { VirtualBackgroundTagStoryblok } from "../../../component-types-sb"
import { VirtualBackgroundSettingsProvider } from "../../contexts/VirtualBackgroundSettingsContext"
import { VirtualBackgroundList } from "../virtualBackgroundComponents/VirtualBackgroundList"

interface Props {
  readonly blok: VirtualBackgroundTagStoryblok
}

const VirtualBackgroundTag: React.FC<Props> = ({ blok }) => (
  <VirtualBackgroundSettingsProvider>
    <SbEditable content={blok}>
      <VirtualBackgroundList tag={blok} blocks={blok.blocks} />
    </SbEditable>
  </VirtualBackgroundSettingsProvider>
)

export default VirtualBackgroundTag
