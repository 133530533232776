import IframeResizer from "iframe-resizer-react"
import React, { Suspense } from "react"
import SbEditable from "storyblok-react"
import { NativeFormIframeStoryblok } from "../../../component-types-sb"
import Spinner from "../general/Spinner"

interface Props {
  readonly blok: NativeFormIframeStoryblok
}

const NativeFormIframe: React.FC<Props> = ({ blok }) => {
  if (typeof window === "undefined") return null
  return (
    <SbEditable content={blok}>
      <Suspense fallback={<div tw="flex justify-center w-full my-8"><Spinner size="large" /></div>}>
        <IframeResizer
          src={`${blok.form_url}?website=${window.location.href}&title=${window.document.title}`}
          heightCalculationMethod="lowestElement"
          seamless
          // Setting height here because resizer doesn't work
          // Needs to be fixed
          style={{ height: `${blok.height}px`, minWidth: "100%", width: "1px" }}
        />
      </Suspense>
    </SbEditable>
  )
}

export default NativeFormIframe
