import React, { useState } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faPlusCircle } from "@fortawesome/pro-duotone-svg-icons"
import styled from "styled-components"
import { PricingBlockStoryblok } from "../../../component-types-sb"
import { PricingPlan, usePricingData } from "../../contexts/PricingDataContext"
import { isUndefined } from "../../utils/typeGuards"
import useLocale from "../../hooks/useLocale"
import LinkButton from "./LinkButton"
import Feature from "./Feature"
import LinkResolver from "./Link"
import PricingViewerSelector from "./PricingViewerSelector"

const BlockWrapper = tw.div`
  shadow
  bg-white
  rounded-xl
  flex-1
  [&[data-featured="true"]]:(
    // Important to override the space-y set in the parent
    desktop-xl:-mt-8!
    ring-blue ring-1
    [[data-theme='secondary'] &]:ring-blue-police
  )
  relative
`

const FeaturedWrapper = tw.div`
  w-full
  desktop-xl:h-8
  text-white bg-blue
  font-bold text-18 leading-8 text-center uppercase
  rounded-t-xl
  [[data-theme='secondary'] &]:bg-blue-police
`

const ContentWrapper = tw.div`
  px-4 pt-6 pb-8
  space-y-4
  desktop-xl:pt-4
`

const PlanInfoWrapper = tw.div`
  flex flex-col
  px-4
  text-left
  text-24
  [[data-is-long='true'] &]:text-20
`

const PriceWrapper = tw.div`
  desktop-xl:h-24
  w-full
  mb-6
  text-center
  desktop-xl:text-left
`

const AltPrice = tw.span`
  text-30 [[data-is-long='true'] &]:text-24 font-heading font-normal text-gray-4 line-through
  mr-2
`

const MainPrice = tw.span`
  text-45 [[data-is-long='true'] &]:text-30 font-heading font-medium
`

const BillingCaption = tw.p`
  desktop-xl:text-13
  text-16 text-gray-2
  [span > span]:font-bold
`

const CtaTextWrapper = tw.div`
  desktop-xl:h-11
  space-y-4
  py-2
`

const SavingsText = tw.span`
  text-green text-13 font-bold uppercase
`

const FeaturesWrapper = tw.div`
  flex flex-col space-y-3
  px-4
  desktop-xl:px-0
  [&[data-show="false"]]:(
    hidden
    desktop-xl:flex
  )
`

const ShowFeaturesButton = tw.button`
  block
  desktop-xl:hidden
  space-x-2
  text-blue font-medium
  mx-auto
  [&[data-show="true"]]:(
    [svg]:--tw-rotate[135deg]
  )
  [svg]:(
    transition-transform
    duration-300
    rotate-0
  )
`

const SaleTag = tw.span`
  text-14
  bg-green
  rounded-lg
  text-white
  px-[11px]
  py-[3px]
  font-bold
  ml-3
  h-[min-content]
  [&[data-yellow-badge="true"]]:(
    bg-yellow
  )
`

const ClockIcon = styled(FontAwesomeIcon)`
  --fa-primary-color: #ffffff;
  --fa-secondary-color: #f2c94c;
  --fa-secondary-opacity: 1;
`

const LimitedOfferBadge = ({ text }: { readonly text: string }) => (
  <div tw="absolute -right-5.75 -top-5">
    <ClockIcon
      icon={faClock}
      tw="absolute border rounded-full left-[-7.75px] top-[-7.75px]"
    />
    <span tw="text-[10px] bg-yellow text-white rounded-lg p-2 font-bold inline-flex">{text}</span>
  </div>
)

const formatPrice = (price: number, locale: "de" | "nl" | "default", currency: string) => {
  const locales = {
    de: "nl-NL",
    default: "en-US",
    nl: "nl-NL",
  }

  const formatter = new Intl.NumberFormat(locales[locale] || locales.default, {
    currency,
    minimumFractionDigits: 0,
    style: "currency",
  })

  return formatter.format(Math.round(price)).replace("US", "")
}

interface Props {
  readonly blok: PricingBlockStoryblok
  readonly plan: PricingPlan
  readonly selectAttendees: string
  readonly perMonthText: string
  readonly limitedOffer?: string
  readonly onlyYearly?: boolean
  readonly perYearText?: string
}

// eslint-disable-next-line complexity
const PricingBlock: React.FC<Props> = ({
  blok, plan, selectAttendees, perMonthText, perYearText, limitedOffer, onlyYearly,
}) => {
  const {
    currency, yearlyBilling, getPrice, currentRate, onlyShowUsd,
  } = usePricingData()

  const { locale } = useLocale()

  const [showFeatures, setShowFeatures] = useState(false)

  const billingCaptionText = {
    advanced: blok.billed_annually_text ?? "",
    premium: (yearlyBilling
      ? blok.billed_annually_text ?? ""
      : blok.billed_monthly_text ?? ""),
    standard: (yearlyBilling
      ? blok.billed_annually_text ?? ""
      : blok.billed_monthly_text ?? ""),
  }[plan].replace(/\d+(?:,\d+)?/u, (subscribers) => `<span>${subscribers}</span>`)

  const monthlyPrice = getPrice("monthly", plan)
  const normalPrice = getPrice("normal", plan)
  const annualPrice = getPrice("annual", plan)

  const priceText = onlyYearly
    ? perYearText ?? ""
    : perMonthText

  const planRecommended = Boolean(blok.most_popular_text)

  const hasPrices = monthlyPrice && normalPrice && annualPrice

  const isLong = currency === "SEK" || currency === "NOK" || currency === "DKK"

  return <SbEditable content={blok}>
    <BlockWrapper
      data-featured={planRecommended}
      data-is-long={isLong}
    >
      {limitedOffer && planRecommended && yearlyBilling && <LimitedOfferBadge text={limitedOffer} />}

      {planRecommended && <FeaturedWrapper>
        {blok.most_popular_text}
      </FeaturedWrapper>}
      <PlanInfoWrapper
        tw="bg-blue-primary-1 px-11 py-8 [[data-featured='false'] &]:rounded-t-xl desktop-xl:min-height[162px]"
      >
        <p tw="my-0 text-30 font-medium mb-4 flex items-center">
          {blok.title}
          {yearlyBilling && blok.pricing_sale_badge_text && <SaleTag data-yellow-badge={blok.pricing_sale_badge_yellow}>
            {blok.pricing_sale_badge_text}
          </SaleTag>}
        </p>
        <p tw="text-16">{blok.short_text}</p>
      </PlanInfoWrapper>
      <ContentWrapper>
        <PlanInfoWrapper>
          {blok.custom_price === "-1" && <PriceWrapper tw="flex items-center justify-center">
            <span tw="text-26 text-center my-8 font-medium [[data-is-long='true'] &]:text-20">
              {blok.custom_price_text}
            </span>
          </PriceWrapper>}

          {blok.custom_price !== "-1" && <PriceWrapper>
            <span
              data-hidden={!blok.custom_price}
              tw="desktop-xl:block leading-none h-[18px] [&[data-hidden='true']]:invisible"
            >
              {blok.custom_price_text}
            </span>
            {blok.custom_price && !isUndefined(onlyShowUsd)
              ? <p>
                <MainPrice>
                  {formatPrice(onlyShowUsd && blok.custom_price_US
                    ? Number(blok.custom_price_US)
                    : Number(blok.custom_price) * currentRate, locale, currency)}
                </MainPrice>
                <span tw="ml-1">
                  {priceText}
                </span>
              </p>
              : isUndefined(onlyShowUsd) && <p tw="text-center">
                Loading...
              </p>
              || <>
                {hasPrices && <p>
                  {yearlyBilling && hasPrices && <AltPrice>
                    {formatPrice(monthlyPrice, locale, currency)}
                  </AltPrice>}
                  <MainPrice>
                    {formatPrice(normalPrice, locale, currency)}
                  </MainPrice>
                  <span tw="ml-1">
                    {priceText}
                  </span>
                </p>}
              </>}
            {!onlyYearly && <BillingCaption>
              <span dangerouslySetInnerHTML={{ __html: billingCaptionText }} />
              {yearlyBilling && plan !== "advanced" && hasPrices && blok.save_text && <> - <SavingsText>
                {`${blok.save_text} ${formatPrice((monthlyPrice - annualPrice) * 12, locale, currency)}`}
              </SavingsText>
              </>}
            </BillingCaption>}
          </PriceWrapper>
          }
          <PricingViewerSelector
            plan={plan}
            selectAttendees={selectAttendees}
            customMessage={blok.viewer_selector_text} />
          {blok.link && blok.link.length > 0 && <CtaTextWrapper>
            <p tw="text-center text-16">
              <LinkResolver url={blok.link[0].url} styling="underline">
                {blok.link[0].text}
              </LinkResolver>
            </p>
          </CtaTextWrapper>}
          {blok.button && <LinkButton tw="w-full mt-4 desktop-xl:mt-0" button={{
            ...blok.button[0],
          }} />}
        </PlanInfoWrapper>
        <ShowFeaturesButton data-show={showFeatures} onClick={() => setShowFeatures((prev) => !prev)}>
          <FontAwesomeIcon icon={faPlusCircle} />
          <span>Show features</span>
        </ShowFeaturesButton>
        <FeaturesWrapper data-show={showFeatures}>
          {blok.features?.map((feature) => {
            if (feature.component === "line") return <hr key={feature._uid} />
            return <Feature key={feature._uid} blok={feature} circleCheck />
          })}
        </FeaturesWrapper>
      </ContentWrapper>
    </BlockWrapper>
  </SbEditable>
}

export default PricingBlock
