import React from "react"
import SbEditable from "storyblok-react"
import { BlogCtaStoryblok } from "../../../component-types-sb"
import LinkButton from "../general/LinkButton"
import Image from "../general/Image"
import { H5 } from "../typography/Typography"

interface Props {
  readonly blok: BlogCtaStoryblok
}

const BlogCta: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div tw="flex justify-between max-w-blog-component bg-white-blue items-center p-8 w-full hover:shadow-hover rounded-lg">
    <div tw="flex flex-col items-start flex-1">
      <H5>{blok.text}</H5>
      {blok.button && blok.button.length > 0 && <LinkButton button={blok.button[0]} />}
    </div>
    <div tw="hidden laptop:block flex-1 pl-20">
      {blok.image?.filename
        ? <Image tw="object-contain ml-auto max-h-40" image={blok.image} height="160px" fit="contain" />
        : <img tw="ml-auto max-h-40 object-contain" src="https://a.storyblok.com/f/110864/x/5f2d5401c2/header.svg" alt="default" />}
    </div>
  </div>
</SbEditable>

export default BlogCta
