interface callbackType {
 (_consentState: { enabled: boolean }): void
}

declare global {
  interface Window {
    Confirmic: ((_x: string) => void) | ((
      _x: string,
      _micropolicy?: { slug: string },
      _callback?: callbackType) => void
      )
  }
}

export const useConfirmic = (): {
  openConfirmic: () => void
  usePolicy: (_slug: string, _callback: callbackType) => void
} => {
  if (typeof window !== "undefined" && typeof window.Confirmic !== "undefined") {
    const { Confirmic: confirmic } = window
    return {
      openConfirmic: () => confirmic("ConsentManager:show"),
      usePolicy: (slug: string, callback: callbackType) => confirmic("getConsentState", { slug }, callback),
    }
  }
  return {
    openConfirmic: () => null,
    usePolicy: () => null,
  }
}
