import { graphql, useStaticQuery } from "gatsby"
import React, { useState, createContext, useContext, useEffect, useMemo } from "react"
import { AcademyTagStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import { ChildrenProp } from "../../../react"

interface Durations {
  min: number
  max: number
}

interface SelectedFilters {
  type: string
  topic: string
  expertLevel: string
  duration: number
}

interface QueryResult {
  allStoryblokEntry: {
    tags: {
      content: string
      lang: string
      uuid: string
    }[]
  }
}

export const tagTypes = ["type", "topic", "expertLevel"] as const

export type TagType = typeof tagTypes[number]

interface Tag {
  name: string
  position?: number
}

export type AvailableTags = Record<TagType, Tag[]>

interface ContextValues {
  selectedFilters: SelectedFilters
  setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFilters>>
  availableTags: AvailableTags
  durations: Durations
  setDurations: React.Dispatch<React.SetStateAction<Durations>>
}

export const getDuration = (value: string | number | undefined | null): number | null => {
  if (typeof value === "number") return value
  if (typeof value === "string" && value.length > 0) return parseInt(value, 10)
  return null
}

interface TagFromQuery {
  content: string
  lang: string
  uuid: string
}

const getAvailableTags = (localizedTags: TagFromQuery[]) => {
  const tagsObj = {
    expertLevel: [] as Tag[],
    topic: [] as Tag[],
    type: [] as Tag[],
  }

  // Group tags by type
  localizedTags.forEach((tag): void => {
    const parsedContent = JSON.parse(tag.content) as AcademyTagStoryblok
    if (tagsObj[parsedContent.tag_type]) {
      tagsObj[parsedContent.tag_type].push({
        name: parsedContent.title,
        position: typeof parsedContent.position_in_filter === "string"
          ? parseInt(parsedContent.position_in_filter, 10)
          : parsedContent.position_in_filter,
      })
    }
  })

  return tagsObj
}

const AcademyFilterContext = createContext<ContextValues | null>(null)

const AcademyWrapper: React.FC<ChildrenProp> = ({ children }) => {
  const { allStoryblokEntry: { tags } } = useStaticQuery<QueryResult>(graphql`
  query allAcademyTags{
    allStoryblokEntry(
      filter:{ field_component: { eq: "academyTag" }, }
    ){
      tags: nodes{
        uuid
        lang
        content
      }
    }
  }
`)

  const { locale } = useLocale()
  const localizedTags = tags.filter((tag) => tag.lang === locale)
  const availableTags = getAvailableTags(localizedTags)

  const [durations, setDurations] = useState<Durations>({ max: 0, min: 0 })

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    duration: 0,
    expertLevel: "AllTags",
    topic: "AllTags",
    type: "AllTags",
  })

  useEffect(() => {
    setSelectedFilters((prev) => ({ ...prev, duration: durations.max }))
  }, [durations])

  const contextValue = useMemo(() => ({
    availableTags,
    durations,
    selectedFilters,
    setDurations,
    setSelectedFilters,
  }), [availableTags, durations, selectedFilters])

  return <AcademyFilterContext.Provider value={contextValue}>
    {children}
  </AcademyFilterContext.Provider>
}

export default AcademyWrapper

export const useAcademyFilter = (): ContextValues => {
  const context = useContext(AcademyFilterContext)
  if (!context) throw new Error("Only call useAcademyFilter within a AcademyFilterContext.Provider")
  return context
}
