import React from "react"
import SbEditable from "storyblok-react"
import { JobCardStoryblok } from "../../../component-types-sb"
import LinkResolver from "../general/Link"
import { CardContainer, CardImage, CardSubtitle, CardTitle } from "../general/Card"
interface Props {
  readonly blok: JobCardStoryblok
}

const JobCard: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <LinkResolver url={blok.url}>
    <CardContainer>
      <CardImage image={blok.image} aspectratio="16/9" />
      <CardTitle>{blok.job}</CardTitle>
      <CardSubtitle>{blok.hours} · {blok.location}</CardSubtitle>
    </CardContainer>
  </LinkResolver>
</SbEditable>

export default JobCard
