import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer-ts"
import { FeatureStoryblok, FeatureTitleStoryblok } from "../../../component-types-sb"
import { B } from "../typography/Typography"
import Image from "./Image"
import { MarkLinkResolver } from "./Link"
import Tooltip from "./Tooltip"

interface Props {
  readonly blok: FeatureStoryblok | FeatureTitleStoryblok
  readonly circleCheck?: boolean
}

const Feature: React.FC<Props> = ({ blok, circleCheck }) => <SbEditable content={blok}>
  <li tw="flex items-baseline">
    {blok.component === "feature"
      && <div tw="flex-shrink-0 w-6">
        {blok.icon_image?.filename
          ? <Image image={blok.icon_image} width="16px" />
          : <span>
            {blok.icon === "tick" && <FontAwesomeIcon
              tw="text-blue [[data-theme='secondary'] &]:text-blue-police"
              icon={circleCheck
                ? faCircleCheck
                : faCheck} />}
            {blok.icon === "cross" && <FontAwesomeIcon tw="text-gray-3" icon={faTimes} />}
          </span>
        }
      </div>
    }
    {blok.text && render(blok.text, {
      markResolvers: {
        bold: (children) => <B>{children}</B>,
        link: (children, url) => <MarkLinkResolver url={url} is_blue={blok?.is_blue_link}>{children}</MarkLinkResolver>,
      },
      nodeResolvers: {
        paragraph: (children) => <span tw="font-light text-left w-fit leading-8">
          {children}
          {blok.tooltip && <Tooltip tooltip={blok.tooltip} />}
        </span>,
      },
    })}
  </li>
</SbEditable>

export default Feature
