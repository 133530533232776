import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer-ts"
import { Block, Mark, DocumentNode } from "@marvr/storyblok-rich-text-types"
import styled from "styled-components"
import tw from "twin.macro"
import {
  ButtonGroupStoryblok,
  RichtextStoryblok,
  SingleImageStoryblok,
  ButtonStoryblok,
  FeatureListStoryblok,
  SubtitleStoryblok,
  WistiaEmbedStoryblok,
  HeadingAnchorStoryblok,
  InlineReviewStoryblok,
  NativeFormStoryblok,
  CarouselStoryblok,
  NativeFormIframeStoryblok,
  BunnyEmbedStoryblok,
} from "../../../component-types-sb"
import { Heading, P, Subtitle } from "../typography/Typography"
import ButtonGroup from "../section/ButtonGroup"
import FeatureList from "../section/FeatureList"
import { OL, Ul } from "../typography/List"
import SingleImage from "../section/SingleImage"
import HeadingAnchor from "../blogComponents/HeadingAnchor"
import InlineReview from "../blogComponents/InlineReview"
import { CarouselBlog } from "../section/Carousel"
import NativeForm from "../section/NativeForm"
import NativeFormIframe from "../section/NativeFormIframe"
import Container from "./Container"
import { MarkLinkResolver } from "./Link"
import IconMarkdown from "./IconMarkdown"
import { WistiaEmbedBlog } from "./WistiaEmbed"
import RegisterForm from "./RegisterForm"
import RichTextButton from "./RichTextButton"
import BunnyEmbed from "./BunnyEmbed"

interface Props {
  readonly blok: RichtextStoryblok
}

interface RichTextProps {
  readonly text: DocumentNode
  readonly blueHeadings?: boolean
}

interface RichTextWrapperProps {
  text_align: "left" | "center"
  contained: boolean
}

const StyledHeadings = styled(Heading)<{ blueHeadings: boolean }>(({ blueHeadings }) => [blueHeadings && tw`text-blue`])

const RichTextWrapper = styled.div<RichTextWrapperProps>(({ text_align, contained }) => [
  tw`w-full max-w-container [.section-dark-background &]:(text-white)`,
  contained && tw`max-w-blog-text`,
  text_align === "left" && tw`text-left`,
  text_align === "center" && tw`text-center`,
])

export const Richtext: React.FC<RichTextProps> = ({ text, blueHeadings }) => <>
  {render(text, {
    blokResolvers: {
      bunnyEmbed: (props: BunnyEmbedStoryblok) => <BunnyEmbed blok={props} />,
      button: (props: ButtonStoryblok) => <RichTextButton {...props} />,
      buttonGroup: (props: ButtonGroupStoryblok) => <ButtonGroup blok={props} />,
      carousel: (props: CarouselStoryblok) => <CarouselBlog blok={props} />,
      featureList: (props: FeatureListStoryblok) => <div><FeatureList blok={props} /></div>,
      headingAnchor: (props: HeadingAnchorStoryblok) => <HeadingAnchor blok={props} />,
      inlineReview: (props: InlineReviewStoryblok) => <InlineReview blok={props} />,
      nativeForm: (props: NativeFormStoryblok) => <NativeForm blok={props} />,
      nativeFormIframe: (props: NativeFormIframeStoryblok) => <NativeFormIframe blok={props} />,
      registerFormComponent: () => <RegisterForm layoutStyle="hero" />,
      singleImage: (props: SingleImageStoryblok) => <SingleImage blok={props} />,
      subtitle: (props: SubtitleStoryblok) => <Subtitle>{props.text}</Subtitle>,
      wistiaEmbed: (props: WistiaEmbedStoryblok) => <WistiaEmbedBlog blok={props} />,
    },
    markResolvers: {
      [Mark.CODE]: (children) => <IconMarkdown icon={children as string} />,
      [Mark.LINK]: (children, url) => <MarkLinkResolver url={url}>{children}</MarkLinkResolver>,
      [Mark.ITALIC]: (children) => <em tw="text-16 text-gray-3">{children}</em>,
    },
    nodeResolvers: {
      [Block.UL_LIST]: (children) => <Ul>{children}</Ul>,
      [Block.HEADING]: (children, { level }) => <StyledHeadings level={level} blueHeadings={Boolean(blueHeadings)}>
        {children}
      </StyledHeadings>,
      [Block.OL_LIST]: (children) => <OL>{children}</OL>,
      [Block.PARAGRAPH]: (children) => <P>{children}</P>,
      [Block.CODE]: (children) => <div tw="w-full p-4 bg-white border-2 border-gray-5 rounded">
        <p tw="font-mono">{children}</p>
      </div>,
    },
  })}
</>

const RichtextEditable: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <Container>
    <RichTextWrapper text_align={blok.text_align || "center"} contained={Boolean(blok.contained)}>
      <Richtext text={blok.text as unknown as DocumentNode} />
    </RichTextWrapper>
  </Container>
</SbEditable>

export default RichtextEditable
