import { Link } from "gatsby"
import React from "react"
import tw from "twin.macro"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Image from "../general/Image"
import { isNullish } from "../../utils/typeGuards"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"

interface Props {
  readonly image: {
    alt?: string
    filename: string
    title?: string
  }
  readonly tag?: string | null
  readonly title: string
  readonly full_slug: string
  readonly variant?: "normal" | "big"
  readonly color?: "light" | "dark"
}

export const Tag = tw.span`
  inline-block
  bg-secondary-container
  text-secondary-container-on text-13
  font-medium
  px-2 py-1.5
  rounded-lg
`

const StyledLink = tw(Link)`
  w-full
  bg-white-blue
  text-gray-1
  p-4
  rounded-lg
  hover:shadow
  transition-card duration-300
  [.section-dark-background &]:(bg-white)
  [&[data-card-variant="big"]]:(desktop:p-6)
  [&[data-card-color="dark"]]:(bg-blue-midnight text-white)
`

const ImageWrapper = tw.div`
  rounded-md
  mb-2.5
  aspect-ratio[16/9]
  overflow-hidden
  [[data-card-variant='big'] &]:(desktop:aspect-ratio[23/16])
`

const StyledText = tw.p`
  text-15 font-medium
  [span]:text-orange
  [[data-card-variant='big'] &]:(desktop:text-26)
`

const ImageCard: React.FC<Props> = ({
  image, tag, title, full_slug, variant = "normal", color = "light", ...rest
}) => {
  const { width } = getImageDimensions(image)
  const defaultWidth = 218
  return (
    <StyledLink
      to={rewriteSlug(full_slug)}
      {...rest}
      data-card-variant={variant}
      data-card-color={color}
    >
      {image?.filename && <ImageWrapper>
        <Image
          image={image}
          fluid={Math.min(defaultWidth, width)}
          lazy={false} showPlaceholder={false} />
      </ImageWrapper>}
      {!isNullish(tag) && <div tw="my-2.5 [[data-card-variant='big'] &]:(desktop:mt-6 mb-4)">
        <Tag>{tag}</Tag>
      </div>}
      <StyledText dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(title) }} />
    </StyledLink>
  )
}

export default ImageCard
