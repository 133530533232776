import React from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { SingleImageStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import Image from "../general/Image"
import { getImageDimensions } from "../../utils/getImageDimensions"

interface Props {
  readonly blok: SingleImageStoryblok
}

const ImageWrapper = styled.div<{ minWidth: boolean }>(({ minWidth }) => [
  tw`flex justify-center w-full mx-auto my-4 overflow-x-auto max-w-container`,
  minWidth && tw`block`,
])

const SingleImage: React.FC<Props> = ({ blok }) => {
  const { width } = getImageDimensions(blok.image)
  return <SbEditable content={blok}>
    <Container>
      <ImageWrapper minWidth={Boolean(blok.min_width)}>
        <Image image={blok.image}
          fluid={blok.max_size
            ? 900
            : Math.min(900, width)}
          {...(width && { width: `${blok.max_size
            ? 900
            : Math.min(900, width)}px` })}
        />
      </ImageWrapper>
    </Container>
  </SbEditable>
}

export default SingleImage
