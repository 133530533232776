import React from "react"
import SbEditable from "storyblok-react"
import { AnchorStoryblok } from "../../../component-types-sb"

interface Props {
  readonly blok: AnchorStoryblok
}

const Anchor: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div id={blok.id} />
</SbEditable>

export default Anchor
