import React from "react"
import SbEditable from "storyblok-react"
import { KeywordDefinitionStoryblok } from "../../../component-types-sb"
import GlossaryItem from "../templates/GlossaryItem"

interface Props {
  readonly blok: KeywordDefinitionStoryblok
}

const KeywordDefinition: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <GlossaryItem
      title={blok.keyword}
      description={blok.definition}
      tag={blok.tag}
      url={blok.url}
      moreText={blok.url_text} />
  </SbEditable>
)

export default KeywordDefinition
