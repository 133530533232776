import React, { useState } from "react"
import SbEditable from "storyblok-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { IntegrationsSearchStoryblok } from "../../../component-types-sb"
import useIntegrationPages from "../../hooks/useIntegrationPages"
import useLocale from "../../hooks/useLocale"
import SearchWithTags from "../general/SearchWithTags"
import useIntegrationTags from "../../hooks/useIntegrationTags"
import Image from "../general/Image"
import { CardContainer, CardSubtitle, CardTagContainer, CardTitle } from "../general/Card"
import Tag from "../general/Tag"
import { GridComponent } from "../section/CardGrid"
import { useZapierSearch } from "../../hooks/useZapierSearch"
import DefaultResolver from "../blogComponents/DefaultResolver"

interface Props {
  blok: IntegrationsSearchStoryblok
}

const IntegrationsSearch: React.FC<Props> = ({ blok }) => {
  const integrationPages = useIntegrationPages()
  const tags = useIntegrationTags()
  const { locale } = useLocale()

  const localeTags = tags.filter((tag) => tag.lang === "default").map((tag) => {
    tags.forEach((tagLang) => {
      if (tagLang.value === `${tag.value}_${locale}`) {
        tag.name = tagLang.name
      }
    })
    return tag
  })

  // sort integration pages by card title alphabetically
  integrationPages.sort((a, b) => {
    const titleA = a.content.card_title?.toLowerCase() ?? ""
    const titleB = b.content.card_title?.toLowerCase() ?? ""
    return titleA.localeCompare(titleB)
  })

  const [searchQuery, setSearchQuery] = useState<string>("")
  const [selectedTag, setSelectedTag] = useState<string | null>(null)

  const { data: zapierData, error: zapierError } = useZapierSearch(searchQuery)

  const filteredZapierData = zapierData?.results.filter(
    (result) => !result.item.name.toLowerCase().includes("webi"),
  )

  const getTagValue = (tagName: string | null) => localeTags.find((tag) => tag.name === tagName)?.value
  const getTagName = (tagValue: string | null) => localeTags.find((tag) => tag.value === tagValue)?.name

  const selectedIntegrationPages = integrationPages.filter((integrationPage) => {
    const matchesLang = integrationPage.lang === locale
    const matchesTagIfDefined = selectedTag === null
      || integrationPage.content.tags?.includes(getTagValue(selectedTag) ?? "")
    const matchesSearchQuery = searchQuery === ""
      || integrationPage.content.card_title?.toLowerCase().includes(searchQuery.toLowerCase())
    return matchesLang && matchesTagIfDefined && matchesSearchQuery
  })

  return (
    <SbEditable content={blok}>
      <SearchWithTags
        noBorder
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        all_filter_text={blok.all_tag}
        search_placeholder={blok.search_placeholder ?? "search"}
        tags={localeTags.slice().sort((a, b) => a.name.localeCompare(b.name))
          .map((tag) => tag.name)}
      />
      <div tw="max-w-container-large w-full desktop-large:px-0 px-4">
        <GridComponent spacing="normal" width="wide" columns={4}>
          {selectedIntegrationPages.map((integrationPage) => (
            <CardContainer
              href={`/${integrationPage.full_slug}`}
              key={integrationPage.full_slug}
            >
              <Image image={integrationPage.content.card_image} tw="h-12 mr-auto" />
              <CardTitle>{integrationPage.content.card_title}</CardTitle>
              <CardSubtitle tw="line-clamp-4">{integrationPage.content.card_description}</CardSubtitle>
              <CardTagContainer>
                {integrationPage.content.tags?.map((tag) => <Tag key={tag}>{getTagName(tag.toString())}</Tag>)}
              </CardTagContainer>
            </CardContainer>
          ))}
          {selectedIntegrationPages.length === 0
            && blok.not_found
            && zapierError === null
            && filteredZapierData?.map((result) => <a href={`https://zapier.com/apps/${result.item.slug}`} key={result.item.slug} target="_blank" rel="noreferrer">
              <CardContainer>
                <img src={result.item.logo_url} alt="logo" tw="h-12 mr-auto" />
                <CardTitle>{result.item.name}</CardTitle>
                <CardSubtitle tw="line-clamp-4 text-right flex items-center gap-1 justify-end">Open in Zapier
                  <FontAwesomeIcon icon={faChevronRight} tw="group-hocus:animate-bounce-x duration-200 pr-1.5" />
                </CardSubtitle>
              </CardContainer>
            </a>)}
          {selectedIntegrationPages.length === 0
            && filteredZapierData?.length === 0
            && blok.not_found
            && <div tw="col-span-full text-center">
              <DefaultResolver content={blok.not_found as DocumentNode} />
            </div>}
        </GridComponent>
      </div>
    </SbEditable>
  )
}
export default IntegrationsSearch
