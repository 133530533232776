import React, { createContext, useCallback, useContext, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { rewriteSlug } from "../utils/rewriteSlug"
import { Locale } from "../hooks/useLocale"
import { ChildrenProp } from "../../react"

interface SitePage {
  isSitePage: (_link: string) => string | null
  getLinkFromId: (_id: string, _locale: string) => string | null
}

interface Query {
  allSitePage: {
    pages: {
      path: string
    }[]
  }
  allStoryblokLink: {
    links: {
      uuid: string
      slug: string
      alternates: {
        lang: "nl" | "de"
        path: string
      }[]
    }[]
  }
}

export const SitePagesContext = createContext<SitePage | null>(null)

export const SitePagesProvider: React.FC<ChildrenProp> = ({ children }) => {
  const { allSitePage: { pages }, allStoryblokLink: { links } } = useStaticQuery<Query>(graphql`
    query AllSitePages{
      allSitePage{
        pages: nodes{
          path
        }
      }
      allStoryblokLink{
        links: nodes{
          uuid
          slug
          alternates{
            lang
            path
          }
        }
      }
    }
  `)

  const isSitePage = useCallback((link: string) => {
    if (pages.some((x) => x.path === rewriteSlug(link))) return rewriteSlug(link)
    return null
  }, [pages])

  const getLinkFromId = useCallback((id: string, locale: Locale) => {
    if (typeof links === "undefined") throw new Error("useLinks must be used within a SitePagesProvider")
    const foundLink = links.find((link) => link.uuid === id)
    if (!foundLink) return null
    if (locale === "default") return rewriteSlug(foundLink.slug)
    const localePath = foundLink.alternates.find((x) => x.lang === locale)?.path
    if (!localePath) return null
    const localeLink = `${locale}/${localePath}`
    if (pages.some((x) => x.path === rewriteSlug(localeLink))) return rewriteSlug(localeLink)
    return rewriteSlug(foundLink.slug)
  }, [links, pages])

  const contextValue = useMemo(() => ({
    getLinkFromId,
    isSitePage,
  }), [getLinkFromId, isSitePage])

  return <SitePagesContext.Provider value={contextValue}>{children}</SitePagesContext.Provider>
}

export const useSitePages = (): SitePage => {
  const context = useContext(SitePagesContext)
  if (!context) throw new Error("useSitePages must be used within a SitePagesProvider")
  return context
}
