import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Helmet } from "react-helmet"
import { createGlobalStyle } from "styled-components"
import { theme } from "twin.macro"
import _components from "../../components.110864.json"
import Button from "../components/general/Button"
import { H1, H2, H3, H4 } from "../components/typography/Typography"

interface IComponent {
  name: string
  display_name: string
  schema: Record<string, {
      type: string
      displayName?: string
      description?: string
      options?: {
        name: string
      }[]
      filter_content_type?: string
      component_whitelist?: string[]
      display_name: string
      required?: boolean
      keys: string[]
    }>
  image: string
  component_group_name?: string
}

interface Components {
  components: IComponent[]
}

const components: Components = _components as unknown as Components

const CSS = createGlobalStyle`
  body {
    font-family: "Roboto";
  }
  table {
    border-spacing: 0px;
  }
  td {
    vertical-align: top;
    text-align: left;
    padding: 6px;
  }
  tr:nth-child(odd) {
    background-color: ${theme`colors.white-blue.3`}
  }
  tr:nth-child(even) {
    background-color: ${theme`colors.gray.5`}
  }
  thead th {
    padding: 6px;
  }
`

const sectionNames = [
  "Pages",
  "Non-page content types",
  "Section components",
  "Blog components",
  "Atomic components",
  "Cards",
  "Misc",
]

const Component: React.FC<{ readonly component: IComponent }> = ({ component }) => {
  // Manually rearramge schema
  const tabKeys = Object.keys(component.schema).filter((x) => component.schema[x].type === "tab")
  const orderedKeys = tabKeys.reduce((acc: string[], tabKey) => (
    acc.concat([tabKey, ...component.schema[tabKey].keys])
  ), [])
  const generalKeys = Object.keys(component.schema).filter((x) => !orderedKeys.includes(x))
  const schemaKeys = [...generalKeys, ...orderedKeys]

  return <div key={component.name} tw="mb-16">
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <H3 id={component.name}>{component.display_name || component.name}</H3>
    <div tw="flex justify-between ">
      <div tw="mr-8">
        {/* <h4 >Schema</h4> */}
        <table>
          <thead tw="text-18">
            <tr tw="bg-gray-5!">
              <th>Field</th>
              <th>Component type</th>
              <th>Options</th>
              <th>Required</th>
              <th>Description</th>
            </tr>
          </thead>

          <tbody>
            {schemaKeys.map((key) => {
              const field = component.schema[key]
              if (!field) return <tr key={key}><td>No component with key {key}</td></tr>
              if (field.type === "tab") {
                return <tr key={key}>
                  <td tw="text-center">
                    <H4 tw="text-18 my-0">{field.display_name}</H4>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              }
              return <tr key={key}>
                <td tw="font-bold">{field.displayName ?? key}</td>
                <td>{field.type}</td>
                <td>
                  {field.options?.map((option) => <div tw="mb-3 last:mb-0" key={option.name}>{option.name}</div>)}
                  {field.filter_content_type && <a
                    tw="mb-3 last:mb-0"
                    href={`#${field.filter_content_type}`}>
                    {field.filter_content_type}
                  </a>}
                  {field.component_whitelist?.map((blok) => <div tw="mb-3 last:mb-0" key={blok}>
                    <a href={`#${blok}`}>{blok}</a>
                  </div>)}
                </td>
                <td tw="text-center">
                  {field.required && <FontAwesomeIcon tw="text-blue" icon={faCheck} />}
                </td>
                <td>
                  {field.description?.split("\n").map((item, index) => <p
                    tw="mt-0 mb-3 last:mb-0"
                    key={index}>
                    {item}
                  </p>)}
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      <div tw="border border-blue">
        {component.image && <img tw="max-w-md " src={component.image} alt={component.name} />}
      </div>
    </div>
  </div>
}

const StoryblokDocumentation: React.FC = () => {
  const sections = sectionNames.map((sectionName) => ({
    name: sectionName,
    sectionComponents: components.components.filter((x) => (
      x.component_group_name && x.component_group_name === sectionName)),
  }))
  return (
    <main>
      <CSS />
      <div tw="fixed right-4 bottom-32">
        <Button variant="orange" size="sm" onClick={() => {
          document.documentElement.scrollTop = 0
        }}><FontAwesomeIcon tw="text-20" icon={faArrowUp} /></Button>
      </div>
      <H1 tw="text-center">Storyblok component documentation</H1>
      <div tw="max-w-container mx-auto">
        <H2 tw="text-center">Table of contents</H2>
        <nav>
          {sections.map(({ name, sectionComponents }) => <div tw=" my-16" key={name}>
            <H3 tw="text-center mb-8"><a href={`#section${name}`}>{name}</a></H3>
            <ul tw="grid grid-cols-3">
              {sectionComponents.map(({ name: otherName, display_name }) => <li key={otherName}>
                <a tw="text-16" href={`#${otherName}`}>{display_name}</a>
              </li>)}
            </ul>
          </div>)}
        </nav>
      </div>
      <div tw="max-w-container-huge mx-auto">
        {sections.map(({ name, sectionComponents }) => <div tw="mb-32" key={name}>
          <H2 tw="text-center pt-8" id={`section${name}`}>{name}</H2>
          {sectionComponents.map((component) => <Component key={component.name} component={component} />)}
        </div>)}
      </div>
    </main>
  )
}

export default StoryblokDocumentation
