import { graphql, PageProps } from "gatsby"
import React from "react"
import { HelpVideoStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import HelpVideo from "../components/templates/HelpVideo"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

const HelpVideoTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: HelpVideoStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <HelpVideo blok={content} />
  </BaseTemplate>
}

export default HelpVideoTemplate

export const query = graphql`
  query HelpVideoQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
