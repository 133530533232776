import React from "react"
import { SbEditableContent } from "storyblok-react"
import Placeholder from "../general/Placeholder"
import Page from "./Page"
import BlogOrChapterPost from "./BlogOrChapterPost"
import CustomerSuccessStory from "./CustomerSuccessStory"
import AcademyCourse from "./AcademyCourse"
import HelpVideo from "./HelpVideo"
import BlogIndexPage from "./BlogIndexPage"
import PricingPage from "./PricingPage"
import RegisterPage from "./RegisterPage"
import CategoryPage from "./CategoryPage"
import Background from "./Background"
import VirtualBackgroundsIndex from "./VirtualBackgroundsIndex"
import VirtualBackgroundTag from "./VirtualBackgroundsTag"
import VirtualBackgroundPlatform from "./VirtualBackgroundsPlatform"
import GlossaryIndex from "./GlossaryIndex"
import GlossaryKeyword from "./GlossaryKeyword"

const Components = {
  academyChapter: BlogOrChapterPost,
  academyCourse: AcademyCourse,
  background: Background,
  blogIndexPage: BlogIndexPage,
  blogPost: BlogOrChapterPost,
  categoryPage: CategoryPage,
  customerSuccessStory: CustomerSuccessStory,
  glossaryIndex: GlossaryIndex,
  glossaryKeyword: GlossaryKeyword,
  helpVideo: HelpVideo,
  page: Page,
  pricingPage: PricingPage,
  registerPage: RegisterPage,
  tagPage: CategoryPage,
  virtualBackgroundPlatform: VirtualBackgroundPlatform,
  virtualBackgroundTag: VirtualBackgroundTag,
  virtualBackgroundsIndex: VirtualBackgroundsIndex,
}

interface Props {
  readonly blok: SbEditableContent
}

const TemplateSelector: React.FC<Props> = ({ blok }) => {
  if (Object.keys(Components).includes(blok.component)) {
    const Component = Components[blok.component] as React.FC<Props>
    return <Component blok={blok} />
  }
  return blok.component
    ? <Placeholder componentName={blok.component} />
    : null
}

export default TemplateSelector
