import React from "react"
import tw from "twin.macro"

interface Props {
  readonly name: string
}

export const StyledRadio = tw.span`
  flex items-center justify-center
  relative
  w-5 h-5
  text-white bg-transparent
  border border-gray-4 rounded-sm
  peer-focus-visible:(
    ring-2 ring-offset-2 ring-black
  )
  peer-checked:(
    shadow-inner-white bg-blue
  )
`

export const RadioLabel = tw.span`
  ml-2
  font-light text-gray-4
  group-hover:(text-gray-1)
  peer-checked:(text-gray-1)
`

const Radio: React.FC<Props> = ({ name }) => <>
  <StyledRadio />
  <RadioLabel>
    {name}
  </RadioLabel>
</>

export default Radio
