import React from "react"
import SbEditable from "storyblok-react"
import { CustomerSuccessStoriesListStoryblok } from "../../../component-types-sb"
import CustomerSuccessStoriesGrid, { SuccessStory } from "../general/CustomerSuccessStoriesGrid"
import ClientOnly from "../general/ClientOnly"
import useLocalizedCSStories from "../../hooks/useLocalizedCSStories"

interface Props {
  readonly blok: CustomerSuccessStoriesListStoryblok
}

const CustomerSuccessStoriesList: React.FC<Props> = ({ blok }) => {
  const localizedStories = useLocalizedCSStories()

  const stories = blok.use_localized_stories
    ? localizedStories
    : blok.success_stories as SuccessStory[]

  if (!stories?.length) return null

  return (
    <SbEditable content={blok}>
      <ClientOnly>
        <CustomerSuccessStoriesGrid stories={stories} spacing={blok.spacing} width={blok.width} columns={4} />
      </ClientOnly>
    </SbEditable>
  )
}

export default CustomerSuccessStoriesList
