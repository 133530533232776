import { faCheck } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useState } from "react"
import SbEditable from "storyblok-react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import tw from "twin.macro"
import styled from "styled-components"
import { LargeRegisterFormStoryblok } from "../../../component-types-sb"
import Button from "../general/Button"
import Container from "../general/Container"
import Image from "../general/Image"
import LinkResolver from "../general/Link"
import StarReveiw from "../general/StarReveiw"
import Input from "../typography/Input"
import Select from "../typography/Select"
import { H1, Subtitle } from "../typography/Typography"
import countriesEn from "../../data/countries-en.json"
import countriesNl from "../../data/countries-nl.json"
import useLocale from "../../hooks/useLocale"
import { Richtext } from "../general/Richtext"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import { handlePromise } from "../../utils/handlePromise"
import useStateWithDelayedCallback from "../../hooks/useStateWithDelayedCallback"

type SubdomainStatus = "invisible" | "valid" | "invalid"
interface SubdomainResponse {
  state: "error" | "ok"
  message: string
  subdomain: string
}

const TextWrapper = tw.div`col-span-2 px-3 text-center [p]:text-14`

interface InputSideProps {
  isValid: boolean
}

const InputSide = styled.div<InputSideProps>(({ isValid }) => [
  tw`flex items-center h-full px-2`,
  isValid
    ? tw`bg-white-blue text-blue`
    : tw`text-red-500 bg-red-200 bg-opacity-60`,
])

const SubdomainWrapper = styled.div<InputSideProps>(({ isValid }) => [
  tw`col-span-2 space-y-2 [p]:(text-18 font-light)`,
  isValid
    ? tw`text-blue`
    : tw`text-red-500`,
])

interface Props {
  readonly blok: LargeRegisterFormStoryblok
}

const LargeRegisterForm: React.FC<Props> = ({ blok }) => {
  const [subdomainStatus, setSubdomainStatus] = useState<SubdomainStatus>("invisible")
  const [hasEditedSubdomain, setHasEditedSubdomain] = useState(false)
  const [validSubdomain, setValidSubdomain] = useState("")
  const [subdomainMessage, setSubdomainMessage] = useState("")
  const [allowInvalid, setAllowInvalid] = useState(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [companyField, setCompanyField] = useState("")
  const { locale } = useLocale()
  const countriesData = locale === "nl"
    ? countriesNl
    : countriesEn

  const handleSubdomainChange = useCallback(async (value: string) => {
    if (value !== "") {
      const res = await handlePromise(fetch(`https://app.qwebinar.nl/account/subdomain_availability?subdomain=${value}`)
        .then((response) => response.json() as Promise<SubdomainResponse>))
      if (res.error) {
        console.error(res.error)
      } else if (res.data.state === "error") {
        setSubdomainStatus("invalid")
        setSubdomainMessage(res.data.message)
      } else if (res.data.state === "ok") {
        setSubdomainStatus("valid")
        setValidSubdomain(res.data.message)
        setSubdomainMessage("")
      }
    }
  }, [])

  const [subdomianField, setSubdomainField] = useStateWithDelayedCallback("", 500, handleSubdomainChange)

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setCompanyField(e.target.value)
    if (!hasEditedSubdomain) setSubdomainField(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)

    const body = []
    formData.forEach((value, key) => {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      body.push(`${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`)
    })
    body.push(`${encodeURIComponent("locale")}=${encodeURIComponent(locale === "nl"
      ? "nl"
      : "en")}`)
    body.push(`${encodeURIComponent("user[password]")}=${encodeURIComponent("password")}`)
    body.push(`${encodeURIComponent("user[password_confirmation]")}=${encodeURIComponent("password")}`)
    body.push(`${encodeURIComponent("user[time_zone]")}=${encodeURIComponent("Europe/Amsterdam")}`)
    body.push(`${encodeURIComponent("user[language]")}=${encodeURIComponent(locale === "nl"
      ? "nl"
      : "en")}`)
    body.push(`${encodeURIComponent("user[account_attributes[referral]]")}=${encodeURIComponent("facebook")}`)

    const res = await handlePromise(fetch("https://app.qwebinar.nl/account.json", {
      body: body.join("&"),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
    }).then((response) => response.json() as Promise<{ redirect_to: string } | { errors: string[] }>))
    if (res.error) console.error(res.error)
    else if ("errors" in res.data) setErrorMessages(res.data.errors)
    else if (res.data.redirect_to) window.location.href = res.data.redirect_to
  }

  return (
    <SbEditable content={blok}>
      <Container>
        <div tw="mx-auto max-w-container-large flex flex-col desktop:(flex-row space-x-8) w-full">
          <div tw="flex-1 text-center desktop:text-left">
            <H1 dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.title) }} />
            <Subtitle dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.subtitle) }} />
            <StarReveiw position="left" />
            <div tw="my-8 space-y-2">
              {blok.checks.map((check) => <p tw="mr-2 text-gray-2 font-light" key={check._uid}>
                <span tw="text-blue mr-2">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                {check.text && <span dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(check.text) }} />}
              </p>)}
            </div>
            <div tw="flex flex-row space-x-5 flex-wrap -ml-5 justify-center desktop:justify-start">
              {blok.badges.map(({ image, url, _uid }) => <LinkResolver url={url} tw="ml-5" key={_uid}>
                <Image tw="width[75px]" image={image} width="75px" />
              </LinkResolver>)}
            </div>
          </div>
          <form
            tw="flex-1 p-10 pt-20 grid grid-cols-2 gap-5 rounded shadow"
            onSubmit={(e) => {
              void handleSubmit(e)
            }}
          >
            <Input wrapperTw={tw`col-span-2 tablet:col-span-1`} type="text" id="first_name" name="user[first_name]"
              autoComplete="given-name" required placeholder={blok.name} allowInvalid={allowInvalid} />
            <Input wrapperTw={tw`col-span-2 tablet:col-span-1`} type="text" id="last_name" name="user[last_name]"
              autoComplete="family-name" required placeholder={blok.surname} allowInvalid={allowInvalid} />
            <Input wrapperTw={tw`col-span-2`} type="email" id="email" name="user[email]"
              autoComplete="email" required placeholder={blok.email_address} allowInvalid={allowInvalid} />
            <Input wrapperTw={tw`col-span-2`} type="password" id="password" name="user[password]"
              required placeholder={blok.password} allowInvalid={allowInvalid} />
            {/* <Input wrapperTw={tw`col-span-2`} type="text" id="subdomain" name="user[account_attributes[subdomain]]"
              autoComplete="organization" required placeholder={blok.subdomain} allowInvalid={allowInvalid} /> */}
            <Select
              wrapperTw={tw`col-span-2 height[42px] text-gray-1`}
              id="country"
              name="user[account_attributes[country]]"
              autoComplete="country"
              required
              defaultText={blok.country}
              allowInvalid={allowInvalid}
            >
              {countriesData.map((country) => <option key={country.name} value={country.alpha2.toUpperCase()}>
                {country.name}
              </option>)}
            </Select>
            <Input
              wrapperTw={tw`col-span-2`}
              id="company"
              type="text"
              name="user[account_attributes[company]]"
              autoComplete="organization"
              value={companyField}
              onChange={handleCompanyChange}
              required
              placeholder={blok.company}
              allowInvalid={allowInvalid}
            />
            {subdomainStatus !== "invisible" && <SubdomainWrapper isValid={subdomainStatus === "valid"}>
              <p tw="text-body">Your personal WebinarGeek subdomain</p>
              {validSubdomain && <p>{validSubdomain}</p>}
              <div tw="height[42px] flex shadow-hover rounded overflow-hidden">
                <InputSide isValid={subdomainStatus === "valid"}>https://</InputSide>
                <input tw="flex-1 px-2" name="user[account_attributes[subdomain]]"
                  value={["invalid", "invisible"].includes(subdomainStatus)
                    ? companyField
                    : subdomianField}
                  onChange={(e) => setSubdomainField(e.target.value)} onInput={() => setHasEditedSubdomain(true)}
                />
                <InputSide isValid={subdomainStatus === "valid"}>.qwebinar.nl</InputSide>
              </div>
              {subdomainMessage && <p tw="text-red-500">{subdomainMessage}</p>}
            </SubdomainWrapper>}
            <Input wrapperTw={tw`col-span-2`} id="phone" type="tel" name="user[account_attributes[phone]]"
              autoComplete="tel" required placeholder={blok.phone_number} allowInvalid={allowInvalid} />
            <input type="hidden" name="agreements[]" value="1" />
            <input type="hidden" name="email_confirmation" value="" />
            {errorMessages.length > 0 && <div tw="col-span-2 text-center">
              {errorMessages.map((message) => <p key={message} tw="text-red mb-2">{message}</p>)}
            </div>}
            <Button
              type="submit"
              variant="orange"
              size="md"
              tw="w-full col-span-2"
              onClick={() => setAllowInvalid(true)}
            >
              {blok.button_text}
            </Button>
            <TextWrapper>
              <Richtext text={blok.disclaimer as DocumentNode} />
            </TextWrapper>
          </form>
        </div>
      </Container>
    </SbEditable>
  )
}

export default LargeRegisterForm
