import { graphql, PageProps } from "gatsby"
import React from "react"
import { AcademyChapterStoryblok, AcademyCourseStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import AcademyCourse from "../components/templates/AcademyCourse"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

interface GQLResult extends GQLStory {
  chapters: {
    nodes: {
      content: string
      full_slug: string
    }[]
  }
}

const AcademyCourseTemplate: React.FC<PageProps<GQLResult>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: AcademyCourseStoryblok }
  const chapters = data.chapters.nodes.map((node) => {
    const chapterContent = JSON.parse(node.content) as unknown as AcademyChapterStoryblok
    return { ...node, content: chapterContent }
  })
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <AcademyCourse blok={content} chapters={chapters} />
  </BaseTemplate>
}

export default AcademyCourseTemplate

export const query = graphql`query AcademyCourseQuery($slug: String!, $regexSlug: String, $lang: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  chapters: allStoryblokEntry(
    filter: {full_slug: {regex: $regexSlug}, field_component: {eq: "academyChapter"}, lang: {eq: $lang}}
    sort: {first_published_at: ASC}
  ) {
    nodes {
      content
      full_slug
    }
  }
}`
