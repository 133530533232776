import { faStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { GlobalSettingsStoryblok, LogoBarStoryblok, RichtextStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Container from "../general/Container"
import Image from "../general/Image"
import { Richtext } from "../general/Richtext"
import { P } from "../typography/Typography"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import ClientOnly from "../general/ClientOnly"
import useRegion from "../../hooks/useRegion"

const LogosWrapper = tw.div`
  grid grid-template-columns[repeat(2, min(120px, 40%))]
  tablet:grid-template-columns[repeat(3, 150px)]
  justify-center
  desktop:flex
  gap-6
`

const LogoWrapper = tw.div`
  relative
`

const Logo = tw.div`
  bg-white
  [[data-section~="blue"] &]:(
    bg-white-blue-2
  )
  [.logobar-white-text &]:(
    bg-transparent
  )
`

const Blend = tw.div`
  mix-blend-lighten
  background-color[hsl(191deg 10% 50%)]
  transition-opacity duration-300
  group-hover:opacity-0
  absolute inset-0
  pointer-events-none
`

interface Props {
  readonly blok: LogoBarStoryblok
}

const RatingsTextWrapper = tw.div`
  ml-2
  [p]:mb-0
`

interface TextProps {
  textStyle: "normal" | "light" | "white"
}

const Text = styled(P)<TextProps>(({ textStyle }) => [
  textStyle === "light" && tw`text-16 text-gray-3`,
  textStyle === "white" && tw`text-white`,
])

interface GlobalSettingsReturnType extends GlobalSettingsStoryblok {
  reviews_text: RichtextStoryblok
}

const LogoBar: React.FC<Props> = ({ blok }) => {
  const {
    reviews_text,
    logos_us,
    logos_nordics,
    logos_dach,
    logos_default,
    logos_us_white,
    logos_default_white,
    logos_nordics_white,
    logos_dach_white,
  } = useGlobalSettings() as GlobalSettingsReturnType
  const sortedLogos = {
    dach: {
      colored: logos_dach,
      white: logos_dach_white,
    },
    default: {
      colored: logos_default,
      white: logos_default_white,
    },
    nordics: {
      colored: logos_nordics,
      white: logos_nordics_white,
    },
    us: {
      colored: logos_us,
      white: logos_us_white,
    },
  }
  const location = useRegion()
  const style = blok.text_style === "white"
    ? "white"
    : "colored"
  const localizedLogos = sortedLogos[location][style]
  const blokLogos = blok.text_style === "white"
    ? blok.logos_white
    : blok.logos
  const logos = blok.show_global_logos
    ? localizedLogos
    : blokLogos

  return <SbEditable content={blok} key={blok._uid}>
    <Container>
      <div tw="max-w-container mx-auto my-4 text-center w-full space-y-4" className={blok.text_style === "white"
        ? "logobar-white-text"
        : ""}>
        {blok.show_review && <div tw="flex justify-center items-center flex-col tablet:(flex-row)">
          <div tw="text-yellow text-18">
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <FontAwesomeIcon icon={faStar} />
            <div tw="relative inline-block">
              <FontAwesomeIcon tw="text-gray-4" icon={faStar} />
              <div tw="absolute inset-0 w-3.25 overflow-hidden">
                <FontAwesomeIcon icon={faStar} />
              </div>
            </div>
          </div>
          <RatingsTextWrapper tw="[.logobar-white-text &]:(text-white)">
            <Richtext text={reviews_text as DocumentNode} />
          </RatingsTextWrapper>
        </div>}
        {blok.text && <Text
          textStyle={blok.text_style}
          dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.text) }}
        />}
        <ClientOnly>
          <LogosWrapper>
            {logos?.map((logo) => <LogoWrapper className="group" key={logo.id}>
              <Logo>
                <Image tw="object-contain width[150px] height[75px] transition[filter 300ms] grayscale group-hover:grayscale-0 [.logobar-white-text &]:grayscale-0"
                  image={logo} height="75px" fit="contain" lazy={false} showPlaceholder={false} useRegularImageTag />
              </Logo>
              {blok.text_style !== "white" && <Blend />}
            </LogoWrapper>)}
          </LogosWrapper>
        </ClientOnly>
      </div>
    </Container>
  </SbEditable>
}

export default LogoBar
