import "twin.macro"

import React from "react"
import SbEditable from "storyblok-react"
import { BackgroundStoryblok, VirtualBackgroundTagStoryblok } from "../../../component-types-sb"
import {
  VirtualBackgroundSettingsProvider, useVirtualBackgroundSettings,
} from "../../contexts/VirtualBackgroundSettingsContext"

import HowToSection from "../virtualBackgroundComponents/HowToSection"
import MoreBackgroundsSection from "../virtualBackgroundComponents/MoreBackgroundsSection"
import VirtualBackgroundHeader from "../virtualBackgroundComponents/VirtualBackgroundHeader"
import ExploreBackgroundsSection from "../virtualBackgroundComponents/ExploreBackgroundsSection"
import { CtaComponent } from "../section/Cta"

interface Props {
  readonly blok: BackgroundStoryblok
}

const Background: React.FC<Props> = ({ blok }) => {
  const tag = blok.tag as unknown as { content: VirtualBackgroundTagStoryblok }
  const {
    divider_inline_title, divider_inline_buttons, divider_inline_description,
  } = useVirtualBackgroundSettings()

  return (
    <SbEditable content={blok}>
      <VirtualBackgroundHeader
        title={blok.title}
        description={blok.description}
        buttons={blok.buttons}
        image={blok.image}
        tag={tag.content}
        backgroundsLeft={false}
      />
      <MoreBackgroundsSection
        tag={tag}
      />
      <div className="section-dark-background" tw="w-screen bg-blue-prussian">
        <HowToSection />
      </div>
      <MoreBackgroundsSection />
      <div tw="w-full max-w-[74.5rem]">
        <CtaComponent
          tw="laptop:rounded-lg rounded-none"
          title={divider_inline_title}
          subtitle={divider_inline_description}
          buttons={divider_inline_buttons}
        />
      </div>
      <ExploreBackgroundsSection />
    </SbEditable>
  )
}

const BackgroundWrapper: React.FC<Props> = ({ blok }) => (
  <VirtualBackgroundSettingsProvider>
    <Background blok={blok} />
  </VirtualBackgroundSettingsProvider>
)

export default BackgroundWrapper
