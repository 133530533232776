import { Currency } from "../contexts/PricingDataContext"

const currencySymbols = {
  DKK: "kr",
  EUR: "€",
  GBP: "£",
  NOK: "kr",
  SEK: "kr",
  USD: "$",
}

export const getCurrencySymbol = (currency: Currency): string => {
  if (Object.keys(currencySymbols).includes(currency)) return currencySymbols[currency]
  return "Symbol not found"
}
