import React, { useCallback, useEffect, useState } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import _ from "lodash"
import Container from "../general/Container"
import { FancyHeroStoryblok } from "../../../component-types-sb"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import { Subtitle } from "../typography/Typography"
import LinkButton from "../general/LinkButton"
import Image from "../general/Image"
import { getImageDimensions } from "../../utils/getImageDimensions"
import ButtonGroup from "./ButtonGroup"
import { HeroTitle, isButtonGroup } from "./Hero"

interface Props {
  readonly blok: FancyHeroStoryblok
}

const ContentWrapper = tw.div`
  // max-w-container-large w-full mx-auto
  w-full
  grid gap-x-8 gap-y-16
  items-center
  pb-8
  desktop:pb-0
  grid-template-areas[
    "image"
    "text"
  ]
  desktop:(
    grid-template-columns[1fr 1fr]
    grid-template-areas["image text"]
  )
  [&[data-hero-text-image-ratio="1:2"]]:(
    desktop:(
      grid-template-columns[2fr 1fr]
    )
  )
`

const AbsoluteLeftImageWrapper = tw.div`
  absolute top[-15rem] left-0
  z-[-1]
  desktop-xl:-top-40
  desktop-2xl:-top-20
  hidden desktop:block
`

const AbsoluteRightImageWrapper = tw.div`
  absolute -bottom-16 right-0
  z-10
  hidden desktop:block
`

const MediaWrapper = tw.div`
  // grid-area[image]
  // grid grid-template-areas['s']
  // [&>*, &:before, &:after]:grid-area[s]
  mr-[-12.5%]
  desktop:mr-0
  relative
  w-full
  flex
  items-center
  justify-center
`

// Hero used for WebinarVerse page
// Optimized for that page so probably not really reusable
const FancyHero: React.FC<Props> = ({ blok }) => {
  const { width } = getImageDimensions(blok.image)
  const defaultWidth = blok.text_image_ratio === "1:2"
    ? 667
    : 556

  const ref = React.useRef<HTMLDivElement>(null)
  const [heightOffset, setHeightOffset] = useState(0)

  // set height offset to center the badges vertically
  const handleScroll = useCallback(() => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect()
      window.requestAnimationFrame(() => setHeightOffset(top))
    }
  }, [])

  useEffect(() => {
    const throttledHandleScroll = _.throttle(handleScroll, 50)
    window.addEventListener("scroll", throttledHandleScroll)

    return () => window.removeEventListener("scroll", throttledHandleScroll)
  }, [handleScroll])

  return (
    <SbEditable content={blok}>
      <Container tw="p-0! max-w-container-huge">
        <ContentWrapper data-hero-text-image-ratio={blok.text_image_ratio ?? "1:1"}>
          <div tw="grid-area[text]">
            <HeroTitle dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.title) }} tw="font-medium mb-0!" />
            <Subtitle
              tw="text-center desktop:text-left font-normal [.section-dark-background &]:(text-white) mt-3 mb-8"
              dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.description) }} />

            {blok.button && blok.button.length > 0 && <>
              {isButtonGroup(blok.button[0])
                ? <ButtonGroup blok={blok.button[0]} />
                : <div tw="flex justify-center desktop:justify-start w-full">
                  <LinkButton button={blok.button[0]} />
                </div>}
            </>}
          </div>
          <MediaWrapper>
            <div style={{ transform: `translateY(${heightOffset * 0.12 + 24}px)` }}
              ref={ref} tw="duration-[50ms] absolute w-3/4 h-full z-10 top-8">
              {blok.text_image?.filename && <Image
                tw="laptop:(w-full object-contain!)"
                image={blok.text_image}
                fluid={Math.min(defaultWidth, width)}
                lazy={false}
                showPlaceholder={false} />}
            </div>

            <div
              tw="w-full"
              ref={ref}
            >
              {blok.image?.filename && <Image
                tw="object-contain"
                image={blok.image}
                fluid={Math.min(defaultWidth, width)}
                lazy={false}
                showPlaceholder={false} />}
            </div>

            {/* <div tw="w-full h-1/2 bg-gradient-to-t from-blue-midnight to-transparent self-end z-10" /> */}
          </MediaWrapper>
        </ContentWrapper>

        <AbsoluteLeftImageWrapper>
          {blok.background_image_left?.filename && <Image
            image={blok.background_image_left}
            fluid={Math.min(defaultWidth, width)}
            lazy={false}
            showPlaceholder={false}
            width={400}
            height={400} />}
        </AbsoluteLeftImageWrapper>

        <AbsoluteRightImageWrapper style={{ transform: `translateY(${heightOffset * 0.05}px)` }}>
          {blok.background_image_right?.filename && <Image
            image={blok.background_image_right}
            fluid={Math.min(defaultWidth, width)}
            lazy={false}
            showPlaceholder={false}
            width={400}
            height={400} />}
        </AbsoluteRightImageWrapper>
      </Container>
    </SbEditable>
  )
}

export default FancyHero
