import React from "react"
import { P } from "../typography/Typography"

import { HowToCardStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { getImageDimensions } from "../../utils/getImageDimensions"
import { useVirtualBackgroundSettings } from "../../contexts/VirtualBackgroundSettingsContext"
import { HowToContainer } from "./VirtualBackgroundComponents"
import { Title } from "./ExploreBackgroundsSection"
interface CardProps {
  readonly sb: HowToCardStoryblok
  readonly index: number
}

const Card = ({ sb, index }: CardProps) => {
  const { width } = getImageDimensions(sb.image)
  const defaultWidth = 280

  return (
    <div tw="w-[280px]">
      {sb.image?.filename && <div tw="rounded-md mb-2.5 aspect-ratio[16/9] overflow-hidden relative">
        <div tw="flex justify-center items-center absolute top-4 left-4 bg-white z-10 w-5 h-5 rounded">
          <span tw="text-12 font-bold text-black">{index}</span>
        </div>
        <Image
          image={sb.image}
          fluid={Math.min(defaultWidth, width)}
          lazy={false} showPlaceholder={false} />
      </div>}
      <P tw="text-left text-14 mt-3.5 leading-6">{sb.description}</P>
    </div>
  )
}

const HowToSection = () => {
  const {
    how_to_title, how_to_steps,
  } = useVirtualBackgroundSettings()
  return (
    <HowToContainer tw="bg-blue-midnight rounded-lg text-white">
      <Title>
        {how_to_title}
      </Title>
      <div tw="flex justify-center w-full gap-[65px] laptop:flex-row flex-col laptop:items-start items-center">
        {how_to_steps?.map((cardData, i) => <Card sb={cardData} index={i + 1} key={cardData._uid} />)}
      </div>
    </HowToContainer>
  )
}

export default HowToSection
