import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ChangeEvent, useState } from "react"
import tw, { styled } from "twin.macro"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import { isUndefined } from "../../utils/typeGuards"
import Button from "../general/Button"
import FilterItem from "../general/FilterItem"
import { H4 } from "../typography/Typography"
import { AvailableTags, TagType, useAcademyFilter } from "./AcademyWrapper"

interface Props {
  readonly texts: {
    all_tag_title: string
    type_filter_title: string
    topic_filter_title: string
    duration_filter_title: string
    reset_filter_btn: string
    open_filter_btn: string
    expert_level_filter_title: string
  }
}

const Filter = tw.div`
  hidden
  laptop:block
  [&[data-academy-filter-open="true"]]:(
    block
    max-w-md
    border border-gray-5 border-t-0
    rounded-b
    px-4
    laptop:(
      border-0
      rounded-none
      px-0
    )
  )
`

const FilterButton = tw.button`
  flex justify-between items-center
  w-full max-w-md
  py-2 px-4 m-0
  border border-gray-5
  rounded
  text-20
  laptop:hidden
  [&[data-academy-filter-open="true"]]:(
    rounded-b-none
  )
`

const StyledSlider = styled.input(() => [
  tw`
    appearance-none -webkit-appearance[none]
    w-full h-2
    my-2
    rounded-full
    bg-gray-5
    focus:outline-none focus-visible:outline-black
    `,
  {
    "::-moz-range-thumb": tw`w-4 h-4 border-none rounded-full cursor-pointer bg-blue`,
    "::-webkit-slider-thumb": tw`
      appearance-none -webkit-appearance[none]
      w-4 h-4
      rounded-full
      border-none
      cursor-pointer
      bg-blue
      `,
  },
])

const getTagsByTypes = (availableTags: AvailableTags, type: TagType) => {
  const tagsByType = availableTags[type]

  tagsByType.sort((a, b) => {
    if (!isUndefined(a.position) && !isUndefined(b.position)) {
      if (a.position < b.position) return -1
      if (a.position > b.position) return 1
      return 0
    }
    return 0
  })

  return tagsByType.map((tag) => tag.name)
}

interface TagFilterProps {
  readonly tagType: TagType
  readonly title: string
  readonly allTagsTitle: string
}

const TagFilter = ({ tagType, title, allTagsTitle }: TagFilterProps) => {
  const {
    selectedFilters, availableTags, setSelectedFilters,
  } = useAcademyFilter()

  const selectTag = (tag: string) => {
    setSelectedFilters((prev) => ({ ...prev, [tagType]: tag }))
  }

  const tags = getTagsByTypes(availableTags, tagType)

  return <div>
    <H4>{title}</H4>
    {allTagsTitle
      ? <div key="all">
        <FilterItem
          name={allTagsTitle}
          value={allTagsTitle}
          onChange={() => selectTag("AllTags")}
          checked={selectedFilters[tagType] === "AllTags"}
          spacing="small"
        />
      </div>
      : null
    }
    {tags.map((tag) => <div key={tag}>
      <FilterItem
        name={tag}
        value={tag}
        onChange={() => selectTag(tag)}
        checked={selectedFilters[tagType] === tag}
        spacing="small"
      />
    </div>)}
  </div>
}

const AcademyTagFilter: React.FC<Props> = ({ texts }) => {
  const [filterOpen, setFilterOpen] = useState(false)
  const {
    setSelectedFilters, selectedFilters, durations,
  } = useAcademyFilter()
  const { min_text } = useGlobalSettings()

  const showResetBtn = selectedFilters.type !== "AllTags" || selectedFilters.topic !== "AllTags"
    || selectedFilters.expertLevel !== "AllTags" || selectedFilters.duration !== durations.max

  const updateSelectedDuration = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setSelectedFilters((prev) => ({ ...prev, duration: parseInt(target.value, 10) }))
  }

  const resetFilter = () => {
    setSelectedFilters({ duration: durations.max, expertLevel: "AllTags", topic: "AllTags", type: "AllTags" })
  }

  return <div tw="w-full flex flex-col items-center">
    {/* Button for responsive filter, only visible on mobile & tablet */}
    <FilterButton data-academy-filter-open={filterOpen} onClick={() => setFilterOpen(!filterOpen)}>
      {texts.open_filter_btn}
      <FontAwesomeIcon icon={filterOpen
        ? faChevronUp
        : faChevronDown} />
    </FilterButton>

    <Filter data-academy-filter-open={filterOpen} tw="w-full pb-4 desktop-xl:(min-width[200px] pb-0)">
      <TagFilter tagType="type" title={texts.type_filter_title} allTagsTitle={texts.all_tag_title} />
      <TagFilter tagType="topic" title={texts.topic_filter_title} allTagsTitle={texts.all_tag_title} />
      <TagFilter tagType="expertLevel" title={texts.expert_level_filter_title} allTagsTitle={texts.all_tag_title} />

      {/* Duration */}
      <div tw="max-w-sm ">
        <H4>{texts.duration_filter_title}</H4>
        <div tw="relative pt-1 flex flex-col">
          <label htmlFor="durationSlider">0 - {selectedFilters.duration} {min_text}</label>
          <StyledSlider
            type="range"
            min={durations.min}
            max={durations.max}
            step="5"
            id="durationSlider"
            value={selectedFilters.duration}
            onChange={updateSelectedDuration}
          />
        </div>
        <div tw="flex justify-between">
          <span>{durations.min}</span>
          <span>{durations.max}</span>
        </div>
      </div>

      {showResetBtn && <Button variant="blue" onClick={resetFilter} tw="w-full p-2 mb-2 mt-6">
        {texts.reset_filter_btn}
      </Button>}
    </Filter>
  </div>
}

export default AcademyTagFilter
