import useLocale from "./useLocale"

export const useBlogDateFormat = (date: string): string => {
  const { locale } = useLocale()
  if (!date) return "Missing date!"
  // Safari can't parse Storyblok dates so we chop off the time with .slice
  return new Intl.DateTimeFormat(locale === "default"
    ? "en-GB"
    : locale, {
    day: "2-digit",
    month: "long",
    year: "numeric",
  }).format(new Date(date.slice(0, 10)))
}

export default useBlogDateFormat
