import React, { createContext, useContext, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import { GlobalSettingsStoryblok } from "../../component-types-sb"
import useLocale from "../hooks/useLocale"
import { sbConfig, Storyblok } from "../hooks/useStoryblok"
import { ChildrenProp } from "../../react"

interface GlobalSettingsData {
  stories: {
    nodes: {
      uuid: string
      lang: string
      content: string
      field_component: string
    }[]
  }
}

const GlobalSettingsContext = createContext<GlobalSettingsStoryblok | null>(null)

export const GlobalSettingsProvider: React.FC<ChildrenProp> = ({ children }) => {
  const data: GlobalSettingsData = useStaticQuery(graphql`
    query GlobalSettingsQuery {
      stories: allStoryblokEntry(
        filter: {field_component: {eq: "globalSettings"}}
      ) {
        nodes {
          uuid
          lang
          content
          field_component
        }
      }
    }
  `)
  const { locale } = useLocale()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const story = data.stories.nodes.find((x) => x.lang === locale)!
  const staticContent = JSON.parse(story.content) as GlobalSettingsStoryblok
  const [content, setContent] = useState<GlobalSettingsStoryblok>(staticContent)
  const location = useLocation()
  useEffect(() => {
    const getUpdatedGlobalSettings = async (): Promise<void> => {
      try {
        const res = await Storyblok.get("cdn/stories/global-settings", {
          language: locale,
          resolve_links: "url",
          resolve_relations: sbConfig.options.resolveRelations.join(","),
          version: "draft",
        }) as { data: { story?: { content: GlobalSettingsStoryblok } } }
        const newContent = res.data?.story?.content
        if (newContent) setContent(newContent)
      } catch (error) {
        console.error("Could not fetch most recent globalsettings object")
      }
    }

    if (location.search.includes("_storyblok")) void getUpdatedGlobalSettings()
  }, [locale, location])
  return <GlobalSettingsContext.Provider value={content}>{children}</GlobalSettingsContext.Provider>
}

export const useGlobalSettings = (): GlobalSettingsStoryblok => {
  const context = useContext(GlobalSettingsContext)
  if (!context) throw new Error("useGlobalSettings must be used within a GlobalSettingsProvider")
  return context
}
