import React from "react"
import SbEditable from "storyblok-react"
import { H1, H2, H3, H4 } from "../typography/Typography"
import { BlogPostStoryblok, BlogIndexPageStoryblok, CategoryPageStoryblok } from "../../../component-types-sb"
import BlogNav from "../blogComponents/BlogNav"
import CardList from "../general/CardList"
import BlogCard from "../cards/BlogCard"
import Container from "../general/Container"
import useTags from "../../hooks/useTags"
import Tag from "../blogComponents/Tag"
import SmallBlogCard from "../cards/SmallBlogCard"
import CategoryCard from "../cards/CategoryCard"
import LinkButton from "../general/LinkButton"

interface Props {
  readonly blok: BlogIndexPageStoryblok
  readonly categoryStories?: Record<string, {
      full_slug: string
      content: string
    }[]>
}

const BlogIndexPage: React.FC<Props> = ({ blok, categoryStories }) => {
  const featuredStories = blok.featured_stories as { content: BlogPostStoryblok, full_slug: string }[]
  const categories = blok.categories as { content: CategoryPageStoryblok, full_slug: string, slug: string }[]
  const tags = useTags()
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div tw="sticky w-full top-17.5 z-20">
        <BlogNav section="blogPost" title="learn" level={0} />
      </div>
      <Container>
        <div tw="max-w-container-large pt-8 laptop:pt-14 pb-20">
          <H1>{blok.title}</H1>
          <div tw="flex flex-col space-x-0 laptop:(flex-row space-x-16)">
            <div tw="flex-1 desktop:flex-2 mb-8">
              <H4 tw="mt-0">{blok.subtitle}</H4>
              {featuredStories[0] && <BlogCard content={featuredStories[0].content} slug={featuredStories[0].full_slug} />}
            </div>
            <div tw="flex-1 space-y-4 mb-2">
              <H2 tw="mt-0">{blok.featured_title}</H2>
              <ul tw="space-y-4">
                {featuredStories.slice(1).map(({ content, full_slug }, index) => (
                  <SmallBlogCard key={content._uid} content={content} full_slug={full_slug} index={index} />
                ))}
              </ul>
              {blok.cta && blok.cta.length > 0 && (
                <LinkButton button={blok.cta[0]} />
              )}
            </div>
          </div>
          <div tw="mt-16">
            <H2 tw="text-center mb-8">{blok.categories_text}</H2>
            <div tw="grid grid-cols-1 tablet:grid-cols-2 gap-8 max-w-container-small mx-auto">
              {categories.map((category) => <CategoryCard
                key={category.full_slug}
                title={category.content.title}
                fullSlug={category.full_slug}
              />)}
            </div>
          </div>
          <div tw="space-y-20 mt-20 mb-20">
            {categoryStories && Object.keys(categoryStories).map((k) => {
              const category = categories.find((x) => x.slug === k)
              if (category) {
                return <CardList
                  key={category.content._uid}
                  name={category.content.title}
                  cards={categoryStories[k]}
                  link={{ full_slug: category.full_slug, text: category.content.link_text }}
                />
              }
              return null
            })}
          </div>
          <div tw="max-w-container mx-auto mb-12">
            <H3 tw="text-center mb-8">{blok.tags_text}</H3>
            <div tw="flex justify-center flex-wrap">
              {tags.map((tag) => <Tag key={tag.title} tag={tag} />)}
            </div>
          </div>
        </div>
      </Container>
    </SbEditable>
  )
}

export default BlogIndexPage
