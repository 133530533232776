import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

interface Props {
  readonly size: "small" | "large"
}

const SpinnerWrapper = styled.div<Props>(({ size }) => [
  tw`animate-spin text-blue`,
  size === "small" && tw`(text-16 leading-4)`,
  size === "large" && tw`(text-40 leading-10)`,
])

const Spinner: React.FC<Props> = ({ size }) => (
  <SpinnerWrapper size={size}>
    <FontAwesomeIcon icon={faSpinnerThird} />
  </SpinnerWrapper>
)

export default Spinner
