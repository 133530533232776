import React, { useState, createContext, useContext, useMemo } from "react"
import { ChildrenProp } from "../../react"

interface Props {
  selectedPlans: string[]
  setSelectedPlans: React.Dispatch<React.SetStateAction<string[]>>
  filterEnabled: boolean
  setFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const FeatureFilterContext = createContext<Props | null>(null)

const FeatureFilterContextProvider: React.FC<ChildrenProp> = ({ children }) => {
  const [selectedPlans, setSelectedPlans] = useState<string[]>([])
  const [filterEnabled, setFilterEnabled] = useState(false)

  const contextValue = useMemo(() => ({
    filterEnabled,
    selectedPlans,
    setFilterEnabled,
    setSelectedPlans,
  }), [filterEnabled, selectedPlans])

  return <FeatureFilterContext.Provider value={contextValue}>
    {children}
  </FeatureFilterContext.Provider>
}

export default FeatureFilterContextProvider

export const useFeatureFilter = (): Props => {
  const context = useContext(FeatureFilterContext)
  if (!context) throw new Error("Only call useFeatureFilter within a FeatureFilterContextProvider")
  return context
}
