import React from "react"
import { Helmet } from "react-helmet"
import SbEditable from "storyblok-react"
import { ZapierEmbedStoryblok } from "../../../component-types-sb"
import LinkButton from "../general/LinkButton"
import Container from "../general/Container"
import Image from "../general/Image"
import { H3, H5, P } from "../typography/Typography"
import CardGrid from "./CardGrid"

interface Props {
  readonly blok: ZapierEmbedStoryblok
}

const ZapierEmbed: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <Container>
      <Helmet>
        <script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js" />
        <link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css" />
      </Helmet>
      <div tw="max-w-container-large mx-auto grid grid-cols-1 desktop:grid-cols-3 gap-y-8 gap-x-12 my-8">
        <div tw="desktop:col-span-2">
          <div tw="flex items-center space-x-2">
            <Image width="20px" image={blok.native_logo} />
            <H5 as="h2">{blok.native_title}</H5>
          </div>
          <P tw="mb-0">{blok.native_description}</P>
        </div>
        <div>
          <div tw="flex items-center space-x-2">
            <Image width="20px" image={blok.zapier_logo} />
            <H5 as="h2">{blok.zapier_title}</H5>
          </div>
          <P tw="mb-0">{blok.zapier_description}</P>
        </div>
        <div tw="desktop:col-span-2 row-start-2 -mt-8">
          <CardGrid blok={blok.cards[0]} noContainer />
        </div>
        <div tw="flex-1">
          <zapier-app-directory
            app="webinargeek"
            theme="light"
            link-target="new-tab"
            zaplimit={2}
            zapstyle="row"
            introcopy="hide"
            hide={blok.excluded_integrations} />
        </div>
        <div tw="desktop:col-span-2">
          <H3>{blok.cta_title}</H3>
          <P>{blok.cta_description}</P>
          <div tw="w-fit mt-8">
            {blok.cta_button && blok.cta_button.length > 0 && <LinkButton button={blok.cta_button[0]} />}
          </div>
        </div>
      </div>
    </Container>
  </SbEditable>
)

export default ZapierEmbed
