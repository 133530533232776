import React from "react"
import tw from "twin.macro"
import { TabbedContentItemStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import { H5, P } from "../typography/Typography"
import Image from "./Image"
import LinkResolver from "./Link"

interface Props {
  readonly blok: TabbedContentItemStoryblok
  readonly direction: "row" | "col" | "col_detail"
}

const StyledTabbedContentItem = tw.div`
  shadow
  p-4 tablet:p-8
  opacity-0
  animate-fadeIn
  text-center
  [.section-dark-background &]:(
    bg-gray-bright
    opacity-100
  )
  [[data-tabbed-content-style="rounded"] &]:(
    rounded-3xl
  )
`

const StyledTabbedContentImage = tw(Image)`
  [[data-tabbed-content-style="rounded"] &]:(
    rounded-2xl
  )
`

const TabbedContentItem: React.FC<Props> = ({ blok, direction }) => {
  const { width } = getImageDimensions(blok.Image)
  if (direction === "col_detail") {
    return <div key={blok._uid} tw="opacity-0 animate-fadeIn hidden laptop:block">
      <StyledTabbedContentImage image={blok.Image} fluid={Math.min(870, width)} />
    </div>
  }
  if (direction === "col") {
    return <StyledTabbedContentItem key={blok._uid}>
      <H5>{blok.title}</H5>
      <div tw="flex flex-col items-center space-x-0 laptop:(flex-row items-start space-x-8)">
        <div tw="flex-1">
          <StyledTabbedContentImage image={blok.Image} fluid={Math.min(836, width)} />
        </div>
        <div tw="flex-1 laptop:(text-left)">
          <P>{blok.description}</P>
        </div>
      </div>
    </StyledTabbedContentItem>
  }
  if (direction === "row") {
    return <StyledTabbedContentItem key={blok._uid}>
      <H5>{blok.title}</H5>
      <P tw="whitespace-pre-line">{blok.description}</P>
      {blok.link?.[0]
      && <div tw="mb-6 text-18">
        <LinkResolver url={blok.link[0].url} styling="textlink">
          {blok.link[0].text}
        </LinkResolver>
      </div>
      }
      <StyledTabbedContentImage image={blok.Image} fluid={Math.min(836, width)} />
    </StyledTabbedContentItem>
  }
  return null
}

export default TabbedContentItem
