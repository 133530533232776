import { m } from "framer-motion"
import React from "react"
import { ChildrenProp } from "../../../react"

const InViewWrapper: React.FC<ChildrenProp> = ({ children, ...rest }) => (
  <m.div
    {...rest}
    initial="offscreen"
    whileInView="onscreen"
    viewport={{ amount: "some", margin: "-200px 0px -200px 0px", once: true }}
  >
    {children}
  </m.div>
)

export default InViewWrapper
