import React from "react"
import SbEditable from "storyblok-react"
import tw, { css } from "twin.macro"
import styled from "styled-components"
import Flickity from "react-flickity-component"
import { CarouselCardsStoryblok } from "../../../component-types-sb"
import { flickityStyles } from "../section/ProductSection"
import DynamicComponent from "./DynamicComponent"

interface Props {
  blok: CarouselCardsStoryblok
}

const FlickityContainer = styled.div(() => [
  flickityStyles,
  tw`w-full h-[min-content] desktop:hidden relative
  [.flickity-page-dots]:(bottom-4)`,
])

const columnStyles = {
  1: tw`grid-cols-1`,
  2: tw`grid-cols-2`,
  3: tw`grid-cols-3`,
  4: tw`grid-cols-4`,
  5: tw`grid-cols-5`,
  6: tw`grid-cols-6`,
}

const isValidColumnNumber = (key: number): key is keyof typeof columnStyles => (
  Object.keys(columnStyles).includes(key.toString())
)

const CardContainer = styled.div<{ gap: boolean, columns: number }>(({ gap, columns }) => [
  tw`
    gap-y-4 gap-0 py-8
    max-w-container-xxl px-4
    hidden desktop:grid
  `,
  css`grid-auto-rows: 1fr;`,
  gap && tw`gap-4`,
  isValidColumnNumber(columns)
    ? columnStyles[columns]
    : columnStyles[3],
])

const CarouselCards: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <CardContainer gap={Boolean(blok.gap)} columns={Number(blok.columns)} className={blok.gap
      ? "has-gap"
      : ""}>
      {blok.cards?.map((card) => (
        <div tw="laptop:-mx-1 desktop:my-0 [.has-gap &]:(m-0 -my-1) h-full" key={card._uid}>
          <DynamicComponent blok={card} />
        </div>
      ))}
    </CardContainer>
    <FlickityContainer>
      <Flickity
        options={{
          pageDots: true,
          prevNextButtons: true,
          wrapAround: false,
        }}
      >
        {blok.cards?.map((card) => (
          <div
            tw="h-[min-content] min-h-full px-2 pb-12 w-10/12"
            key={card._uid}
          >
            <DynamicComponent blok={card} />
          </div>
        ))}
      </Flickity>
    </FlickityContainer>
  </SbEditable>
)

export default CarouselCards
