import { captureException } from "@sentry/gatsby"
import { handlePromise } from "./handlePromise"

const getLocationFromIp = async () => {
  const countryData = await handlePromise(fetch("https://freeipapi.com/api/json/")
    .then((response) => (
        response.json() as Promise<{ countryCode: string, continent: string }>
    )))
  if (countryData.error || !countryData.data?.countryCode) {
    captureException(countryData.error)
    throw new Error("Failed getting location data from ip address")
  }

  return countryData.data
}

export default getLocationFromIp
