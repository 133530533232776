import { graphql, useStaticQuery } from "gatsby"
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from "react"
import { captureException } from "@sentry/gatsby"
import { PricingDataStoryblok } from "../../component-types-sb"
import useIsTimezone from "../hooks/useIsTimezone"
import { RatesResponse, getCurrencyRates } from "../gatsby/sourceNodes"
import getLocationFromIp from "../utils/getLocationFromIp"
import { ChildrenProp } from "../../react"

export type Currency = "EUR" | "USD" | "GBP" | "SEK" | "NOK" | "DKK"
export type GetPriceMode = "normal" | "basic" | "annual" | "monthly"

export const pricingPlans = ["standard", "premium", "advanced"] as const
export type PricingPlan = (typeof pricingPlans)[number]

type AvailablePlans = Record<PricingPlan, PricingDataStoryblok[]>
type SelectedPlans = Record<PricingPlan, number>

interface ProviderProps {
  readonly pricingData: PricingDataStoryblok[]
}

interface PricingData {
  currency: Currency
  currentRate: number
  yearlyBilling: boolean
  viewerIndex: number
  setCurrency: React.Dispatch<React.SetStateAction<Currency>>
  setYearlyBilling: React.Dispatch<React.SetStateAction<boolean>>
  setViewerIndex: React.Dispatch<React.SetStateAction<number>>
  getPrice: (mode: GetPriceMode, plan: PricingPlan) => number | null
  getCardPrice: (price: number | string | undefined) => string
  pricingData: PricingDataStoryblok[]
  formattedViewers: string
  onlyShowUsd?: boolean
  availablePlans: AvailablePlans
  selectedPlans: SelectedPlans
  setSelectedPlans: React.Dispatch<React.SetStateAction<SelectedPlans>>
}

const PricingDataContext = createContext<PricingData | null>(null)

const PricingDataProvider: React.FC<ProviderProps & ChildrenProp> = ({ children, pricingData }) => {
  const [currency, setCurrency] = useState<Currency>("EUR")
  const [yearlyBilling, setYearlyBilling] = useState(true)
  const [viewerIndex, setViewerIndex] = useState(1)
  const [onlyShowUsd, setOnlyShowUsd] = useState<boolean | undefined>()
  const isTimezone = useIsTimezone()

  const availablePlans: AvailablePlans = pricingData.reduce((obj, data) => {
    const plan = data.available_for_plan
    if (!plan) return obj
    return ({
      ...obj,
      [plan]: [
        ...obj[plan],
        data,
      ],
    })
  }, {
    advanced: [],
    premium: [],
    standard: [],
  })

  const [selectedPlans, setSelectedPlans] = useState<SelectedPlans>({
    advanced: 0,
    premium: 0,
    standard: 0,
  })

  const buildRates = useStaticQuery<{ rates: RatesResponse["rates"] }>(graphql`
    query AllRates{
      rates {
        EUR
        GBP
        USD
        SEK
        NOK
        DKK
      }
    }
  `)

  const [rates, setRates] = useState<RatesResponse["rates"]>(buildRates.rates)

  useEffect(() => {
    const fetchRates = async (): Promise<void> => {
      try {
        const data = await getCurrencyRates()
        if (data.success) {
          setRates({
            DKK: data.rates.DKK,
            EUR: 1,
            GBP: data.rates.GBP,
            NOK: data.rates.NOK,
            SEK: data.rates.SEK,
            USD: data.rates.USD,
          })
        }
      } catch (error) {
        console.error("Failed getting real rates. Use rates from build time instead")
      }
    }
    void fetchRates()
  }, [])

  useEffect(() => {
    const setCurrencyFromTimezone = () => {
      // Force currency to USD if only showing USD, so we show the correct symbol
      if (onlyShowUsd) {
        setCurrency("USD")
        return
      }
      if (isTimezone(/London/u)) setCurrency("GBP")
      else if (isTimezone(/America/u)) setCurrency("USD")
      else if (isTimezone(/Copenhagen/u)) setCurrency("DKK")
      else if (isTimezone(/Oslo/u)) setCurrency("NOK")
      else if (isTimezone(/Stockholm/u)) setCurrency("SEK")
      else setCurrency("EUR")
    }
    setCurrencyFromTimezone()
  }, [isTimezone, onlyShowUsd])

  useEffect(() => {
    const setCurrencyFromTimezone = () => {
      getLocationFromIp().then((data) => {
        if (!isTimezone(/America/u) && data.continent !== "Americas") setOnlyShowUsd(false)
        else setOnlyShowUsd(true)
      })
        .catch((err: unknown) => {
          captureException(err)
          setOnlyShowUsd(false)
        })
    }
    setCurrencyFromTimezone()
  }, [isTimezone])

  const currentRate = useMemo(() => {
    if (onlyShowUsd) return 1
    return rates[currency]
  }, [currency, onlyShowUsd, rates])

  const getPrice = useCallback((mode: GetPriceMode, plan: PricingPlan) => {
    const selectedPlanIndex = selectedPlans[plan]
    const selectedPlan = availablePlans?.[plan]?.[selectedPlanIndex]
    if (!selectedPlan) return null

    const yearly = mode === "annual" || (mode === "normal" && yearlyBilling)
    if (onlyShowUsd && selectedPlan.annually_US && selectedPlan.monthly_US) {
      return yearly
        ? Number(selectedPlan.annually_US)
        : Number(selectedPlan.monthly_US)
    }
    return yearly
      ? Number(selectedPlan.annually_EUR) * currentRate
      : Number(selectedPlan.monthly_EUR) * currentRate
  }, [availablePlans, currentRate, selectedPlans, yearlyBilling, onlyShowUsd])

  const getCardPrice = useCallback((price: number | string | undefined) => (
    (currentRate * Number(price)).toFixed(0)
  ), [currentRate])

  const formattedViewers = parseInt(pricingData[viewerIndex].viewers, 10).toLocaleString()

  const contextValue = useMemo(() => ({
    availablePlans,
    currency,
    currentRate,
    formattedViewers,
    getCardPrice,
    getPrice,
    onlyShowUsd,
    pricingData,
    selectedPlans,
    setCurrency,
    setSelectedPlans,
    setViewerIndex,
    setYearlyBilling,
    viewerIndex,
    yearlyBilling,
  }), [
    availablePlans,
    currency,
    currentRate,
    formattedViewers,
    getCardPrice,
    getPrice,
    onlyShowUsd,
    pricingData,
    selectedPlans,
    viewerIndex,
    yearlyBilling,
  ])

  return <PricingDataContext.Provider value={contextValue}>
    {children}
  </PricingDataContext.Provider>
}

export const usePricingData = (): PricingData => {
  const context = useContext(PricingDataContext)
  if (!context) throw new Error("usePricingData must be used within a PricingDataProvider")
  return context
}

export default PricingDataProvider
