import { getPlainText, Richtext } from "@storyofams/storyblok-toolkit"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import SbEditable from "storyblok-react"
import { AccordionStoryblok } from "../../../component-types-sb"
import AccordionItem from "../general/AccordionItem"
import Container from "../general/Container"

interface Props {
  readonly blok: AccordionStoryblok
}

const Accordion: React.FC<Props> = ({ blok }) => {
  const [open, setOpen] = useState(-1)
  const richResult = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: blok.items.map((item) => ({
      "@type": "Question",
      acceptedAnswer: {
        "@type": "Answer",
        text: getPlainText(item.answer as Richtext),
      },
      name: item.question,
    })),
  }

  return <SbEditable content={blok}>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(richResult)}</script>
    </Helmet>
    <Container>
      <div tw="max-w-container w-full my-4">
        {blok.title && <div tw="text-13 text-gray-2 my-4 [.section-dark-background &]:text-white">
          {blok.title?.toUpperCase()}
        </div>}
        <div tw="divide-y border-t border-b border-gray-4">
          {blok.items.map((item, index) => <AccordionItem
            key={item._uid}
            blok={item}
            open={open === index}
            setOpen={() => setOpen(open === index
              ? -1
              : index)}
          />)}
        </div>
      </div>
    </Container>
  </SbEditable>
}

export default Accordion
