import React from "react"
import SbEditable from "storyblok-react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import tw, { styled } from "twin.macro"
import { css } from "styled-components"
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { P } from "../typography/Typography"

import Image, { ImageType } from "../general/Image"
import { ProductCarouselFeatureStoryblok } from "../../../component-types-sb"
import { Richtext } from "./Richtext"
import LinkResolver from "./Link"
import Popup from "./Popup"

interface Props {
  blok: ProductCarouselFeatureStoryblok
}

export const colors = [
  tw`bg-[#E8F1F5] border-b-8 border-[#DAE8EF]`,
  tw`bg-[#CDE4F1] border-b-8 border-[#BCDCEC]`,
  tw`bg-[#C2DFEE] border-b-8 border-[#ACD3E8]`,
  tw`bg-[#ACD3E8] border-b-8 border-[#9BCAE3]`,
  tw`bg-[#6C94A9] border-b-8 border-[#56839B]`,
  tw`bg-[#F8FDFF] border-b-8 border-[#F8FDFF] shadow`,
]

const ItemWrapper = styled.div<{ i: number }>(() => [
  tw`
  rounded-[30px] py-8 px-8
  flex flex-col justify-between
  text-left!
  h-full
  shadow-xl
  hover:(z-10 scale-[1.01]) duration-200
  desktop-xl:w-full
  `,
  css`scroll-snap-align: center;`,
  ({ i = 0 }) => colors[i],
  { ".nonegm": tw`m-0 hover:(m-0)` },
])

interface ItemProps {
  title: string
  description: DocumentNode
  color: number
  category?: string
  image?: ImageType
  readMore?: ProductCarouselFeatureStoryblok["read_more"]
  readMoreText?: string
}

export const ProductCarouselItem = ({
  description,
  title,
  color,
  image,
  readMoreText,
}: ItemProps) => (<motion.div
  initial={{ opacity: 0, x: 50 }}
  animate={{ opacity: 1, transition: { delay: 0.40 + 0.05 * color, duration: 0.1, ease: "easeOut" }, x: 0 }}
  exit={{ opacity: 0, transition: { delay: 0.05 * color, duration: 0.2, ease: "easeOut" }, x: -50 }}
  tw="h-full"
>
  <ItemWrapper i={color} className="group">
    <div>
      <Image
        tw="h-12 mb-8 mx-auto"
        image={image}
        height="160px"
        fit="contain"
      />
      <P tw="font-bold">
        {title}
      </P>
      <Richtext text={description} />
    </div>
    {readMoreText && (
      <span tw="font-medium text-18 flex items-center gap-2 text-left">
        {readMoreText}
        <FontAwesomeIcon tw="motion-safe:group-hocus:animate-bounce-x" icon={faArrowRight} />
      </span>
    )}
  </ItemWrapper>
</motion.div>)

const ProductCarouselFeature: React.FC<Props> = ({ blok }) => {
  if (blok.popup && blok.popup.length > 0) {
    return <SbEditable content={blok}>
      <Popup blok={blok.popup[0]} tw="mb-2 desktop:h-full">
        <ProductCarouselItem
          title={blok.title ?? "not title provided"}
          description={blok.description as DocumentNode}
          color={Number(blok.color) ?? 0}
          image={blok.icon}
          readMoreText={blok.read_more_text}
        />
      </Popup>
    </SbEditable>
  }

  if (blok.read_more) {
    return <SbEditable content={blok}>
      <LinkResolver url={blok.read_more} tw="desktop:h-full">
        <ProductCarouselItem
          title={blok.title ?? "not title provided"}
          description={blok.description as DocumentNode}
          color={Number(blok.color) ?? 0}
          image={blok.icon}
          readMoreText={blok.read_more_text}
        />
      </LinkResolver>
    </SbEditable>
  }

  return <SbEditable content={blok}>
    <ProductCarouselItem
      title={blok.title ?? "not title provided"}
      description={blok.description as DocumentNode}
      color={Number(blok.color) ?? 0}
      image={blok.icon}
      readMoreText={blok.read_more_text}
    />
  </SbEditable>
}

export default ProductCarouselFeature
