import { Link } from "gatsby"
import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { TagPage } from "../../hooks/useTags"

interface Props {
  readonly tag: TagPage
}

export const StyledTag = styled.span`
  ${tw`
    flex items-center justify-center
    text-white!
    w-min h-[min-content] py-2 px-3 rounded-lg
    text-14 font-medium
    whitespace-nowrap
    bg-blue-light
  `}
  mask-image: linear-gradient(-45deg, #000000 40%, #FFFFFFB3 50%, #000000 60%);
  mask-size: 300%;
  mask-position: 100%;
  &.shimmer {
    .group:hover & {
      mask-position: 0%;
      transition: mask-position 0.25s ease-in-out;
    }
  }
`

export const Tag: React.FC<Props> = ({ tag }) => <Link to={`/${tag.full_slug}`}>
  <StyledTag tw="mb-2 mr-2 hover:(bg-none bg-blue-light/90)">
    {tag.title}
  </StyledTag>
</Link>

export default Tag

export const SmallTag = tw(StyledTag)`
  leading-6
  border-radius[10px]
`
