import React from "react"
import Flickity from "react-flickity-component"
import SbEditable from "storyblok-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons"
import { Link } from "gatsby"
import tw from "twin.macro"
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons"
import { RegisterPageStoryblok, ReviewStoryblok, ReviewCompanyStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { A, H1, H4, Subtitle, P } from "../typography/Typography"
import RegisterForm from "../general/RegisterForm"
import CompanyStarReview from "../general/CompanyStarReview"
import "../../fonts/fonts.css"
import "flickity/css/flickity.css"
import LinkResolver from "../general/Link"
import ClientOnly from "../general/ClientOnly"

interface Props {
  readonly blok: RegisterPageStoryblok
}

const CarouselContainer = tw.div`
  flex-shrink-0
  flex
  justify-center
  flex-col
  w-full
  laptop:(width[350px])
  desktop:(width[576px])
  bg-blue-midnight
  relative
  padding-bottom[6vh]
  rounded-tl-lg
  text-white
  [.flickity-page-dot]:(bg-[#CEE7EF] h-3 w-3 hocus:shadow-[0px_0px_0px_4px_#00677C])
  [.flickity-page-dot.is-selected]:(bg-[#00677C] h-3 w-3)
  [.flickity-page-dots]:(-bottom-2)
`

const SsoButton = tw(LinkResolver)`
  rounded-full
  border-gray-3 border
  px-8 py-2 font-medium
  text-[#18647c] whitespace-nowrap
  flex items-center justify-center
  laptop:w-min w-full
`

const RegisterPage: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div tw="flex flex-col laptop:(flex-row)">
    <div tw="flex-1 flex flex-col justify-center laptop:(h-screen py-20) py-40 px-4 text-center relative items-center">
      <Link to="/" tw="width[200px] absolute top-4 left-4">
        <Image image={blok.logo} fluid={200} width="200px" showPlaceholder={false} lazy={false} />
      </Link>
      <div tw="mb-4">
        <H1>{blok.heading}</H1>
        <Subtitle tw="font-normal">{blok.subheading}</Subtitle>
      </div>
      {blok.sso_enabled && <>
        <div tw="flex flex-col laptop:flex-row tablet:w-1/2 justify-center items-center w-3/4 gap-2">
          <SsoButton url={blok.google_button_url}>
            <FontAwesomeIcon icon={faGoogle} tw="mr-2" />
            {blok.google_button_text}
          </SsoButton>
          <SsoButton url={blok.microsoft_button_url}>
            <FontAwesomeIcon icon={faMicrosoft} tw="mr-2" />
            {blok.microsoft_button_text}
          </SsoButton>
        </div>
        <div tw="flex flex-row justify-center items-center tablet:w-1/2 w-3/4 gap-2 mt-4">
          <hr tw="border-t border-gray-3 w-full" />
          <P tw="text-18  mb-0 text-gray-3 font-normal">{blok.button_subtitle_text ?? "OR"}</P>
          <hr tw="border-t border-gray-3 w-full" />
        </div>
      </>}
      <ClientOnly>
        <RegisterForm layoutStyle="register" buttonText={blok.button_text} inputSubtitle={blok.email_input_subtitle} emailErrorMessage={blok.wrong_email_error} />
      </ClientOnly>
      <div>
        <P tw="text-14 font-light">{blok.login_text}{" "}
          <A tw="text-14 font-light" href="https://app.webinargeek.com/">{blok.login_url_text}</A>
        </P>
      </div>
      <P tw="text-body left-1/2 transform -translate-x-1/2 absolute bottom-12 font-light text-16">{blok.text}</P>
    </div>
    <CarouselContainer>
      <Flickity
        options={{
          autoPlay: 3000,
          pageDots: true,
          prevNextButtons: false,
          wrapAround: true,
        }}
      >
        {blok.quotes.map((quote) => {
          const { content } = quote as unknown as { content: ReviewStoryblok }
          const { content: reviewCompany } = content.review_company as unknown as { content: ReviewCompanyStoryblok }
          return <div
            key={content._uid}
            tw="w-full laptop:(width[350px]) desktop:(width[574px])
               flex flex-col text-center justify-evenly"
          >
            <div tw="p-8">
              <div tw="text-orange text-28">
                <FontAwesomeIcon icon={faQuoteLeft} />
              </div>
              <H4 tw="mb-8">{content.title}</H4>

              <CompanyStarReview logo={blok.capterra_logo} />

              <P tw="mb-6 mt-6 text-18 text-white font-heading font-normal">{content.quote}</P>
              {content.photoQuotee && <div tw="mx-auto w-fit">
                <Image image={content.photoQuotee} height="50px" width="50px" fixed={[50, 50]} />
              </div>}
              <P tw="text-14 font-bold mb-2 text-white">{content.quotee}</P>
              <P tw="text-14">{content.functionQuotee}</P>
              <div tw="flex justify-center">
                {reviewCompany && <a href={reviewCompany.url} target="_blank" rel="noreferrer nofollow">
                  <Image tw="h-4" image={reviewCompany.logo} />
                </a>}
              </div>
            </div>
          </div>
        })}
      </Flickity>
      <div tw="flex w-full items-center justify-center gap-6 mt-10">
        {blok.awards?.map((award) => <div key={award.filename} tw="h-20">
          <Image
            image={award}
            key={award.filename}
            fluid={60}
            showPlaceholder={false}
            lazy={false}
            tw="object-contain h-full"
            useRegularImageTag
          />

        </div>)}
      </div>
    </CarouselContainer>
  </div>
</SbEditable>

export default RegisterPage
