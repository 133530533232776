import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getPlainText, Richtext } from "@storyofams/storyblok-toolkit"
import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import SbEditable from "storyblok-react"
import { FeatureGridStoryblok, FeatureStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import Image from "../general/Image"
import Button from "../general/Button"
import { H5, P } from "../typography/Typography"
import { useFeatureFilter } from "../../contexts/FeatureFilterContext"
import Tooltip from "../general/Tooltip"
import { isEmpty } from "../../utils/typeGuards"

interface Props {
  readonly blok: FeatureGridStoryblok
}

interface WrapperProps {
  allFeaturesShown?: boolean
  onlyFeatures?: boolean
}

interface FilterProps {
  available?: boolean
}

interface CrossIconProps {
  lightTextColor?: boolean
}

const Wrapper = styled.ul<WrapperProps>(({ allFeaturesShown, onlyFeatures }) => [
  tw`relative grid grid-cols-1 gap-8 mx-auto mt-8 max-w-container tablet:grid-cols-2 laptop:grid-cols-3 border-gray-4`,
  allFeaturesShown && tw`pb-8 mb-8`,
  onlyFeatures && tw`pb-0 mb-8`,
  !onlyFeatures && tw`pt-8 pb-12 mb-12`,
])

const FeatureTitle = styled(H5)<FilterProps>(({ available }) => [
  tw`mt-0 font-normal text-20 tablet:text-21 laptop:text-23`,
  !available && tw`text-gray-4`,
])

const FeatureText = styled(P)<FilterProps>(({ available }) => [
  tw`text-18 text-gray-2`,
  !available && tw`text-gray-4`,
])

const FeatureIconWrapper = styled.span<FilterProps>(({ available }) => [
  tw`text-blue`,
  !available && tw`text-gray-4`,
])

const StyledCrossIcon = styled(FontAwesomeIcon)<CrossIconProps>(({ lightTextColor }) => [
  tw`text-gray-3`,
  lightTextColor && tw`text-gray-4`,
])

const FeatureGrid: React.FC<Props> = ({ blok }) => {
  // eslint-disable-next-line @typescript-eslint/init-declarations
  let lim: number | undefined
  if (isEmpty(blok.initial_limit)) {
    lim = blok.features.length
  } else {
    lim = typeof blok.initial_limit === "string"
      ? parseInt(blok.initial_limit, 10)
      : blok.initial_limit
  }
  const [limit, setLimit] = useState(lim)
  const allFeaturesShown = (limit === blok.features.length)
  const { selectedPlans, filterEnabled } = useFeatureFilter()

  const isAvailable = (feature: FeatureStoryblok) => (
    !filterEnabled || selectedPlans?.includes(feature.available_for_plan ?? "")
  )

  return (
    <SbEditable content={blok}>
      <Container>
        <Wrapper allFeaturesShown={allFeaturesShown} onlyFeatures={blok.only_features}>
          {/* eslint-disable-next-line complexity */}
          {blok.features.slice(0, limit).map((feature) => <li tw="flex items-baseline" key={feature._uid}>
            <div tw="flex-shrink-0 w-6">
              {feature.icon_image?.filename
                ? <Image image={feature.icon_image} width="16px" />
                : <FeatureIconWrapper available={isAvailable(feature)}>
                  {feature.icon === "tick" && isAvailable(feature) && <FontAwesomeIcon icon={faCheck} />}
                  {(feature.icon === "cross" || (feature.icon === "tick" && !isAvailable(feature)))
                    && <StyledCrossIcon
                      icon={faTimes}
                      lightTextColor={(feature.icon === "tick" && !isAvailable(feature))
                      || (feature.icon === "cross" && !isAvailable(feature))}
                    />}
                </FeatureIconWrapper>}
            </div>
            <div tw="flex-1">
              <div tw="height[62px] flex items-center">
                <FeatureTitle as="p" available={isAvailable(feature)}>
                  {getPlainText(feature.text as Richtext)}
                  {feature.extra_info && <span tw="text-16">
                    <Tooltip tooltip={feature.extra_info} />
                  </span>}
                </FeatureTitle>
              </div>
              <FeatureText available={isAvailable(feature)}>
                {feature.tooltip}
              </FeatureText>
            </div>
          </li>)}
          {!allFeaturesShown && !blok.only_features && <Button
            variant="blue"
            size="md"
            onClick={() => setLimit(blok.features.length)}
            tw="absolute bottom-0 transform translate-y-1/2 left-1/2 -translate-x-1/2"
          >
            {blok.button_text}
          </Button>}
        </Wrapper>
      </Container>
    </SbEditable>
  )
}

export default FeatureGrid
