import { graphql, PageProps } from "gatsby"
import React from "react"
import { PageStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import BlogNav from "../components/blogComponents/BlogNav"
import Page from "../components/templates/Page"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

const PageTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: PageStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    {data.story.default_full_slug === "academy/"
      && <div tw="sticky w-full top-17.5 z-20">
        <BlogNav section="academyChapter" title="academy" level={0} />
      </div>}
    <Page blok={content} />
  </BaseTemplate>
}

export default PageTemplate

export const query = graphql`
  query PageQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
