import { graphql, PageProps } from "gatsby"
import React from "react"
import { BlogIndexPageStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import BlogIndexPage from "../components/templates/BlogIndexPage"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

interface PageContext {
  categories: Record<string, {
      full_slug: string
      content: string
    }[]>
}

const CategoryTemplate: React.FC<PageProps<GQLStory, PageContext>> = ({ data, location, pageContext }) => {
  const { content } = useStoryblok(data.story, location) as { content: BlogIndexPageStoryblok }
  return (
    <BaseTemplate
      content={content}
      story={data.story}
    >
      <BlogIndexPage
        blok={content}
        categoryStories={pageContext.categories}
      />
    </BaseTemplate>
  )
}

export default CategoryTemplate

export const query = graphql`
  query LearnPage($slug: String) {
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
