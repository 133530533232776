// Type guard for undefined
export const isUndefined = (value: unknown): value is undefined => typeof value === "undefined"

// Type guard for nullish
export const isNullish = (value: unknown): value is null | undefined => typeof value === "undefined" || value === null

// Type guard for string
export const isString = (value: unknown): value is string => typeof value === "string"

type EmptyString = "" | null | undefined

// Type guard for empty string
export const isEmptyString = (value: string | EmptyString): value is EmptyString => (
  value === "" || isNullish(value)
)

export const isEmpty = (value: unknown): value is null | undefined | EmptyString => (
  isNullish(value) || (isString(value) && isEmptyString(value))
)

/**
 * Typesafe includes method for readonly arrays
 * @param collection readonly array
 * @param el element to test if included in array
 * @returns true if element is in the array
 */
export const includes = <TNarrow extends TWide, TWide>(collection: readonly TNarrow[], el: TWide): el is TNarrow => (
  collection.includes(el as TNarrow)
)
