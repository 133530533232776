import { graphql, PageProps } from "gatsby"
import React from "react"
import { CategoryPageStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import CategoryPage from "../components/templates/CategoryPage"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

interface GraphQLResult extends GQLStory {
  stories: {
    nodes: {
      name: string
      full_slug: string
      content: string
    }[]
  }
}

const CategoryTemplate: React.FC<PageProps<GraphQLResult>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: CategoryPageStoryblok }

  return (
    <BaseTemplate
      content={content}
      story={data.story}
    >
      <CategoryPage blok={content} stories={data.stories.nodes} />
    </BaseTemplate>
  )
}

export default CategoryTemplate

export const query = graphql`query FilterByCategory($slug: String, $regexSlug: String, $lang: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  stories: allStoryblokEntry(
    filter: {default_full_slug: {regex: $regexSlug}, lang: {eq: $lang}, field_component: {eq: "blogPost"}}
    sort: {field_datePublished_string: DESC}
  ) {
    nodes {
      full_slug
      content
    }
  }
}`
