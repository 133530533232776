import React, { useEffect, useRef } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { ChannelStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import { useWindowSize } from "../../hooks/useWindowSize"

declare global {
  interface Window {
    mw?: (name: string, options?: Record<string, unknown>) => void | undefined
  }
}

interface Props {
  readonly blok: ChannelStoryblok
}

const DescriptionContainer = tw.div`
  px-6
  grid grid-template-columns[1fr minmax(0, 1280px) 1fr]
  [> *]:(col-start-2)
`

const embedCode = (
  locale: string,
  uuid: string,
  headless = true,
  showWebinarsOnly = false,
  excludedCategories: ("ondemand" | "series" | "live" | "")[] = [],
) => `
(function (m, a, long) {
w=window;n=m.name;w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};
a=w.document.createElement('script'),long=w.document.getElementsByTagName('script')[0];
a.src='https://malong.webinargeek.com/malong.embed.js';a.id=n;a.async=1;long.parentNode.insertBefore(a,long);w[n]('init',m);
}({ element: document.getElementById('mw-${uuid.substring(0, 11)}'),
name: "mw",
project: "channel",
uuid: "${uuid}",
language: "${locale}",
channelLanguage: "${locale}",
${locale === "nl"
    ? ""
    : "webinarLanguage: \"en\", filterLanguage: \"en\","}
headless: ${headless.toString()},
showWebinarsOnly: ${showWebinarsOnly.toString()},
excludedCategories: ${JSON.stringify(excludedCategories)}
}
));
`

const Channel: React.FC<Props> = ({ blok }) => {
  const { locale } = useLocale()
  const containerRef = useRef<HTMLDivElement>(null)
  const { header_options, excluded_categories, channel_id } = blok
  const channelRef = useRef<HTMLDivElement>(null)

  const { width: containerWidth } = useWindowSize()

  useEffect(() => {
    const options = {
      channelLanguage: locale.replace("default", "en"),
      element: channelRef.current,
      excludedCategories: excluded_categories,
      language: locale.replace("default", "en"),
      name: "mw",
      project: "channel",
      uuid: channel_id,
      ...locale.replace("default", "en") === "nl"
        ? {}
        : { filterLanguage: "en", webinarLanguage: "en" },
      headless: header_options?.includes("hide_options"),
      showWebinarsOnly: !header_options?.includes("show_header"),
    }

    if (window.mw) {
      window.mw("destroy")
      window.mw("init", options)
    } else {
      const script = document.createElement("script")
      script.innerHTML = embedCode(
        locale.replace("default", "en"),
        options.uuid,
        options.headless,
        options.showWebinarsOnly,
        options.excludedCategories,
      )
      channelRef.current?.parentElement?.appendChild(script)
    }
  }, [locale, header_options, excluded_categories, channel_id])

  return (
    <SbEditable content={blok}>
      <div ref={containerRef} tw="w-full relative pb-2 mt-8">
        {containerWidth >= 782 && header_options?.includes("hide_options") && header_options?.includes("show_header")
          && <div tw="absolute block top-0 desktop:mt-4 w-full ">
            <DescriptionContainer>
              <p tw="pr-6 mr-56">{blok.description}</p>
            </DescriptionContainer>
          </div>}
        <div id={`mw-${channel_id.substring(0, 11)}`} ref={channelRef} />
      </div>
    </SbEditable>
  )
}

export default Channel
