import React from "react"
import tw from "twin.macro"
import useBackgroundPlatforms from "../../hooks/useBackgroundPlatforms"
import { H2 } from "../typography/Typography"
import { useVirtualBackgroundSettings } from "../../contexts/VirtualBackgroundSettingsContext"
import Tag from "../general/Tag"
import useLocale from "../../hooks/useLocale"
import { rewriteSlug } from "../../utils/rewriteSlug"

export const Title = tw(H2)`
  [&[data-text-size='smaller']]:(text-23 tablet:text-24 laptop:text-[32px])
  mt-14 mb-7 font-medium tracking-[1.6px]
`

const ExploreContainer = tw.div`
  text-black [.section-dark-background &]:text-white
  text-center flex flex-col items-center w-full pb-14 px-4
  my-[60px]
`

const ExploreBackgroundsSection = () => {
  const locale = useLocale()
  const allTags = useBackgroundPlatforms()
  const langTags = allTags.filter((x) => x.lang === locale.locale)
  const tags = langTags.slice(0, 4)

  const { explore_title } = useVirtualBackgroundSettings()

  return (
    <ExploreContainer>
      <Title>
        {explore_title}
      </Title>
      <div tw="flex w-full justify-center gap-x-[18px]">
        {tags.map((tag) => <Tag key={tag.full_slug} tw="hover:underline" href={rewriteSlug(tag.full_slug)}>
          {tag.title}
        </Tag>)}
      </div>
    </ExploreContainer>
  )
}

export default ExploreBackgroundsSection
