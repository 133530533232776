import { Link } from "gatsby"
import React from "react"
import tw from "twin.macro"
import { BackgroundStoryblok, VirtualBackgroundTagStoryblok } from "../../../component-types-sb"

import Image from "../general/Image"
import { getImageDimensions } from "../../utils/getImageDimensions"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Tag from "../general/Tag"
import { isUndefined } from "../../utils/typeGuards"

interface Props {
  readonly background: {
    content: BackgroundStoryblok
     full_slug: string
    lang: string
  }
  readonly key: string
}

const StyledLink = tw(Link)`
  px-3.5 py-3.5 mx-auto
  rounded
  w-[308px]  // 280 + 2 * padding-3.5 (14px) to make image same size as how to cards
  tablet:(w-full mx-0)
  [.hide-last &]:(laptop:last:hidden desktop:last:block)
  hover:(scale-[102%] shadow)
  duration-100
  ease-in-out
  bg-[#f8fdff]
`

const BackgroundCard: React.FC<Props> = ({ background, key }) => {
  const { width } = getImageDimensions(background.content.image)
  const defaultWidth = 246

  if (isUndefined(background.content.image)) {
    return null
  }

  const tag = background.content.tag as unknown as { content: VirtualBackgroundTagStoryblok }
  return (
    <StyledLink to={rewriteSlug(background.full_slug)} key={key}>
      <div tw="rounded-md mb-2.5 aspect-ratio[16/9] overflow-hidden">
        <Image
          image={background.content.image}
          fluid={Math.min(defaultWidth, width)}
          lazy={false} showPlaceholder={false} />
      </div>
      <div tw="flex flex-row laptop:(flex-col gap-0 items-baseline) items-center gap-2">
        <div tw="mt-2.5 mb-3.25">
          <Tag>{tag.content.title}</Tag>
        </div>
        <p tw="tablet:text-16 text-16 font-medium text-gray-1 truncate w-full">
          {background.content.title}
        </p>
      </div>
    </StyledLink>
  )
}

export default BackgroundCard
