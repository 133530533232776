import { graphql, PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { getPlainText, Richtext } from "@storyofams/storyblok-toolkit"
import { StoryblokStory } from "storyblok-generate-ts"
import useStoryblok from "../hooks/useStoryblok"
import { BlogPostStoryblok, PeopleStoryblok } from "../../component-types-sb"
import BlogOrChapterPost from "../components/templates/BlogOrChapterPost"
import BaseTemplate from "../components/general/BaseTemplate"
import { GQLStory } from "../lib/baseStoryTemplate"

interface GQLResult extends GQLStory {
  blogs: {
    nodes: {
      full_slug: string
      content: string
    }[]
  }
}

interface PageContext {
  lang: string
  name: string
  regexSlug: string
  slug: string
}

const getAuthorName = (s: string | StoryblokStory<PeopleStoryblok>) => {
  if (!s) return null
  const { content: author } = s as unknown as { content: PeopleStoryblok }
  return author.name
}

const BlogPostTemplate: React.FC<PageProps<GQLResult, PageContext>> = ({ data, location, pageContext }) => {
  const { content } = useStoryblok(data.story, location) as { content: BlogPostStoryblok }
  const richResult = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    about: content.description,
    author: {
      "@type": "Person",
      name: getAuthorName(content.author),
    },
    dateModified: data.story.published_at,
    datePublished: content.datePublished,
    headline: content.title,
    image: content.coverImage.filename,
    keywords: data.story.tag_list,
    name: content.title,
    text: getPlainText(content.content as Richtext),
    url: data.story.full_slug,
  }

  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(richResult)}</script>
    </Helmet>
    <BlogOrChapterPost blok={content} tag_list={data.story.tag_list} related={data.blogs.nodes} regexCategory={pageContext.regexSlug} />
  </BaseTemplate>
}

export default BlogPostTemplate

export const query = graphql`query BlogPostQuery($slug: String!, $lang: String, $regexSlug: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  blogs: allStoryblokEntry(
    filter: {field_component: {eq: "blogPost"}, full_slug: {regex: $regexSlug, ne: $slug}, lang: {eq: $lang}}
    limit: 3
    sort: {published_at: DESC}
  ) {
    nodes {
      full_slug
      content
    }
  }
}`
