import React from "react"
import { SbEditableContent } from "storyblok-react"
import CustomerSuccessStoriesHighlight from "../section/CustomerSuccessStoriesHighlight"
import Cta from "../section/Cta"
import ReviewCard from "../section/ReviewCard"
import Placeholder from "./Placeholder"

const Components = {
  cta: Cta,
  customerSuccessStoriesHighlight: CustomerSuccessStoriesHighlight,
  reviewCarousel: ReviewCard,
}

interface Props {
  readonly blok: SbEditableContent
}

const FixedGridItemDynamicComponent: React.FC<Props> = ({ blok }) => {
  if (Object.keys(Components).includes(blok.component)) {
    const Component = Components[blok.component] as React.FC<Props>
    return <Component blok={blok} />
  }

  return blok.component
    ? <Placeholder componentName={blok.component} />
    : null
}

export default FixedGridItemDynamicComponent
