import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Cookies from "js-cookie"
import React, { useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import tw from "twin.macro"
import { LinkStoryblok } from "../../../../component-types-sb"
import useTranslatedLinks from "../../../contexts/TranslatedLinksContext"
import { useMobileHeader } from "../../../contexts/MobileHeaderContext"
import useLocale from "../../../hooks/useLocale"
import LinkResolver from "../../general/Link"
import locales from "../../../data/locales"

interface Props {
  readonly title?: string
  readonly links?: LinkStoryblok[]
  readonly open: boolean
  readonly setOpen: () => void
  readonly useLanguages?: boolean
}

const Border = styled.span(({ open }: { open: boolean }) => [
  tw`border-b`,
  open
    ? tw`border-gray-1`
    : tw`border-transparent`,
])

const OpenButton = styled.button<{ useLanguages: boolean }>(({ useLanguages }) => [
  tw`flex items-center justify-between w-full px-12 py-5 cursor-pointer hocus:text-blue`,
  useLanguages && tw`px-4 tablet:px-6`,
])

const LinksWrapper = styled.div<{ useLanguages: boolean }>(({ useLanguages }) => [
  tw`px-12 overflow-hidden (transition-max-height duration-300 ease-linear)  `,
  useLanguages && tw`px-4 tablet:px-6`,
])

const MobileCategoryLinks: React.FC<Props> = ({
  title, links, open, setOpen, useLanguages = false,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  let maxHeight = 0
  if (open && ref.current) maxHeight = ref.current.scrollHeight

  const { locale } = useLocale()
  const languageLinks = useTranslatedLinks()
  const { closeAll } = useMobileHeader()

  const handleLanguageClick = (selectedLocale: string) => {
    closeAll()
    Cookies.set("wg_preferred_lang", selectedLocale)
  }

  return <div>
    <OpenButton useLanguages={useLanguages} onClick={setOpen}>
      <Border open={open}>
        {useLanguages
          ? <span tw="flex items-center">
            <FontAwesomeIcon icon={faGlobe} tw="mr-2" />
            {locale === "default"
              ? "EN"
              : locale.toUpperCase()}
          </span>
          : title
        }
      </Border>
      <FontAwesomeIcon icon={open
        ? faMinus
        : faPlus} />
    </OpenButton>
    <LinksWrapper useLanguages={useLanguages} ref={ref} style={{ maxHeight: `${maxHeight}px` }}>
      {useLanguages
        ? locales.filter((lang) => lang.locale !== locale).map((lang) => <Link
          key={lang.name}
          to={languageLinks[lang.locale]}
          tw="mb-4 font-medium cursor-pointer block w-fit hocus:(text-blue)"
          tabIndex={open
            ? 0
            : -1}
          onClick={() => handleLanguageClick(lang.locale)}
        >
          {lang.locale === "default"
            ? "EN"
            : lang.locale.toUpperCase()}
        </Link>)
        : links?.map(({ text, url }) => <LinkResolver
          key={text}
          tw="mb-4 block w-fit font-medium"
          url={url}
          tabIndex={open
            ? 0
            : -1}
          onClick={closeAll}
          styling="highlight"
        >
          {text}
        </LinkResolver>)}
    </LinksWrapper>
  </div>
}

export default MobileCategoryLinks
