import { Link } from "gatsby"
import React from "react"
import { BlogPostStoryblok } from "../../../component-types-sb"
import useBlogDateFormat from "../../hooks/useBlogDateFormat"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { H3, H5 } from "../typography/Typography"

interface Props {
  readonly content: BlogPostStoryblok
  readonly full_slug: string
  readonly index: number
}

const SmallBlogCard: React.FC<Props> = ({ content, full_slug, index }) => {
  const datePublished = useBlogDateFormat(content.datePublished)
  return (
    <li>
      <Link className="group" to={rewriteSlug(full_slug)} key={content._uid} tw="flex items-center space-x-4">
        <div tw="text-gray-5">
          <H3>0{index + 1}</H3>
        </div>
        <div>
          <H5 tw="my-0 group-hocus:(text-blue)">{content.title}</H5>
          <p tw="text-gray-3 text-14">{`${datePublished} - ${content.timeToRead ?? ""} min`}</p>
        </div>
      </Link>
    </li>
  )
}

export default SmallBlogCard
