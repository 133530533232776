import { graphql, useStaticQuery } from "gatsby"

import { PageStoryblok } from "../../component-types-sb"

interface IntegrationEntries {
  stories: {
    nodes: {
      content: string
      full_slug: string
      lang: string
    }[]
  }
}

const useIntegrationPages = (): {
  content: PageStoryblok
  full_slug: string
  lang: string
}[] => {
  const data = useStaticQuery<IntegrationEntries>(graphql`{
    stories: allStoryblokEntry(
      filter: {full_slug: {regex: ".*/(integraties|integrations|integrationen)/.+/"}, field_component: {eq: "page"} }
      sort: {first_published_at: DESC}
    ) {
      nodes {
        full_slug
        lang
        content
      }
    }
  }`)

  const allIntegrations = data.stories.nodes.map((integration) => ({
    ...integration,
    content: JSON.parse(integration.content) as PageStoryblok,
  }))

  return allIntegrations
}

export default useIntegrationPages
