import React from "react"
import SbEditable from "storyblok-react"
import tw, { css, styled } from "twin.macro"
import { FixedGridItemStoryblok } from "../../../component-types-sb"
import { ChildrenProp } from "../../../react"
import FixedGridItemDynamicComponent from "./FixedGridItemDynamicComponent"

interface Props {
  readonly blok: FixedGridItemStoryblok
}

const colSpanStyles = {
  1: tw`col-span-1`,
  2: tw`col-span-full desktop:col-span-2 not-desktop:[&[data-mobile-col-span="single_col"]]:col-span-1`,
  full: tw`col-span-full`,
}

const rowSpanStyles = {
  1: tw`row-span-1`,
  2: tw`row-span-1 desktop:row-span-2`,
}

// Duplicating the style for desktop here
// Otherwise the start position gets overwritten by the span styles
const alignStyles = {
  end: tw`grid-column-end[-1] desktop:grid-column-end[-1]`,
  start: tw`col-start-1 desktop:col-start-1`,
}

interface GridItemProps {
  readonly align: "start" | "end"
  readonly colSpan: "1" | "2" | "full"
  readonly rowSpan: number
  readonly mobileHidden?: true
  readonly rowStart: number
  readonly mobileOffset?: number
}

const GridItem = styled.div<GridItemProps>(({
  align, colSpan, rowSpan, mobileHidden, rowStart, mobileOffset,
}) => [
  tw`flex`,
  colSpanStyles[colSpan],
  alignStyles[align],
  rowSpanStyles[rowSpan],
  mobileHidden
    ? tw`desktop:block hidden`
    : "",
  css`
    @media (max-width: 1280px) {
      grid-row-start: ${rowStart - (mobileOffset
    ? mobileOffset
    : 0)};
    };
    grid-row-start: ${rowStart} !important;
  `,
])

interface FixedGridItemComponentProps extends GridItemProps {
  readonly mobileColSpan?: "full_width" | "single_col"
}

export const FixedGridItemComponent: React.FC<ChildrenProp & FixedGridItemComponentProps> = ({
  align, colSpan, rowSpan, rowStart, mobileColSpan, children, mobileHidden, mobileOffset,
}) => (
  <GridItem
    align={align}
    colSpan={colSpan}
    rowSpan={rowSpan}
    mobileHidden={mobileHidden}
    mobileOffset={mobileOffset}
    rowStart={rowStart}
    data-mobile-col-span={mobileColSpan}
  >
    {children}
  </GridItem>
)

const FixedGridItem: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <FixedGridItemComponent
      align={blok.align}
      colSpan={blok.col_span}
      rowSpan={Number(blok.row_span)}
      rowStart={Number(blok.row_start)}
      mobileColSpan={blok.mobile_col_span}
    >
      <FixedGridItemDynamicComponent blok={blok.item[0]} />
    </FixedGridItemComponent>
  </SbEditable>
)

export default FixedGridItem
