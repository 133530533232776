import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { PricingBlocksStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import PricingYearlyBillingSelector from "../general/PricingYearlyBillingSelector"
import PricingBlock from "../general/PricingBlock"
import PricingCurrencySelector from "../general/PricingCurrencySelector"
import { pricingPlans } from "../../contexts/PricingDataContext"
import { useWindowSize } from "../../hooks/useWindowSize"
import ClientOnly from "../general/ClientOnly"

const PricingBlocksWrapper = tw.div`
  flex flex-col self-center
  flex-1
  max-width[66rem]
  my-8
  space-y-4
  z-[-10]
  desktop-xl:(
    flex-row
    space-x-8 space-y-0
    mt-16
    [&[data-limited-offer="true"]]:(
      mt-20
    )
    w-full
  )
  [&[data-limited-offer="true"]]:(
    mt-12
  )
`

interface Props {
  readonly blok: PricingBlocksStoryblok
}

const PricingBlocks: React.FC<Props> = ({ blok }) => {
  const { width } = useWindowSize()
  const pricingDataIndexes = width < 1280 && !(blok.theme === "secondary")
    ? [1, 0, 2]
    : [0, 1, 2]
  return <SbEditable content={blok} key={blok._uid}>
    {!blok.only_yearly && <div tw="mt-12">
      <PricingYearlyBillingSelector
        billedYearly={blok.billed_yearly_text}
        monthlyText={blok.monthly_text}
        yearlyText={blok.annually_text}
        saveText={blok.save_up_to_text}
        saveTextYellow={blok.save_up_to_yellow} />
    </div>}
    <Container tw="grid grid-template-columns[1fr auto 1fr] items-start"
      data-theme={blok.theme
        ? blok.theme
        : "primary"}
    >
      <div tw="sticky top[86px] mt-16 -ml-6 w-0 tablet:w-max">
        <PricingCurrencySelector currencyLabel={blok.currency_text} />
      </div>
      <ClientOnly>
        <PricingBlocksWrapper data-limited-offer={Boolean(blok.limited_time_offer)}>
          {pricingDataIndexes.map((i) => {
            const block = blok.blocks?.[i]
            if (!block) return null
            return <PricingBlock
              blok={block}
              key={block._uid}
              plan={pricingPlans[i]}
              selectAttendees={blok.select_attendees_text}
              limitedOffer={blok.limited_time_offer}
              perMonthText={blok.per_month_text}
              perYearText={blok.annually_text}
              onlyYearly={blok.only_yearly}
            />
          })}
        </PricingBlocksWrapper>
      </ClientOnly>
    </Container>
  </SbEditable>
}

export default PricingBlocks
