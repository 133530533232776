import React, { useState } from "react"
import LinkButton from "../../general/LinkButton"
import { useMobileHeader } from "../../../contexts/MobileHeaderContext"
import LinkResolver from "../../general/Link"
import MobileCategoryMenu from "./MobileCategoryMenu"
import MobileCategoryLinks from "./MobileCategoryLinks"
import MobileHeaderTransition from "./MobileHeaderTransition"

interface Props {
  readonly open: boolean
}

const MobileMenu: React.FC<Props> = ({ open }) => {
  const { content, closeAll } = useMobileHeader()
  const [languagesOpen, setLanguagesOpen] = useState(false)

  return <MobileHeaderTransition open={open}>
    <div tw="divide-y divide-gray-5 pt-2">
      {content.sections.map((section) => (section.component === "link"
        ? <LinkResolver
          url={section.url}
          onClick={closeAll}
          styling="highlight"
          key={section._uid}
          tw="flex px-4 tablet:px-6 py-5"
        >
          {section.text}
        </LinkResolver>
        : <MobileCategoryMenu key={section._uid} section={section} />
      ))}
      <MobileCategoryLinks
        useLanguages
        open={languagesOpen}
        setOpen={() => setLanguagesOpen((prev) => !prev)}
      />
    </div>
    <div tw="flex flex-col items-center mt-4 space-y-2 pb-4 margin-bottom[70px]">
      {content.free_trial_button.length > 0
        && <LinkButton button={content.free_trial_button[0]} tw="width[60vw]" onClick={closeAll} />
      }
      {content.login_link.length > 0 && <LinkResolver url={content.login_link[0].url} styling="highlight" onClick={closeAll}>
        {content.login_link[0].text}
      </LinkResolver>}
    </div>
  </MobileHeaderTransition>
}

export default MobileMenu
