import seedrandom from "seedrandom"
import random from "../../random-seed.json"

interface Item {
  uuid: string
}

export const randomOrder = <TItem extends Item> (items: TItem[], seed?: string): TItem[] => {
  // eslint-disable-next-line etc/no-assign-mutated-array
  const soretdItems = items.sort((a, b) => a.uuid.localeCompare(b.uuid))
  // Initialize seeded random number generator  with seed created at build time
  const randNumGenerator = seedrandom(seed ?? random.seed)
  const randoms = {}
  soretdItems.forEach((item) => {
    randoms[item.uuid] = randNumGenerator()
  })
  // eslint-disable-next-line etc/no-assign-mutated-array
  return soretdItems.sort((item1, item2) => randoms[item1.uuid] - randoms[item2.uuid])
}
