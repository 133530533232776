import React, { useState } from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { TabbedContentStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import TabbedContentItem from "../general/TabbedContentItem"

interface Props {
  readonly blok: TabbedContentStoryblok
}

interface Direction {
  direction?: "row" | "col" | "col_detail"
  active?: boolean
}

const TabbedContentWrapper = styled.div<Direction>(({ direction }) => [
  tw`flex flex-col w-full my-4 space-x-0 space-y-0`,
  direction === "row" && tw`space-y-6 max-w-container`,
  direction === "col" && tw`laptop:(flex-row space-x-4) max-w-container`,
  direction === "col_detail" && tw`laptop:(flex-row space-x-8) max-w-container-large`,
])

const TabsWrapper = styled.div<Direction>(({ direction }) => [
  tw`flex flex-col flex-1 space-y-6 text-center `,
  direction === "row" && tw`laptop:(flex-row space-y-0 space-x-4 w-full justify-around)`,
  direction === "col" && tw`mb-8`,
])

const Tab = styled.button<Direction>(({ direction }) => [
  tw`
    py-2
    rounded
    max-w-none laptop:maxWidth[200px]
    shadow hocus:shadow-hover
    cursor-pointer
    [[data-tabbed-background-color="bright_gray"] &]:bg-gray-bright
    [&[data-tab-active="true"]]:(
      text-white cursor-default shadow-hover bg-blue focus:outline-none
    )
  `,
  direction === "row" && tw`flex-1`,
])

const DetailedTab = styled.button<Direction>(({ active }) => [
  tw`p-5 text-left rounded`,
  active && tw`border cursor-default shadow-hover border-blue focus:outline-none`,
  !active && tw`shadow cursor-pointer hocus:shadow-hover`,
])

const TabbedContent: React.FC<Props> = ({ blok }) => {
  const [index, setIndex] = useState(0)
  return (<SbEditable content={blok}>
    <Container>
      <TabbedContentWrapper
        direction={blok.direction}
        data-tabbed-content-style={blok.style}
        data-tabbed-background-color={blok.background_color}
      >
        <TabsWrapper direction={blok.direction}>
          {blok.tabbedContentItem.map((item, i) => {
            if (blok.direction === "col_detail") {
              return <DetailedTab key={item._uid} active={index === i} onClick={() => setIndex(i)} tabIndex={index === i
                ? -1
                : 0}>
                <div tw="text-20">{item.clickableText}</div>
                {index === i && <div tw="font-light mt-4">{item.description}</div>}
              </DetailedTab>
            }
            return <React.Fragment key={item._uid}>
              <Tab direction={blok.direction} key={item._uid} onClick={() => setIndex(i)} tabIndex={index === i
                ? -1
                : 0} data-tab-active={index === i}>
                {item.clickableText}
              </Tab>
              {index === i && <div tw="laptop:hidden">
                <TabbedContentItem blok={item} direction={blok.direction} />
              </div>}
            </React.Fragment>
          })}
        </TabsWrapper>
        <div tw="hidden laptop:block flex-3">
          {blok.tabbedContentItem.map((item, i) => {
            if (index === i) return <TabbedContentItem key={item._uid} blok={item} direction={blok.direction} />
            return null
          })
          }
        </div>
      </TabbedContentWrapper>
    </Container>
  </SbEditable>)
}

export default TabbedContent
