declare global {
  interface Window {
    Cookiebot: {
      consent: {
        marketing: boolean
      }
    }
  }
}

export const useCookiebot = (): {
  useIsMarketing: () => boolean
} => ({
  useIsMarketing: () => {
    if (typeof window !== "undefined" && typeof window.Cookiebot !== "undefined") {
      const { Cookiebot: cookiebot } = window
      return cookiebot.consent.marketing
    }
    return false
  },
})
