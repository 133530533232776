import React, { useEffect, useState } from "react"
import SbEditable from "storyblok-react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { BadgeBannerStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import ClientOnly from "../general/ClientOnly"
import { useWindowSize } from "../../hooks/useWindowSize"

interface Props {
  blok: BadgeBannerStoryblok
}

const BadgeWrapper = styled.div<{ len: number }>(() => [
  tw`w-full flex flex-row py-8 justify-center items-center`,
  // Only show the middle three/five badges on mobile devices, the 3/5 coresponding to the amount of badges
  ({ len }) => css`
  @media only screen and (max-width: 600px) {
    &>*:nth-last-child(-n + ${(len - 3) / 2}) {
      display: none;
    };
    &>*:nth-child(-n + ${(len - 3) / 2}) {
      display: none;
    };
  };
  @media only screen and (max-width: 800px) {
    &>*:nth-last-child(-n + ${(len - 5) / 2}) {
      display: none;
    };
    &>*:nth-child(-n + ${(len - 5) / 2}) {
      display: none;
    };
  };
  `,
])

const BadgeBanner: React.FC<Props> = ({ blok }) => {
  const amountOfBadges = blok.badges.length
  const [heightOffset, setHeightOffset] = useState(800)

  const ref = React.useRef<HTMLDivElement>(null)

  const { height } = useWindowSize()

  // set height offset to center the badges vertically
  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const { top } = ref.current.getBoundingClientRect()
        setHeightOffset(top)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [ref])

  const generateTranslation = (length: number, i: number) => {
    const offset = Math.ceil(length / 2)
    if (i < offset) return (offset - i)
    return (i - offset + 2)
  }

  return (<ClientOnly>
    <SbEditable content={blok}>
      <BadgeWrapper ref={ref} len={blok.badges.length}>
        {blok.badges.map((badge, i) => <Image
          key={badge.id}
          image={badge}
          tw="-mx-2 drop-shadow-xl hover:(-translate-y-3 ease-out) transition-transform duration-200"
          height={120}
          width={120}
          fit="contain"
          // Translate the badges to the center of the screen, staggering them
          style={{
            translate: `0px ${((heightOffset - height / 2) / (height))
              * (generateTranslation(blok.badges.length, i)) * -20}px`,
            zIndex: amountOfBadges / 2 - 1 > i
              ? i
              : amountOfBadges - i,
          }}
          useRegularImageTag
        />)}
      </BadgeWrapper>
    </SbEditable>
  </ClientOnly>
  )
}

export default BadgeBanner
