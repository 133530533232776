import React from "react"
import SbEditable from "storyblok-react"
import {
  VirtualBackgroundSettingsProvider,
} from "../../contexts/VirtualBackgroundSettingsContext"
import {
  VirtualBackgroundPlatformStoryblok,
  VirtualBackgroundTagStoryblok,
  VirtualBackgroundsIndexStoryblok,
} from "../../../component-types-sb"
import { VirtualBackgroundList } from "../virtualBackgroundComponents/VirtualBackgroundList"

interface Props {
  readonly blok: VirtualBackgroundsIndexStoryblok
}

const VirtualBackgroundsIndexWrapper: React.FC<Props> = ({ blok }) => {
  const tag = blok.category as unknown as { content: VirtualBackgroundTagStoryblok } |
  { content: VirtualBackgroundPlatformStoryblok }
  return <VirtualBackgroundSettingsProvider>
    <SbEditable content={blok}>
      <VirtualBackgroundList tag={tag.content} blocks={blok.blocks} />
    </SbEditable>
  </VirtualBackgroundSettingsProvider>
}

export default VirtualBackgroundsIndexWrapper
