import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import SbEditable from "storyblok-react"
import { CustomerSuccessStoryCardStoryblok, CustomerSuccessStoryStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import { rewriteSlug } from "../../utils/rewriteSlug"
import CardBase from "../cards/CardBase"
import Container from "../general/Container"
import Image from "../general/Image"
import { H3, P } from "../typography/Typography"

interface Props {
  readonly blok: CustomerSuccessStoryCardStoryblok
}

interface CardProps {
  readonly content: CustomerSuccessStoryStoryblok
  readonly full_slug: string
}

export const CustomerSuccessStoryCard: React.FC<CardProps> = ({ content, full_slug }) => {
  const { width } = getImageDimensions(content.image)
  const { width: logoWidth } = getImageDimensions(content.companyLogo)
  return <Container>

    <Link to={rewriteSlug(full_slug)} className="group" tw="relative my-4 w-full max-w-container">
      <div tw="w-full desktop:w-1/2 text-left p-6 relative">
        <div tw="mb-4 desktop:mb-24">
          <Image tw="object-left! " image={content.companyLogo} fit="contain" height="40px" fluid={logoWidth} showPlaceholder={false} />
        </div>
        <H3 tw="text-21 tablet:text-22 laptop:text-26 group-hocus:text-blue">{content.title}</H3>
        <P tw="mb-8">{content.intro}</P>
        <div tw="text-28 absolute bottom-4 right-8">
          <FontAwesomeIcon tw="group-hocus:(animate-bounce-x)" icon={faArrowRight} />
        </div>
      </div>
      <CardBase tw="absolute inset-0 flex justify-end">
        <div tw="w-1/2 hidden desktop:block relative">
          <div tw="absolute inset-0">
            <Image image={content.image} fit="cover" height="100%" fluid={width < 900
              ? width
              : 900} />
          </div>
        </div>
      </CardBase>
    </Link>

  </Container>
}

interface ParsedSuccessStory {
  content: CustomerSuccessStoryStoryblok
  full_slug: string
  uuid: string
}

const CustomerSuccessStoryCardEditable: React.FC<Props> = ({ blok }) => {
  if (!blok.success_story) return null
  const successStory = blok.success_story as unknown as ParsedSuccessStory
  return <SbEditable content={blok}>
    <CustomerSuccessStoryCard {...successStory} />
  </SbEditable>
}

export default CustomerSuccessStoryCardEditable
