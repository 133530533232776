import React from "react"
import SbEditable from "storyblok-react"
import { SimpleCardStoryblok } from "../../../component-types-sb"
import LinkResolver from "../general/Link"
import { H4, P } from "../typography/Typography"

interface Props {
  readonly blok: SimpleCardStoryblok
}

const SimpleCard: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div tw="flex flex-col">
    <H4 as="p">{blok.title}</H4>
    <P tw="mb-4 text-18 text-gray-2">{blok.description}</P>
    {blok.link_text && blok.link && <p>
      <LinkResolver url={blok.link} styling="textlink">
        {blok.link_text}
      </LinkResolver>
    </p>}
  </div>
</SbEditable>

export default SimpleCard
