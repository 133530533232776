import React, { useState, useContext, createContext, useEffect, useMemo } from "react"
import { useLocation } from "@reach/router"
import { ChildrenProp } from "../../react"

interface Context {
  id: string
  setId: React.Dispatch<React.SetStateAction<string>>
}

const HiddenContext = createContext<Context | null>(null)

const HiddenContextProvider: React.FC<ChildrenProp> = ({ children }) => {
  const [id, setId] = useState<string>("")
  const { href } = useLocation()

  // Reset the hidden id when location changes
  useEffect(() => {
    setId("")
  }, [href])

  const contextValue = useMemo(() => ({
    id,
    setId,
  }), [id])

  return <HiddenContext.Provider value={contextValue}>
    {children}
  </HiddenContext.Provider>
}

export const useHiddenContext = (): Context => {
  const context = useContext(HiddenContext)
  if (!context) throw new Error("Use context in a Hidden context provider")
  return context
}

export default HiddenContextProvider
