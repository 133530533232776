import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SbEditable from "storyblok-react"
import { PeopleStoryblok, PersonGridStoryblok } from "../../../component-types-sb"
import { randomOrder } from "../../utils/randomOrder"
import useLocale from "../../hooks/useLocale"
import Container from "../general/Container"
import PersonCard from "../cards/PersonCard"
import ClientOnly from "../general/ClientOnly"

interface Props {
  readonly blok: PersonGridStoryblok
}

interface PersonWithId extends PeopleStoryblok {
  uuid: string
}

interface QueryResult {
  stories: {
    nodes: {
      uuid: string
      lang: string
      content: string
    }[]
  }
}

const PersonGrid: React.FC<Props> = ({ blok }) => {
  const { locale } = useLocale()
  const { stories: { nodes } } = useStaticQuery<QueryResult>(graphql`
    query AllStoryblokPeople{
      stories: allStoryblokEntry(
        filter:{field_component: {eq: "people"}}
      ) {
        nodes{
          uuid
          lang
          content
        }
      }
    }
  `)
  const filteredPeople = nodes.filter((x) => x.lang === locale)
  // Keep the uuid so can use the randomOrder function. _uid sometimes returns the same key
  const people = filteredPeople.map((person) => ({
    ...JSON.parse(person.content),
    uuid: person.uuid,
  }) as unknown as PersonWithId)
  const items = randomOrder(people)

  return <SbEditable content={blok}>
    <ClientOnly>
      <Container>
        <div
          tw="max-w-container grid w-full grid-cols-2 my-4
            laptop:grid-cols-3 desktop:grid-cols-4 gap-4 tablet:gap-8 laptop:gap-9"
        >
          {items.map((item) => <PersonCard blok={{
            ...item,
            name: blok.names_visible
              ? item.name
              : "",
          }} key={item.uuid} />)}
        </div>
      </Container>
    </ClientOnly>
  </SbEditable>
}

export default PersonGrid
