import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"

type ReturnValue<TValue> = [
  value: TValue,
  setValue: Dispatch<SetStateAction<TValue>>,
]

const useStateWithDelayedCallback = <TValue>(
  initialValue: TValue,
  delay: number,
  callback: (value: TValue) => Promise<void>,
): ReturnValue<TValue> => {
  const [value, setValue] = useState<TValue>(initialValue)
  const previousState = useRef<TValue>(value)

  useEffect(() => {
    previousState.current = value
    setTimeout(() => {
      if (previousState.current === value) void callback(value)
    }, delay)
  }, [value, delay, callback])

  return [value, setValue]
}

export default useStateWithDelayedCallback
