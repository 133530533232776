import React from "react"
import Flickity from "react-flickity-component"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { PersonCarouselStoryblok, PeopleStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Container from "../general/Container"
import Image from "../general/Image"
import { H5 } from "../typography/Typography"
import Placeholder from "../general/Placeholder"
import useLocale from "../../hooks/useLocale"

import "flickity/css/flickity.css"
import ClientOnly from "../general/ClientOnly"
import { randomOrder } from "../../utils/randomOrder"

interface Props {
  readonly blok: PersonCarouselStoryblok
}

interface QueryResult {
  stories: {
    people: {
      lang: string
      uuid: string
      content: string
    }[]
  }
}

const CarouselWrapper = styled.div(() => [
  tw`relative w-full max-w-container-large laptop:px-16 desktop:px-32`,
  {
    ".flickity-prev-next-button": tw`top[130px] tablet:top[175px] desktop:top-1/2`,
    ".flickity-prev-next-button.next": tw`right-0 desktop:-right-12`,
    ".flickity-prev-next-button.previous": tw`left-0 desktop:-left-12`,
  },
])

const PersonCarousel: React.FC<Props> = ({ blok }) => {
  const { questions } = useGlobalSettings()
  const { locale } = useLocale()
  const { stories: { people } } = useStaticQuery<QueryResult>(graphql`
    query AllPeople{
      stories: allStoryblokEntry(
        filter: {
          field_component: {eq: "people"},
        },
      ) {
        people: nodes {
          lang
          uuid
          content
        }
      }
    }
  `)
  const localePeople = people.filter((person) => (
    blok.people.some((blokPerson: string) => blokPerson === person.uuid)) && person.lang === locale)
  const mixedUpPeople = randomOrder(localePeople)
  if (!people) return <Placeholder>Please add some people to the carousel</Placeholder>
  return <SbEditable content={blok}>
    <ClientOnly>
      <Container>
        <CarouselWrapper>
          <Flickity
            options={{
              adaptiveHeight: true,
              autoPlay: 5000,
              pageDots: false,
              prevNextButtons: true,
              wrapAround: true,
            }}
          >
            {mixedUpPeople.map((person) => {
              const content = JSON.parse(person.content) as unknown as PeopleStoryblok
              return <div key={person.uuid} tw="w-full flex-col desktop:( flex-row)  flex px-4">
                <div tw="flex-1 px-12 desktop:pr-8 mx-auto w-full max-width[400px]">
                  <Image image={content.photo} fluid={304} />
                </div>
                <div tw="flex-1">
                  <H5>{content.name} &mdash; {content.function}</H5>
                  {questions.map((question, i) => {
                    if (!content.answers?.[i]) return null
                    return <p key={question._uid} tw="text-18 mb-2.5">
                      <span>{question.text}</span>
                      <br />
                      <span tw="font-light">{content.answers[i].text}</span>
                    </p>
                  })}
                </div>
              </div>
            })}
          </Flickity>
        </CarouselWrapper>
      </Container>
    </ClientOnly>
  </SbEditable>
}

export default PersonCarousel
