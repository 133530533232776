import React, { useState } from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { GlossaryIndexStoryblok } from "../../../component-types-sb"
import useKeywordEntries from "../../hooks/useKeywordEntries"
import useLocale from "../../hooks/useLocale"
import { H1, H3 } from "../typography/Typography"
import SearchWithTags from "../general/SearchWithTags"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Tag from "../general/Tag"
import Container from "../general/Container"
import GlossaryItem from "./GlossaryItem"

export const LetterTag = tw(Tag)`
  text-15
  [&[data-oneletter~="true"]]:(
    flex justify-center items-center
    w-8
    h-8
    p-0
  )
`

interface Props {
  readonly blok: GlossaryIndexStoryblok
}

const GlossaryIndex: React.FC<Props> = ({ blok }) => {
  const allKeywords = useKeywordEntries()
  const { locale } = useLocale()
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedTag, setSelectedTag] = useState<string | null>(null)

  const sortedAvailableKeywords = allKeywords.filter((keyword) => {
    const isCorrectLanguage = keyword.lang === locale
    const tagIsSelected = keyword.content.title?.[0].toUpperCase() === selectedTag || selectedTag === null
    const isInSearchQuery = keyword.content.title.toLowerCase().includes(searchQuery.toLowerCase())
    return isCorrectLanguage && tagIsSelected && isInSearchQuery
  }).sort((a, b) => {
    if (a.content.title && b.content.title) {
      return a.content.title.localeCompare(b.content.title)
    }
    return 0
  })

  const letters = Array.from(
    new Set(allKeywords.map((keyword) => keyword.content.title?.[0]?.toUpperCase() ?? "")),
  ).filter(Boolean)

  const groupedKeywords: Record<string, (typeof sortedAvailableKeywords[0])[]> = {}

  sortedAvailableKeywords.forEach((kw) => {
    const firstLetter = kw.content.title?.[0]?.toUpperCase()
    if (!firstLetter) return
    if (!groupedKeywords[firstLetter]) {
      groupedKeywords[firstLetter] = []
    }
    groupedKeywords[firstLetter].push(kw)
  })

  return (<SbEditable content={blok}>
    <Container tw="bg-blue-midnight text-center text-white pt-28 pb-24">
      <H1>{blok.header_title}</H1>
      <H3 tw="font-light">{blok.header_subtitle}</H3>
    </Container>
    <SearchWithTags
      selectedTag={selectedTag}
      setSelectedTag={setSelectedTag}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      all_filter_text={blok.all_search_tag}
      search_placeholder={blok.search_placeholder}
      search_title={blok.search_title}
      sortTagsFunction={(a: string, b: string) => a.localeCompare(b)}
      tags={letters}
    />
    <Container tw="w-full laptop:px-32 desktop:px-72 flex flex-col">
      {Object.keys(groupedKeywords).map((letter) => <div key={letter} tw="relative flex flex-col gap-4 mb-20 w-full">
        <LetterTag
          tw="absolute -left-16 hidden tablet:block"
          data-background-style="transparent"
          data-oneletter
        >
          {letter}
        </LetterTag>
        {groupedKeywords[letter].map((kw) => kw.content.title && kw.content.description && <GlossaryItem
          key={kw.content._uid}
          title={kw.content.title}
          description={kw.content.description}
          url={rewriteSlug(kw.full_slug)}
          moreText={blok.more_text}
          tag={kw.content.title[0].toUpperCase()}
        />)}
      </div>)}
    </Container>
  </SbEditable>)
}

export default GlossaryIndex
