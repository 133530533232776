import { faChevronDown } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import tw, { TwStyle } from "twin.macro"

interface SelectProps {
  showPlaceholder: boolean
  allowInvalid?: boolean
}

const StyledLabel = styled.span<SelectProps>(({ showPlaceholder }) => [
  tw`absolute transition transform left-2 text-gray-3 text-16 top-2.5 scale-75 -translate-y-5.75 bg-white px-1
  origin-left peer-focus:(scale-75 -translate-y-5.75 text-blue bg-white) pointer-events-none`,
  showPlaceholder && tw`scale-100 translate-y-0`,
])

const FakeSelect = styled.div<SelectProps>(({ showPlaceholder, allowInvalid }) => [
  tw`py-2 pl-3 pr-8 border rounded-[5px] appearance-none border-gray-4 text-gray-1 h-full
  peer-focus:(border-blue)`,
  showPlaceholder && tw`text-white`,
  allowInvalid && tw`invalid:focus:(border-red sibling:text-red)`,
])

interface Props extends React.ComponentPropsWithoutRef<"select"> {
  readonly wrapperTw?: TwStyle
  readonly allowInvalid?: boolean
  readonly defaultText: string
}

const Select: React.FC<Props> = ({ children, defaultText, wrapperTw, ...rest }: Props) => {
  const [value, setValue] = useState(defaultText)
  const realSelectRef = useRef<HTMLSelectElement>(null)

  const getDisplayedValue = (): string => {
    const sel = realSelectRef.current
    if (!sel) return ""
    return sel.options[sel.selectedIndex].text
  }

  // eslint-disable-next-line react/no-unknown-property
  return <label css={[wrapperTw]} tw="relative">
    <select ref={realSelectRef} className="peer"
      tw="absolute inset-0 w-full h-full opacity-0 -webkit-appearance[none]"
      onChange={(e) => setValue(e.target.value)} {...rest}
    >
      {children}
    </select>
    <StyledLabel showPlaceholder={value === defaultText}>
      {defaultText}
    </StyledLabel>
    <FakeSelect showPlaceholder={value === defaultText}>
      {getDisplayedValue()}
      <span tw="absolute top-0 bottom-0 right-4 flex items-center text-gray-2 pointer-events-none">
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    </FakeSelect>
  </label>
}

export default Select
