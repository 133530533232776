import React from "react"
import SbEditable from "storyblok-react"
import { Helmet } from "react-helmet"
import { ZapierListStoryblok } from "../../../component-types-sb"

interface Props {
  blok: ZapierListStoryblok
}

const ZapierList: React.FC<Props> = ({ blok }) => (
  <>
    <Helmet>
      <script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js" />
      <link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css" />
    </Helmet>
    <SbEditable content={blok}>
      <div tw="w-full px-4 max-w-[734px] pb-8">
        <zapier-zap-templates
          client-id="eHawCrHrSApj2lfL9Hcy1ZrB8k6dRtz1vLpqkIRb"
          theme="light"
          apps={`webinargeek,${blok.integration}`}
          create-without-template="hide"
          limit={10}
          link-target="new-tab"
          use-this-zap="show"
        />
      </div>
    </SbEditable>
  </>
)

export default ZapierList
