import React from "react"
import SbEditable from "storyblok-react"
import { BlogImageStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Image from "../general/Image"

interface Props {
  readonly blok: BlogImageStoryblok
}

const BlogImage: React.FC<Props> = ({ blok }) => {
  const { width } = getImageDimensions(blok.image)
  return <SbEditable content={blok}>
    <div tw="max-w-blog-component mb-4 justify-center w-full flex ">
      <Image image={blok.image} fluid={Math.min(820, width)} {...(width && { width: `${Math.min(820, width)}px` })} />
    </div>
  </SbEditable>
}

export default BlogImage
