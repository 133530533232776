import { Link } from "gatsby"
import React from "react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { faGlobe } from "@fortawesome/pro-solid-svg-icons"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import LinkButton from "../general/LinkButton"
import Image from "../general/Image"
import { changePreferredLang } from "../../utils/changePreferredLang"
import locales from "../../data/locales"
import useTranslatedLinks from "../../contexts/TranslatedLinksContext"
import useLocale from "../../hooks/useLocale"

interface Props {
  readonly hideTrialBtn: boolean
}

const Wrapper = tw.div`
  w-full
  flex flex-col justify-between
  px-4 tablet:px-6 desktop:px-20 py-3
  space-y-4
  tablet:(
    flex-row items-center
    space-y-0
  )
`
const convertLocale = (locale: string) => {
  if (locale === "default") return "EN"
  return locale.toUpperCase()
}

const LanguageMenuItem = styled(Link)<{ isactive: string }>(({ isactive }) => [
  tw`px-6 text-center text-16`,
  isactive === "true" && tw`text-blue`,
])

const FakeHeader: React.FC<Props> = ({ hideTrialBtn }) => {
  const { logo, register_button } = useGlobalSettings()
  const { preferredLang } = useLocale()
  const translatedLinks = useTranslatedLinks()

  return (
    <Wrapper>
      <Link to="/" tw="width[200px]">
        <Image image={logo} fluid={200} width="200px" showPlaceholder={false} lazy={false} />
      </Link>
      <div tw="flex items-center gap-4">
        {!hideTrialBtn && <LinkButton button={register_button[0]} />}
        <Menu>
          <div tw="relative">
            <Menu.Button tw="flex items-center hocus:(text-blue) focus:outline-none focus-visible:outline-black">
              <FontAwesomeIcon icon={faGlobe} tw="mr-2" />
              <span>{convertLocale(preferredLang)}</span>
            </Menu.Button>
            <Menu.Items
              tw="absolute top-full -right-8 z-50 flex flex-col bg-white space-y-2
                  shadow items-center mt-2 py-4 mr-4 rounded w-fit
                  focus:outline-none focus-visible:outline-black"
            >
              {locales.filter((lang) => lang.locale !== preferredLang).map(({ locale }) => <Menu.Item key={locale}>
                {({ active }) => <LanguageMenuItem
                  isactive={active
                    ? "true"
                    : "false"}
                  to={translatedLinks[locale]}
                  onClick={() => changePreferredLang(locale)}
                >
                  {convertLocale(locale)}
                </LanguageMenuItem>
                }
              </Menu.Item>)}
            </Menu.Items>
          </div>
        </Menu>
      </div>
    </Wrapper>
  )
}

export default FakeHeader
