import styled from "styled-components"
import tw from "twin.macro"

export const Ul = styled.ul`
  ${tw`pl-10 list-disc`}
  li p {
    ${tw`mb-2`}
  }
`

export const OL = styled.ol`
  ${tw`pl-6 list-decimal`}
  li p {
    ${tw`mb-2`}
  }
`
