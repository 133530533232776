import { graphql, useStaticQuery } from "gatsby"
import useLocale from "./useLocale"

export interface TagPage {
  full_slug: string
  title: string
  slug: string
  lang: string
}

interface QueryResult {
  tags: {
    nodes: TagPage[]
  }
}

const useTags = (): TagPage[] => {
  const { locale } = useLocale()
  const data = useStaticQuery<QueryResult>(graphql`
    query allTags{
      tags: allStoryblokEntry(filter: {field_component: {eq:"tagPage"}} ){
        nodes {
          lang
          full_slug
          title: field_title_string
          slug
        }
      }
    }
  `)
  return data.tags.nodes.filter((x) => x.lang === locale)
}

export default useTags
