import React from "react"
import { SbEditableContent } from "storyblok-react"
import AcademyCard from "../cards/AcademyCard"
import ComparisionCard from "../cards/ComparisionCard"
import ContentCard from "../cards/ContentCard"
import CustomerSuccessStoryCardNew from "../cards/CustomerSuccessStoryCardNew"
import IntegrationCard from "../cards/IntegrationCard"
import JobCard from "../cards/JobCard"
import PageCard from "../cards/PageCard"
import PersonCard from "../cards/PersonCard"
import PricingCard from "../cards/PricingCard"
import VideoCardEditable from "../cards/VideoCard"
import OpenIntercomCard from "../section/OpenIntercomCard"
import SimpleCard from "../cards/SimpleCard"
import Placeholder from "./Placeholder"
import ModernCard from "./ModernCard"

const Components = {
  academyCard: AcademyCard,
  comparisonCard: ComparisionCard,
  contentCard: ContentCard,
  customerSuccessStoryCardNew: CustomerSuccessStoryCardNew,
  integrationCard: IntegrationCard,
  jobCard: JobCard,
  modernCard: ModernCard,
  openIntercomCard: OpenIntercomCard,
  pageCard: PageCard,
  personCard: PersonCard,
  pricingCard: PricingCard,
  simpleCard: SimpleCard,
  videoCard: VideoCardEditable,
}

interface Props {
  readonly blok: SbEditableContent
}

const CardGridComponent: React.FC<Props> = ({ blok }) => {
  if (Object.keys(Components).includes(blok.component)) {
    const Component = Components[blok.component] as React.FC<Props>
    return <Component blok={blok} />
  }

  return blok.component
    ? <Placeholder componentName={blok.component} />
    : null
}

export default CardGridComponent
