import React, { createContext, useContext, useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useLocation } from "@reach/router"
import useLocale from "../hooks/useLocale"
import { sbConfig, Storyblok } from "../hooks/useStoryblok"
import { VirtualBackgroundSettingsStoryblok } from "../../component-types-sb"
import { ChildrenProp } from "../../react"

interface VirtualBackgroundSettingsData {
  stories: {
    nodes: {
      uuid: string
      lang: string
      content: string
      field_component: string
    }[]
  }
}

const VirtualBackgroundSettingsContext = createContext<VirtualBackgroundSettingsStoryblok | null>(null)

export const VirtualBackgroundSettingsProvider: React.FC<ChildrenProp> = ({ children }) => {
  const data: VirtualBackgroundSettingsData = useStaticQuery(graphql`
    query VirtualBackgroundSettingsQuery {
      stories: allStoryblokEntry(
        filter: {field_component: {eq: "virtualBackgroundSettings"}}
      ) {
        nodes {
          uuid
          lang
          content
          field_component
        }
      }
    }
  `)
  const { locale } = useLocale()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const story = data.stories.nodes.find((x) => x.lang === locale)!
  const staticContent = JSON.parse(story.content) as VirtualBackgroundSettingsStoryblok
  const [content, setContent] = useState<VirtualBackgroundSettingsStoryblok>(staticContent)
  const location = useLocation()

  useEffect(() => {
    const getUpdatedVirtualBackgroundSettings = async (): Promise<void> => {
      try {
        const res = await Storyblok.get("cdn/stories/virtual-backgrounds/settings", {
          language: locale,
          resolve_links: "url",
          resolve_relations: sbConfig.options.resolveRelations.join(","),
          version: "draft",
        }) as { data: { story?: { content: VirtualBackgroundSettingsStoryblok } } }
        const newContent = res.data?.story?.content
        if (newContent) setContent(newContent)
      } catch (error) {
        console.error("Could not fetch most recent virtualBackgroundSettings object")
      }
    }

    if (location.search.includes("_storyblok")) void getUpdatedVirtualBackgroundSettings()
  }, [locale, location])

  return <VirtualBackgroundSettingsContext.Provider
    value={content}
  >
    {children}
  </VirtualBackgroundSettingsContext.Provider>
}

export const useVirtualBackgroundSettings = (): VirtualBackgroundSettingsStoryblok => {
  const context = useContext(VirtualBackgroundSettingsContext)
  if (!context) throw new Error("useVirtualBackgroundSettings must be used within a VirtualBackgroundSettingsProvider")
  return context
}
