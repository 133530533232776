import React from "react"
import { graphql, PageProps } from "gatsby"
import { BackgroundStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import Background from "../components/templates/Background"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

const BackgroundTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: BackgroundStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <Background blok={content} />
  </BaseTemplate>
}

export default BackgroundTemplate

export const query = graphql`
  query BackgroundQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
