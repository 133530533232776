import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef, useEffect, useState } from "react"
import tw from "twin.macro"
import { useWindowSize } from "../../hooks/useWindowSize"

interface Props {
  readonly tooltip: string
}

const TooltipWrapper = tw.div`
  relative inline-flex flex-col items-center
  cursor-pointer
  ml-1.5
  focus:outline-none
  focus-visible:[.speech-bubble]:visible
  focus-visible:[.speech-tail]:visible
`

const Tooltip: React.FC<Props> = ({ tooltip }) => {
  const [translateX, setTranslateX] = useState(0)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const { width: windowWidth } = useWindowSize()

  useEffect(() => {
    if (windowWidth !== 0 && tooltipRef.current) {
      const { x, width } = tooltipRef.current.getBoundingClientRect()
      const moveLeft = 144 - (width / 2) + 24 - x
      const moveRight = 144 - (width / 2) + 24 - windowWidth + x + width
      if (moveRight > 0) setTranslateX(-moveRight)
      else if (moveLeft > 0) setTranslateX(moveLeft)
      else setTranslateX(0)
    }
  }, [windowWidth])

  return <TooltipWrapper className="group" tabIndex={0}>
    <div ref={tooltipRef}>
      <FontAwesomeIcon tw="text-gray-5" icon={faInfoCircle} />
    </div>
    <div
      className="speech-bubble"
      tw="absolute z-10 -left-36 bottom-0 flex flex-col items-center invisible mb-11 w-72 group-hover:visible"
      style={{ transform: `translateX(${translateX}px)` }}
    >
      <div tw="relative py-2 px-4 text-14 bg-white shadow-xl border border-gray-4 rounded-lg font-normal">
        {tooltip}
      </div>
    </div>
    <div
      className="speech-tail"
      tw="absolute bottom-0 w-3 h-3 margin-bottom[38px] z-10
        transform rotate-45 bg-white border-b border-r border-gray-4 invisible group-hover:visible "
    />
  </TooltipWrapper>
}

export default Tooltip
