import React from "react"
import { faCheck, faChevronDown, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import tw from "twin.macro"
import Tooltip from "../general/Tooltip"
import { sortByIndex } from "../section/ComparisonTable"
import { isUndefined } from "../../utils/typeGuards"

interface TBody {
  _uid: string
  body: {
    _uid: string
    value: string
  }[]
}

interface TableCategoryProps {
  readonly category: {
    name: string
    rows: TBody[]
  }
  readonly indexes: number[]
  readonly isOpen: boolean
  readonly openCategory: () => void
  readonly closeCategory: () => void
  readonly isFirst: boolean
}

interface CellProps {
  hasTop?: boolean
  isFilled?: boolean
}

const ButtonCell = styled.th<CellProps>(({ hasTop }) => [
  tw`bg-clip-padding  sticky left-0 bg-white border border-t-0 border-gray-5  min-width[220px]`,
  hasTop && tw`border-t`,
])

const TD = styled.td<CellProps>(({ isFilled, hasTop }) => [
  tw`w-40 py-4 text-center border-b border-r border-gray-5 last-of-type:border-r`,
  isFilled && tw`bg-white-blue-2`,
  hasTop && tw`border-t`,
])

const RenderCheck: React.FC<{ readonly value: string }> = ({ value }) => {
  if (value === "TRUE") return <FontAwesomeIcon tw="text-blue" icon={faCheck} />
  if (value === "FALSE") return <FontAwesomeIcon tw="text-gray-3" icon={faTimes} />
  return <>{value}</>
}

const TableCategory: React.FC<TableCategoryProps> = ({
  category: { name, rows }, indexes, isOpen, openCategory, closeCategory, isFirst,
}) => {
  const columns = [...Array(6).keys()]

  return <tbody>
    <tr>
      <ButtonCell hasTop={isFirst}>
        <button className="group" tw="cursor-pointer select-none p-4 w-full h-full" onClick={isOpen
          ? closeCategory
          : openCategory}>
          <span tw="group-hocus:text-blue mr-2 font-medium">{name}</span>
          <FontAwesomeIcon tw="text-gray-4" icon={faChevronDown} />
        </button>
      </ButtonCell>
      {columns.map((x, i) => <TD key={x} isFilled={(i < 2)} hasTop={isFirst} />)}
    </tr>
    {isOpen && <>
      {rows.map((row) => <tr key={row._uid}>
        {sortByIndex(row.body, indexes).map((item, i) => {
          if (isUndefined(item) || isUndefined(item.value)) {
            return <TD tw="text-red font-bold" key={i}>MISSING VALUE</TD>
          }
          if (i === 0) {
            return <TD key={item._uid} tw="px-4 sticky left-0 bg-white bg-clip-padding border-l" isFilled={false}>
              <div tw="flex justify-between text-left items-center">
                {item.value}
                {row.body[1].value && <Tooltip tooltip={row.body[1].value} />}
              </div>
            </TD>
          }
          if (i === 1) return null
          return <TD key={item._uid} isFilled={(i < 4)}>
            <RenderCheck value={item.value} />
          </TD>
        })}
      </tr>)}
    </>}
  </tbody>
}

export default TableCategory
