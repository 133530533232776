import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"
import tw, { styled } from "twin.macro"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useLocale from "../../hooks/useLocale"
import { handlePromise } from "../../utils/handlePromise"
import Button from "./Button"
import Spinner from "./Spinner"
import { FancyInput } from "./MaterialInput"

interface Props {
  readonly layoutStyle: "hero" | "register"
  readonly customCta?: string
  readonly buttonText?: string
  readonly inputSubtitle?: string
  readonly emailErrorMessage?: string
}

const Input = styled.input<{ allowInvalid: boolean }>(
  ({ allowInvalid }) => [allowInvalid && tw`invalid:focus:border-red`],
)

const RegisterForm: React.FC<Props> = ({ layoutStyle, customCta, inputSubtitle, buttonText, emailErrorMessage }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [allowInvalid, setAllowInvalid] = useState(false)
  const { placeholder_text, button_text, error_message } = useGlobalSettings()
  const { locale } = useLocale()

  const [emailInputValue, setEmailInputValue] = useState("")
  const [showError, setShowError] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  useEffect(() => {
    if (errorMessage.length > 0 && !isTyping) setShowError(true)
    else setShowError(false)
  }, [errorMessage, isTyping])

  useEffect(() => {
    setIsTyping(true)
    const timeOutId = setTimeout(() => setIsTyping(false), 1000)
    return () => clearTimeout(timeOutId)
  }, [emailInputValue])

  let ctaText = customCta && customCta.length > 0
    ? customCta
    : button_text

  if (buttonText) {
    ctaText = buttonText
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const body = []
    const geekAffiliate = Cookies.get("webinargeek_affiliate")
    const promoCode = Cookies.get("webinargeek_promo")
    const email = formData.get("email")
    if (!email) return
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    body.push(`${encodeURIComponent("account_lead[email]")}=${encodeURIComponent(email.toString())}`)
    if (geekAffiliate) body.push(`${encodeURIComponent("account_lead[affiliate_code]")}=${geekAffiliate}`)
    if (promoCode) body.push(`${encodeURIComponent("account_lead[discount_code]")}=${promoCode}`)
    body.push(`${encodeURIComponent("locale")}=${encodeURIComponent(locale === "default"
      ? "en"
      : locale)}`)

    setIsLoading(true)
    const res = await handlePromise(fetch("https://app.webinargeek.com/account/create_lead", {
      body: body.join("&"),
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
    }).then((response) => response.json() as Promise<{ direct_link: string } | { error: string }>))

    if (res.error) setErrorMessage(error_message)
    else if ("error" in res.data) setErrorMessage(res.data.error)
    else window.location.href = res.data.direct_link

    setIsLoading(false)
  }

  const validateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.target.value)
    const email = e.target.value
    const emailreg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/u
    if (email.length === 0) {
      setErrorMessage("")
    } else if (emailreg.test(email)) {
      setErrorMessage("")
      setAllowInvalid(false)
    } else {
      setErrorMessage(emailErrorMessage ?? "")
      setAllowInvalid(true)
    }
  }

  if (layoutStyle === "register") {
    return <div>
      <form
        tw="my-4 flex flex-col items-center"
        onSubmit={(e) => {
          void onSubmit(e)
        }}
      >
        <div tw="relative w-full laptop:max-w-[24rem] max-w-[80vw]">
          <FancyInput
            placeholder="geek@example.com"
            type="email"
            onChange={validateInput}
            error={showError}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            required
            name="email"
            value={emailInputValue}
            id="email-input"
          />
        </div>
        <p tw="w-full px-4 text-left text-blue-police laptop:max-w-[24rem] max-w-[80vw] text-14 mb-6 mt-1">
          {errorMessage && showError
            ? <span tw="text-red">{errorMessage}</span>
            : inputSubtitle}
        </p>
        <Button
          tw="mx-auto w-screen-80 laptop:(w-96)"
          variant="orange"
          size="sm"
          type="submit"
          disabled={isLoading}
        >
          {isLoading
            ? <Spinner size="small" />
            : <>{ctaText}</>}
        </Button>
      </form>
      {/* {errorMessage && <p tw="text-red mb-2 text-center ">{errorMessage}</p>} */}
    </div>
  }

  return <div>
    <form
      tw="flex flex-col laptop:(flex-row items-stretch) items-center
      space-y-6 laptop:space-y-0  w-full my-4 max-w-2xl mx-auto"
      onSubmit={(e) => {
        void onSubmit(e)
      }}
    >
      <Input
        allowInvalid={allowInvalid}
        tw="flex-1 border border-gray-5 rounded-[5px] pl-5 py-4 w-screen-80 text-black
        laptop:(w-auto rounded-r-none) placeholder:text-gray-3"
        placeholder={placeholder_text}
        type="email"
        name="email"
        required
      />
      <Button variant="orange" type="submit" disabled={isLoading} onClick={() => setAllowInvalid(true)}
        tw="laptop:(w-auto rounded-l-none) w-screen-80 text-16 px-8 height[58px]">
        {isLoading
          ? <Spinner size="small" />
          : <>{ctaText}</>
        }
      </Button>
    </form>
    {errorMessage && <p tw="text-red mb-2 text-center desktop:text-left">{errorMessage}</p>}
  </div>
}

export default RegisterForm
