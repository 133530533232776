import React from "react"
import { DocumentNode, Block, HeadingNode } from "@marvr/storyblok-rich-text-types"
import styled from "styled-components"
import tw from "twin.macro"
import { Link } from "react-scroll"
import usePageNav from "../../hooks/usePageNav"
import { ChapterNavWrapper, Chapter, ChapterLine } from "../general/PageNavChapterList"

interface Props {
  readonly content: DocumentNode
}

interface ChapterProps {
  state: string
}

const ChapterNav = styled.nav<ChapterProps>(({ state }) => [
  tw`hidden sticky top[168px] transition-transform duration-500 transform -translate-x-full desktop-xl:block pr-8`,
  state === "active" && tw`translate-x-0`,
])

const BlogChapterList: React.FC<Props> = ({ content }) => {
  const chapterList = content?.content?.filter((x): x is HeadingNode => x.type === Block.HEADING && x.attrs.level === 2)
  const { activeId, showMenu } = usePageNav("h2")

  return (
    <div tw="flex-1">
      <ChapterNavWrapper tw="max-width[calc((100vw - 820px)/2)]">
        <ChapterNav state={showMenu
          ? "active"
          : ""}>
          <ul tw="text-14">
            {chapterList?.map((heading, index) => {
              if (!heading.content) return null
              const text = heading.content.map((node) => node.text).join("")
              if (!text) return null
              let state = "unread"
              if (index === activeId) state = "current"
              if (index < activeId) state = "read"
              return <Chapter
                key={text}
                state={state}
              >
                <Link
                  to={heading.content[0].text}
                  smooth={true}
                  offset={-160}
                >
                  <ChapterLine state={state}>{`${text.slice(0, 64)}${text.length > 64
                    ? "..."
                    : ""}`}</ChapterLine>
                </Link>
              </Chapter>
            })}
          </ul>

        </ChapterNav>
      </ChapterNavWrapper>
    </div>
  )
}

export default BlogChapterList
