import React, { ReactElement } from "react"
import tw from "twin.macro"
import Container from "../general/Container"
import { H1, Subtitle } from "../typography/Typography"
import Image, { ImageType } from "../general/Image"
import { ButtonGroupStoryblok, VirtualBackgroundTagStoryblok } from "../../../component-types-sb"
import { getImageDimensions } from "../../utils/getImageDimensions"
import ButtonGroup from "../section/ButtonGroup"
import Tag from "../general/Tag"
import useBackgroundTags from "../../hooks/useBackgroundTags"

const ImageContainer = tw.div`
  flex-1 w-full laptop:order-last mb-12 hidden tablet:block
  [&[data-mobile-image="show"]]:(
    block
  )
`

interface Props {
  readonly tag?: VirtualBackgroundTagStoryblok
  readonly title: string
  readonly description?: string
  readonly image?: ImageType
  readonly buttons?: ButtonGroupStoryblok[]
  readonly featuredBackgrounds?: ReactElement[]
  readonly backgroundsLeft?: boolean
}

const VirtualBackgroundHeader: React.FC<Props> = ({
  tag, title, description, image, buttons, featuredBackgrounds, backgroundsLeft: switched,
}) => {
  const { width } = getImageDimensions(image)
  const defaultWidth = 584
  const tags = useBackgroundTags()
  const matchingTags = tags?.filter((t) => t.title === tag?.title)
  const currentTag = matchingTags?.[0]

  return (
    <Container tw="bg-blue-prussian text-white py-20">
      <div tw="grid grid-cols-1 laptop:grid-cols-2 gap-x-6 max-w-container-large items-center">
        <div tw="flex flex-col items-start">
          {tag && <Tag tw="mx-auto tablet:mx-0" href={`/${currentTag.full_slug}`}>{tag.title}</Tag>}
          <H1 tw="text-center w-full tablet:text-left mb-0">{title}</H1>
          {description && <Subtitle tw="text-center tablet:text-left">{description}</Subtitle>}
          <div tw="flex-1" />
          {buttons && buttons.length > 0
          && <ButtonGroup data-align-button-container="left" blok={buttons[0]} />}
        </div>
        <ImageContainer
          data-mobile-image={tag && "show"}
          style={switched
            ? { order: -1 }
            : { marginTop: "12px", order: 9999 }}
        >
          {image?.filename && <Image
            tw="rounded-md"
            image={image}
            fluid={Math.min(defaultWidth, width)}
            lazy={false} showPlaceholder={false} />}
          {featuredBackgrounds && <div tw="grid grid-cols-1 tablet:grid-cols-2 gap-y-8 gap-x-6">
            {featuredBackgrounds}
          </div>}
        </ImageContainer>
      </div>
    </Container>
  )
}

export default VirtualBackgroundHeader
