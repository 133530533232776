import { SuccessStory } from "../components/general/CustomerSuccessStoriesGrid"
import { useGlobalSettings } from "../contexts/GlobalSettingsContext"
import useRegion from "./useRegion"

const useLocalizedCSStories = () => {
  const {
    css_stories_default,
    css_stories_us,
    css_stories_nordics,
    css_stories_dach,
  } = useGlobalSettings()

  const region = useRegion()

  const localizedStories = {
    dach: css_stories_dach,
    default: css_stories_default,
    nordics: css_stories_nordics,
    us: css_stories_us,
  }

  return localizedStories[region] as SuccessStory[]
}

export default useLocalizedCSStories
