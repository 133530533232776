import React from "react"
import useScrollPercentage from "../../hooks/useScrollPercentage"

interface Props {
  readonly containerRef: React.RefObject<HTMLDivElement>
}

const ScrollProgress: React.FC<Props> = ({ containerRef }) => {
  const { scrollPercentage } = useScrollPercentage(containerRef)

  return (
    <div tw="h-1 bg-white w-full">
      <div tw="bg-blue left-0 h-full" style={{ width: `${scrollPercentage}%` }} />
    </div>
  )
}

export default ScrollProgress
