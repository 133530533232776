/* eslint-disable max-len */
// Because of SVG
import React from "react"
import { RegisterPageStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"

interface CompanyStarReviewProps {
  readonly logo: RegisterPageStoryblok["capterra_logo"]
}

const CompanyStarReview = ({ logo }: CompanyStarReviewProps) => <div tw="w-full flex justify-center items-center flex-col">
  <svg xmlns="http://www.w3.org/2000/svg" width="137" height="23" viewBox="0 0 137 23" fill="none">
    <path d="M11.5884 0.274353L14.1174 8.05771H22.3013L15.6803 12.8681L18.2093 20.6514L11.5884 15.8411L4.96748 20.6514L7.49644 12.8681L0.875526 8.05771H9.05943L11.5884 0.274353Z" fill="#F2C94C" />
    <path d="M40.0454 0.274353L42.5744 8.05771H50.7583L44.1374 12.8681L46.6663 20.6514L40.0454 15.8411L33.4245 20.6514L35.9535 12.8681L29.3326 8.05771H37.5165L40.0454 0.274353Z" fill="#F2C94C" />
    <path d="M68.5025 0.274353L71.0314 8.05771H79.2153L72.5944 12.8681L75.1234 20.6514L68.5025 15.8411L61.8815 20.6514L64.4105 12.8681L57.7896 8.05771H65.9735L68.5025 0.274353Z" fill="#F2C94C" />
    <path d="M96.9595 0.274353L99.4885 8.05771H107.672L101.051 12.8681L103.58 20.6514L96.9595 15.8411L90.3386 20.6514L92.8675 12.8681L86.2466 8.05771H94.4305L96.9595 0.274353Z" fill="#F2C94C" />
    <path d="M125.417 0.274353L127.945 8.05771H136.129L129.508 12.8681L132.037 20.6514L125.417 15.8411L118.796 20.6514L121.325 12.8681L114.704 8.05771H122.888L125.417 0.274353Z" fill="#F2C94C" />
  </svg>
  <Image image={logo} fluid={70} width="70px" showPlaceholder={false} lazy={false} tw="mt-3" />
</div>

export default CompanyStarReview
