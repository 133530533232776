import React from "react"
import styled from "styled-components"
import tw, { TwStyle } from "twin.macro"

interface Props extends React.ComponentPropsWithoutRef<"input"> {
  readonly wrapperTw?: TwStyle
  readonly allowInvalid?: boolean
}

const InputComponent = styled.input<{ allowInvalid?: boolean }>(({ allowInvalid }) => [
  tw`w-full px-3 py-2 placeholder-transparent border rounded-[5px] border-gray-4`,
  allowInvalid && tw`invalid:focus:(border-red sibling:text-red)`,
])

// eslint-disable-next-line react/no-unknown-property
const Input: React.FC<Props> = ({ wrapperTw, ...rest }) => <label tw="relative" css={[wrapperTw]}>
  <InputComponent className="peer" {...rest} />
  <span
    tw="absolute transition transform left-2 text-gray-3 text-16 top-2.5 scale-75
    -translate-y-5.75 bg-white px-1 origin-left
    peer-placeholder-shown:(scale-100 translate-y-0 bg-transparent)
    peer-focus:(scale-75 -translate-y-5.75 text-blue bg-white) pointer-events-none
  ">
    {rest.placeholder}
  </span>
</label>

export default Input
