import { graphql, PageProps } from "gatsby"
import React from "react"
import { PricingPageStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import PricingPage from "../components/templates/PricingPage"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

const PageTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: PricingPageStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <PricingPage blok={content} />
  </BaseTemplate>
}

export default PageTemplate

export const query = graphql`
  query PricingPageQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
