import { graphql, PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { AppInfoPageStoryblok } from "../../component-types-sb"
import useStoryblok from "../hooks/useStoryblok"
import AppInfoPage from "../components/templates/AppInfoPage"
import "../fonts/fonts.css"
import { GQLStory } from "../lib/baseStoryTemplate"

const AppInfoTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: AppInfoPageStoryblok }

  return <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <AppInfoPage blok={content} />
  </>
}

export default AppInfoTemplate

export const query = graphql`
  query AppInfoPageQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
