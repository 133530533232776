import React from "react"
import { graphql, PageProps } from "gatsby"
import { VirtualBackgroundTagStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"
import VirtualBackgroundTag from "../components/templates/VirtualBackgroundsTag"

interface GQLResult extends GQLStory {
  tags: {
    nodes: {
      full_slug: string
      content: string
      lang: string
    }[]
  }
}

const VirtualBackgroundTagTemplate: React.FC<PageProps<GQLResult>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: VirtualBackgroundTagStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <VirtualBackgroundTag blok={content} />
  </BaseTemplate>
}

export default VirtualBackgroundTagTemplate

export const query = graphql`query VirtualBackgroundTagQuery($slug: String!, $lang: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  tags: allStoryblokEntry(
    filter: {field_component: {eq: "virtualBackgroundTag"}, lang: {eq: $lang}}
    sort: {first_published_at: DESC}
  ) {
    nodes {
      content
      full_slug
      lang
    }
  }
}`
