import { useState, useEffect, useRef } from "react"

type ElementType = "section" | "h2"

const usePageNav = (elType: ElementType): { activeId: number, showMenu: boolean } => {
  const [activeId, setActiveId] = useState<number>(-1)
  const [showMenu, setShowMenu] = useState(false)
  const navElementsRef = useRef({})

  useEffect(() => {
    const navElements = Array.from(document.querySelectorAll(elType))
      .filter((el) => el.id !== "")

    const callback = (elements: IntersectionObserverEntry[]) => {
      // Create a map of navigation elements by their id
      navElementsRef.current = elements.reduce((map, navElement) => {
        map[navElement.target.id] = navElement
        return map
      }, navElementsRef.current)

      // Create an array of all elements that are visible (intersecting) on the page
      const visibleElements = Object.keys(navElementsRef.current).reduce((a: IntersectionObserverEntry[], key) => {
        const navElement = navElementsRef.current[key] as IntersectionObserverEntry
        if (navElement.isIntersecting) a.push(navElement)
        return a
      }, [])

      // Utility function for getting the position of a element in the list from it's id
      const getIndexFromId = (id: string) => navElements.findIndex((element) => element.id === id)

      // If just one element then that is the one. If more than one, set the one further up on the list
      if (visibleElements.length === 1) {
        setActiveId(getIndexFromId(visibleElements[0].target.id))
      } else if (visibleElements.length > 1) {
        // eslint-disable-next-line etc/no-assign-mutated-array
        const sortedVisibleElements = visibleElements.sort((a, b) => (
          getIndexFromId(a.target.id) - getIndexFromId(b.target.id)))
        setActiveId(getIndexFromId(sortedVisibleElements[0].target.id))
      }

      // Show the menu if at least one element is visible
      if (visibleElements.length > 0) setShowMenu(true)
    }

    // Set up observer and obsever all navigation elements with an id
    const observer = new IntersectionObserver(callback, {
      rootMargin: "-150px 0px -40% 0px",
    })

    navElements.forEach((el) => observer.observe(el))

    // Clean up observer on unmount
    return () => observer.disconnect()
  }, [elType])

  return { activeId, showMenu }
}

export default usePageNav
