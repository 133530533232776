import { faChevronDown } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { Link as SpyLink } from "react-scroll"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import SbEditable from "storyblok-react"
import { PageNavItemStoryblok } from "../../../component-types-sb"
import usePageNav from "../../hooks/usePageNav"

const Wrapper = tw.div`
  sticky top-[70px]
  bg-white text-gray-3
  text-14
  z-20
  shadow
  h-11 w-full
  px-4
  tablet:px-6
  [&[data-bg-color="dark_blue"]]:(
    bg-surface text-white
    shadow-blue-dark
  )
  [&[data-stick-to-top="true"]]:(
    top-0
  )
`

const DesktopMenu = tw.div`
  hidden
  laptop:flex
  justify-center items-stretch
  space-x-8
  h-full
`

const MobileMenu = tw.div`
  laptop:hidden
  relative
  h-full
  flex items-center
`

const MenuItems = tw(Menu.Items)`
  absolute top-full left-0
  z-50
  bg-white shadow
  p-1
  rounded
  w-fit
  focus:outline-none
  focus-visible:outline-black
  [[data-bg-color="dark_blue"] &]:(
    bg-surface shadow-blue-dark
  )
`

const MenuButton = tw(Menu.Button)`
  hocus:text-blue focus:outline-none focus-visible:outline-black
  [[data-bg-color="dark_blue"] &]:(
    hocus:text-surface-on
  )
`

type ActiveColor = "blue" | "surface"

const StyledSpyLink = tw(SpyLink)`
  flex items-center
  rounded
  cursor-pointer
  px-2 py-1
  laptop:(
    rounded-none
    px-2
  )
  [&[data-active-color="surface"]]:(
    statelayer before:bg-surface-on
    [&[data-is-active="true"]]:(
      before:opacity-[0.16]
    )
  )
`

const MenuItem = styled.button<{ active: boolean, activeColor: ActiveColor }>(({ active, activeColor }) => [
  tw`block w-full px-2 py-1 text-left rounded`,
  active && activeColor === "blue" && tw`text-white bg-blue`,
])

const Text = styled.span<{ active: boolean, activeColor: ActiveColor }>(({ active, activeColor }) => [
  (active && activeColor === "blue") && tw`text-blue`,
  (active && activeColor === "surface") && tw`text-surface-on`,
])

interface Props {
  readonly pageNav: PageNavItemStoryblok[]
  readonly color?: "white" | "dark_blue"
  readonly stickToTop: boolean
}

const PageNav: React.FC<Props> = ({ pageNav, color, stickToTop }) => {
  const { activeId } = usePageNav("section")
  const activeColor = color === "dark_blue"
    ? "surface"
    : "blue"

  return (
    <Wrapper data-bg-color={color} data-stick-to-top={stickToTop}>
      <MobileMenu>
        <Menu>
          <MenuButton>
            Sections
            <FontAwesomeIcon tw="ml-2" icon={faChevronDown} />
          </MenuButton>
          <MenuItems>
            {pageNav.map((item, index) => <Menu.Item key={item.anchor}>
              {({ active }) => <MenuItem active={active} activeColor={activeColor}>
                <StyledSpyLink
                  to={item.anchor}
                  offset={-114}
                  smooth
                  data-is-active={active}
                  data-active-color={activeColor}
                >
                  <Text active={activeId === index && !active} activeColor={activeColor}>{item.text}</Text>
                </StyledSpyLink>
              </MenuItem>
              }
            </Menu.Item>)}
          </MenuItems>
        </Menu>
      </MobileMenu>
      <DesktopMenu>
        {pageNav.map((item, index) => {
          const active = activeId === index
          return <SbEditable content={item} key={item._uid}>
            <StyledSpyLink
              to={item.anchor}
              offset={-114}
              smooth
              data-is-active={active}
              data-active-color={activeColor}
            >
              <Text active={active} activeColor={activeColor}>{item.text}</Text>
            </StyledSpyLink>
          </SbEditable>
        })}
      </DesktopMenu>
    </Wrapper>
  )
}

export default PageNav
