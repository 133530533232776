import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { H5 } from "../typography/Typography"
import CardBase from "./CardBase"

interface Props {
  readonly title: string
  readonly fullSlug: string
}

const CategoryCard: React.FC<Props> = ({ title, fullSlug }) => <div tw="relative px-5 py-4 ">
  <Link className="group" to={rewriteSlug(fullSlug)}>
    <CardBase tw="absolute inset-0" />
    <div tw="flex justify-between items-center">
      <H5 as="h3" tw="my-0 group-hocus:text-blue">{title}</H5>
      <div tw="text-28">
        <FontAwesomeIcon tw="group-hocus:(animate-bounce-x)" icon={faArrowRight} />
      </div>
    </div>
  </Link>
</div>

export default CategoryCard
