import React from "react"
import { graphql, PageProps } from "gatsby"
import { VirtualBackgroundsIndexStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import VirtualBackgroundsIndex from "../components/templates/VirtualBackgroundsIndex"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

interface GQLResult extends GQLStory {
  backgrounds: {
    nodes: {
      full_slug: string
      content: string
      lang: string
    }[]
  }
}

const VirtualBackgroundsIndexTemplate: React.FC<PageProps<GQLResult>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: VirtualBackgroundsIndexStoryblok }
  return <BaseTemplate
    content={content}
    story={data.story}
  >
    <VirtualBackgroundsIndex blok={content} />
  </BaseTemplate>
}

export default VirtualBackgroundsIndexTemplate

export const query = graphql`query VirtualBackgroundsIndexQuery($slug: String!, $lang: String) {
  story: storyblokEntry(full_slug: {eq: $slug}) {
    ...BaseStoryQuery
  }
  backgrounds: allStoryblokEntry(
    filter: {field_component: {eq: "background"}, lang: {eq: $lang}}
    sort: {first_published_at: DESC}
  ) {
    nodes {
      content
      full_slug
      lang
    }
  }
}`
