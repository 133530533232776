import { graphql, PageProps } from "gatsby"
import React from "react"
import { RegisterPageStoryblok } from "../../component-types-sb"
import useStoryblok from "../hooks/useStoryblok"
import RegisterPage from "../components/templates/RegisterPage"
import BaseTemplate from "../components/general/BaseTemplate"
import { GQLStory } from "../lib/baseStoryTemplate"

const RegisterTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: RegisterPageStoryblok }
  return <BaseTemplate story={data.story} content={content} renderLayout={false}>
    <RegisterPage blok={content} />
  </BaseTemplate>
}

export default RegisterTemplate

export const query = graphql`
  query RegisterPageQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
