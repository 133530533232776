import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import Placeholder from "../general/Placeholder"

export const H1 = tw.h1`my-6 font-normal font-heading text-35 tablet:text-40 laptop:text-45`
export const H2 = tw.h2`mb-5 font-normal mt-7 font-heading text-26 tablet:text-30 laptop:text-40`
export const H3 = tw.h3`mb-4 mt-6 font-normal font-heading text-23 tablet:text-24 laptop:text-34`
export const H4 = tw.h4`mb-4 mt-5 font-normal font-heading text-21 tablet:text-22 laptop:text-26`
export const Subtitle = tw.p`mb-4 mt-5 font-light font-heading text-21 tablet:text-22 laptop:text-26`
export const H5 = tw.h5`font-normal mt-5 mb-4 text-20 laptop:text-23`
export const H6 = tw.h6`font-normal mt-5 mb-4 text-18`
export const P = tw.p`mb-4 text-18 font-light`
export const Strong = tw.strong`font-bold text-gray-1`
export const B = tw.b`font-normal`
export const A = styled.a(tw`cursor-pointer text-blue hocus:(underline)`)
export const StyledLink = styled(Link)`${tw`text-blue hocus:(underline)`}`
export const BlockQuote = tw.blockquote`text-18 text-blue font-normal italic`

interface Props extends React.ComponentPropsWithoutRef<"h1" | "h2" | "h3" | "h4" | "h5" | "h6"> {
  readonly level: number
}

export const Heading: React.FC<Props> = ({ level, children, ...props }) => {
  const headings = {
    1: H1,
    2: H2,
    3: H3,
    4: H4,
    5: H5,
    6: H6,
  }

  const isValidHeadingLevel
    = (key: number): key is keyof typeof headings => typeof headings[key as keyof typeof headings] !== "undefined"
  if (isValidHeadingLevel(level)) {
    const Component = headings[level]
    return <Component {...props}>{children}</Component>
  }

  return <Placeholder componentName={level.toString()} />
}
