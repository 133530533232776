import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import styled from "styled-components"
import { TabbedContentNewItemStoryblok } from "../../../component-types-sb"
import { H6, P } from "../typography/Typography"
import { useWindowSize } from "../../hooks/useWindowSize"
import { getImageDimensions } from "../../utils/getImageDimensions"
import LinkResolver from "./Link"
import Image from "./Image"

interface Props {
  readonly blok: TabbedContentNewItemStoryblok
  readonly active: boolean
  readonly setSelectedItemIndex: (_index: number) => void
  readonly index: number
}

interface StyledTabProps {
  active: boolean
}

const StyledTab = styled.div<StyledTabProps>(({ active }) => [
  tw`px-0 py-3 text-left rounded shadow-none transition-shadow desktop-xl:(px-6)`,
  active && tw`desktop-xl:(cursor-default shadow-hover)`,
  !active && tw`desktop-xl:(cursor-pointer hocus:shadow)`,
])

const TabbedContentItemNew: React.FC<Props> = ({ blok, active, index, setSelectedItemIndex }) => {
  const { width: windowWidth } = useWindowSize()
  const { width: imageWidth } = getImageDimensions(blok.picture)

  const selectTab = () => {
    setSelectedItemIndex(index)
  }

  return (<SbEditable content={blok}>
    <StyledTab as={windowWidth < 1024
      ? "div"
      : "button"} active={active} onClick={selectTab}>
      <div tw="w-full desktop-xl:hidden mb-4">
        <Image
          image={blok.picture}
          tw="h-full"
          fit="cover"
          height="100%"
          width="100%"
          fluid={Math.min(672, imageWidth)}
        />
      </div>

      <H6 as="p" tw="mt-0 mb-1 font-light">{blok.title}</H6>
      <P tw="mb-2 text-gray-2">{blok.description}</P>
      {blok.link && blok.link.length > 0 && <p>
        <LinkResolver url={blok.link[0].url} styling="textlink">
          {blok.link[0].text}
        </LinkResolver>
      </p>}
    </StyledTab>
  </SbEditable>)
}

export default TabbedContentItemNew
