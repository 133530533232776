import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { MediaTextStoryblok } from "../../../component-types-sb"
import { Richtext } from "../general/Richtext"
import Container from "../general/Container"
import DynamicComponent from "../general/DynamicComponent"
import classNames from "../../utils/classNames"

const Wrapper = tw.div`
  grid grid-cols-1 grid-auto-flow[dense]
  desktop:grid-template-columns[4fr 3fr]
  max-w-container-large w-full
  gap-x-16 gap-y-8
  my-10
  [&[data-media-text~="image-below"]]:(
    [> *]:(
      first:row-start-2
      desktop:first:row-auto
    )
  )
  [&[data-media-text~="image-right"]]:(
    desktop:(
      grid-template-columns[3fr 4fr]
      [> *]:first:col-start-2
    )
  )
  [&[data-media-text~="gap-none"]]:(
    gap-x-0
  )
  [&[data-no-text~="true"]]:(
    grid-rows-1
    desktop:grid-template-columns[1fr]
    my-0
    mt-10
  )

`

const TextWrapper = tw.div`
  self-center
  text-center desktop:text-align[revert]
`

interface Props {
  readonly blok: MediaTextStoryblok
}

const MediaText: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <Container>
      <Wrapper
        tw="[.section-dark-background &]:text-white"
        data-media-text={classNames([
          blok.image_right && "image-right",
          blok.gap_size,
          blok.image_below_mobile && "image-below",
        ])}
        data-no-text={blok.no_text}
      >
        {blok.media_block.length > 0 && <DynamicComponent blok={blok.media_block[0]} />}
        {!blok.no_text && <TextWrapper>
          <Richtext text={blok.text as DocumentNode} />
        </TextWrapper>}
      </Wrapper>
    </Container>
  </SbEditable>
)

export default MediaText
