import { getPlainText, Richtext } from "@storyofams/storyblok-toolkit"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { CustomerSuccessStoryStoryblok } from "../../component-types-sb"
import BaseTemplate from "../components/general/BaseTemplate"
import CustomerSuccessStory from "../components/templates/CustomerSuccessStory"
import useStoryblok from "../hooks/useStoryblok"
import { GQLStory } from "../lib/baseStoryTemplate"

const CustomerSuccessStoryTemplate: React.FC<PageProps<GQLStory>> = ({ data, location }) => {
  const { content } = useStoryblok(data.story, location) as { content: CustomerSuccessStoryStoryblok }
  const richResult = {
    "@context": "https://schema.org",
    "@type": "Article",
    about: content.intro,
    dateModified: data.story.published_at,
    datePublished: data.story.first_published_at,
    headline: content.title,
    image: content.image.filename,
    name: content.title,
    text: getPlainText(content.content as Richtext),
    url: data.story.full_slug,
  }

  return <BaseTemplate
    content={content}
    story={data.story}
    article
  >
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(richResult)}</script>
    </Helmet>
    <CustomerSuccessStory blok={content} />
  </BaseTemplate>
}

export default CustomerSuccessStoryTemplate

export const query = graphql`
  query CustomerQuery($slug: String!){
    story: storyblokEntry(full_slug: {eq: $slug}){
      ...BaseStoryQuery
    }
  }
`
