import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { YoutubeVideoSectionStoryblok, YoutubeVideoStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"

const VideoContainer = tw.div`
  max-w-container mx-auto w-full
  grid place-items-stretch
  aspect-ratio[16/9]
`

interface VideoProps {
  readonly url?: string
  readonly title?: string
}

const Video: React.FC<VideoProps> = ({ url, title }) => (
  <VideoContainer>
    {url
      // eslint-disable-next-line react/iframe-missing-sandbox
      ? <iframe
        src={url}
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        allowFullScreen
      />
      : <p>Needs a url</p>}
  </VideoContainer>
)

interface Props {
  readonly blok: YoutubeVideoStoryblok | YoutubeVideoSectionStoryblok
}

// Use this one for blog as no container
export const YoutubeVideoBlog: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <Video {...blok} />
  </SbEditable>
)

const YoutubeVideo: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <Container>
      <Video {...blok} />
    </Container>
  </SbEditable>
)
export default YoutubeVideo
