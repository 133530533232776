/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { H1, P } from "../typography/Typography"
import { CustomerSuccessStoryStoryblok } from "../../../component-types-sb"
import DefaultResolver from "../blogComponents/DefaultResolver"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Container from "../general/Container"
import { replaceCodeToHtml } from "../../utils/replaceCodeToHtml"
import { WistiaVideo } from "../general/WistiaEmbed"
import { GridComponent } from "../section/CardGrid"
import { isUndefined } from "../../utils/typeGuards"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Image from "../general/Image"
import { BunnyVideo } from "../general/BunnyEmbed"

interface Props {
  readonly blok: CustomerSuccessStoryStoryblok
}

const HeaderContent = tw.div`
  max-w-container-huge
  mx-auto
  w-full
  flex flex-row flex-wrap items-center gap-x-10
  [& > *:first-child]:flex[1 1 300px]
  [& > * + *]:flex[2 1 600px]
`

// eslint-disable-next-line complexity
const CustomerSuccessStory: React.FC<Props> = ({ blok }) => {
  const { customer_success_story_default_footer } = useGlobalSettings()
  const imageCount = blok.image_list?.length
  const { width: headerImageWidth } = getImageDimensions(blok.header_image)
  const defaultHeaderImageWIdth = 708

  return (
    <SbEditable content={blok} key={blok._uid}>
      <Container tw="w-full bg-blue-prussian text-white pb-12 pt-6 desktop:p-16 text-center desktop:text-left">
        <HeaderContent
          data-show-content={
            (!isUndefined(blok.wistia_url) && blok.wistia_url !== "")
        || (!isUndefined(blok.header_image) && blok.header_image?.filename !== null)
        || (!isUndefined(blok.bunny_id) && blok.bunny_id !== "")
          }
        >
          <div>
            <H1 tw="[span]:text-orange" dangerouslySetInnerHTML={{ __html: replaceCodeToHtml(blok.title) }} />
            <P tw="whitespace-pre-line">{blok.intro}</P>
          </div>
          <div hidden={!blok.wistia_url && !blok.header_image?.filename && !blok.bunny_id}>
            {blok.wistia_url && !blok.bunny_id && <WistiaVideo url={blok.wistia_url} />}
            {!blok.wistia_url && !blok.bunny_id && blok.header_image?.filename && <Image
              image={blok.header_image}
              fluid={Math.min(defaultHeaderImageWIdth, headerImageWidth)}
              lazy={false}
              showPlaceholder={false}
              tw="w-44 tablet:width[unset] rounded-2xl" />
            }
            {blok.bunny_id && <BunnyVideo bunnyId={blok.bunny_id} />}
          </div>
        </HeaderContent>
      </Container>
      <Container>
        {(!isUndefined(blok.image_list) && blok.image_list.length > 0)
        && <GridComponent
          columns={isUndefined(imageCount) || imageCount > 4
            ? 4
            : imageCount
          }
          tw="auto-cols-min items-center justify-items-center mt-10 gap-5"
        >
          {blok.image_list.map((image) => {
            const { width } = getImageDimensions(image)
            const defaultWidth = 218

            return <Image
              key={image.id}
              image={image}
              fluid={Math.min(defaultWidth, width)}
              lazy={false} showPlaceholder={false}
              tw="w-44 tablet:width[unset]" />
          })}
        </GridComponent>
        }
        <div tw="w-full max-w-blog-text mx-auto pt-4 pb-20 laptop:pt-8">
          <DefaultResolver content={blok.long_text as DocumentNode} />
          <DefaultResolver content={blok.footer_text as DocumentNode || customer_success_story_default_footer} />
        </div>
      </Container>
    </SbEditable>
  )
}

export default CustomerSuccessStory
