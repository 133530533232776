import { useState, useEffect } from "react"

interface TBody {
  _uid: string
  body: {
    _uid: string
    value: string
  }[]
}

interface Table {
  thead: {
    _uid: string
    value: string
  }[]
  tbody: TBody[]
}

const useGoogleSheets = (locale: "default" | "nl" | "de") => {
  const [data, setData] = useState<Table>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>()

  useEffect(() => {
    fetch(`/api/getComparisonTable?locale=${locale}`).then((response) => {
      if (response.ok) return response.json() as Promise<Table>
      throw Error(`Error fetching data, response code ${response.status}`)
    })
      .then((respData) => {
        setData(respData)
      })
      .catch((fetchError: unknown) => setError(fetchError))
      .finally(() => setLoading(false))
  }, [locale])

  return { data, error, loading }
}

export default useGoogleSheets
