import React from "react"
import SbEditable from "storyblok-react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import Flickity from "react-flickity-component"
import tw, { styled } from "twin.macro"
import { ProductSectionStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { H3 } from "../typography/Typography"
import { Richtext } from "../general/Richtext"
import LinkResolver from "../general/Link"
import Tag from "../general/Tag"
import { colors } from "../general/ProductCarouselFeature"
import ProductCard from "./ProductCard"

interface Props {
  blok: ProductSectionStoryblok
}

export const flickityStyles = tw`
  [.flickity-page-dot]:(bg-[#CEE7EF] h-3 w-3 hocus:shadow-[0px_0px_0px_4px_#00677C])
  [.flickity-page-dot.is-selected]:(bg-[#00677C] h-3 w-3)
  [.flickity-page-dots]:(-bottom-8)
  [.flickity-button]:(bg-transparent hidden)
  [.flickity-button:focus]:(shadow-[none])
  [.flickity-button:disabled]:(hidden)
  [.flickity-prev-next-button.next]:(right-2 z-10)
  [.flickity-prev-next-button.previous]:(left-2 z-10)
`

const FlickityContainer = styled.div(() => [
  tw` w-screen
  pr-4
  -ml-2
  col-start-1
  col-end-7
  relative
  laptop:hidden
  pb-8`,
  flickityStyles,
])

const TextWrapper = tw.div`
  row-start-2
  col-start-1  col-end-7
  laptop:(col-end-3 row-start-1 mr-0)
  ml-9 mr-9 mt-9 mb-0 laptop:mb-9
  flex flex-col
  justify-center
`

const Background = styled.div<{
  color: Props["blok"]["color"]
}>(() => [
  tw`w-full px-9 pt-9 pb-4 rounded-2xl laptop:col-span-2 col-span-6 flex flex-col justify-between`,
  ({ color = "100" }) => colors[(Number(color) / 100) - 1],
])

const ProductSection: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <div tw="grid grid-cols-6 max-w-container-large w-full laptop:gap-14 gap-6 laptop:px-8 px-4">
      <Background tw="col-start-1 laptop:row-start-1 row-start-2 col-end-7" color={blok.color} />
      <div tw="laptop:col-start-3 col-start-1 col-end-7 row-start-1 laptop:mb-0 -mb-12 py-4 laptop:pr-4">
        <Image tw="object-contain w-full h-full " image={blok.image} fluid={1000} fit="contain" />
      </div>
      <TextWrapper>
        <Tag>{blok.tag}</Tag>
        <H3 tw="font-medium">{blok.title}</H3>
        <Richtext text={blok.description as DocumentNode} />
        {blok.url && <LinkResolver url={blok.url} tw="flex items-center gap-2 font-bold text-18" className="group">
          {blok.url_text
            && <>
              {blok.url_text}
              <FontAwesomeIcon icon={faChevronRight} tw="group-hocus:animate-bounce-x duration-200" />
            </>
          }
        </LinkResolver>}
      </TextWrapper>
      {/* Laptop */}
      <div tw="laptop:flex col-start-1 col-end-7 gap-14 hidden">
        {blok.cards?.map((card) => <ProductCard key={card._uid} blok={card} />)}
      </div>
      {/* Mobile */}
      <FlickityContainer>
        <Flickity
          options={{
            pageDots: true,
            prevNextButtons: true,
            wrapAround: false,
          }}
        >
          {blok.cards?.map((card) => <div key={card._uid} tw="h-[min-content] w-11/12 px-2 py-1">
            <ProductCard key={card._uid} blok={card} />
          </div>)}
        </Flickity>
      </FlickityContainer>
    </div>
  </SbEditable>
)

export default ProductSection
