import React, { createContext, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ChildrenProp } from "../../react"

interface BunnyContext {
  hashed_id: string
  thumbnailUrl: string
  duration: number
}

interface QueryResult {
  allBunnyMedia: {
    nodes: BunnyContext[]
  }
}

const BunnyInfoContext = createContext<BunnyContext[]>([])

const BunnyInfoProvider: React.FC<ChildrenProp> = ({ children }) => {
  const data = useStaticQuery<QueryResult>(graphql`
    query{
      allBunnyMedia{
        nodes{
          hashed_id
          thumbnailUrl
          duration
        }
      }
    }
  `)

  return <BunnyInfoContext.Provider value={data.allBunnyMedia.nodes}>
    {children}
  </BunnyInfoContext.Provider>
}

export const useBunnyInfo = (): BunnyContext[] => {
  const context = useContext(BunnyInfoContext)
  if (!context) throw new Error("Only call useBunnyInfo inside a BunnyInfoProvider")
  return context
}

export default BunnyInfoProvider
