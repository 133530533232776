import React, { Fragment } from "react"
import SbEditable from "storyblok-react"
import styled from "styled-components"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons"
import { ContentCardStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import { H5 } from "../typography/Typography"
import LinkResolver from "../general/Link"
import { getImageDimensions } from "../../utils/getImageDimensions"
import Popup from "../general/Popup"
import { isEmptyString } from "../../utils/typeGuards"
import CardBase from "./CardBase"

interface ContentCardBlok extends ContentCardStoryblok {
  isIntegrationCard?: boolean
  isProductCard?: boolean
  isChallengeTicked?: "ticked" | "unticked" | "current"
}

interface Props {
  readonly blok: ContentCardBlok
}

const CardTitle = styled(H5)<{ blue_title?: boolean }>(({ blue_title }) => [blue_title && tw`text-blue`])

const CardText = styled.p<{ text_size: "normal" | "smaller" }>(({ text_size }) => [
  tw`mt-4 mb-2.5 font-light whitespace-pre-line`,
  text_size === "normal" && tw`text-18`,
  text_size === "smaller" && tw`text-16`,
])

const GridItemWrapper = styled.div<{ size: string }>(({ size }) => [
  tw`
    relative
    flex flex-col
    bg-white
    [[data-item-style="rounded"] &]:(
      rounded-3xl
    )
    [[data-item-background-color="bright_gray"] &]:bg-gray-bright
  `,
  size.includes("icon") && tw`p-2.5`,
  size.includes("sm") && tw`p-4`,
  size.includes("md") && tw`p-7`,
  size.includes("lg") && tw`p-7`,
  size.includes("full") && tw`p-7 laptop:(flex-row) items-center`,
])

const TextContainer = styled.div<{ alignment: "left" | "center" }>(({ alignment }) => [
  tw`relative`,
  alignment === "center" && tw`text-center`,
  alignment === "left" && tw`text-left`,
])

const ImageWrapper = styled.div<{
  alignment: "left" | "center"
  isIntegrationCard?: boolean
  isProductCard?: boolean
}>(({ alignment, isIntegrationCard, isProductCard }) => [
  tw`flex flex-shrink-0 w-full`,
  alignment === "center" && tw`justify-center`,
  alignment === "left" && tw`justify-start`,
  isIntegrationCard && tw`max-h-10 max-width[200px]`,
  isProductCard && tw`max-h-36`,
])

// eslint-disable-next-line complexity
const ContentCard: React.FC<Props> = ({ blok }) => {
  const { width } = getImageDimensions(blok.image)

  const imageAlignment = isEmptyString(blok.image_alignment)
    ? "center"
    : blok.image_alignment

  const textAlignment = isEmptyString(blok.text_alignment)
    ? "center"
    : blok.text_alignment

  const CardLinkInside = () => (
    <Fragment>
      <CardBase tw="absolute inset-0" />
      {blok.image?.filename && <ImageWrapper
        alignment={imageAlignment}
        isIntegrationCard={blok.isIntegrationCard}
        isProductCard={blok.isProductCard}
      >
        <Image fit="contain" image={blok.image} fluid={700} {...(width && { width })} />
      </ImageWrapper>}
      <TextContainer tw="flex-1 mb-3" alignment={textAlignment}>
        {blok.title && <CardTitle blue_title={blok.blue_title} tw="w-full group-hocus:text-blue">
          {blok.title}
        </CardTitle>}
        {blok.text && <CardText tw="mb-6" text_size={blok.text_size}>{blok.text}</CardText>}
        {(blok.isChallengeTicked === "ticked") && <div tw="absolute top-4 right-0 text-blue text-24">
          <FontAwesomeIcon icon={faCheckCircle} />
        </div>}
      </TextContainer>
      {!blok.remove_arrow && <div tw="text-28 absolute bottom-4 right-8">
        <FontAwesomeIcon tw="group-hocus:(animate-bounce-x)" icon={faArrowRight} />
      </div>}
    </Fragment>
  )

  if (blok.popup && blok.popup.length === 1) {
    return (
      <SbEditable content={blok}>
        <GridItemWrapper
          as={Popup}
          blok={blok.popup[0]}
          className="group"
          size={blok.style}
          tw="cursor-pointer"
        >
          <CardLinkInside />
        </GridItemWrapper>
      </SbEditable>
    )
  }

  if (
    !blok.url
    || (blok.url?.linktype === "story" && !blok.url.id)
    || blok.isChallengeTicked === "current"
    || blok.isChallengeTicked === "unticked"
  ) {
    return (
      <SbEditable content={blok}>
        <GridItemWrapper size={blok.style} tw="shadow">
          {blok.style === "full"
            ? <>
              {blok.image?.filename && <div tw="laptop:(width[200px] flex-shrink-0 flex justify-center)">
                <Image image={blok.image} fluid={200} {...(width && { width })} />
              </div>}
              <div tw="pl-0 laptop:pl-5 text-left w-full">
                {blok.title && <CardTitle blue_title={blok.blue_title} tw="text-left">{blok.title}</CardTitle>}
                {blok.text && <CardText text_size={blok.text_size} tw="text-left ">{blok.text}</CardText>}
              </div>
            </>
            : <>
              {blok.image?.filename && <ImageWrapper alignment={imageAlignment}>
                <Image image={blok.image} fluid={700} {...(width && { width })} />
              </ImageWrapper>}
              <TextContainer alignment={textAlignment}>
                {blok.title && <CardTitle blue_title={blok.blue_title}>{blok.title}</CardTitle>}
                {blok.text && <CardText text_size={blok.text_size}>{blok.text}</CardText>}
                {blok.isChallengeTicked === "current" && <div tw="absolute top-4 right-0 text-blue text-24">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>}
              </TextContainer>
            </>
          }
        </GridItemWrapper>
      </SbEditable>
    )
  }

  return (
    <SbEditable content={blok}>
      <GridItemWrapper
        as={LinkResolver}
        url={blok.url}
        styling="none"
        className="group"
        size={blok.style}
        tw="cursor-pointer"
      >
        <CardLinkInside />
      </GridItemWrapper>
    </SbEditable>
  )
}

export default ContentCard
