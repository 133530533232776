import React from "react"
import SbEditable from "storyblok-react"
import tw from "twin.macro"
import IframeResizer from "iframe-resizer-react"
import { IframeStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"

interface Props {
  readonly blok: IframeStoryblok
}

const EmbedContainer = tw.div`
  max-w-container mx-auto w-full
  grid place-items-center
`

const Iframe: React.FC<Props> = ({ blok }) => (
  <SbEditable content={blok}>
    <Container>
      <EmbedContainer>
        <IframeResizer
          aria-label={blok.label}
          src={blok.url}
          heightCalculationMethod="lowestElement"
          seamless
          style={{
            ...blok.width.length > 0 && blok.width === "full"
              ? { width: "100%" }
              : { maxWidth: "100%" },
          }}
        />
      </EmbedContainer>
    </Container>
  </SbEditable>
)

export default Iframe
