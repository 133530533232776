import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import SbEditable from "storyblok-react"
import { HelpVideoStoryblok, VideoGridStoryblok } from "../../../component-types-sb"
import useLocale from "../../hooks/useLocale"
import { VideoCard } from "../cards/VideoCard"
import Container from "../general/Container"

interface QueryResult {
  allStoryblokEntry: {
    videos: HelpVideoStoryblok[]
  }
}

interface Props {
  readonly blok: VideoGridStoryblok
}

const VideoGrid: React.FC<Props> = ({ blok }) => {
  const { allStoryblokEntry: { videos } } = useStaticQuery<QueryResult>(graphql`query allVideos {
  allStoryblokEntry(
    filter: {field_component: {eq: "helpVideo"}}
    sort: {first_published_at: ASC}
  ) {
    videos: nodes {
      uuid
      lang
      title: field_title_string
      subtitle: field_subtitle_string
      bunny_id: field_bunny_id_string
      full_slug
    }
  }
}`)
  const { locale } = useLocale()
  const localizedVideos = videos.filter((video) => video.lang === locale)

  return <SbEditable content={blok}>
    <Container>
      <div
        tw="grid w-full grid-cols-1 laptop:grid-cols-2 my-4
          desktop:grid-cols-3 gap-4 tablet:gap-8 max-w-container-large"
      >
        {localizedVideos.map((video) => <VideoCard
          key={video.uuid}
          content={{ ...video }}
          full_slug={video.full_slug} />)}
      </div>
    </Container>
  </SbEditable>
}

export default VideoGrid
