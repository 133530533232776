import React from "react"
import SbEditable from "storyblok-react"
import { graphql, useStaticQuery } from "gatsby"
import { HelpVideoStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"
import { H1, Subtitle } from "../typography/Typography"
import { WistiaVideo } from "../general/WistiaEmbed"
import CardList from "../general/CardList"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useLocale from "../../hooks/useLocale"
import ClientOnly from "../general/ClientOnly"
import { randomOrder } from "../../utils/randomOrder"
import { BunnyVideo } from "../general/BunnyEmbed"

interface Props {
  readonly blok: HelpVideoStoryblok
}

interface QueryResult {
  allStoryblokEntry: {
    otherVideos: {
      uuid: string
      full_slug: string
      lang: string
      content: string
      field_component: string
      title: string
    }[]
  }
}

const HelpVideo: React.FC<Props> = ({ blok }) => {
  const { recommended_videos_text } = useGlobalSettings()
  const { locale } = useLocale()
  const { allStoryblokEntry: { otherVideos } } = useStaticQuery<QueryResult>(graphql`
    query allStoryblokVideos{
      allStoryblokEntry(
        filter: {
          field_component: {eq: "helpVideo"}
        }
      ){
        otherVideos: nodes {
          uuid
          full_slug
          lang
          content
          field_component
          title: field_title_string
        }
      }
    }
  `)

  const localeVideos = otherVideos.filter((video) => video.lang === locale && video.title !== blok.title)
  const recommendedVideos = randomOrder(localeVideos, blok._uid).slice(0, 3)

  return (
    <SbEditable content={blok}>
      <Container>
        <div tw="max-w-container-large w-full py-20">
          <div tw="mb-8 text-center max-w-container mx-auto">
            <H1>{blok.title}</H1>
            <Subtitle>{blok.subtitle}</Subtitle>
          </div>
          {!blok.bunny_id && <WistiaVideo url={blok.wistia_url} />}
          {blok.bunny_id && <BunnyVideo bunnyId={blok.bunny_id} />}
          <div tw="mb-16" />
          <ClientOnly>
            <CardList name={recommended_videos_text} cards={recommendedVideos} />
          </ClientOnly>
        </div>
      </Container>
    </SbEditable>
  )
}

export default HelpVideo
