import React from "react"
import { YoutubeVideoSectionStoryblok } from "../../../component-types-sb"
import YoutubeVideo from "./YoutubeVideo"

interface Props {
  readonly blok: YoutubeVideoSectionStoryblok
}

const YoutubeVideoSection: React.FC<Props> = ({ blok }) => (
  <YoutubeVideo blok={blok} />
)

export default YoutubeVideoSection
