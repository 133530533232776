import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import tw from "twin.macro"

const DisclosureIcon = tw.span`
  inline-block
  rotate-0
  transition-transform duration-300 ease-in-out
  leading-none
  [details[open] > summary &]:(rotate-90)
  [&[data-is-open="true"]]:(rotate-90)
`

interface Props {
  readonly isOpen?: boolean
}

const DetailsOpenIcon: React.FC<Props> = ({ isOpen }) => (
  <DisclosureIcon data-is-open={isOpen}>
    <FontAwesomeIcon icon={faChevronRight} />
  </DisclosureIcon>
)

export default DetailsOpenIcon
