import React from "react"
import { ChildrenProp } from "../../../react"

interface Props {
  readonly componentName?: string
}

const Placeholder: React.FC<ChildrenProp & Props> = ({ componentName, children }) => (
  <div tw="w-full py-4 border border-red-2 bg-red-3">
    <p tw="text-red italic text-center mb-0">
      {componentName
        ? <>The component <strong>{componentName}</strong> has not been created yet.</>
        : <>{children}</>
      }
    </p>
  </div>
)

export default Placeholder
