import React, { useEffect, useRef, useState } from "react"
import SbEditable from "storyblok-react"
import {
  BlogPostStoryblok,
  CategoryPageStoryblok,
  TagPageStoryblok,
} from "../../../component-types-sb"
import Container from "../general/Container"
import { H1, P } from "../typography/Typography"
import BlogCard from "../cards/BlogCard"
import Spinner from "../general/Spinner"
import BlogNav from "../blogComponents/BlogNav"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useLocale from "../../hooks/useLocale"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { StyledTag } from "../blogComponents/Tag"
import useBlogDateFormat from "../../hooks/useBlogDateFormat"
import {
  CardContainer,
  CardImage,
  CardSubtitle,
  CardTagContainer,
  CardTitle,
} from "../general/Card"

interface Props {
  readonly blok: CategoryPageStoryblok | TagPageStoryblok
  readonly stories?: {
    name: string
    full_slug: string
    content: string
  }[]
}

const initialLoadSize = 9

const CategoryPage: React.FC<Props> = ({ blok, stories = [] }) => {
  const [blogs, setBlogs] = useState([...stories.slice(0, initialLoadSize)])

  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(stories.length > initialLoadSize)
  const loadRef = useRef<HTMLDivElement>(null)
  const { min_text } = useGlobalSettings()
  const { locale } = useLocale()

  const handleObserver: IntersectionObserverCallback = (entities) => {
    const [target] = entities
    if (target.isIntersecting) setLoadMore(true)
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) observer.observe(loadRef.current)
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = blogs.length
      const isMore = currentLength < stories.length
      const nextResults = isMore
        ? stories.slice(currentLength, currentLength + initialLoadSize)
        : []
      setBlogs((prev) => [...prev, ...nextResults])
      setHasMore(isMore)
      setLoadMore(false)
    }
  }, [loadMore, hasMore, blogs, stories])

  let fpContent = blok.featured_post as BlogPostStoryblok

  if (!fpContent) {
    fpContent = {
      ...blogs[0],
      content: blogs[0] && JSON.parse(blogs[0].content),
    } as unknown as BlogPostStoryblok
  }

  const datePublished = useBlogDateFormat(
    fpContent.content
      ? fpContent.content.datePublished
      : "",
  )

  return (
    <SbEditable content={blok}>
      <div tw="sticky w-full top-17.5 z-20">
        <BlogNav title={blok.title} section="blogPost" level={1} />
      </div>
      <H1 tw="z-10 px-8 text-center">{blok.title}</H1>
      <Container tw="max-w-container-large pb-20">
        <div tw="grid grid-cols-1 laptop:grid-cols-2 desktop:grid-cols-3 gap-8 desktop:mt-8">
          {fpContent.content && (
            <CardContainer
              href={
                ["de"].includes(locale)
                  ? rewriteSlug(fpContent.full_slug?.slice(3))
                  : rewriteSlug(fpContent.full_slug)
              }
              size="wide"
            >
              <CardImage
                image={fpContent.content.coverImage}
                aspectratio="22/10"
              />
              <div tw="">
                <CardTagContainer>
                  <StyledTag className="shimmer">{blok.title}</StyledTag>
                  <CardSubtitle>
                    {`${datePublished} - ${fpContent.content.timeToRead ?? ""} ${min_text}`}
                  </CardSubtitle>
                </CardTagContainer>
                <CardTitle tw="line-clamp-2">
                  {fpContent.content.title}
                </CardTitle>
                <P tw="font-normal line-clamp-6 text-18!">
                  {fpContent.content.description}
                </P>
              </div>
            </CardContainer>
          )}
          {blogs.map((node) => {
            if (node.full_slug === fpContent.full_slug) return null
            const blogContent = JSON.parse(node.content) as BlogPostStoryblok
            return (
              <BlogCard
                key={node.full_slug}
                content={blogContent}
                slug={node.full_slug}
                tagList={[blok.title]}
              />
            )
          })}
        </div>
        <div tw="my-8 flex justify-center w-full" ref={loadRef}>
          {hasMore && <Spinner size="large" />}
        </div>
      </Container>
    </SbEditable>
  )
}

export default CategoryPage
