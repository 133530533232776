import { useCallback } from "react"
import { isUndefined } from "../utils/typeGuards"

export type Timezone = string | RegExp
const isTimezoneArray = (value: Timezone | Timezone[]): value is Timezone[] => Array.isArray(value)

const isTimezone = (timezone: Timezone) => {
  // eslint-disable-next-line new-cap
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  return tz.match(timezone) !== null
}

const useIsTimezone = () => useCallback((timezone: Timezone | Timezone[]) => {
  if (isUndefined(timezone)) return false
  if (isTimezoneArray(timezone)) return timezone.some((tz) => isTimezone(tz))
  return isTimezone(timezone)
}, [])

export default useIsTimezone
