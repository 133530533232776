import { Link } from "gatsby"
import React from "react"
import { BlogPostStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useBlogDateFormat from "../../hooks/useBlogDateFormat"
import useLocale from "../../hooks/useLocale"
import { rewriteSlug } from "../../utils/rewriteSlug"
import { StyledTag } from "../blogComponents/Tag"
import {
  CardContainer, CardImage, CardSubtitle, CardTagContainer, CardText, CardTitle,
} from "../general/Card"

interface Props {
  content: BlogPostStoryblok
  slug: string
  tagList?: string[]
}

const BlogCard: React.FC<Props> = ({ content, slug, tagList }) => {
  const { min_text } = useGlobalSettings()
  const datePublished = useBlogDateFormat(content.datePublished)
  const { locale } = useLocale()

  return (
    <Link
      className="group"
      to={["de"].includes(locale)
        ? rewriteSlug(slug.slice(3))
        : rewriteSlug(slug)}
      tw="flex flex-col p-4 rounded-[12.5px] bg-blue-sky hover:shadow duration-300 outline-none"
    >
      <CardContainer>
        <CardImage image={content.coverImage} aspectratio="22/10" />
        {tagList && <CardTagContainer>
          {tagList.map((tag, index) => (
            <StyledTag key={index} className="shimmer">
              {tag}
            </StyledTag>
          ))}
        </CardTagContainer>}
        <CardSubtitle>
          {`${datePublished}${content.timeToRead
            ? ` - ${content.timeToRead} ${min_text}`
            : ""}`}
        </CardSubtitle>
        <CardTitle tw="line-clamp-2">{content.title}</CardTitle>
        <CardText tw="line-clamp-3">{content.description}</CardText>
      </CardContainer>
    </Link>

  )
}

export default BlogCard
