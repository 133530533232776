import React from "react"
import tw from "twin.macro"
import { usePricingData } from "../../contexts/PricingDataContext"

const Wrapper = tw.div`
  w-max
`

const CurrencyWrapper = tw.div`
  grid grid-cols-1
  divide-y divide-gray-5
  rounded-r-lg overflow-hidden
  bg-white-blue
  shadow-md
  tablet:shadow-none
`

const CurrencyButton = tw.button`
  bg-transparent text-blue-midnight
  before:bg-blue-police
  text-16
  font-medium
  px-4 py-2
  statelayer
  [&[data-selected="true"]]:(
    bg-blue text-white
  )
  [[data-theme='secondary'] &]:(
    statelayer
    [&[data-selected="true"]]:(
      bg-blue-police text-white
    )
  )
`

interface Props {
  readonly currencyLabel: string
}

const PricingCurrencySelector: React.FC<Props> = ({ currencyLabel }) => {
  const {
    currency, setCurrency, onlyShowUsd,
  } = usePricingData()

  if (onlyShowUsd) return null

  return (
    <Wrapper>
      <span tw="sr-only">{currencyLabel}</span>
      <CurrencyWrapper>
        <CurrencyButton onClick={() => setCurrency("EUR")} data-selected={currency === "EUR"}>EUR</CurrencyButton>
        <CurrencyButton onClick={() => setCurrency("USD")} data-selected={currency === "USD"}>USD</CurrencyButton>
        <CurrencyButton onClick={() => setCurrency("GBP")} data-selected={currency === "GBP"}>GBP</CurrencyButton>
        <CurrencyButton onClick={() => setCurrency("SEK")} data-selected={currency === "SEK"}>SEK</CurrencyButton>
        <CurrencyButton onClick={() => setCurrency("NOK")} data-selected={currency === "NOK"}>NOK</CurrencyButton>
        <CurrencyButton onClick={() => setCurrency("DKK")} data-selected={currency === "DKK"}>DKK</CurrencyButton>
      </CurrencyWrapper>
    </Wrapper>
  )
}

export default PricingCurrencySelector
