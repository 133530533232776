/* eslint-disable complexity */
import React from "react"
import { Helmet } from "react-helmet"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import useLocale from "../../hooks/useLocale"
import { rewriteSlug } from "../../utils/rewriteSlug"

interface Props {
  readonly description?: string
  readonly og_description?: string
  readonly og_image?: string
  readonly og_title?: string
  readonly title?: string
  readonly twitter_description?: string
  readonly twitter_image?: string
  readonly twitter_title?: string
  readonly article?: boolean
  readonly full_slug: string
  readonly default_full_slug: string
  readonly noindex?: boolean
  readonly nofollow?: boolean
  readonly translated_slugs: {
    path: string
    lang: string
  }[]
  readonly canonicals?: string[]
}

const Seo: React.FC<Props> = ({
  description = "",
  og_image = "",
  title = "",
  article = false,
  noindex = false,
  nofollow = false,
  canonicals,
  full_slug,
  default_full_slug,
  translated_slugs,
}) => {
  const { meta_data, site_url, twitter_username } = useGlobalSettings()
  const { locale } = useLocale()
  const seo = {
    description: description || meta_data.description,
    image: og_image || meta_data.og_image,
    title: title || meta_data.title,
    url: `${site_url}${rewriteSlug(full_slug)}`,
  }

  const organizationRichResult = {
    "@context": "https://schema.org",
    "@type": "Organization",
    logo: "https://a.storyblok.com/f/110864/640x640/751e539bb0/logo.png",
    url: "http://www.webinargeek.com",
  }

  const richResult = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    copyrightYear: "2015",
    description: seo.description,
    headline: seo.title,
    image: seo.image,
    inLanguage: locale === "default"
      ? "en"
      : locale,
    name: seo.title,
    url: seo.url,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate="%s | WebinarGeek"
      htmlAttributes={{ lang: locale === "default"
        ? "en"
        : locale }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {article
        ? <meta property="og:type" content="article" />
        : <meta property="og:type" content="website" />
      }
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitter_username && (
        <meta name="twitter:creator" content={twitter_username} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link rel="canonical" href={seo.url} />
      <link rel="alternate" hrefLang="x-default" href={`${site_url}${rewriteSlug(default_full_slug)}`} />
      <link rel="alternate" hrefLang="en" href={`${site_url}${rewriteSlug(default_full_slug)}`} />
      {translated_slugs.map(({ path, lang: translatedLang }) => {
        const correctPath = `${site_url}/${translatedLang}${rewriteSlug(path)}`
        return <link key={translatedLang} rel="alternate" hrefLang={translatedLang} href={correctPath} />
      })}
      <script type="application/ld+json">{JSON.stringify(organizationRichResult)}</script>
      {!article && <script type="application/ld+json">{JSON.stringify(richResult)}</script>}
      {noindex && <meta name="robots" content="noindex" />}
      {nofollow && <meta name="robots" content="nofollow" />}
      {canonicals?.map((canonical) => (
        <link key={canonical} rel="canonical" href={`${site_url}${rewriteSlug(canonical)}`} />
      ))}
    </Helmet>
  )
}

export default Seo
