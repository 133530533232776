import React from "react"
import SbEditable from "storyblok-react"
import { HeadingAnchorStoryblok } from "../../../component-types-sb"
import { Heading } from "../typography/Typography"

interface Props {
  readonly blok: HeadingAnchorStoryblok
}

const HeadingAnchor: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <Heading id={blok.id} level={Number(blok.level)}>{blok.heading}</Heading>
</SbEditable>

export default HeadingAnchor
