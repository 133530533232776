import { faQuoteLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import SbEditable from "storyblok-react"
import { m, useAnimation, Variants } from "framer-motion"
import tw from "twin.macro"
import { ReviewCarouselStoryblok, ReviewCompanyStoryblok, ReviewStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Container from "../general/Container"
import Image from "../general/Image"
import { H4, P } from "../typography/Typography"
import InViewWrapper from "../general/InViewWrapper"
import classNames from "../../utils/classNames"
import ClientOnly from "../general/ClientOnly"

const Wrapper = tw(InViewWrapper)`
  w-full py-8 my-4
  [&[data-review-card~="blue"]]:(
    bg-white-blue
  )
  [&[data-has-padding="false"]]:(
    py-0 my-0
  )
`

const cardVariants: Variants = {
  onscreen: {
    transition: {
      staggerChildren: 0.4,
    },
  },
}

const Card = tw(m.div)`
  flex flex-col items-center
  relative
  bg-white
  px-16 pt-14 pb-10
  border border-gray-5 rounded-xl
  max-w-container
  [[data-bg-color="dark-blue"] &]:(
    bg-blue-midnight text-white
    px-14 pt-6 pb-4
  )
`

const QuoteIconWrapper = tw.div`
  text-30
  text-blue
  mb-10
  [[data-bg-color="dark-blue"] &]:text-orange
  [[data-spacing="compact"] &]:mb-2
`

const textVariants: Variants = {
  offscreen: {
    opacity: 0,
    y: 50,
  },
  onscreen: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
    y: 0,
  },
}

const FramerH4 = m(H4)
const ReviewText = tw(FramerH4)`
  text-center
  mt-0 mb-12
  [[data-spacing="compact"] &]:(text-18 mb-2)
`

const ReviewerAvatar = tw.div`
  mb-1
`

const ReviewerInfo = tw(P)`
  text-14 font-normal mb-0
`

const ReviewPlatform = tw.a`
  mt-2
  [img]:(h-4)
`

const BadgeGrid = tw.div`
  grid grid-cols-2 tablet:grid-cols-4 gap-4 mt-8
  [img]:width[75px]
`

interface Props {
  readonly blok: ReviewCarouselStoryblok
}

const ReviewCard: React.FC<Props> = ({ blok }) => {
  const [selectedId, setSelectedId] = useState(-1)
  const { badges } = useGlobalSettings()

  useEffect(() => {
    setSelectedId(Math.floor(Math.random() * blok.reviews.length))
  }, [blok.reviews.length])

  const controls = useAnimation()

  useEffect(() => {
    controls.start("onscreen").catch((error: unknown) => console.error(error))
  }, [controls])

  if (selectedId === -1 || blok.reviews.length === 0) return null

  const { content: rawReview } = blok.reviews[selectedId] as { content: ReviewStoryblok }
  if (!rawReview) return null
  const { content: reviewCompany } = rawReview.review_company as unknown as { content: ReviewCompanyStoryblok }

  return <SbEditable content={blok}>
    <ClientOnly>
      <Wrapper
        data-review-card={classNames([blok.background_color])}
        data-has-padding={blok.padding}
        data-bg-color={blok.background_color}
        data-spacing={blok.spacing}
      >
        <Container tw="[[data-has-padding='false'] &]:(px-0 tablet:px-0)">
          <Card variants={cardVariants} animate={controls}>
            <QuoteIconWrapper>
              <FontAwesomeIcon icon={faQuoteLeft} />
            </QuoteIconWrapper>
            <ReviewText variants={textVariants} animate={controls}>
              {rawReview.quote}
            </ReviewText>
            <ReviewerAvatar>
              {rawReview.photoQuotee && <Image image={rawReview.photoQuotee} width={50} fixed={[50, 50]} />}
            </ReviewerAvatar>
            <ReviewerInfo tw="[[data-spacing='compact'] &]:font-bold">{rawReview.quotee}</ReviewerInfo>
            <ReviewerInfo>{rawReview.functionQuotee}</ReviewerInfo>
            {typeof reviewCompany !== "undefined" && (
              <ReviewPlatform href={reviewCompany.url} target="_blank" rel="noreferrer nofollow">
                <Image image={reviewCompany.logo} width={80} />
              </ReviewPlatform>
            )}
          </Card>
          {blok.show_badges && (
            <BadgeGrid>
              {badges.map((badge) => <Image key={badge._uid} image={badge.image} width="75" />)}
            </BadgeGrid>
          )}
        </Container>
      </Wrapper>
    </ClientOnly>
  </SbEditable>
}

export default ReviewCard
