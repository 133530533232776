import { faComments } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useChat } from "react-live-chat-loader"
import SbEditable from "storyblok-react"
import { OpenIntercomCardStoryblok } from "../../../component-types-sb"
import Container from "../general/Container"

interface Props {
  readonly blok: OpenIntercomCardStoryblok
}

const OpenIntercomCard: React.FC<Props> = ({ blok }) => {
  const [, loadChat] = useChat()
  return (
    <SbEditable content={blok}>
      <Container>
        <button
          className="group"
          tw="max-w-container w-full p-8 text-center shadow
            hover:shadow-hover focus-visible:outline-normal outline-none"
          onClick={() => loadChat({ open: true })}
        >
          <p tw="mb-4 text-blue group-hover:underline">
            <FontAwesomeIcon tw="mr-2" icon={faComments} />
            {blok.link_text}
          </p>
          <p>{blok.text}</p>
        </button>
      </Container>
    </SbEditable>
  )
}

export default OpenIntercomCard
