import React, { useState } from "react"
import { BackgroundStoryblok } from "../../../component-types-sb"
import { CtaComponent } from "../section/Cta"
import { FixedGridItemComponent } from "../general/FixedGridItem"
import { GridComponent } from "../section/CardGrid"
import { useVirtualBackgroundSettings } from "../../contexts/VirtualBackgroundSettingsContext"
import { H4 } from "../typography/Typography"
import Button from "../general/Button"
import HowToSection from "./HowToSection"
import BackgroundCard from "./BackgroundCard"

interface Props {
  readonly backgrounds: {
    content: BackgroundStoryblok
    full_slug: string
    lang: string
  }[]
  readonly hideFixedCard?: boolean
}

const calculateDividerInlineRowStart = (amountOfRows: number) => amountOfRows >= 2
  ? 2
  : amountOfRows
const calculateDividerBlockRowStart = (amountOfRows: number) => amountOfRows >= 5
  ? 5
  : amountOfRows + 1
const calculateInlineAlign = (
  emptyColsEnd: number,
  dividerInlineRowStart: number,
) => (emptyColsEnd < 2 && dividerInlineRowStart !== 2)
  ? "start"
  : "end"

const VirtualBackgroundGrid: React.FC<Props> = ({ backgrounds, hideFixedCard }) => {
  const {
    divider_inline_title, divider_inline_buttons, divider_inline_description,
    divider_block_title, divider_block_buttons, divider_block_description, index_no_backgrounds, index_load_more_button,
  } = useVirtualBackgroundSettings()

  const [loadedItems, setLoadedItems] = useState(22)

  // add 2 for inline divider
  const amountOfItems = backgrounds.length + 2
  const isLastRowFull = (amountOfItems % 4) === 0
  const amountOfRows = Math.ceil(amountOfItems / 4)
  const emptyColsEnd = backgrounds.length % 4
  const showCard = !hideFixedCard
  const showBackgrounds = backgrounds.length > 0
  const dividerInlineRowStart = calculateDividerInlineRowStart(amountOfRows)
  const dividerBlockRowStart = calculateDividerBlockRowStart(amountOfRows)
  const inlineAlign = calculateInlineAlign(emptyColsEnd, dividerInlineRowStart)

  return (
    <GridComponent spacing="normal" width="wide" columns={4}>
      {backgrounds.map((bg, i: number) => i < loadedItems && <BackgroundCard
        background={bg}
        key={`${bg.content._uid}_${i}_${bg.content.title}`}
      />)}
      {showCard && showBackgrounds
        && <>
          {backgrounds.length >= 6
          && <FixedGridItemComponent
            align={inlineAlign}
            colSpan="2"
            rowSpan={1}
            rowStart={dividerInlineRowStart}
            mobileHidden
          >
            <CtaComponent
              tw="laptop:rounded-lg rounded-none "
              title={divider_inline_title}
              subtitle={divider_inline_description}
              buttons={divider_inline_buttons}
            />
          </FixedGridItemComponent>}
          <FixedGridItemComponent
            align="start"
            colSpan="full"
            rowSpan={1}
            rowStart={dividerBlockRowStart}
            mobileOffset={1}
          >
            <CtaComponent
              tw="laptop:rounded-lg rounded-none"
              title={divider_block_title}
              subtitle={divider_block_description}
              buttons={divider_block_buttons}
            />
          </FixedGridItemComponent>
        </>}
      <FixedGridItemComponent align="start" colSpan="full" rowSpan={1} rowStart={amountOfRows === 6 && !isLastRowFull
        ? 8
        : 7}>
        <HowToSection />
      </FixedGridItemComponent>
      {!showBackgrounds
      && <FixedGridItemComponent align="start" colSpan="full" rowSpan={1} rowStart={dividerBlockRowStart}>
        <div tw="w-full flex justify-center"><H4>{index_no_backgrounds}</H4></div>
      </FixedGridItemComponent>}
      {loadedItems < amountOfItems
      && <div tw="w-full flex justify-center items-center desktop:col-span-4 tablet:col-span-2 col-span-1 pb-8">
        <Button variant="text" size="md" onClick={() => setLoadedItems((prev) => prev + 20)}>
          {index_load_more_button}
        </Button>
      </div>}

    </GridComponent>
  )
}

export default VirtualBackgroundGrid
