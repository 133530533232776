import { faStar } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { DocumentNode } from "@marvr/storyblok-rich-text-types"
import styled from "styled-components"
import tw from "twin.macro"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import { Richtext } from "./Richtext"

interface Props {
  readonly position: "left" | "center"
}

const RatingsTextWrapper = styled.div(() => [
  tw`ml-2`,
  {
    "> p": tw`mb-1`,
  },
])

const Wrapper = styled.div<Props>(({ position }) => [
  tw`flex items-center flex-col tablet:(flex-row)`,
  position === "left" && tw`justify-center desktop:justify-start`,
  position === "center" && tw`justify-center`,
])

const StarReveiw: React.FC<Props> = ({ ...rest }) => {
  const { reviews_text } = useGlobalSettings() as { reviews_text: DocumentNode }
  return <Wrapper {...rest}>
    <div tw="text-yellow text-18 mb-1">
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <FontAwesomeIcon icon={faStar} />
      <div tw="relative inline-block">
        <FontAwesomeIcon tw="text-gray-4" icon={faStar} />
        <div tw="absolute inset-0 w-3.25 overflow-hidden">
          <FontAwesomeIcon icon={faStar} />
        </div>
      </div>
    </div>
    <RatingsTextWrapper>
      <Richtext text={reviews_text} />
    </RatingsTextWrapper>
  </Wrapper>
}

export default StarReveiw
