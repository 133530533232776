import React from "react"
import SbEditable from "storyblok-react"
import { HelpVideoStoryblok, VideoCardStoryblok } from "../../../component-types-sb"
import { useBunnyInfo } from "../../contexts/BunnyInfoContext"
import { rewriteSlug } from "../../utils/rewriteSlug"
import {
  CardContainer, CardImage, CardSubtitle, CardText, CardTitle,
} from "../general/Card"

interface Props {
  readonly blok: VideoCardStoryblok
}

interface CardProps {
  readonly content: HelpVideoStoryblok
  readonly full_slug: string
}

export const VideoCard: React.FC<CardProps> = ({ content, full_slug }) => {
  const videos = useBunnyInfo()
  const video = videos.find((x) => x.hashed_id === content.bunny_id)

  if (!video) return null

  return <CardContainer size="default" href={rewriteSlug(full_slug)} tw="">
    <CardImage image={video.thumbnailUrl} aspectratio="16/9" />
    <CardSubtitle>{video.duration} min</CardSubtitle>
    <CardTitle tw="line-clamp-2">{content.title}</CardTitle>
    <CardText>{content.subtitle}</CardText>
  </CardContainer>
}

const VideoCardEditable: React.FC<Props> = ({ blok }) => {
  const { content, full_slug } = blok.video as unknown as CardProps
  return <SbEditable content={blok}>
    {content
      ? <VideoCard content={content} full_slug={full_slug} />
      : <div tw="text-center">Select a video</div>}
  </SbEditable>
}

export default VideoCardEditable
