import { GatsbyNode } from "gatsby"
import fetch from "node-fetch"
import { isUndefined } from "../utils/typeGuards"
import { Currency } from "../contexts/PricingDataContext"

export interface RatesResponse {
  success: boolean
  base: string
  date: string
  rates: Record<Currency, number>
}

export const getCurrencyRates = async () => {
  const data = await fetch("https://api.frankfurter.app/latest?to=USD,GBP,SEK,NOK,DKK")
    .then((res) => res.json())
    .then((json) => ({
      ...json,
      rates: {
        DKK: json.rates.DKK,
        EUR: 1,
        GBP: json.rates.GBP,
        NOK: json.rates.NOK,
        SEK: json.rates.SEK,
        USD: json.rates.USD,
      },
      success: !isUndefined(json.rates.GBP) && !isUndefined(json.rates.USD),
    }))
    .catch((error: unknown) => console.error(error)) as RatesResponse
  return data
}

export const sourceNodes: GatsbyNode["sourceNodes"] = async ({ actions, createNodeId, createContentDigest }) => {
  const { createNode } = actions

  const data = await getCurrencyRates()

  if (!data.success) throw new Error("Failed getting pricing data")

  const nodeMeta = {
    children: [],
    id: createNodeId("ratesData"),
    internal: {
      content: JSON.stringify(data.rates),
      contentDigest: createContentDigest(data.rates),
      mediaType: "text/html",
      type: "Rates",
    },
    parent: null,
  }

  const node = { ...data.rates, ...nodeMeta }
  createNode(node)
}
