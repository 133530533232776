import React, { useState, useEffect } from "react"

interface Output {
  scrollPercentage: number
}

const getScrollPercentage = (element: HTMLDivElement | null) => {
  if (element === null) return NaN
  const { top, height } = element.getBoundingClientRect()
  const windowHeight = window.innerHeight
  const elParent = element.offsetParent as HTMLElement
  const elOffsetTop = element.offsetTop || elParent.offsetTop
  const percentage = (-top - elOffsetTop) / (height - windowHeight - elOffsetTop) * 100
  if (percentage < 0) return 0
  if (percentage > 100) return 100
  return percentage
}

export default function useScrollPercentage(scrollRef: React.RefObject<HTMLDivElement>): Output {
  const [scrollPercentage, setScrollPercentage] = useState(NaN)

  useEffect(() => {
    const node = scrollRef.current
    const reportScroll = () => setScrollPercentage(getScrollPercentage(node))

    if (node !== null) window.addEventListener("scroll", reportScroll, { passive: true })

    return () => {
      if (node !== null) window.removeEventListener("scroll", reportScroll)
    }
  }, [scrollRef])

  return { scrollPercentage: Number.isNaN(scrollPercentage)
    ? 0
    : scrollPercentage }
}
