import React from "react"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { ButtonStoryblok } from "../../../component-types-sb"
import Button from "./Button"
import LinkResolver from "./Link"
import Popup from "./Popup"
import ClientOnly from "./ClientOnly"
import Icon from "./Icon"

interface LinkButtonProps extends React.ComponentPropsWithoutRef<"a"> {
  readonly button: ButtonStoryblok
}

const LinkButtonComponent: React.FC<LinkButtonProps> = ({ button, ...rest }) => {
  const icon = button.icon && button.icon.length > 0
    ? <Icon blok={button.icon[0]} variant={button.variant} />
    : null
  if (button.popup && button.popup.length > 0) {
    return (
      <Button
        as={Popup}
        blok={button.popup[0]}
        styling="none"
        {...(button.screen && { "data-screen": button.screen })}
        variant={button.variant}
        size={button.size}
        icon={Boolean(icon)}
        {...rest}>
        {icon}
        {button.text}
      </Button>
    )
  }

  if (button.variant === "text") {
    return (
      <LinkResolver
        {...(button.screen && { "data-screen": button.screen })}
        url={button.url}
        {...rest}
        className="group"
        styling="textlink">
        {button.text}
      </LinkResolver>
    )
  }

  return (
    <Button
      as={LinkResolver}
      {...(button.screen && { "data-screen": button.screen })}
      styling="none"
      url={button.url}
      variant={button.variant}
      size={button.size}
      icon={Boolean(icon)}
      {...rest}>
      {icon}
      {button.text}
    </Button>
  )
}

const LinkButtonWithQuery = (props: LinkButtonProps) => {
  const { url } = props.button
  const { search } = useLocation()
  const { email } = queryString.parse(search)
  if (url?.linktype !== "url") return <LinkButtonComponent {...props} />
  const buttonProps = typeof email === "string"
    ? {
      ...props.button,
      url: {
        ...props.button.url,
        cached_url: `${url.cached_url ?? ""}/${email}`,
        url: `${url.url ?? ""}/${email}`,
      },
    }
    : props.button
  return <LinkButtonComponent {...props} button={buttonProps} />
}

const LinkButton = (props: LinkButtonProps) => {
  const { propigateQueryParams } = props.button
  if (propigateQueryParams) {
    return <ClientOnly>
      <LinkButtonWithQuery {...props} />
    </ClientOnly>
  }
  return <LinkButtonComponent {...props} />
}

export default LinkButton
