import React from "react"
import SbEditable from "storyblok-react"
import {
  render,
} from "storyblok-rich-text-react-renderer-ts"
import { Block } from "@marvr/storyblok-rich-text-types"
import { TextStoryblok } from "../../../component-types-sb"
import { Heading, P } from "../typography/Typography"

interface Props {
  readonly blok: TextStoryblok
}

const Text: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div tw="mb-16 max-w-4xl text-center mx-6">
    {render(blok.Text, {
      nodeResolvers: {
        [Block.HEADING]: (children, { level }) => <Heading level={level}>{children}</Heading>,
        [Block.PARAGRAPH]: (children) => <P>{children}</P>,
      },
    })}
  </div>
</SbEditable>

export default Text
