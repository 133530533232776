import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import SbEditable from "storyblok-react"
import { PageCardStoryblok, PageStoryblok } from "../../../component-types-sb"
import { rewriteSlug } from "../../utils/rewriteSlug"
import Image from "../general/Image"
import { H5, P } from "../typography/Typography"
import Tag from "../general/Tag"
import { isUndefined } from "../../utils/typeGuards"
import CardBase from "./CardBase"

interface Props {
  readonly blok: PageCardStoryblok
}

// Currently only setup the styling to work with Integration card. Need to extend to for styling other pages too
const PageCard: React.FC<Props> = ({ blok }) => {
  const cardData = blok.page as unknown as { content: PageStoryblok, full_slug: string }

  if (!cardData) return <p tw="text-red-500 font-bold">Please add a card</p>
  if (isUndefined(cardData.content)) return null

  const { card_title: title, card_description: description, card_image: image } = cardData.content
  return <SbEditable content={blok}>
    <div
      className="group"
      tw="relative px-5 py-10 max-width[350px] laptop:max-w-none w-full mx-auto [&[data-has-tag='true']]:pt-3"
      data-has-tag={!isUndefined(blok.tag) && blok.tag !== ""}
    >
      <Link to={rewriteSlug(cardData.full_slug)}>
        <CardBase tw="absolute inset-0" />
        {blok.tag && <Tag tw="bg-yellow text-yellow-on">{blok.tag}</Tag>}
        <div tw="flex justify-between items-center space-x-4">
          {image && <Image tw="w-12 h-12" image={image} />}
          <div tw="flex-1 space-y-2.5">
            {title && <H5 as="p">{title}</H5>}
            {description && <P tw="text-16 text-gray-3">{description}</P>}
          </div>
        </div>
        <div tw="absolute top-1/2 -translate-y-1/2 right-4 text-28">
          <FontAwesomeIcon tw="group-hocus:(animate-bounce-x)" icon={faArrowRight} />
        </div>
      </Link>
    </div>
  </SbEditable>
}

export default PageCard
