import { graphql, useStaticQuery } from "gatsby"

import useLocale from "./useLocale"

export interface TagPage {
  full_slug: string
  title: string
  slug: string
  lang: string
}

interface QueryResult {
  tags: {
    nodes: TagPage[]
  }
}

const useBackgroundTags = (): TagPage[] => {
  const { locale } = useLocale()
  const data = useStaticQuery<QueryResult>(graphql`
    query allBackgroundTags {
      tags: allStoryblokEntry(filter: { field_component: { eq: "virtualBackgroundTag" } }) {
        nodes {
          lang
          full_slug
          title: field_title_string
          slug
        }
      }
    }
  `)
  return data.tags.nodes.filter((x) => x.lang === locale)
}

export default useBackgroundTags
