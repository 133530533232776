import React, { useState, useEffect } from "react"
import { ChildrenProp } from "../../../react"

const ClientOnly: React.FC<ChildrenProp> = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) return null

  return (
    <>
      {children}
    </>
  )
}

export default ClientOnly
