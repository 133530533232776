import { faFacebook, faInstagram, faLinkedin, faTwitter, IconDefinition } from "@fortawesome/free-brands-svg-icons"
import {
  faAward, faComment, faGlobe, faPhone, faTrophy, faCommentDots, faPhoneAlt,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

interface Props {
  readonly icon: string
}

const icons: Record<string, IconDefinition> = {
  award: faAward,
  comment: faComment,
  commentDots: faCommentDots,
  facebook: faFacebook,
  globe: faGlobe,
  instagram: faInstagram,
  linkedIn: faLinkedin,
  phone: faPhone,
  phoneMirror: faPhoneAlt,
  trophy: faTrophy,
  twitter: faTwitter,
}

const IconMarkdown: React.FC<Props> = ({ icon }) => {
  if (!Object.keys(icons).includes(icon)) return <span>Icon not found.</span>
  return (
    <FontAwesomeIcon tw="h-6 w-6! leading-8 mt-0.5" icon={icons[icon]} />
  )
}

export default IconMarkdown
