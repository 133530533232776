import { graphql, useStaticQuery } from "gatsby"

export interface TagPage {
  full_slug: string
  title: string
  slug: string
  lang: string
}

interface QueryResult {
  tags: {
    nodes: TagPage[]
  }
}

const useBackgroundPlatforms = (): TagPage[] => {
  const data = useStaticQuery<QueryResult>(graphql`
    query allBackgroundPlatforms {
      tags: allStoryblokEntry(filter: { field_component: { eq: "virtualBackgroundPlatform" } }) {
        nodes {
          lang
          full_slug
          title: field_title_string
          slug
        }
      }
    }
  `)
  return data.tags.nodes
}

export default useBackgroundPlatforms
