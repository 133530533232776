import React from "react"
import { ChallengeCardsStoryblok } from "../../../component-types-sb"
import { useGlobalSettings } from "../../contexts/GlobalSettingsContext"
import Grid from "./CardGrid"

interface Props {
  readonly blok: ChallengeCardsStoryblok
}

const getStatus = (index: number, number: number) => {
  if (index < number - 1) return "ticked"
  if (index === number - 1) return "current"
  return "unticked"
}

const ChallengeCards: React.FC<Props> = ({ blok }) => {
  const { challenge_cards } = useGlobalSettings()
  const [{ items }] = challenge_cards
  items.forEach((item, index) => {
    item.isChallengeTicked = getStatus(index, Number(blok.day_number))
  })
  return (
    <Grid blok={{ ...challenge_cards[0], items }} />
  )
}

export default ChallengeCards
