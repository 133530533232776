import React from "react"
import SbEditable from "storyblok-react"
import { ButtonStoryblok } from "../../../component-types-sb"
import LinkButton from "./LinkButton"

const RichTextButton: React.FC<ButtonStoryblok> = (props) => (
  <SbEditable content={props}>
    <div tw="mx-auto my-4 desktop:mx-0 w-fit">
      <LinkButton button={props} />
    </div>
  </SbEditable>
)

export default RichTextButton
