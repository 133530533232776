import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import SbEditable from "storyblok-react"
import { IntegrationCardStoryblok } from "../../../component-types-sb"
import Image from "../general/Image"
import LinkResolver from "../general/Link"
import { H5, P } from "../typography/Typography"
import CardBase from "./CardBase"

interface Props {
  readonly blok: IntegrationCardStoryblok
}

const IntegrationCard: React.FC<Props> = ({ blok }) => <SbEditable content={blok}>
  <div className="group" tw="relative px-5 py-10 max-width[350px] laptop:max-w-none w-full mx-auto">
    <LinkResolver url={blok.link}>
      <CardBase tw="absolute inset-0" />
      <div tw="flex justify-between items-center space-x-4">
        <Image tw="w-12 h-12" image={blok.logo} />
        <div tw="flex-1">
          <H5 as="h3" tw="mt-0 mb-2.5">{blok.title}</H5>
          <P tw="text-16 mb-0 text-gray-3">{blok.description}</P>
        </div>
      </div>
      <div tw="absolute top-1/2 -translate-y-1/2 right-4 text-28">
        <FontAwesomeIcon tw="group-hocus:(animate-bounce-x)" icon={faArrowRight} />
      </div>
    </LinkResolver>
  </div>
</SbEditable>

export default IntegrationCard
