import { useStaticQuery, graphql } from "gatsby"

export interface Response {
  allStoryblokEntry: {
      nodes: {
        full_slug: string
        alternates: {
          full_slug: string
        }[]
      }[]
  }
}

// Custom hook to fetch all data and filter by slug
const useAlternatesBySlug = (slug: string) => {
  const data: Response = useStaticQuery(
    graphql`
      query AllCategoryPages {
        allStoryblokEntry {
          nodes {
            full_slug
            alternates {
              full_slug
            }
          }
        }
      }
    `,
  )

  // Filter the data based on the provided slug
  const filteredData = data.allStoryblokEntry.nodes.filter((node) => node.full_slug === slug)

  // Assuming you want to return the first node's alternates, adjust as needed
  return { alternates: filteredData[0]?.alternates || [] }
}

export default useAlternatesBySlug
